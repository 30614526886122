angular.module('portfolio_web.api.user.documents.services', [])

.service('ApiUserDocumentsService', [
	'$rootScope',
	'$http',
	'$q',
	'$timeout',
	'$stateParams',
	'NwuiSession',
	'PORTFOLIO_WEBSERVER',
	function(
		$rootScope,
		$http,
		$q,
		$timeout,
		$stateParams,
		NwuiSession,
		PORTFOLIO_WEBSERVER
	) {

		this.getDocuments = function(query, curPage, pageSize) {
			var deferred = $q.defer()

			var page_data = {
				page_number: curPage,
				page_size: pageSize
			}

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/user/documents', {params : page_data}).then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise

		}

		this.getSignedUrl = function(document_id) {

			var deferred = $q.defer()

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/user/documents/' + document_id + '/signed_url').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise

		}

	}
]);