angular.module('portfolio_web.manage.config', [
	'ui.router'
])

.config([
	'$stateProvider', 
	'$locationProvider', 
	'$urlRouterProvider', 

	'NWUI_AUTH_ROLES',
	function(
		$stateProvider, 
		$locationProvider, 
		$urlRouterProvider, 

		NWUI_AUTH_ROLES
	) {

		var manage = {
			url : '/manage',
			templateUrl: 'portfolio_webserver/manage.html',
			controller : 'ManageController',
			abstract: true,
			data: {
				breadcrumb_label_i18n: 'nav.manage',
				breadcrumb_proxy: 'app.manage.password',
				auth_roles: [NWUI_AUTH_ROLES.user]
			},
			resolve: {
				ResolveResources: [
					'$q',
					'$stateParams',
					'ApiClientsService',
					function(
						$q,
						$stateParams,
						ApiClientsService
					) {
						var deferred = $q.defer();

						ApiClientsService.getResources().then(function(obj) {
							deferred.resolve(obj.data);
						}, function(e) {
							deferred.reject(e);
						});

						return deferred.promise;
					}
				]
			}
		}

		$stateProvider.state('app.manage', manage)

	}
]);