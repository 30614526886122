angular.module('portfolio_web.api.goals.services', [])

.service('ApiGoalsService', [
	'$rootScope', 
	'$http', 
	'$q', 
	'$timeout', 
	'$stateParams',
	'DATE_FORMAT',
	'PORTFOLIO_WEBSERVER',
	'CacheService',
	function(
		$rootScope, 
		$http, 
		$q, 
		$timeout, 
		$stateParams,
		DATE_FORMAT,
		PORTFOLIO_WEBSERVER,
		CacheService
	) {

		var self = this;

		this.getGoals = function(force) {
			var deferred = $q.defer()

			if(cache.get('getGoals') && force !== true)
				deferred.resolve(cache.get('getGoals'));
			else {
				$http.get(PORTFOLIO_WEBSERVER.app + '/api/goals/').then(function(obj){
					cache.set('getGoals', obj);
					deferred.resolve(obj)
				}, function(err){
					////console.log(err)
					deferred.reject(err)
				})
			}
	
			return deferred.promise;
			
		}
		
		this.getGoal = function(goal) {
			
			var deferred = $q.defer()
	
			$http.get(PORTFOLIO_WEBSERVER.app + '/api/goals/' + goal.id + '').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})
	
			return deferred.promise
			
		}
		
		this.getHoldings = function(goal_id) {
			
			var deferred = $q.defer()
	
			$http.get(PORTFOLIO_WEBSERVER.app + '/api/goals/' + goal_id + '/holdings-value').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})
	
			return deferred.promise
			
		}
		
		this.getTracking = function(goal) {
			
			var deferred = $q.defer()
	
			$http.get(PORTFOLIO_WEBSERVER.app + '/api/goals/' + goal.id + '/tracking').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})
	
			return deferred.promise
			
		}
		
		this.getAllocation = function(goal) {
			
			var deferred = $q.defer()
	
			$http.get(PORTFOLIO_WEBSERVER.app + '/api/goals/' + goal.id + '/allocation').then(function(obj) {
				deferred.resolve(obj)
				
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})
	
			return deferred.promise
			
		}
		
		this.getPerformance = function(goal) {
			
			var deferred = $q.defer()
	
			$http.get(PORTFOLIO_WEBSERVER.app + '/api/goals/' + goal.id + '/performance').then(function(obj) {
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})
	
			return deferred.promise
			
		}
		
		this.getPerformanceGraph = function(goal_id) {
			
			var deferred = $q.defer()
	
			$http.get(PORTFOLIO_WEBSERVER.app + '/api/goals/' + goal_id + '/performance-graph').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})
	
			return deferred.promise
			
		}
		
		this.getBalanceSeries = function(goal) {
			
			var deferred = $q.defer()
	
			$http.get(PORTFOLIO_WEBSERVER.app + '/api/goals/' + goal.id + '/balance-series').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})
	
			return deferred.promise
			
		}
		
		this.getAccounts = function(goal_id) {
			
			var deferred = $q.defer()
	
			$http.get(PORTFOLIO_WEBSERVER.app + '/api/goals/' + goal_id + '/accounts').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})
	
			return deferred.promise
			
		}
		
		this.setTarget = function(data) {
			
			var body = {
				description: data.description,
				target_amount: data.target_amount,
				yearly_contribution: data.yearly_contribution,
				target_date: data.target_date
			}
			
			var deferred = $q.defer()
	
			$http.post(PORTFOLIO_WEBSERVER.app + '/api/goals/' + data.goal_id + '/set-target', body).then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})
	
			return deferred.promise
			
		}
		
		this.createGoal = function(data) {
			
			var body = {
				description: data.description,
				target_amount: data.target_amount,
				yearly_contribution: data.yearly_contribution,
				target_date: data.target_date
			}
			
			var deferred = $q.defer()
	
			$http.post(PORTFOLIO_WEBSERVER.app + '/api/goals/', body).then(function(obj){
				cache.clear('getGoals');
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})
	
			return deferred.promise
			
		}
		
		this.splitGoal = function(data) {
			
			var body = {
				source_goal_id: data.source_goal_id,
				amount: data.amount
			}
			
			var deferred = $q.defer()
	
			$http.post(PORTFOLIO_WEBSERVER.app + '/api/goals/' + data.goal_id + '/funding', body).then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})
	
			return deferred.promise
			
		}

		var cache = CacheService.register(this);
		this.cache = cache;
	
	}

]);