angular.module('portfolio_web.transfers.overview.controllers', [])

.controller('TransfersOverviewController', [
	'DATE_FORMAT',
	'FundFactsService',
	'$q',
	'$scope',
	'$state',
	'$stateParams',
	'$timeout',
	// '$intercom',
	'$filter',
	'$rootScope',

	'NwuiCardHeaderFactory',
	'NwuiCardHeaderV2Factory',
	'NwuiAccordionFactory',
	'NwuiCardTableFactory',
	'NwuiCardTableV3Factory',
	'NwuiModalFactory',
	'NwuiModalV2Factory',
	'NwuiToastFactory',
	'NwuiFormV3Factory',
	'NwuiInputFactory',
	'NwuiButtonV3Factory',

	'NwuiSession',
	'NwuiInputService',
	'ApiGoalsService',
	'ApiAccountsService',
	'ApiTransfersService',
	'ApiClientsService',
	'ApiUserService',
	'EftService',
	'NwuiFormV3Service',
	'NwuiToastManagerService',
	'NwuiSigningService',
	'NwuiPageScrollService',
	'NWUI_SELECT_OPTIONS',
	'NwButtonService',
	'TransfersEftRequestService',

	'ResolveAccounts',
	'ResolveDestinationAccounts',
	'NWUI_AUTH_EVENTS',

	'NwuiTransferFrequencyFilter',

	'locale',
	function(
		DATE_FORMAT,
		FundFactsService,
		$q,
		$scope,
		$state,
		$stateParams,
		$timeout,
		// $intercom,
		$filter,
		$rootScope,

		NwuiCardHeaderFactory,
		NwuiCardHeaderV2Factory,
		NwuiAccordionFactory,
		NwuiCardTableFactory,
		NwuiCardTableV3Factory,
		NwuiModalFactory,
		NwuiModalV2Factory,
		NwuiToastFactory,
		NwuiFormV3Factory,
		NwuiInputFactory,
		NwuiButtonV3Factory,

		NwuiSession,
		NwuiInputService,
		ApiGoalsService,
		ApiAccountsService,
		ApiTransfersService,
		ApiClientsService,
		ApiUserService,
		EftService,
		NwuiFormV3Service,
		NwuiToastManagerService,
		NwuiSigningService,
		NwuiPageScrollService,
		NWUI_SELECT_OPTIONS,
		NwButtonService,
		TransfersEftRequestService,

		ResolveAccounts,
		ResolveDestinationAccounts,
		NWUI_AUTH_EVENTS,

		NwuiTransferFrequencyFilter,

		locale
	) {

		////console.log('TransfersController Loaded');

		var expand_timeout = undefined;

		var loadTransferRequests = function() {

			// $scope.cards.transfers.table.reload();

		}

		var changeSlide = function(slide_num) {

			$scope.current_slide = slide_num;
			$scope.slider_style = {
				left: (-100 * slide_num) + '%'
			}

		}

		var goToWithdrawalsPage = function() {
			$state.go('app.manage.withdrawals.list');
		}

		var goToTransferSourcesPage = function() {
			$state.go('app.manage.transfer_sources.list');
		}

		var loadGoal = function(goal,i,callback) {}

		//used to stop an animation before beginning another
		var transfer_detail_timeout = undefined;

		$scope.loaded = false;
		$scope.current_slide = 0;
		$scope.accounts_efts = [];
		$scope.transfer_detail_style = {};
		$scope.transfer_detail_selected = 0;
		$scope.data = {
			goToWithdrawalsPage: goToWithdrawalsPage,
			goToTransferSourcesPage: goToTransferSourcesPage,
			accounts: ResolveAccounts,
			user: NwuiSession.user,
			active_brokerage_transfer: undefined,
			active_eft_request: undefined,
			org_slug: org_slug,
			org: organization_data,
			locale: locale.getLocale(),
			goal_funding_account_id: $state.params.account_id,
			has_withdrawals: (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.withdrawals &&
				organization_data.port_data.withdrawals.enabled === true
			),
			has_withdrawals_tab: (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.withdrawals &&
				organization_data.port_data.withdrawals.ui && 
				organization_data.port_data.withdrawals.ui.tab === true
			),
			has_transfer_sources_card: lodashGet(organization_data, 'port_data.transfers.ui.sources', false),
			use_transfers_page: (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.transfers &&
				organization_data.port_data.transfers.create &&
				organization_data.port_data.transfers.create.version === 'page'
			),
			risk_profile_approval_required: (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.transfers &&
				organization_data.port_data.transfers.risk_profile &&
				organization_data.port_data.transfers.risk_profile.approval_required === true
			),
			filterRESPTransfers: lodashGet(organization_data, 'port_data.transfers.brokerage_transfers.filter_resp', false)
		}

		var getEfts = function() {

			var promises = [];

			if(!$scope.data.use_transfers_page) {
				promises = [
					EftService.getEftsAccounts(),
					ApiAccountsService.getBrokerageAccountDestinations()
				]
			}

			$q.all(promises).then(function(results) {

				if(promises.length > 0) {

					$scope.compounds.create_eft.accounts_efts = $scope.accounts_efts = results[0].data;
					$scope.accounts = ResolveAccounts;

					results[1].data.filter(function(account){
						return account.status !== "Closed" 
					}).forEach(function(account, i) {

						var account_type;

						if (account.slug) {
							var slug = 'nwui.account_types.' + account.slug + '.name';
							account_type = locale.isTranslated(slug)
								? locale.getString(slug)
								: account.account_type;
						} else {
							account_type = locale.getString('nwui.account_types.' + account.account_type + '.name') || account.account_type;
						}

						NwuiInputService.inputs['create-brokerage-transfer-form-input-account'].options.push({
							id: 'create-brokerage-transfer-form-input-account-'+i,
							text_i18n: 'nwui.common.input.destination_account.option',
							text_i18n_data: {
								accountType: account_type,
								accountId: account.custodian_id
							},
							value: account.id
						});
					})

				}

				$scope.cards.accounts.table.rows = ResolveAccounts.map(function(account,i) {

					var account_type;
					if (account.slug) {
						var slug = 'nwui.account_types.' + account.slug + '.name';
						account_type = locale.isTranslated(slug)
							? locale.getString(slug)
							: account.account_type;
					} else {			
						account_type = locale.getString('nwui.account_types.' + account.account_type + '.name') || account.account_type;
					}

					var disableTransfer = false;
					if ($scope.data.filterRESPTransfers) {
						disableTransfer = (
							['JointRESPFam', 'JointRESPIndividual', 'RESP', 'RESPFam'].indexOf(account.account_type) >= 0
							|| account.group_type === 'resp'
						);
					}

					if (account.status === "Closed") {
						disableTransfer = true;
					}

					return {
						columns: [{
							text: account.custodian_id
						},{
							text: account_type
						},{
							button: {
								id: 'eft-account-button-' + i,
								// text: 'Transfer',
								text_i18n: 'transfers.accounts.button',
								disabled: disableTransfer,
								stretch: true,
								size: 'short',
								color: '',
								onClick: function() {
									if($scope.data.use_transfers_page) {
										$state.go($scope.data.risk_profile_approval_required ? 'app.transfers.new.type.destination' : 'app.transfers.new.type.destination.form', { transfer_type: 'chequing-savings', account_id: account.id });
									} else {
										NwuiInputService.inputs['create-eft-form-input-to-account'].model = account.id;
										$scope.compounds.create_eft.open = true;
									}
								}
							}
						}]
					}

				});

			});

		}

		$scope.changeDetail = function(number) {
			var height = $('#transfer .details .content-details .content').eq($scope.transfer_detail_selected).height();
			$scope.transfer_detail_style = {
				'height': height + 'px'
			};
			$timeout(function() {
				$scope.transfer_detail_selected = number;
				var height = $('#transfer .details .content-details .content').eq(number).height();
				$scope.transfer_detail_style = {
					'height': height + 'px'
				};
			}, 0);
			$timeout.cancel(transfer_detail_timeout);
			$timeout(function() {
				$scope.transfer_detail_style = {};
			}, 1000);
		}

		$scope.toggleExpanded = function(e, item) {

			$timeout.cancel(expand_timeout);

			var delay;

			item.style = {
				height: ($(e.target).closest('.row').height() + $(e.target).closest('.outer').children('.sub').height()) + 'px'
			}

			if(!item.expanded) {
				item.expanded = true;
				delay = 10;
			} else {
				delete item.expanded;
				delay = 1000;
			}

			expand_timeout = $timeout(function() {
				delete item.style;
			}, delay);

		}

			$scope.current_slide = 0;
			$scope.slider_style = { left: 0 }
			$scope.changeSlide = changeSlide;

			$scope.nextSlide = function() {
					var slide = ($scope.current_slide+1)%2;
					changeSlide(slide);
			}

			$scope.prevSlide = function() {
					var slide = ($scope.current_slide-1+2)%2;
					changeSlide(slide);
			}

		$timeout(function() {
			$scope.loaded = true;
			if (NwuiInputService.inputs['create-eft-form-input-to-account'] && $scope.data.goal_funding_account_id) {
				NwuiInputService.inputs['create-eft-form-input-to-account'].model = $scope.data.goal_funding_account_id;
				$scope.compounds.create_eft.open = true;
			}
		}, 1000);
		//new


		$scope.modules = {
			efts_one_time: {
				resource: ApiUserService.getOneTimeFundingRequests,
				reload: undefined
			},
			efts_recurring: {
				resource: ApiUserService.getRecurringFundingRequests,
				reload: undefined
			},
			bts: {
				resource: ApiUserService.getRequestedBrokerageTransfers,
				reload: undefined
			}
		}

		$scope.cards = {
			empty: {
				button: new NwuiButtonV3Factory({
					// text: 'Back to Summary',
					text_i18n: 'transfers.empty.button',
					icon: {
						name: 'arrow_back'
					},
					onClick: function() {
						$state.go('app.summary');
					}
				})
			},
			info: {
				header: new NwuiCardHeaderV2Factory({
					// name: 'Transfer Funds',
					name_i18n: 'transfers.info.title',
					icon: {
						name: 'info'
					}
				}),
				withdrawals: {
					create: new NwuiButtonV3Factory({
						id:'new-withdrawal-button',
						text_i18n: 'transfers.info.button_withdrawal_new',
						color: '',
						size: '',
						stretch: true,
						onClick: function() {
							$state.go('app.manage.withdrawals.new');
						}
					}),
				},
				copy: {
					buttons: {
						eft: new NwuiButtonV3Factory({
							id: 'transfer-option-bill-pay-button',
							// text: 'Pre-Authorized Contribution',
							text_i18n: 'transfers.info.button_eft',
							color: '',
							size: '',
							stretch: true,
							onClick: function() {

								if($scope.data.use_transfers_page)
									$state.go('app.transfers.new.type', { transfer_type: 'chequing-savings' });
								else
									$scope.compounds.create_eft.open = true;

							}
						}),
						brokerage: new NwuiButtonV3Factory({
							id: 'transfer-option-eft-button',
							// text: 'Deposit From Bank Account',
							text_i18n: 'transfers.info.button_bt',
							color: '',
							size: '',
							stretch: true,
							onClick: function() {

								if($scope.data.use_transfers_page)
									$state.go('app.transfers.new.type', { transfer_type: 'investment' });
								else
									$scope.modals.brokerage_transfer_info.open = true;
							}
						})
					}
				}
			},
			sources: {
				header: new NwuiCardHeaderV2Factory({
					// name: 'Transfer Sources',
					name_i18n: 'transfers.sources.title',
					icon: {
						name: 'info'
					}
				}),
				copy: {
					buttons: {
						create: new NwuiButtonV3Factory({
							id: 'new-transfer-source-button',
							text_i18n: 'transfers.sources.button_transfer_sources_new',
							color: 'secondary',
							size: '',
							stretch: true,
							hollow: true,
							onClick: function() {
								$state.go('app.manage.transfer_sources.list', {modal: true});
							}
						})
					}
				}
			},
			accounts: {
				header: new NwuiCardHeaderV2Factory({
					// name: 'My Accounts',
					name_i18n: 'transfers.accounts.title',
					icon: {
						name: 'account_balance_wallet'
					}
				}),
				table: new NwuiCardTableFactory({
					name: 'bill-pay-table',
					// empty_text: 'no accounts found',
					empty_text_i18n: 'transfers.accounts.empty',
					columns: [{
						// name: 'Acct. #',
						name_i18n: 'transfers.accounts.col_0',
						directive: 'text',
						sortable: false,
						mobile_title: true
					},{
						// name: 'Acct. Type',
						name_i18n: 'transfers.accounts.col_1',
						directive: 'text',
						sortable: false
					},{
						name: '',
						directive: 'button',
						width: 120,
						sortable: false
					}],
					rows: []
				})
			},
			transfers: {
				header: new NwuiCardHeaderV2Factory({
					// name: 'Transfer Requests',
					name_i18n: 'transfers.requests.title',
					icon: {
						name: 'swap_horiz'
					}
				}),
				table: new NwuiCardTableV3Factory({
					id: 'transfer-history-table',
					resource: ApiClientsService.getTransferRequests,
					rowMap: function(transfer,i) {
						////console.log('TransfersController', 'transfers.table', 'rowMap', i, transfer);

						var button = {
							id: 'eft-requests-button-' + i,
							stretch: true,
							size: 'short',
							icon: {
								name: ''
							}
						}

						var status = '';

						//is bt and cancelled
						if(transfer.envelope && transfer.is_cancelled === 1) {

							button.icon.name = 'close';
							if($scope.data.has_transfer_sources_card) {
								button.color = 'status-cancel';
							} else {
								button.icon.color = 'alternate';
								// button.color = 'error';
								button.hollow = true;
								// button.text = 'Cancelled';
							}
							button.text_i18n = 'transfers.requests.status_cancelled';
							button.onClick = function() {}

							status = 'Cancelled';

						}

						//is eft and cancelled
						else if(transfer.cancelled === true) {

							if($scope.data.has_transfer_sources_card) {
								button.color = 'status_cancel';
							} else {
								button.icon.name = 'close';
								button.icon.color = 'alternate';
								button.hollow = true;
							}
							// button.color = 'error';
							// button.text = 'Cancelled';
							button.text_i18n = 'transfers.requests.status_cancelled';
							button.onClick = function() {}

							status = 'Cancelled';

						}

						//is bt and not signed
						else if(transfer.envelope && transfer.envelope.signatures.length === 0) {

							button.icon.name = 'gesture';
							// button.text = 'Submit';
							button.text_i18n = 'transfers.requests.status_signature_req';
							button.color = '';
							if($scope.data.has_transfer_sources_card) {
								button.color = 'status-pending'
							} 
							button.onClick = function() {

								$scope.data.active_brokerage_transfer = transfer;
								$scope.modals.pending_brokerage_signature.open = true;

							}

							status = 'Pending';

						//is complete
						} else if(transfer.reconciled || transfer.is_reconciled) {

							// button.hollow = true;
							button.icon.name = 'check';
							if($scope.data.has_transfer_sources_card) {
								button.icon.color = 'status-complete';
							} else {
								button.hollow = true;
							}
							// button.text = 'Done';
							button.text_i18n = 'transfers.requests.status_done';
							if(transfer.frequency != 'one time') {
								button.text_i18n = 'transfers.requests.status_done_active';
							}
							// button.color = 'done';
							button.onClick = function() {}

							status = 'Complete';

						//is in processing
						} else {

							// button.text = 'Processing';
							button.text_i18n = 'transfers.requests.status_pending';
							if($scope.data.has_transfer_sources_card) {
								button.color = 'status-pending'
							} else {
								button.icon.name = 'cached';
								button.color = 'warning';
							}
							button.onClick = function() {
								$scope.data.active_eft_request = transfer;
								$scope.modals.pending_transfers_info.open = true;
							}

							status = 'Processing';

							if(transfer.envelope) {
								$scope.modals.pending_transfers_info.body = "transfers.modal.processing.p1_bt";
							} else {
								$scope.modals.pending_transfers_info.body = "transfers.modal.processing.p1";
							}
						}

						return {
							columns: [{
								text: moment(transfer.createdAt).format(DATE_FORMAT.short)
							}, {
								text: transfer.financial_institution || transfer.EFT.bank_info
							}, {
								text: transfer.Account ? transfer.Account.account_type : ''
							}, {
								text: (transfer.EFT) ? transfer.amount ? $filter('currency')(transfer.amount) : '--' : '',
								text_i18n: (transfer.EFT) ? undefined : 'transfers.requests.type_bt_amount'
							}, {
								text: transfer.frequency ? NwuiTransferFrequencyFilter(transfer.frequency) : '',
								text_i18n: transfer.frequency ? undefined : 'transfers.requests.frequency_ad_hoc'
							}, {
								button: button
							}]
						}

					},
					empty_text_i18n: 'cards.activities.table.empty_text_i18n',
					columns: [{
						// name: 'Date',
						name_i18n: 'transfers.requests.col_0',
						directive: 'text',
						sortable: false
					},{
						// name: 'Source',
						name_i18n: 'transfers.requests.col_1',
						directive: 'text',
						sortable: false
					},{
						// name: 'Source',
						name_i18n: 'transfers.requests.col_5',
						directive: 'text',
						sortable: false
					},{
						// name: 'Amount',
						name_i18n: 'transfers.requests.col_2',
						directive: 'text',
						sortable: false
					},{
						// name: 'Frequency',
						name_i18n: 'transfers.requests.col_3',
						directive: 'text',
						sortable: false
					},{
						// name: 'Status',
						name_i18n: 'transfers.requests.col_4',
						directive: 'button',
						width: 140,
						sortable: false
					}],
					rows: undefined,
					footer: {
						page_size: 5
					}
				})
			}
		}

		$scope.compounds = {
			create_eft: {
				open: false,
				accounts: ResolveDestinationAccounts.filter(function(account){
					return account.status !== "Closed"
				}).map(function(account,i) {

					var account_type;

					if (account.slug) {
						var slug = 'nwui.account_types.' + account.slug + '.name';
						account_type = locale.isTranslated(slug)
							? locale.getString(slug)
							: account_name || account.account_type;
					} else {
						account_type = account_name || account.account_type;
					}

					return {
						id: i,
						// text: account.account_type + ' (' + account.custodian_id + ')',
						text_i18n: 'transfers.input.destination_account.option',
						text_i18n_data: {
							accountType: account_type,
							accountId: account.custodian_id
						},
						value: account.id
					}
				}),
				accounts_efts: [],
				formResource: EftService.submitEftRequest,
				submitCb: function(obj) {

					////console.log('TransfersController', 'create_eft submitCb', obj);
					// loadTransferRequests();
					$scope.modules.efts_one_time.reload();
					$scope.modules.efts_recurring.reload();

				}
			}
		}

		$scope.modals = {
			//adding a modal -> and its translation to onesky
			pending_transfers_info: new NwuiModalV2Factory({
				open: false,
				wide: false,
				header: new NwuiCardHeaderV2Factory({
					// name: 'Transfers in Processing',
					name_i18n: 'transfers.modal.processing.title',
					icon: {
						name: 'info'
					}
				}),
				button: new NwuiButtonV3Factory({
					id: 'pending-transfer-cancel-button',
					// text: 'Cancel Transfer',
					text_i18n: 'transfers.modal.processing.button',
					color: 'error',
					size: '',
					icon: {
						name: 'close'
					},
					stretch: true,
					onClick: function() {

						NwButtonService.buttons['pending-transfer-cancel-button'].processing = true;
						TransfersEftRequestService.cancel($scope.data.active_eft_request.id).then(function() {

							NwuiToastManagerService.register(
								new NwuiToastFactory({
									// text: 'Transfer cancelled successfully!',
									text_i18n: 'transfers.modal.processing.success',
									icon: {
										name: 'check'
									}
								}
							));

							// $scope.cards.transfers.table.reload();
							$scope.modules.efts_recurring.reload();
							$scope.modules.efts_one_time.reload();
							$scope.modules.bts.reload();

							$scope.modals.pending_transfers_info.open = false;
							NwButtonService.buttons['pending-transfer-cancel-button'].processing = false;

						}, function() {

							NwButtonService.buttons['pending-transfer-cancel-button'].processing = false;

						})

					}
				})
			}),
			pending_brokerage_signature: new NwuiModalV2Factory({
					open: false,
					wide: false,
					header: new NwuiCardHeaderV2Factory({
						// name: 'Signature Required',
						name_i18n: 'transfers.modal.signing.title',
						icon: {
							name: 'info'
						}
					}),
					copy: {
						buttons: {
							sign: new NwuiButtonV3Factory({
								id: 'brokerage-transfer-info-sign-button',
								// text: 'Sign',
								text_i18n: 'transfers.modal.signing.button_yes',
								color: '',
								size: '',
								icon: {
									name: 'gesture'
								},
								stretch: true,
								onClick: function() {

									$scope.modals.pending_brokerage_signature.open = false;
									$timeout(function() {
										NwuiSigningService.setEnvelope('global-document-signing', $scope.data.active_brokerage_transfer.envelope_id);
										NwuiSigningService.setSubmitCb('global-document-signing', function(obj) {

											////console.log('TransfersController', 'NwuiSigningService.setSubmitCb', obj);

											if(obj.is_signed) {
												// loadTransferRequests();
												$scope.modules.bts.reload();
											}

										});

										NwuiPageScrollService.open('global-document-signing');
									}, 0);

								}
							}),
							cancel: new NwuiButtonV3Factory({
								id: 'brokerage-transfer-info-cancel-button',
								// text: 'Cancel',
								text_i18n: 'transfers.modal.signing.button_no',
								color: 'error',
								size: '',
								icon: {
									name: 'close'
								},
								stretch: true,
								onClick: function() {

									NwButtonService.buttons['brokerage-transfer-info-cancel-button'].processing = true;
									ApiClientsService.cancelBt($scope.data.active_brokerage_transfer.id).then(function(obj) {

										$scope.modals.pending_brokerage_signature.open = false;
										// loadTransferRequests();
										$scope.modules.bts.reload();

										NwuiToastManagerService.register(
											new NwuiToastFactory({
												// text: 'Brokerage transfer cancelled successfully!',
												text_i18n: 'transfers.modal.signing.cancelled',
												icon: {
													name: 'check'
												}
											}
										));

										$timeout(function() {
											NwButtonService.buttons['brokerage-transfer-info-cancel-button'].processing = false;
										}, 750);

									})

								}
							})
						}
					}
			}),
			// bill_pay_info: new NwuiModalV2Factory({
			// 		open: false,
			// 		wide: false,
			// 		header: new NwuiCardHeaderV2Factory({
			// 			name: 'Fund Using Bill Pay',
			// 			icon: {
			// 				name: 'attach_money'
			// 			}
			// 		}),
			// 		table: new NwuiCardTableFactory({
			// 		name: 'bill-pay-table',
			// 				empty_text: 'no accounts found',
			// 				columns: [{
			// 					name: 'Acct. #',
			// 					directive: 'text',
			// 			sortable: false
			// 				},{
			// 					name: 'Acct. Type',
			// 					directive: 'text',
			// 			sortable: false
			// 				}],
			// 				rows: ResolveAccounts.map(function(account,i) {
			// 					return {
			// 						columns: [{
			// 							text: account.custodian_id
			// 						},{
			// 							text: account.account_type
			// 						}]
			// 				}
			// 				})
			// 	})
			// }),
			brokerage_transfer_info: new NwuiModalV2Factory({
					open: false,
					wide: false,
					header: new NwuiCardHeaderV2Factory({
						// name: 'Brokerage Transfers',
						name_i18n: 'transfers.modal.about_bt.title',
						icon: {
							name: 'swap_horiz'
						}
					}),
					button: new NwuiButtonV3Factory({
						id: 'start-brokerage-transfer-button',
						// text: 'Set Up Brokerage&nbsp;Transfer',
						text_i18n: 'transfers.modal.about_bt.button',
						color: '',
						size: '',
						icon: {
							name: 'account_balance'
						},
						stretch: true,
						onClick: function() {

							$scope.modals.brokerage_transfer_info.open = false;

							$timeout(function() {
								$scope.modals.create_brokerage_transfer.open = true;
							}, 0);

						}
					})
				}),
			create_brokerage_transfer: new NwuiModalV2Factory({
				open: false,
				wide: false,
				header: new NwuiCardHeaderV2Factory({
					// name: 'Brokerage Transfer',
					name_i18n: 'transfers.modal.bt.title',
					icon: {
						name: 'account_balance'
					}
				}),
				form: new NwuiFormV3Factory({
					name: 'create-brokerage-transfer-form',
					onSubmit: function(post_data) {

						////console.log('TransfersController', 'brokerage transfer form submit', NwuiSession, post_data);

						return ApiTransfersService.createBrokerageTransfer(post_data, NwuiSession.user.client_id);

					},
					onComplete: function(obj) {
						////console.log('TransfersController', 'create_brokerage_transfer.onComplete', obj.data)

						NwuiToastManagerService.register(
							new NwuiToastFactory({
								// text: 'Your brokerage transfer has been created successfully',
								text_i18n: 'transfers.modal.bt.success',
								icon: {
									name: 'check'
								}
							}
						));

						// loadTransferRequests();
						$scope.modules.bts.reload();

						$scope.modals.create_brokerage_transfer.open = false;

						if (obj.data.envelope_id) {
							$timeout(function() {
								NwuiSigningService.setEnvelope('global-document-signing', obj.data.envelope_id);
								NwuiSigningService.setSubmitCb('global-document-signing', function(obj) {

									////console.log('TransfersController', 'NwuiSigningService.setSubmitCb', obj);

									if(obj.is_signed)
										$scope.modules.bts.reload();

								});

								NwuiPageScrollService.open('global-document-signing');
							}, 0);
						}

					},
					steps: [
						{
							columns: [{
								directive: 'input',
								width: 100,
								data: {
									directive: 'text',
									data: {
										// label: 'Source Financial Institution',
										label_i18n: 'transfers.input.source_institution.label',
										model: '',
										id: 'create-brokerage-transfer-form-input-financial-institution',
										stretch: true,
										required: true,
										size: '',
										post_var: 'financial_institution',
										// placeholder: "ABC Bank",
										placeholder_i18n: 'nwui.common.input.institution_name.placeholder'
									}
								}
							}, {
								directive: 'input',
								width: 100,
								data: {
									directive: 'text',
									data: {
										// label: 'Source Account Number',
										label_i18n: 'transfers.input.source_account_number.label',
										model: '',
										id: 'create-brokerage-transfer-form-input-account-number',
										stretch: true,
										required: true,
										size: '',
										post_var: 'account_number',
										// placeholder: "ABCD1234"
										placeholder_i18n: 'transfers.input.source_account_number.placeholder'
									}
								}
							}
						]
					},
					{
						columns: [{
								directive: 'input',
						width: 100,
								data: {
									directive: 'text',
									data: {
										// label: 'Financial Institution&nbsp;Address',
										label_i18n: 'transfers.input.source_address.label',
										model: '',
										id: 'create-brokerage-transfer-form-input-financial-institution-address',
										stretch: true,
										required: true,
										size: 'small',
										post_var: 'street_address',
										// placeholder: "672 Dupont Street",
										placeholder_i18n: 'nwui.common.input.address.placeholder'
									}
								}
							}, {
								directive: 'input',
								width: 100,
								data: {
									directive: 'text',
									data: {
										// label: 'City',
										label_i18n: 'nwui.common.input.city.label',
										model: '',
										id: 'create-brokerage-transfer-form-input-city',
										stretch: true,
										required: true,
										size: 'small',
										post_var: 'city',
										// placeholder: "Toronto",
										placeholder_i18n: 'nwui.common.input.city.placeholder'
									}
								}
							}, {
								directive: 'input',
								width: 55,
								data: {
									directive: 'select',
									data: {
										// label: 'Province',
										label_i18n: 'nwui.common.input.province.label',
										model: '',
										id: 'create-brokerage-transfer-form-input-province',
										stretch: true,
										required: true,
										size: 'small',
										post_var: 'province',
										options: NWUI_SELECT_OPTIONS.PROVINCES,
										// placeholder: "Ontario"
										placeholder_i18n: 'nwui.common.input.province.placeholder'
									}
								}
							}, {
								directive: 'input',
						width: 45,
								data: {
									directive: 'text',
									data: {
										// label: 'Postal Code',
										label_i18n: 'nwui.common.input.postal_code.label',
										model: '',
										id: 'create-brokerage-transfer-form-input-postal',
										stretch: true,
										required: true,
										mask: 'postal',
										validators: ['postal'],
										size: 'small',
										post_var: 'postal_code',
										// placeholder: "M6G 1Z6"
										placeholder_i18n: 'nwui.common.input.postal_code.placeholder'
								}
								}
							}]
				}, {
					// description: 'Providing a brokerage statement is optional, but doing so can decrease the time it takes for other institutions to send your assets to <nobr>' + $filter('NwuiOrgSlugToShortName')(org_slug) + '</nobr>.',
					description_i18n: 'transfers.modal.bt.upload_info',
					description_i18n_data: {
						orgNameShort: organization_data.name_short
					},
					columns: [{
							directive: 'input',
							width: 100,
							data: {
								directive: 'upload',
									data: {
									id: 'create-brokerage-transfer-form-input-upload-file',
									// label: 'Brokerage Statement (optional)',
									label_i18n: 'transfers.input.upload.label',
									model: '',
									url: '/api/documents',
									// placeholder: 'Choose a file...',
									placeholder_i18n: 'nwui.common.input.file_upload.placeholder',
									required: false,
									onSuccess: function() {

										NwuiToastManagerService.register(
										new NwuiToastFactory({
											// text: 'Document upload successful',
											text_i18n: 'nwui.common.toast.document_upload',
											icon: {
												name: 'check'
											}
										}
									));

									}
								}
							}
						}]
				}, {
					columns: [{
								directive: 'input',
						width: 100,
								data: {
									directive: 'select',
									data: {
										// label: 'Destination Account',
										label_i18n: 'transfers.input.destination_account.label',
										model: '',
										id: 'create-brokerage-transfer-form-input-account',
										stretch: true,
										required: true,
										options: [],
										size: '',
										post_var: 'account_id',
										onChange: function(val) {
											if(val == undefined || val == '')
												return false;
											if(organization_data &&
											organization_data.port_data &&
											organization_data.port_data.transfers &&
											organization_data.port_data.transfers.efts &&
											organization_data.port_data.transfers.efts.fund_facts &&
											organization_data.port_data.transfers.efts.fund_facts.show === true) {
												ApiAccountsService.getAllocation( {id: val} ).then(function(res) {
													FundFactsService.populateFundFactStepInForm(res, $scope.modals.create_brokerage_transfer.form);
												});
											}
										},
										// placeholder: "Select a destination...",
										placeholder_i18n: 'transfers.input.destination_account.placeholder',
								}
								}
							}, {
								directive: 'input',
						width: 100,
								data: {
									directive: 'checkbox',
									data: {
										// label: 'Please confirm the following before&nbsp;submission',
										label_i18n: 'transfers.input.confirm_check.label',
										//model: [],
										id: 'create-brokerage-transfer-form-input-confirmations',
										stretch: true,
										required: true,
										options: [{
											// label: "The account type that you are transferring to matches the source.",
											label_i18n: 'transfers.input.confirm_check.check_0',
											model: "account_match",
											value: 1,
											required: true
										}, {
											// label: "Transfer is all in cash.",
											label_i18n: 'transfers.input.confirm_check.check_1',
											model: "in_kind",
											value: 1,
											required: true
										}],
										size: '',
										post_var: 'user_confirmations',
										//placeholder: "Select a destination..."
									}
								}
							}]
						}]
					})
			})
		}

		$scope.$watch('modals.create_brokerage_transfer.open', function(new_val) {

			if(new_val === undefined) return;

			if(new_val === false) {
				$timeout(function() {
					NwuiFormV3Service.clear($scope.modals.create_brokerage_transfer.form.name);
					$scope.modals.create_brokerage_transfer.form.active_step_index = 0;
				}, 750);
			} else {
				NwuiFormV3Service.focus($scope.modals.create_brokerage_transfer.form.name);
			}

		});

		if(NwuiSession.user.id) {
			getEfts();
		} else {
			var deregister = $rootScope.$on(NWUI_AUTH_EVENTS.authDone, getEfts);
			$scope.$on('$destroy', deregister);
		}

	}
])
