angular.module('portfolio_web.manage.risk_profiles.view.controllers', [])

  .controller('ManageRiskProfilesViewController', [
    'ResolveGoal',
    '$scope',
    '$state',
    '$http',
    '$q',
    '$stateParams',
    '$timeout',
    'RiskProfileService',
    'ApiGoalsService',
    'NwButtonService',
    'NwuiToastManagerService',
    'NwuiFormV3Service',
    'NwuiSession',
    'NwuiCardHeaderV2Factory',
    'NwuiAllocationFactory',
    'NwuiFormV3Factory',
    'NwuiModalV2Factory',
    'NwuiButtonV3Factory',
    'NwuiRecommendationFactory',
    'NwuiAccordionFactory',
    'NwuiToastFactory',
    'ApiAccountsService',
    'ApiUserService',
    'locale',
    function (
      ResolveGoal,
      $scope,
      $state,
      $http,
      $q,
      $stateParams,
      $timeout,
      RiskProfileService,
      ApiGoalsService,
      NwButtonService,
      NwuiToastManagerService,
      NwuiFormV3Service,
      NwuiSession,
      NwuiCardHeaderV2Factory,
      NwuiAllocationFactory,
      NwuiFormV3Factory,
      NwuiModalV2Factory,
      NwuiButtonV3Factory,
      NwuiRecommendationFactory,
      NwuiAccordionFactory,
      NwuiToastFactory,
      ApiAccountsService,
      ApiUserService,
      locale
    ) {
      var loadRecommendation = function () {

        $scope.cards.info.button = undefined;
        $scope.cards.info.recommendation = undefined;

        if (data.risk_profiles.locked === false) {

          if (data.is_first_run && $stateParams.review !== false) {
            $timeout(function () {
              $scope.modals.unlocked.open = true;
            }, 500);
            data.is_first_run = false;
          }

          $scope.cards.info.recommendation = {
            type: 'error',
            icon: {
              name: 'assignment_late'
            },
            text_i18n: 'manage.risk_profiles.view.about.recommendation_ready',
            button: undefined
          }

          $scope.cards.info.button = new NwuiButtonV3Factory({
            id: 'update-risk-profile-button',
            text_i18n: 'manage.risk_profiles.view.about.button_ready',
            icon: {
              name: 'assignment'
            },
            color: 'error',
            size: 'small',
            stretch: true,
            onClick: function () {

              if ($scope.data.use_update_page)
                $state.go('app.manage.risk_profiles.view.edit');
              else
                $scope.modals.edit_risk_profile.open = true;

            }
          })

          $scope.modals.confirm_risk_profile.header.name_i18n = 'manage.risk_profiles.view.modal.confirm.title_ready';

          $scope.modals.confirm_risk_profile.recommendation = {
            type: 'warning',
            icon: {
              name: 'warning'
            },
            text_i18n: 'manage.risk_profiles.view.modal.confirm.recommendation_ready',
            button: undefined
          }

        } else {

          if ($scope.data.risk_profiles['new'] && $scope.data.risk_profiles['new'].kyc_instance.client_submitted) {

            $scope.cards.info.recommendation = {
              type: '',
              icon: {
                name: 'assignment_turned_in'
              },
              text_i18n: 'manage.risk_profiles.view.about.recommendation_pending',
              button: undefined
            };

            $scope.cards.info.button = {
              id: 'update-risk-profile-button',
              text_i18n: 'manage.risk_profiles.view.about.button_pending',
              icon: {
                name: 'find_in_page'
              },
              color: '',
              size: 'small',
              stretch: true,
              onClick: function () {
                $scope.modals.confirm_risk_profile.open = true;
              }
            }

            $scope.modals.confirm_risk_profile.header.name_i18n = 'manage.risk_profiles.view.modal.confirm.title_pending';

            $scope.modals.confirm_risk_profile.recommendation = {
              type: 'success',
              icon: {
                name: 'check'
              },
              text_i18n: 'manage.risk_profiles.view.modal.confirm.recommendation_pending',
              button: undefined
            }
          }
        }
      }

      var data = {
        risk_profiles: undefined,
        is_first_run: true,
      };

      $scope.data = {
        isHideHyperlink: (
        organization_data &&
        organization_data.port_data &&
        organization_data.port_data.hide_email_hyperlink),
        use_update_page: (
          organization_data &&
          organization_data.port_data &&
          organization_data.port_data.risk_profiles &&
          organization_data.port_data.risk_profiles.update &&
          organization_data.port_data.risk_profiles.update.version === 'page'
        ),
        user: NwuiSession.user,
        org_slug: org_slug,
        is_first_run: true,
        risk_profiles: undefined,
        org: organization_data,
        openChat: function (e) {
          e.stopPropagation();
          e.preventDefault();
          if(window.Intercom) {
          	window.Intercom('show');
          } else {
          	var contactArr = organization_data.footer.contact;
          	var urlInfo = contactArr.filter(contactInfo => contactInfo.type === "email");
          	window.location.href = `mailto:${urlInfo[0].data.id}`;
          }

        }
      }

      $scope.blocks = {
        breadcrumbs: {
          root: {
            icon: {
              name: 'home'
            },
            state: 'app.summary'
          }
        }
      }

      $scope.modules = {
        update_recommendation: undefined,
        update_button: undefined,
        title: {
          text_i18n: 'manage.risk_profiles.view.about.title',
          icon: {
            name: 'donut_large'
          },
          recommendation: {
            type: '',
            text_i18n: 'manage.risk_profiles.view.about.copy',
            text_i18n_data: {
              orgNameShort: (organization_data || {}).name_short
            },
            button: undefined
          }
        }
      }

      $scope.cards = {
        info: {
          // header: {
          // 	name_i18n: 'manage.risk_profiles.view.about.title',
          // 	icon: {
          // 		name: 'info_outline'
          // 	}
          // },
          recommendation: undefined,
          button: undefined
        },
        overview: {
          header: {
            name_i18n: 'manage.risk_profiles.view.profile.title',
            level: 2,
            icon: {
              name: 'donut_large'
            }
          },
          allocation: new NwuiAllocationFactory({
            series: [],
            decimal: 0
          }),
          // accordion: new NwuiAccordionFactory({
          // 	name_i18n: 'manage.risk_profiles.view.profile.accordion',
          // 	expanded: false,
          // 	color: 'alternate'
          // }),
        },
        questionnaire: {
          header: {
            name_i18n: 'manage.risk_profiles.view.profile.accordion',
            level: 2,
            icon: {
              name: 'assignment'
            }
          },
          form: new NwuiFormV3Factory({
            name: 'goal-risk-profile-form',
            active_step_index: 0,
            submit_button: false,
            onSubmit: function (post_data) { },
            onComplete: function (obj) { },
            steps: [{
              columns: []
            }]
          })
        }
      }

      $scope.modals = {
        unlocked: new NwuiModalV2Factory({
          open: false,
          wide: false,
          header: {
            name_i18n: 'manage.risk_profiles.view.modal.ready.title',
            icon: {
              name: 'assignment_late'
            }
          },
          copy: {
            buttons: {
              yes: {
                id: 'edit-risk-profile-yes',
                text_i18n: 'manage.risk_profiles.view.modal.ready.button_yes',
                icon: {
                  name: 'check'
                },
                color: '',
                stretch: true,
                onClick: function () {

                  $scope.modals.unlocked.open = false;

                  $timeout(function () {
                    if ($scope.data.use_update_page)
                      $state.go('app.manage.risk_profiles.view.edit');
                    else
                      $scope.modals.edit_risk_profile.open = true;
                  }, 250);

                }
              },
              no: {
                id: 'edit-risk-profile-no',
                text_i18n: 'manage.risk_profiles.view.modal.ready.button_no',
                icon: {
                  name: 'close'
                },
                color: 'error',
                stretch: true,
                onClick: function () {
                  $scope.modals.unlocked.open = false;
                }
              }
            }
          }
        }),
        edit_risk_profile: new NwuiModalV2Factory({
          open: false,
          wide: true,
          header: {
            name_i18n: 'manage.risk_profiles.view.modal.edit.title',
            icon: {
              name: 'assignment'
            }
          },
          form: new NwuiFormV3Factory({
            name: 'review-goal-risk-profile-form',
            active_step_index: 0,
            submit_button: {
              disabled: false,
              text_i18n: 'manage.risk_profiles.view.modal.edit.submit_button',
              icon: {
                name: 'find_in_page'
              }
            },
            onSubmit: function (post_data) {

              if ($scope.data.risk_profiles.locked) {
                var deferred = $q.defer();

                $timeout(function () {
                  deferred.resolve();
                }, 0);

                return deferred.promise;
              } else {
                post_data.age = $scope.data.risk_profiles['new'].kyc_answers.age || $scope.data.risk_profiles['current'].kyc_answers.age;
                post_data._id = $scope.data.risk_profiles['new'].kyc_answers._id;
                return RiskProfileService.previewRiskProfile($stateParams.goal_id, post_data).then(function (obj) {
                  return loadRiskProfile(true)
                });

              }
            },
            onComplete: function (obj) {

              if (!$scope.data.risk_profiles.locked) {
                $scope.data.risk_profiles['new'] = obj.risk_profiles['new'];
              }

              $scope.modals.edit_risk_profile.open = false;

              $timeout(function () {
                $scope.accountData = [];
                $scope.confirmRiskProfileModalOpen = true;
              }, 250);

            },
            steps: [{
              columns: []
            }]
          })
        }),
        confirm_risk_profile: new NwuiModalV2Factory({
          open: false,
          wide: true,
          header: {
            // name: 'Confirm Risk Profile',
            name_i18n: 'manage.risk_profiles.view.modal.confirm.title_ready',
            icon: {
              name: 'swap_horiz'
            }
          },
          recommendation: undefined,
          data: [],
          buttons: {
            yes: {
              id: 'submit-risk-profile-yes',
              // text: 'Submit',
              text_i18n: 'manage.risk_profiles.view.modal.confirm.button_yes',
              icon: {
                name: 'check'
              },
              color: '',
              stretch: true,
              onClick: function () {

                NwButtonService.buttons['submit-risk-profile-yes'].processing = true;

                RiskProfileService.submitRiskProfile($stateParams.goal_id).then(function (res) {
                  reloadRiskProfile();
                });

              }
            },
            no: {
              id: 'submit-risk-profile-edit',
              // text: 'Back',
              text_i18n: 'manage.risk_profiles.view.modal.confirm.button_no',
              color: 'error',
              icon: {
                name: 'undo'
              },
              stretch: true,
              onClick: function () {

                $scope.confirmRiskProfileModalOpen = false;

                $timeout(function () {
                  $scope.accountData = [];
                  $scope.riskProfileForConfirmModal = mapRiskProfileResponseToModal($scope.data.risk_profiles);
                  $scope.confirmRiskProfileModalOpen = true;
                }, 250);

              }
            }
          }
        })
      }

      $scope.$watch('modals.edit_risk_profile.open', function (new_val) {

        if (new_val === undefined) return;

        if (new_val === true) {
          NwuiFormV3Service.focus($scope.modals.edit_risk_profile.form.name);
        }
      });

      $scope.previewSliceHighlight = function (allocation) {
        $scope.active_goal.risk_profiles['new'].pie_data.data.forEach(function (o, i) {
          if (o == allocation)
            $scope.data.active_preview_pie_slice = o;
        });
      }

      var loadRiskProfile = async (force) => {

        var deferred = $q.defer();

        $scope.risk_profile_loaded = false;

        RiskProfileService.getRiskProfile($scope.active_goal.id, force).then(async (res) => {
          $scope.riskProfileForConfirmModal = mapRiskProfileResponseToModal(res.risk_profiles);
          data.risk_profiles = res.risk_profiles;
          $scope.data.risk_profiles = res.risk_profiles;
          $scope.data.risk_profiles.locked = res.locked;

          if ($scope.data.risk_profiles['new']) {

            var aggregate = {};

            $scope.data.risk_profiles['new'].pie_data.data.forEach(function (datum, i) {
              if (!aggregate[datum.type]) {
                aggregate[datum.type] = [datum.description || datum.type, 0, 0];
              }

              aggregate[datum.type][2] += datum.percent
            });

            $scope.data.risk_profiles['current'].pie_data.data.forEach(function (datum, i) {
              if (!aggregate[datum.type]) {
                aggregate[datum.type] = [datum.description || datum.type, 0, 0];
              }

              aggregate[datum.type][1] += datum.percent
            });

            // this would have been a lot easier in ES6
            Object.keys(aggregate).forEach(function (aggregateKey) {
              $scope.modals.confirm_risk_profile.data.push({
                type: aggregateKey,
                i18n: 'nwui.common.asset.type.' + aggregateKey,
                total: undefined,
                delta: undefined,
                dir: 'up'
              });
            });

            $scope.modals.confirm_risk_profile.data.forEach(function (item, i) {
              item.total = (Math.round(aggregate[item.type][2] * 10) / 10).toFixed(1);
              item.delta = Math.round((aggregate[item.type][2] - aggregate[item.type][1]) * 10) / 10
              if (item.delta < 0) {
                item.dir = 'dn';
                item.delta = (item.delta * -1).toFixed(1);
              } else if (item.delta == 0) {
                item.dir = 'eq';
              }
            });

          }
          

          //parse current asset allocation
          var current = res.risk_profiles.current;

          var selectedAccountType = ''
          $scope.active_goal.Accounts.forEach(account => {
            selectedAccountType =  account.account_type
          });
          let lifCheck = false;
          let liraCheck = false;
          var AccountLevelKycAnswers;
          await ApiUserService.getEsignEvents().then( (resObj ) => {
            if(resObj.data.data[0] && resObj.data.data[0].signingData && resObj.data.data[0].signingData.accountLevelRpqKyc){
              AccountLevelKycAnswers = resObj.data.data[0].signingData.AccountLevelKycAnswers;
              
              AccountLevelKycAnswers.map((accountAnswers) => {
                accountAnswers['kyc_income'] = resObj.data.data[0].signingData.kyc_income;
                accountAnswers['kyc_net_worth'] = resObj.data.data[0].signingData.kyc_net_worth;
                accountAnswers['kyc_investment_knowledge'] = resObj.data.data[0].signingData.kyc_investment_knowledge;

                lifCheck = ApiUserService.getLifCheck(accountAnswers.account_key, selectedAccountType)
								liraCheck = ApiUserService.getLiraCheck(accountAnswers.account_key, selectedAccountType)

                if((selectedAccountType == accountAnswers.account_key) || lifCheck || liraCheck){
                  if(accountAnswers && accountAnswers.asset_mix){
                    $scope.cards.overview.allocation.series = accountAnswers.asset_mix.map( (account_asset_mix) => {
                      return {
                        account_value: accountAnswers.account_value,
                        account_key: accountAnswers.account_key,
                        name: account_asset_mix.name.includes('Cash')  ?  'cash' : account_asset_mix.name,
                        percent: account_asset_mix.value,
                        percent_range: null,
                        // type: account_asset_mix.type,
                        hide: false
                      }
                    })
                  }
                  else {
                    $scope.cards.overview.allocation.series = res.risk_profiles.current.pie_data.data.map(function (datum, i) {

                      return {
                        name: datum.description,
                        percent: datum.percent,
                        percent_range: datum.assetRanges ? datum.assetRanges : null,
                        type: datum.type,
                        hide: datum.hide ? datum.hide : false
                      }
                    });
                  }
                }
              })
            } else {
              $scope.cards.overview.allocation.series = res.risk_profiles.current.pie_data.data.map(function (datum, i) {

                return {
                  name: datum.description,
                  percent: datum.percent,
                  percent_range: datum.assetRanges ? datum.assetRanges : null,
                  type: datum.type,
                  hide: datum.hide ? datum.hide : false
                }
              });
            }
          })


          //update questions and answers
          var columns = [];
          current.kyc_instance.steps.forEach(function (step, stepIndex) {
            step.rows.forEach(function (row, rowIndex) {
              row.fields.forEach(function (field, fieldIndex) {
              
                // handle fields that cannot be changed
                if (
                  field.input.model === 'first_name' ||
                  field.input.model === 'last_name' ||
                  field.input.model === 'age'
                ) return;

                var value = current.kyc_answers[field.input.model];
                var input_type = '';
                var input_type = field.input.input_type === 'check'
                  ? 'checkbox'
                  : 'text';

                // handle options for input types that need it
                field.input.options.forEach(function (option, i) {
                  if (field.input.input_type === 'check' && current.kyc_answers[field.input.model]) {
                    option.label_i18n = 'nwui.questionnaire.questions.' + field.input.i18n + '.options.' + option.model;
                    // get updated answer to options
                    current.kyc_answers[field.input.model].forEach(function (ans, j) {
                      if (ans.model === option.model) {
                        option.value = ans.value;
                      }
                    })
                  } else if (option.value == current.kyc_answers[field.input.model]) {
                    value = locale.getString('nwui.questionnaire.questions.' + field.input.i18n + '.options.' + option.value);
                  }
                });

                if(AccountLevelKycAnswers){
                  AccountLevelKycAnswers.forEach(accountAnswers => {
                    if((selectedAccountType == accountAnswers.account_key) || lifCheck || liraCheck){
                      if(field.input.model == 'portfolio_type'){
                        value = accountAnswers.kyc_investment_strategy;
                      } else {
                        value = accountAnswers[`kyc_${field.input.model}`]
                      }
                    }                  
                  });
                }

                // show those questions whoes values are there like investment strategy
                if((AccountLevelKycAnswers && value) || field.input.model == 'risk_disclaimer' || !AccountLevelKycAnswers){
                  columns.push({
                    width: (field.width > 50) ? 50 : field.width,
                    directive: 'input',
                    data: {
                      directive: input_type,
                      data: {
                        label_i18n: 'nwui.questionnaire.questions.' + field.input.i18n + '.input.label',
                        model: value,
                        id: 'risk-profile-input-' + field.input.model,
                        placeholder_i18n: 'nwui.common.labels.not_set',
                        options: field.input.options,
                        stretch: true,
                        post_var: field.input.model,
                        disabled: true
                      }
                    }
                  });
                }

              })
            })
          });

          $scope.cards.questionnaire.form.steps[0].columns = columns;

          //parse new risk profile if unlocked
          if (res.risk_profiles['new']) {
            // var steps = [];
            var rp = res.risk_profiles['new'];
            var columns = [];
            rp.kyc_instance.steps.forEach(function (step, i) {
              step.rows.forEach(function (row, j) {
                row.fields.forEach(function (field, k) {

                  if (
                    field.input.model === 'first_name' ||
                    field.input.model === 'last_name' ||
                    field.input.model === 'age'
                  ) return;

                  var value = rp.kyc_answers[field.input.model];
                  var input_type = ''; 

                  switch (field.input.input_type) {
                    case "select":
                    case "radio":
                      input_type = 'select'
                      break;
                    case "input":
                      input_type = 'text'
                      break;
                    case "check":
                      input_type = 'checkbox';
                      break;
                    case "subheading":
                    case "disclaimer":
                      input_type = 'subheading';
                      break;
                    default:
                      input_type = 'text'
                  }

                  if (field.input.input_type == 'check') {
                    value = field.input.options = field.input.options.map(function (option, i) {

                      var value = false;

                      if (rp.kyc_answers[field.input.model]) {

                        var index = 0;
                        rp.kyc_answers[field.input.model].forEach(function (answer_option, j) {
                          if (answer_option.model === option.model)
                            index = j;
                        });

                        value = rp.kyc_answers[field.input.model][index].value;

                      }

                      return {
                        label_i18n: 'nwui.questionnaire.questions.' + field.input.i18n + '.options.' + option.model,
                        model: option.model,
                        value: value
                      }
                    });
                  } else if (input_type == 'select') {
                    field.input.options.forEach(function (option, k) {
                      option.text_i18n = 'nwui.questionnaire.questions.' + field.input.i18n + '.options.' + option.value;
                    });
                  }

                  if ($scope.data.risk_profiles.locked) {
                    field.input.options.forEach(function (option, i) {
                      if (option.value == rp.kyc_answers[field.input.model])
                        value = option.text;
                    });

                    if (field.input.input_type == 'check') {
                      value = field.input.options.reduce(function (total, option) {
                        if (total.length > 0)
                          total += ', ';
                        return total + option.label;
                      }, '');
                    }
                  }

                  columns.push({
                    width: 100,
                    directive: 'input',
                    data: {
                      directive: $scope.data.risk_profiles.locked ? 'text' : input_type,
                      data: {
                        label_i18n: 'nwui.questionnaire.questions.' + field.input.i18n + '.input.label',
                        model: value,
                        id: 'edit-goal-risk-profile-input-' + field.input.model,
                        type: (input_type === 'text') ? 'text' : 'stacked',
                        placeholder_i18n: 'nwui.questionnaire.questions.' + field.input.i18n + '.input.placeholder',
                        options: field.input.options,
                        stretch: true,
                        required: !field.input.optional,
                        size: 'small',
                        post_var: field.input.model,
                        default_value: value,
                        disabled: $scope.data.risk_profiles.locked || (field.input.model === 'country') || field.input.input_type === 'disclaimer'
                      }
                    }
                  });

                })
              })

              // steps.push({
              // 	columns: columns
              // })
            });

            $scope.modals.edit_risk_profile.form.steps[0].columns = columns;
          }

          deferred.resolve(res);

        })

        return deferred.promise;

      }

      $scope.$watch('active_goal', function (new_val, old_val) {
        if (new_val === undefined) return;

        loadRiskProfile(false).then(function (obj) {
          // $scope.cards.questionnaire.accordion.expanded = true;
          loadRecommendation();
        });
      });

      $scope.active_goal = ResolveGoal;

      $scope.closeConfirmRiskProfileModal = function () {
        $scope.confirmRiskProfileModalOpen = false;
        reloadRiskProfile().then(function (obj) {

          if ($state.is('app.manage.risk_profiles.view.edit'))
            $state.go('app.manage.risk_profiles.view', { review: false });

        }, function () {

          if ($state.is('app.manage.risk_profiles.view.edit'))
            $state.go('app.manage.risk_profiles.view', { review: false });

        });
      }

      function mapRiskProfileResponseToModal(riskProfile) {
        return {
          old: riskProfile.current,
          new: riskProfile.new
        }
      }

      function reloadRiskProfile() {

        var deferred = $q.defer();

        RiskProfileService.submitRiskProfile($stateParams.goal_id).then(function (res) {
          loadRiskProfile(true).then(function () {

            $timeout(function () {
              loadRecommendation();
              $scope.confirmRiskProfileModalOpen = false;
            }, 1000);

            NwuiToastManagerService.register(
              new NwuiToastFactory({
                text_i18n: 'manage.risk_profiles.view.modal.confirm.success',
                icon: {
                  name: 'check'
                }
              }
              ));

            if (NwButtonService.buttons['submit-risk-profile-yes'])
              NwButtonService.buttons['submit-risk-profile-yes'].processing = false;

            deferred.resolve(true);

          })

        }, function (err) {

          var text_i18n = 'nwui.common.error.unknown';

          if (err && err.data) {
            switch (err.data.code) {
              case 4310:
                text_i18n = 'manage.risk_profiles.edit.review.error.4310';
                break;
            }
          }

          NwuiToastManagerService.register(
            new NwuiToastFactory({
              text_i18n: text_i18n,
              type: 'error',
              icon: {
                name: 'close'
              }
            }
            ));

          if (NwButtonService.buttons['submit-risk-profile-yes'])
            NwButtonService.buttons['submit-risk-profile-yes'].processing = false;

          deferred.reject(true);

        });

        return deferred.promise;

      }

    }
  ]);
