angular.module('portfolio_web.activity.directives', [
	'nwui-toolkit',
	'nwui-legacy-toolkit',
	'portfolio_web.api'
])

.directive('nwActivityContent', [
	function() {
		return {
			restrict: 'E',
      replace: true,
      templateUrl: 'portfolio_webserver/content/activity.html',
      scope: false,
      link: function(scope, element, attrs) {}
    }
	}
]);