angular.module('portfolio_web.transfers.overview.config', [
	'ui.router'
])

.config([
	'$stateProvider', 
	'$locationProvider', 
	'$urlRouterProvider', 

	'NWUI_AUTH_ROLES',
	function(
		$stateProvider, 
		$locationProvider, 
		$urlRouterProvider, 

		NWUI_AUTH_ROLES
	) {

		var route = {
			controller : 'TransfersOverviewController',
			url : '/',
			templateUrl: 'portfolio_webserver/transfers/overview/template.html',
			data: {
				// page_title: 'Transfers',
				page_title_i18n: 'nav.transfers',
				icon_name: 'compare_arrows',
				breadcrumb_label_i18n: 'nav.transfers',
				breadcrumb_proxy: undefined,
				auth_roles: [NWUI_AUTH_ROLES.user]
			},
			resolve: {}
		}

		$stateProvider.state('app.transfers.overview', route);

	}
]);