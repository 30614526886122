angular
  .module('portfolio_web.api.household.services', [])
  .service('ApiHouseholdService', [
    '$http', 
    '$q', 
    'PORTFOLIO_WEBSERVER',
    function(
      $http, 
      $q, 
      PORTFOLIO_WEBSERVER
    ) {

      /**
       * Returns household details with permissioned members
       */
      this.getHouseholdDetails = function() {
        var deferred = $q.defer();
        $http.get(PORTFOLIO_WEBSERVER.app + '/api/households/detail').then(function(data){
          deferred.resolve(data);
        }, function(err){
          deferred.reject(err);
        });
        return deferred.promise;
      }
  
      /**
       * Returns accounts of permissioned members
       */
      this.getHouseholdAccounts = function() {
        var deferred = $q.defer();
        $http.get(PORTFOLIO_WEBSERVER.app + '/api/households/accounts').then(function(data){
          deferred.resolve(data);
        }, function(err){
          deferred.reject(err);
        });
        return deferred.promise;
      }
    }
  ]);
  
