angular.module('portfolio_web.manage.account_statements.controllers', [])

  .controller('ManageAccountStatementsController', [
    '$scope',
    '$timeout',
    '$state',
    'NwuiCardHeaderV2Factory',
    'NwuiAccordionFactory',
    'NwuiCardTableV3Factory',
    'NwuiButtonV3Factory',
    'NwuiButtonV2Service',
    'NwButtonService',
    'ReportService',
    'ClientsService',
    'locale',
    function(
      $scope,
      $timeout,
      $state,
      NwuiCardHeaderV2Factory,
      NwuiAccordionFactory,
      NwuiCardTableV3Factory,
      NwuiButtonV3Factory,
      NwuiButtonV2Service,
      NwButtonService,
      ReportService,
      ClientsService,
      locale
    ) {

      $scope.blocks = {
        breadcrumbs: {
          root: {
            icon: {
              name: 'home'
            },
            state: 'app.summary'
          }
        }
      }

      $scope.cards = {
        empty: {
          button: {
            id: 'empty-account-statements-button',
            // text: 'Back to Summary',
            text_i18n: 'manage.account_statements.empty.button',
            icon: {
              name: 'arrow_back'
            },
            onClick: function() {
              NwButtonService.buttons['empty-account-statements-button'].processing = true;

              $timeout(function() {
                $state.go('app.summary');
              }, 750);
            }
          }
        },
        info: {
          header: {
            name_i18n: 'manage.account_statements.about.title',
            icon: {
              name: 'info_outline'
            }
          },
          accounts: []
        },
        accounts: []
      }

      ClientsService.getMyClients().then(function(res) {
        res.forEach(function(client, i) {
          createClientReportsCard(client, i);
        });
      });

      function createNewReportType(client, i, reportType) {
        return {
          accordion: {
            name_i18n: 'manage.account_statements.table.' + reportType + '.title',
            color: 'alternate',
            expanded: (i==0) && reportType === 'account',
          },
          table: new NwuiCardTableV3Factory({
            id: reportType + '-reports-table-' + i,
            resource: ReportService.getClientReports(client.id),
            empty_text_i18n: 'manage.account_statements.table.empty',
            columns: [{
              name_i18n: 'manage.account_statements.table.col_0',
              width: 95,
              directive: 'text',
              sortable: false
            },{
              name_i18n: 'manage.account_statements.table.col_1',
              directive: 'text',
              sortable: false
            },{
              name: '',
              directive: 'button',
              width: 110,
              sortable: false
            }],
            rowMap: function(report, i) {
              return {
                columns: [{
                  text: (report.tag === 'quarterly' || report.tag === 'quarter') ? report.year + ' Q' + report.quarter : report.year
                },{
                  text: (report.translation_key) ? undefined : report.name,
                  text_i18n: (report.translation_key) ? 'manage.account_statements.report.' + report.translation_key : undefined,
                  text_i18n_data: report
                },{
                  button: {
                    processing: false,
                    id: reportType + '-statements-account-dl-report-' + report.id,
                    text_i18n: 'nestkit.buttons.download',
                    onClick: function() {
                      var self = this;
                      self.processing = true;
                      ReportService.download(client.id, report.id).then(function(res) {
                        self.processing = false;
                      });
                    }
                  }
                }]
              }
            },
            footer: {
              query: reportType,
              page_size: 5
            }
          })
        }
      }

      function getAccountName(account) {
        const normalizedAccType = account.account_type ? account.account_type.replace(/ /g, '') : account.account_type;
        const slug = 'nwui.account_types.' + normalizedAccType + '.name';
				return locale.isTranslated(slug) ? locale.getString(slug)	: account.name;
      }

      function createClientReportsCard(client, i) {
        $scope.cards.accounts.push({
          header: new NwuiCardHeaderV2Factory({
            name: 'Account #' + client.custodian_id,
            icon: {
              name: 'account_balance_wallet'
            },
            level: 2,
            button: {
              processing: false,
              id: 'acc-statements-account-dl-all-' + client.id,
              text: 'Download All',
              icon: 'download',
              onClick: function() {

              }
            }
          })
        });

        const accountNames = [];
        client.Accounts.forEach((account) => {
          accountNames.push(getAccountName(account));
        })

        const accountTableSource = {
          accordion: {},
          tableSources: []
        };
        accountTableSource.accordion = {
          name_i18n: 'manage.account_statements.table.title',
          name_i18n_data: {
            accountId: client.custodian_id + " " + (accountNames.length > 0 ? "(" + accountNames.join(", ") + ")" : "")
          },
        };

        // we can create a new source category now by adding to the accountTableSource object, of course this filter logic needs to be added to the backend.
        accountTableSource.tableSources.push(createNewReportType(client, i, 'account'));
        accountTableSource.tableSources.push(createNewReportType(client, i, 'tax'));
        accountTableSource.tableSources.push(createNewReportType(client, i, 'other'));

        $scope.cards.info.accounts.push(accountTableSource);
      }
    }
  ]);
