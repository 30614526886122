angular.module('portfolio_web.manage.security.config', [
	'ui.router'
])

.config([
	'$stateProvider',
	'NWUI_AUTH_ROLES',
	function(
		$stateProvider,
		NWUI_AUTH_ROLES
	) {

		var password = {
			url : '/security',
			templateUrl: 'portfolio_webserver/manage/security/template.html',
			controller : 'ManageSecurityController',
			data: {
				// page_title: 'Manage - Security',
				page_title_i18n: 'nav.security',
				breadcrumb_label_i18n: 'nav.manage',
				auth_roles: [NWUI_AUTH_ROLES.user]
			}
		}

		$stateProvider.state('app.manage.password', password);

	}
]);