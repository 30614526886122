angular.module('portfolio_web.manage.withdrawals.list.controllers', [])

  .controller('ManageWithdrawalsListController', [
    '$scope',
    '$timeout',
    '$state',
    '$filter',
    'NwuiCardHeaderV2Factory',
    'NwuiAccordionFactory',
    'NwuiCardTableV3Factory',
    'NwuiButtonV3Factory',
    'NwuiButtonV2Service',
    'NwButtonService',
    'ApiUserService',
    'ResolveResources',
    'DATE_FORMAT',
    function(
      $scope,
      $timeout,
      $state,
      $filter,
      NwuiCardHeaderV2Factory,
      NwuiAccordionFactory,
      NwuiCardTableV3Factory,
      NwuiButtonV3Factory,
      NwuiButtonV2Service,
      NwButtonService,
      ApiUserService,
      ResolveResources,
      DATE_FORMAT
    ) {

      console.log('ManageWithdrawalsListController Loaded');

      $scope.blocks = {
        breadcrumbs: {
          root: {
            icon: {
              name: 'home'
            },
            state: 'app.summary'
          }
        }
      }

      $scope.cutouts = {
        new_withdrawal: {
          button: new NwuiButtonV3Factory({
            id: 'new-withdrawal-button',
            text_i18n: 'manage.withdrawals.cutout.button',
            icon: {
              name: 'create'
            },
            color: 'secondary',
            onClick: function() {
              $state.go('app.manage.withdrawals.new');
            }
          })
        }
      }

      $scope.cards = {
        empty: {
          button: {
            id: 'empty-withdrawals-button',
            // text: 'Back to Summary',
            text_i18n: 'manage.withdrawals.empty.button',
            icon: {
              name: 'arrow_back'
            },
            onClick: function() {
              NwButtonService.buttons['empty-withdrawals-button'].processing = true;

              $timeout(function() {
                $state.go('app.summary');
              }, 750);
            }
          }
        },
        info: {
          header: {
            // name: 'Withdrawals',
            name_i18n: 'manage.withdrawals.about.list.title',
            icon: {
              name: 'info_outline'
            },
            button: {
              id: 'new-withdrawal-button-sm',
              text: '',
              color: '',
              icon_only: true,
              icon: {
                name: 'create'
              },
              onClick: function() {
                $state.go('app.manage.withdrawals.new')
              }
            }
          }
        },
        withdrawals: {
          header: {
            // name: 'Withdrawals',
            name_i18n: 'manage.withdrawals.list.title',
            icon: {
              name: 'keyboard_return'
            }
          },
          accordions: []
        }
      }

      var accordions = [
        {
          // name: 'Pending Withdrawals',
          name_i18n: 'manage.withdrawals.accordions.pending.name',
          type: 'pending' 
        },
        {
          // name: 'Inprocess Withdrawals',
          name_i18n: 'manage.withdrawals.accordions.processing.name',
          type: 'inprocess' 
        },
        {
          // name: 'Approved Withdrawals',
          name_i18n: 'manage.withdrawals.accordions.completed.name',
          type: 'approved' 
        },
        {
          // name: 'Cancelled Withdrawals',
          name_i18n: 'manage.withdrawals.accordions.cancelled.name',
          type: 'cancelled' 
        }
      ]

      $scope.cards.withdrawals.accordions = accordions.map(function(accordion, i) {
        return new NwuiAccordionFactory({
          // name: accordion.name,
          name_i18n: accordion.name_i18n,
          expanded: (i === 0 || i === 1),
          table: new NwuiCardTableV3Factory({
            id: accordion.type + 'withdrawals-table-' + i,
            resource: ApiUserService.getWithdrawalFundingRequests(accordion.type),
            empty_text_i18n: 'manage.withdrawals.table.empty',
            columns: [{
              name_i18n: 'manage.withdrawals.table.col_0',
              directive: 'text',
              sortable: true
            },{
              name_i18n: 'manage.withdrawals.table.col_1',
              directive: 'text',
              sortable: false
            },{
              name_i18n: 'manage.withdrawals.table.col_2',
              directive: 'text',
              sortable: false
            },{
              name_i18n: 'manage.withdrawals.table.col_3',
              directive: 'text',
              sortable: false,
              width: 200
            },{
              name_i18n: 'manage.withdrawals.table.col_4',
              directive: 'text',
              sortable: false
            }],
            rowMap: function(row, i) {
              return {
                columns: [{
                  text: moment(row.createdAt).format(DATE_FORMAT.long)
                },{
                  text: (row.EFT.Account.custodian_id)
                },{
                  text: row.EFT.Account.account_type,
                },{
                  text: (row.EFT.bank_info)
                },{
                  text: $filter('currency')(row.amount)
                }]
              }
            }
          })
        });
      });
    }
  ]);
