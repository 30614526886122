angular.module('portfolio_web.manage.transfer_sources.new.config', [
	'ui.router'
])

.config([
	'$stateProvider',
	'NWUI_AUTH_ROLES',
	function(
		$stateProvider,
		NWUI_AUTH_ROLES
	) {

		var transfer_sources_new = {
			url : '/new',
			templateUrl: 'portfolio_webserver/manage/transfer_sources/new/template.html',
			controller : 'ManageTransferSourcesNewController',
			data: {
				page_title_i18n: 'manage.transfer_sources.new.nav.title',
				icon_name: 'swap_horiz',
				breadcrumb_label_i18n: 'manage.transfer_sources.new.nav.base',
				auth_roles: [NWUI_AUTH_ROLES.user]
			}
		}

		$stateProvider.state('app.manage.transfer_sources.new', transfer_sources_new);

	}
]);