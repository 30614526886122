angular.module('portfolio_web.manage.general.config', [
	'ui.router'
])

.config([
	'$stateProvider',
	'NWUI_AUTH_ROLES',
	function(
		$stateProvider,
		NWUI_AUTH_ROLES
	) {

		var general = {
			url : '/general',
			templateUrl:'portfolio_webserver/manage/general.html',
			controller : 'ManageGeneralController',
			data: {
				auth_roles: [NWUI_AUTH_ROLES.user]
			}
		}

		$stateProvider.state('app.manage.general', general);

	}
]);