angular.module('portfolio_web.directives', [
	'nwui-legacy-toolkit'
])

.directive('disableAnimate', ['$animate', function($animate) {
	
	return {
		restrict: 'A',
		link: function(scope, element) {
			$animate.enabled(element, false)
		} 
	}
	
}])

.directive('selectInputOnLoad', [function($timeout) {
	return {
		link: function(scope, element) {
			$(element[0]).focus();
		}
	}
}])

.directive('nwSavingsDisclaimer', [function() {
	return {
		restrict: 'E',
				replace: true,
				templateUrl: 'portfolio_webserver/partials/savings-disclaimer.html',
				link: function(scope, element, attrs) {}
	}
}]);