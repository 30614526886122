angular.module('portfolio_web.manage.services', [])

.service('ErrorService', [function() {
    
    var errors = {
	    
	    'pr000': {
		    message: 'Please enter a password in both fields.'
	    },
	    'pr001': {
		    message: 'Your passwords must match.'
	    },
	    'pr002': {
		    message: 'Your password must be at least 8 characters.'
	    },
	    'default': {
		    message: 'Something went wrong.'
	    }
	    
    }
    
    this.find = function(code) {
	    if(errors[code])
		    return errors[code];
		else
			return errors['default'];
    }
    
}]);