angular.module('portfolio_web.api', [
	'portfolio_web.api.accounts',
	'portfolio_web.api.clients',
	'portfolio_web.api.esignevents',
	'portfolio_web.api.general',
	'portfolio_web.api.goals',
	'portfolio_web.api.helpers',
	'portfolio_web.api.legacy',
	'portfolio_web.api.signing',
	'portfolio_web.api.transfers',
	'portfolio_web.api.user',
	'portfolio_web.api.idVerification',
	'portfolio_web.api.broadcast_notifications',
	'portfolio_web.api.household',
]);
