angular.module('portfolio_web.api.transfers.services', [])

.service('ApiTransfersService', [
	'$rootScope', 
	'$http', 
	'$q', 
	'$timeout', 
	'$stateParams',
	'NwuiSession', 
	'PORTFOLIO_WEBSERVER',
	function(
		$rootScope, 
		$http, 
		$q, 
		$timeout, 
		$stateParams,
		NwuiSession,
		PORTFOLIO_WEBSERVER
	) {
		
		this.createBrokerageTransfer = function(body, client_id) {
			
			////console.log('NwuiSession', NwuiSession, body)

			client_id = client_id || NwuiSession.user.client_id;

			var deferred = $q.defer()
	
			$http.post(PORTFOLIO_WEBSERVER.app + '/api/clients/' + client_id + '/bt-request', body).then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})
	
			return deferred.promise
			
		}
		
	}
]);