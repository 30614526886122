angular.module('portfolio_web.manage.risk_profiles.view.edit.config', [
	'ui.router'
])

.config([
	'$stateProvider',
	'NWUI_AUTH_ROLES',
	function(
		$stateProvider,
		NWUI_AUTH_ROLES
	) {

		var edit = {
			url : '/edit?status',
			templateUrl: 'portfolio_webserver/manage/risk_profiles/view/edit/template.html',
			controller : 'ManageRiskProfilesViewEditController',
			data: {
				page_title_i18n: 'manage.risk_profiles.edit.nav.title',
				icon_name: 'donut_large',
				breadcrumb_label: undefined,
				breadcrumb_label_i18n: 'manage.risk_profiles.edit.nav.base',
				auth_roles: [NWUI_AUTH_ROLES.user]
			},
			params: {
				status: null
			},
			resolve: {
				ResolveFormQuestions: [
					'$q',
					'$stateParams',
					'ApiGeneralService',
					function(
						$q,
						$stateParams,
						ApiGeneralService
					) {

						var deferred = $q.defer();

						ApiGeneralService.getFormQuestions().then(function(obj) {
							deferred.resolve(obj.data);
						}, function() {
							deferred.resolve({
								form_instance: { steps: [] }
							});
						})
						
						return deferred.promise;

					}
				],
				ResolveFormAnswers: [
					'$q',
					'$stateParams',
					'ApiUserService',
					function(
						$q,
						$stateParams,
						ApiUserService
					) {

						var deferred = $q.defer();

						ApiUserService.getFormAnswers().then(function(obj) {
							deferred.resolve(obj.data);
						}, function() {
							deferred.resolve({});
						})
						
						return deferred.promise;

					}
				]
			}
		}

		$stateProvider.state('app.manage.risk_profiles.view.edit', edit);

	}
]);