angular.module('portfolio_web.accounts.new.controllers', [])

	.controller('AccountsNewController', [
		'$scope',
		'$state',
		'$stateParams',
		'$timeout',
		'locale',

		'ApiUserService',
		'ApiGeneralService',
		'NwButtonService',
		'NwuiToastManagerService',
		'NwuiSigningService',
		'NwuiPageScrollService',
		'NwuiInputService',
		'NwuiFormV3Service',
		'NwuiSession',

		'NwuiFormV3Factory',
		'NwuiAllocationFactory',
		'NwuiCardHeaderV2Factory',
		'NwuiToastFactory',

		'ResolveLatestRiskProfile',
		'ResolveFormQuestions',
		'ResolveFormAnswers',
		'ResolveEftSources',

		function(
			$scope,
			$state,
			$stateParams,
			$timeout,
			locale,

			ApiUserService,
			ApiGeneralService,
			NwButtonService,
			NwuiToastManagerService,
			NwuiSigningService,
			NwuiPageScrollService,
			NwuiInputService,
			NwuiFormV3Service,
			NwuiSession,

			NwuiFormV3Factory,
			NwuiAllocationFactory,
			NwuiCardHeaderV2Factory,
			NwuiToastFactory,

			ResolveLatestRiskProfile,
			ResolveFormQuestions,
			ResolveFormAnswers,
			ResolveEftSources
		) {

			/**
				* Taken from join_webserver to have the same evaluators (need to abstract to be consistent everywhere)
				* Default: compares against form questions models
				* Can pass different vaues to compare against, like KYC models (i.e. kyc blocking feature)
				* Can now handle multiple conditions or, and etc
			*/
			var ngIfEvaluator = function (ng_if, values) {
				var operator, conditions;
				values =
					typeof values !== 'undefined' ?
					values :
					data.full_form_answers;

				if (ng_if.ng_if_model) {
					/**
					 * Allows to compare undefined values with null.
					 * `ngIfEvaluator` is being used on models where condition may be null,
					 * meaning that we expect the value to be falsy, but sometimes it may not
					 * be present in the object with supplied values.
					 * this switch case should be more generic. to work with values
					 */
					switch (ng_if.ng_if_operator) {
						case 'gt':
							return values[ng_if.ng_if_model] > ng_if.ng_if_value;
							break;
						case 'gte':
							return values[ng_if.ng_if_model] >= ng_if.ng_if_value;
							break;
						case 'lt':
							return values[ng_if.ng_if_model] < ng_if.ng_if_value;
							break;
						case 'lte':
							return values[ng_if.ng_if_model] <= ng_if.ng_if_value;
							break;
						case 'ne':
							return values[ng_if.ng_if_model] != ng_if.ng_if_value;
							break;
						case 'regex':
							const regex = new RegExp(ng_if.ng_if_value, 'gi');
							return regex.test(values[ng_if.ng_if_model]);
							break;
						default:
							return values[ng_if.ng_if_model] === ng_if.ng_if_value;
							break;
					}
				} else if (ng_if.and) {
					operator = '&&';
					conditions = ng_if.and;
				} else if (ng_if.or) {
					operator = '||';
					conditions = ng_if.or;
				} else {
					return true; // No ng_if
				}

				var conditional_string = '(';

				const count = conditions.length;
				for (var i = 0; i < count; i++) {
					conditional_string += ngIfEvaluator(conditions[i], values);
					if (i !== count - 1) conditional_string += operator;
				}

				conditional_string += ')';
				/* eslint-disable no-eval */
				return eval(conditional_string)
			}

			var checkShow = function(step_index, test) {

				var show = true;

				if (test.ng_if) {
					show = ngIfEvaluator(test.ng_if);
				}

				//if question
				if (test && test.input) {
					var input = NwuiInputService.inputs['accounts-new-account-full-form-input-' + step_index + '-' + test.input.model];
					// was getting an error: input was undefined - causing FE lag when opening new account page with lots of account types
					if(input) {
						input.hide = !show;
						// Make sure the model is null when hidden due to hidden default values being sent as well
						// If the model is unhidden, reset to default value from form answers
						input.model = input.hide ? null : ResolveFormAnswers[input.post_var];
					}
				}

				//if step
				else {
					$scope.modules.account.sections[test.model].show = show;
					if (test.title === "Communication and Consent" && show) {
						$scope.modules.account.sections[test.model].communicationAndConsent = true;
					}
				}

			}

			var checkShowOptions = function(step_index, option) {
				NwuiInputService.inputs['accounts-new-account-full-form-input-' + step_index + '-' + option.watchers[0].model].options = NwuiInputService.inputs['accounts-new-account-full-form-input-' + step_index + '-' + option.watchers[0].model].totalOptions.filter(op => {
					return !op.ng_if || (op.ng_if && ngIfEvaluator(op.ng_if))
				})
			}

			var applyWatchersLoop = function(ng_if, questions, test, map, isOptionWatcher = false) {

				if (!ng_if) {
					return;
				}

				if (ng_if.and) {

					return applyWatchersLoop(ng_if.and, questions, test, map, isOptionWatcher);

				} else if (ng_if.or) {

					return applyWatchersLoop(ng_if.or, questions, test, map, isOptionWatcher);

				} else if (ng_if.ng_if_model) {

					//if question
					if (!isOptionWatcher && test && test.input) {

						//check if model belongs to any checkbox options
						questions.forEach(function(q) {
							if (q.input.input_type === 'check') {

								q.input.options.forEach(function(option) {
									if (ng_if.ng_if_model === option.model && q.question_watchers.indexOf(test) === -1) {
										q.question_watchers.push(test);
									}
								});

							}
						});

						//apply watchers to the question
						if (questions[map[ng_if.ng_if_model]] && questions[map[ng_if.ng_if_model]].question_watchers.indexOf(test) === -1)
							questions[map[ng_if.ng_if_model]].question_watchers.push(test);

					}
					else if (isOptionWatcher && test) {
						//apply watcher to the options
						if (isOptionWatcher && questions[map[ng_if.ng_if_model]]) {
							const questionOptions = questions[map[ng_if.ng_if_model]].input.options
							// find the index of the option based on ng_if_value
							const index = questionOptions.map(el => el.value).indexOf(ng_if.ng_if_value)
							// if no wather on that option
							if (index > -1 && questionOptions[index].watchers.indexOf(test.model) === -1) {
								// set watcher for that option
								questionOptions[index].watchers.push(test);
							}
						}
					}
					//if step
					else {

						//check if model belongs to any checkbox options
						questions.forEach(function(q) {
							if (q.input.input_type === 'check') {

								q.input.options.forEach(function(option) {
									if (ng_if.ng_if_model === option.model && q.step_watchers.indexOf(test) === -1) {
										q.step_watchers.push(test);
									}
								});

							}
						});

						//apply watchers
						if (questions[map[ng_if.ng_if_model]] && questions[map[ng_if.ng_if_model]].step_watchers.indexOf(test) === -1)
							questions[map[ng_if.ng_if_model]].step_watchers.push(test);

					}

				} else if (ng_if) {

					for (var i = 0; i < ng_if.length; i++) {
						applyWatchersLoop(ng_if[i], questions, test, map, isOptionWatcher);
					}

				}

			}

			var applyQuestionWatchers = function(questions) {

				//build map from array
				var map = {};
				for (var i = 0; i < questions.length; i++) {
					questions[i].question_watchers = [];
					map[questions[i].input.model] = i;
					if (questions[i].input?.options?.length > 0)
						for (let j = 0; j < questions[i].input.options.length; j++) {
							questions[i].input.options[j].watchers = [];
						}
				}

				questions.forEach(function(question, i) {

					// loop through ng_if conditions for question
					applyWatchersLoop(question.ng_if, questions, question, map);

					// loop through ng_if conditions for options
					if (question.input?.options?.length > 0) {
						for (let i = 0; i < question.input.options.length; i++) {
							if (question.input.options[i].ng_if) {
								applyWatchersLoop(question.input.options[i].ng_if, questions, question, map, true)
							}
						}
					}

				})

			}

			var applyStepWatchers = function(questions, steps) {

				//build map from array
				var map = {};
				for (var i = 0; i < questions.length; i++) {
					questions[i].step_watchers = [];
					map[questions[i].input.model] = i;
				}

				steps.forEach(function(step, i) {
					step.model = i;

					//loop through if conditions
					applyWatchersLoop(step.ng_if, questions, step, map);
				})

			}

			var check_ng_if = function(ng_if, field, models, new_models) {

				if (ng_if.and) {

					check_ng_if(ng_if.and, field, models, new_models);

				} else if (ng_if.or) {

					check_ng_if(ng_if.or, field, models, new_models);

				} else if (ng_if.ng_if_model) {

					if ($scope.data.single_account_select) {

						if (models.indexOf(ng_if.ng_if_value) !== -1)
							new_models.push(field.input.model);

					} else {

						if (models.indexOf(ng_if.ng_if_model) !== -1)
							new_models.push(field.input.model);

					}

				} else if (ng_if) {

					for (var i = 0; i < ng_if.length; i++) {
						check_ng_if(ng_if[i], field, models, new_models);
					}

				}

			}

			var check_ng_if_step = function(ng_if, step_index, models, new_models) {

				if (ng_if.and) {

					check_ng_if_step(ng_if.and, step_index, models, new_models);

				} else if (ng_if.or) {

					check_ng_if_step(ng_if.or, step_index, models, new_models);

				} else if (ng_if.ng_if_model) {

					//if match
					if ($scope.data.single_account_select) {

						if (models.indexOf(ng_if.ng_if_value) !== -1)
							new_models.push(step_index);

					} else {

						if (models.indexOf(ng_if.ng_if_model) !== -1)
							new_models.push(step_index);

					}

				} else if (ng_if) {

					for (var i = 0; i < ng_if.length; i++) {
						check_ng_if_step(ng_if[i], step_index, models, new_models);
					}

				}

			}

			var getStepsForNewAccountOpening = function (form) {
				var stepNames = ((organization_data.port_data.accounts || {}).additional_account_opening_forms || [])
					.map(function (form) { return form.step });
				return form.steps.reduce(function (result, step, index) {
					 if (stepNames.includes(step.title)) {
						 result.push(index);
					 }

					 return result;
				}, []);
			}

			var getAssociatedSteps = function(form, models) {
				var new_models = [];
				for (var i = 0; i < form.steps.length; i++) {

					var step = form.steps[i];
					if (models.indexOf(i) === -1 && step.ng_if)
						check_ng_if_step(step.ng_if, i, models, new_models);

				}

				models = models.concat(new_models);

				if (new_models.length > 0) {
					return getAssociatedSteps(form, models);
				} else {
					return models;
				}

			}

			var getModelsForNewAccountOpeningStep = function (step) {
				var newAccountForm = ((organization_data.port_data.accounts || {}).additional_account_opening_forms || [])
				.find(function (form) {
					return form.step === step.title
				});
				if (!newAccountForm) {
					return [];
				}

				return newAccountForm.models || [];
			}

			var getAssociatedModels = function(step, models) {

				var new_models = [];

				for (var j = 0; j < step.rows.length; j++) {
					var row = step.rows[j];

					for (var k = 0; k < step.rows[j].fields.length; k++) {
						var field = step.rows[j].fields[k];

						if (models.indexOf(field.input.model) === -1 && field.ng_if)
							check_ng_if(field.ng_if, field, models, new_models);

					}
				}
				models = models.concat(new_models);
				if (new_models.length > 0) {
					return getAssociatedModels(step, models);
				} else {
					return models;
				}

			}

			var getTrimmedQuestions = function(step, models) {
				var questions = [];
				var added_models = [];

				for (var j = 0; j < step.rows.length; j++) {
					var row = step.rows[j];

					for (var k = 0; k < step.rows[j].fields.length; k++) {
						var field = step.rows[j].fields[k];

						if (models.indexOf(field.input.model) !== -1 && added_models.indexOf(field.input.model) === -1) {
							added_models.push(field.input.model);
							questions.push(field);
						}

					}
				}
				return questions;

			}

			var getEsignEventSign = function(id) {

				ApiUserService.getEsignEventSign(id).then(function(obj) {

					NwButtonService.buttons[$scope.modules.submit.id].processing = false;

					$timeout(function() {
						NwuiSigningService.setEnvelope('global-document-signing', obj.data.envelopeId);
						NwuiSigningService.setSubmitCb('global-document-signing', function(obj) {

							ApiUserService.getEsignEvents().then(function() {
								if($scope.data.is_enable === true) {
									$state.go('app.accounts.view', { reload: true });
								} else {
									$state.go('app.summary');
								}
							})

						});

						NwuiSigningService.setErrorCb('global-document-signing', function(err) {

							var text_i18n = 'nwui.common.error.unknown';

							if (err && err.code) {
								switch (err.code) {
									case 4311:
										text_i18n = 'manage.risk_profiles.edit.review.error.4310';
										break;
								}
							}

							NwuiToastManagerService.register(
								new NwuiToastFactory({
									text_i18n: text_i18n,
									type: 'error',
									icon: {
										name: 'close'
									}
								}
								));

							if($scope.data.is_enable === true) {
								$state.go('app.accounts.view');
							} else {
								$state.go('app.summary');
							}

						});

						NwuiPageScrollService.open('global-document-signing');

					}, 0);

				}, function() {

					NwButtonService.buttons[$scope.modules.submit.id].processing = false;

				})

			}

			var isKYCBlocking = function() {
				var hasBlocking = organization_data.join_data.questionnaire.check_if_user_can_be_blocked;
				if (!hasBlocking) return false;
				var riskProfileFormData = $scope.modules.manage_risk_profile_editor.getFormData();
				var blockingConditions = organization_data.join_data.questionnaire.answers_to_check.ng_if.or
					.map(function(condition){
						return {
							ng_if_model: 'score',
							ng_if_value: condition.ng_if_value,
							ng_if_operator: condition.ng_if_operator
						}
					});

				return ngIfEvaluator({or: blockingConditions}, riskProfileFormData);
			}

			var go_to_rpq_aao = function(accounts) {
				const url = organization_data.rpqUi.data.url + `?transactionType=AAO&accounts=${accounts}`;
				window.location.href = url;
			}

			$scope.blocks = {
				breadcrumbs: {
					root: {
						icon: {
							name: 'home'
						},
						state: 'app.summary'
					}
				}
			}

			var data = {
				active_esign_event: false,
				can_preview: (((organization_data || {}).port_data || {}).accounts || {}).show_risk_profile_preview,
				full_form_answers: Object.assign({}, ResolveFormAnswers),
			}

			$scope.data = {
				enableBill64: (
					organization_data &&
					organization_data.port_data &&
					organization_data.port_data.accounts &&
					organization_data.port_data.accounts.enableBill64 === true
				),
				use_rpq_aao : organization_data.use_rpq_aao,
				agreedToBill64: false,
				has_fields_for_review: false,
				reviewed_personal_info: (
					$stateParams.status === 'personal-info-success'
				),
				joint_code_required: (((organization_data || {}).port_data || {}).accounts || {}).joint_code_required,
				review_personal_info: (
					organization_data &&
					organization_data.port_data &&
					organization_data.port_data.accounts &&
					organization_data.port_data.accounts.review_personal_info === true
				),
				single_account_select: (
					organization_data &&
					organization_data.port_data &&
					organization_data.port_data.accounts &&
					organization_data.port_data.accounts.create_single
				),
				is_joint: $stateParams.ownership_type.toLowerCase() === 'joint',
				access_code: undefined,
				has_form_questions: false,
				show_risk_profile: false,
				show_review_risk_profile: (
					organization_data &&
					organization_data.port_data &&
					organization_data.port_data.accounts &&
					organization_data.port_data.accounts.show_review_risk_profile === true
				),
				is_enable: (
					organization_data &&
					organization_data.port_data &&
					organization_data.port_data.ui &&
					organization_data.port_data.ui.enable === true
				),
				show_scheduler: false,
				openChat: function(e) {
					if (window.Intercom) {
						e.stopPropagation();
						e.preventDefault();
						window.Intercom('show');
					}

				}
			}
			if ($scope.data.show_review_risk_profile) {
				$scope.data.show_risk_profile = true;
			}
			if ($scope.data.is_joint && $scope.data.joint_code_required) {
				ApiGeneralService.createAccessCode().then(function(obj) {
					$scope.data.access_code = obj.data;
				})
			}

			var populateBankingInfoFields = function (eftSource, isHidden) {
				NwuiInputService.inputs['accounts-new-account-full-form-input-0-FileVoidCheque'].model = eftSource ? ResolveFormAnswers.FileVoidCheque : '';
				NwuiInputService.inputs['accounts-new-account-full-form-input-0-AHFinancialInstitutionName'].model = eftSource ? eftSource.bank_name : '';
				NwuiInputService.inputs['accounts-new-account-full-form-input-0-AHFinancialInstitutionAccount'].model = eftSource ? eftSource.bank_account : '';
				NwuiInputService.inputs['accounts-new-account-full-form-input-0-AHFinancialInstitutionTransit'].model = eftSource ? eftSource.bank_branch_str : '';
				NwuiInputService.inputs['accounts-new-account-full-form-input-0-AHFinancialInstitutionNumber'].model = eftSource ? eftSource.bank_institution_str : '';

				NwuiInputService.inputs['accounts-new-account-full-form-input-0-AHFinancialInstitutionName'].hide = !!isHidden;
				NwuiInputService.inputs['accounts-new-account-full-form-input-0-AHFinancialInstitutionAccount'].hide = !!isHidden;
				NwuiInputService.inputs['accounts-new-account-full-form-input-0-AHFinancialInstitutionTransit'].hide = !!isHidden;
				NwuiInputService.inputs['accounts-new-account-full-form-input-0-AHFinancialInstitutionNumber'].hide = !!isHidden;
				NwuiInputService.inputs['accounts-new-account-full-form-input-0-FileVoidCheque'].hide = !!isHidden;
			};

			var EftSourceQuestionOptions = ResolveEftSources.map(function(eftSource, index) {
				return {
					id: index,
					text: (eftSource.nickname ? eftSource.nickname + ' ' : '') + eftSource.bank_name + ' ' + eftSource.bank_info,
					value: index
				}
			});

			EftSourceQuestionOptions.unshift({
				id: 'add-new',
				text_i18n: 'nwui.common.input.source_account.new_account',
				value: 'add-new',
			});

			var handleEftSourceSelectChange = function(val) {
				var eftSource = ResolveEftSources[val];
				if (val === 'add-new') {
					populateBankingInfoFields();
				} else {
					populateBankingInfoFields(eftSource, true);
				}
			};

			var selectEftSourceQuestion = {
				width: 50,
				directive: 'input',
				data: {
					directive: 'select',
					data: {
						placeholder_i18n: 'nwui.common.input.source_account.new_account',
						label_i18n: 'accounts.new.select.eft.label',
						model: EftSourceQuestionOptions[0],
						options: EftSourceQuestionOptions,
						onChange: handleEftSourceSelectChange,
					},
				}
			};

			$scope.modules = {
				title: {
					text_i18n: 'accounts.new.title.text',
					icon: {
						name: 'account_balance'
					},
					// button: {
					// 	id: 'accounts-new-switch-type',
					// 	text_i18n: 'accounts.new.title.button',
					// 	onClick: function() {

					// 		$state.go('app.accounts.view', { reload: true });

					// 	}
					// }
				},
				verify: {
					card: {
						header: new NwuiCardHeaderV2Factory({
							name_i18n: "accounts.new.verify.header.name",
							level: 2,
							icon: {
								name: 'person'
							}
						}),
						recommendation: {
							type: 'success',
							icon: {
								name: 'check'
							},
							text_i18n: 'manage.risk_profiles.edit.review.success',
							button: undefined
						},
						form: {
							full: new NwuiFormV3Factory({
								name: 'accounts-new-verify-full-form',
								onSubmit: function(post_data) {
								},
								onComplete: function(obj) {
								},
								submit_button: false,
								steps: [{
									columns: (function() {
										var columns = [];
										var custodianSlug = ResolveFormQuestions.custodian_slug;
										ResolveFormQuestions.form_instance.steps.forEach(function(step) {
											step.rows.forEach(function(row) {
												row.fields.forEach(function(field) {

													var value = (function(form_answers) {

														switch (field.input.input_type) {
															case 'select':
															case 'radio':
																for (var i = 0; i < field.input.options.length; i++) {
																	if (form_answers[field.input.model] === field.input.options[i].value) {
																		var localeString = custodianSlug ?
																			'nwui.forms.' + custodianSlug + '.questions.' + field.input.i18n + '.options.' + field.input.options[i].value :
																			'nwui.forms.questions.' + field.input.i18n + '.options.' + field.input.options[i].value;
																		return locale.getString(localeString)
																	}
																}
															case 'check':
																return field.input.options.reduce(function(total, option) {
																	if (total.length > 0)
																		total += ', ';

																	if (form_answers[option.model]) {
																		var localeString = custodianSlug ?
																			'nwui.forms.' + custodianSlug + '.questions.' + field.input.i18n + '.options.' + option.model :
																			'nwui.forms.questions.' + field.input.i18n + '.options.' + option.model;
																		return total + locale.getString(localeString);
																	} else {
																		return total;
																	}
																}, '');
															case 'input':
															default:
																return form_answers[field.input.model];
														}

													})(ResolveFormAnswers);

													if (field.is_review_required) {
														$scope.data.has_fields_for_review = true;
														var label = custodianSlug ?
															'nwui.forms.' + custodianSlug + '.questions.' + field.input.i18n + '.input.label' :
															'nwui.forms.questions.' + field.input.i18n + '.input.label';
														columns.push({
															width: (field.width > 50) ? 50 : field.width,
															directive: 'input',
															data: {
																directive: 'text',
																data: {
																	// label: field.label,
																	label_i18n: label,
																	model: value,
																	id: 'accounts-new-verify-full-form-input-' + field.input.model,
																	type: 'stacked',
																	// placeholder: 'Not set',
																	sortBy: field.input.sort_by,
																	options: field.input.options,
																	stretch: true,
																	required: true,
																	size: 'small',
																	post_var: field.input.model,
																	default_value: value,
																	disabled: true,
																	mask: field.input.mask
																}
															}
														})
													}

												});
											})
										});

										return columns;
									})()
								}]
							}),
							review: new NwuiFormV3Factory({
								name: 'accounts-new-verify-form',
								onSubmit: function(post_data) {
								},
								onComplete: function(obj) {
								},
								submit_button: false,
								steps: [{
									columns: [{
										directive: 'input',
										width: 100,
										data: {
											directive: 'radio',
											data: {
												type: 'stacked',
												model: 'no',
												id: 'accounts-new-verify-form-has-changes',
												stretch: true,
												required: true,
												options: [{
													label_i18n: "accounts.new.verify.form.option.no",
													value: 'no'
												}, {
													label_i18n: "accounts.new.verify.form.option.yes",
													value: 'yes'
												}],
												onChange: function(model) {

													if ($scope.data.review_personal_info) {
														if (model === 'yes') {
															$state.go('app.manage.personal_information', { redirect: 'account_opening', ownership: $stateParams.ownership_type, accounts: $stateParams.account_types })
														}
													} else {
														$scope.data.show_scheduler = (model === 'yes');
													}

												},
												post_var: 'hasChanges'
											}
										}
									}]
								}]
							}),
						}
					}
				},
				account: {
					// card: {
					// 	header: new NwuiCardHeaderV2Factory({
					// 		name_i18n: "accounts.new.account.header.name",
					// 		icon: {
					// 			name: 'account_balance'
					// 		}
					// 	})
					// },
					sections: (function(form) {

						data.full_form_answers['ownershipType'] = $stateParams.ownership_type;
						var account_types = $stateParams.account_types.split(',');
						var custodianSlug = ResolveFormQuestions.custodian_slug;
						var userOwnershipTypes = [];
						if (NwuiSession.user.clients) {
							userOwnershipTypes = Array.from(NwuiSession.user.clients.map(function(client) { return client.ownership_type}));
						}
						var isNewOwnership = !(userOwnershipTypes.includes(data.full_form_answers.ownershipType));

						if (
							$scope.data.single_account_select &&
							organization_data &&
							organization_data.port_data &&
							organization_data.port_data.accounts &&
							organization_data.port_data.accounts.account_type_field
						) {

							data.full_form_answers[organization_data.port_data.accounts.account_type_field] = account_types[0];

						} else {

							for (var i = 0; i < account_types.length; i++) {
								data.full_form_answers[account_types[i]] = true;
							}

						}

						account_types.push('ownershipType'); // what is the point of this string?

						var step_indexes = getAssociatedSteps(form, account_types);
						step_indexes.splice(0, account_types.length);

						var stepsWithModel = getStepsForNewAccountOpening(form);
						step_indexes = step_indexes.concat(stepsWithModel);

						var steps = form.steps.filter(function(step, i) {
							return (
								step_indexes.indexOf(i) !== -1 &&
								step.title !== 'Funds Transfer' &&
								step.title !== 'Brokerage Transfer'
							)
						});

						return steps.map(function(step, i) {
							$scope.data.has_form_questions = true;
							(function(step, i) {
								$timeout(function() {
									checkShow(i, step);
								}, 100);
							})(step, i);

							var name_i18n = custodianSlug ?
								'nwui.forms.' + custodianSlug + '.steps.' + step.key + '.title' :
								'nwui.forms.steps.' + step.key + '.title';


							return {
								show: true,
								header: new NwuiCardHeaderV2Factory({
									name_i18n: name_i18n,
									level: 2,
									icon: {
										name: 'assignment'
									}
								}),
								form: {
									full: new NwuiFormV3Factory({
										name: 'accounts-new-account-full-form-' + step.key,
										onSubmit: function(post_data) { },
										onComplete: function(obj) { },
										submit_button: false,
										steps: [{
											columns: (function(step_index) {
												var columns = [];
												var account_types = $stateParams.account_types.split(',');
												account_types.push('ownershipType');
												var models = getAssociatedModels(step, account_types);
												// if need to add any model for Communication and Consent
												// add it to config-default.json in config-api
												if (step.title === 'Communication and Consent') {
													models = [];
												}

												// add Select previous eft source question when it's the same ownership
												if (!isNewOwnership && step.title === 'Banking and Income Information') {
													columns.push(selectEftSourceQuestion);
												}


												var newAccountOpeningModels = getModelsForNewAccountOpeningStep(step);

												models = models.concat(newAccountOpeningModels);

												var questions = getTrimmedQuestions(step, models);
												applyQuestionWatchers(questions);
												applyStepWatchers(questions, steps);

												questions.forEach(function(field) {
													var directive = '';
													switch (field.input.input_type) {
														case 'disclaimer':
														case "subheading":
															directive = 'subheading';
															break;
														case "select":
															directive = 'select'
															break;
														case "radio":
															directive = 'radio'
															break;
														case "input":
															directive = 'text'
															break;
														case "check":
															directive = 'checkbox';
															break;
														case "upload":
															directive = 'upload';
															break;
														default:
															directive = 'text'
													}

													if (!ResolveFormAnswers[field.input.model] || step.title === 'Banking and Income Information') {
														ResolveFormAnswers[field.input.model] = '';
													}

													if (directive === 'checkbox') {
														for (var i = 0; i < field.input.options.length; i++) {
															field.input.options[i].label = field.input.options[i].text;
															var label_i18n = custodianSlug ?
																'nwui.forms.' + custodianSlug + '.questions.' + field.input.i18n + '.options.' + field.input.options[i].model :
																'nwui.forms.questions.' + field.input.i18n + '.options.' + field.input.options[i].model;
															field.input.options[i].label_i18n = label_i18n;
															field.input.options[i].value = ResolveFormAnswers[field.input.options[i].model] || false;
															ResolveFormAnswers[field.input.model] = [];
															field.input.options[i].ng_if = field.input.options[i].ng_if;
															field.input.options[i].watchers = field.input.options[i].watchers;
															field.input.options[i].validate = field.input.validate
														}
													}

													if (directive === 'radio') {
														// we can only change marital status if we leave the page and come back so
														// doing this here in initialization works fine in this case
														// regex checks if we have AH*BeneficiaryValue for our model to ensure we can automatically accomodate any account types added
														// going forward, any AH<ACCOUNT_TYPE>BeneficiaryValue model is automatically flagged to have its proper option removed for spouse
														// when marital status is single
														if(
															/^AH.+BeneficiaryValue$/.test(field.input.model) &&
															ResolveFormAnswers.AHMaritalStatusValue !== 'Married' &&
															ResolveFormAnswers.AHMaritalStatusValue !== 'CommonLaw'
														) {
															const spouseOptionIndex = field.input.options.findIndex(op => op.value === 'SurvivorHolder');
															if(spouseOptionIndex >= 0)
																field.input.options.splice(spouseOptionIndex, 1);
														}
														field.input.options = field.input.options.map(function(option, i) {
															var label_i18n = custodianSlug ?
																'nwui.forms.' + custodianSlug + '.questions.' + field.input.i18n + '.options.' + option.value :
																'nwui.forms.questions.' + field.input.i18n + '.options.' + option.value;
															return {
																id: i,
																label: option.text,
																label_i18n: label_i18n,
																value: option.value,
																ng_if: option.ng_if,
																watchers: option.watchers
															}
														})
													}

													if (directive === 'select') {
														field.input.options = field.input.options.map(function(option, i) {
															var label_i18n = custodianSlug ?
																'nwui.forms.' + custodianSlug + '.questions.' + field.input.i18n + '.options.' + option.value :
																'nwui.forms.questions.' + field.input.i18n + '.options.' + option.value;
															return {
																id: i,
																text: option.text,
																text_i18n: label_i18n,
																value: option.value,
																ng_if: option.ng_if,
																watchers: option.watchers
															}
														})
													}

													var label_i18n = (directive === 'subheading') ?
														field.input.model :
														custodianSlug ?
															'nwui.forms.' + custodianSlug + '.questions.' + field.input.i18n + '.input.label' :
															'nwui.forms.questions.' + field.input.i18n + '.input.label';

													var placeholder_i18n = custodianSlug ?
														'nwui.forms.' + custodianSlug + '.questions.' + field.input.i18n + '.input.placeholder' :
														'nwui.forms.questions.' + field.input.i18n + '.input.placeholder';


													columns.push({
														width: field.width,
														directive: 'input',
														data: {
															directive: directive,
															data: {
																label: field.label,
																label_i18n: label_i18n,
																type: 'stacked',
																// model: (directive==='checkbox') ? undefined : '',
																model: (directive === 'checkbox') ? field.input.options : ResolveFormAnswers[field.input.model],
																url: (directive === 'upload') ? '/api/documents' : '',
																id: 'accounts-new-account-full-form-input-' + step_index + '-' + field.input.model,
																placeholder_i18n: placeholder_i18n,
																size: 'short',
																maxlength: field.input.maxlength,
																required: (field.input.optional !== true),
																totalOptions: field.input.options, // keeps the total / original options intact
																options: field.input.options.filter(option => !option.ng_if || ngIfEvaluator(option.ng_if)),
																post_var: field.input.model,
																validators: field.input.validate ? [field.input.validate] : [],
																mask: field.input.mask,
																sortBy: field.input.sort_by,
																default_value: field.input.default_value || '',
																onChange: function(model) {

																	switch (directive) {
																		case 'select':

																		case 'radio':
																			var post_var = this.post_var;
																			this.options.forEach(function(option) {
																				if (option.value === model) {
																					data.full_form_answers[post_var] = option.value;
																				}
																			});
																			break;
																		case 'checkbox':
																			this.options.forEach(function(option) {
																				model.forEach(function(item) {
																					if (option.model === item.model && data.full_form_answers[option.model] !== item.value) {
																						data.full_form_answers[option.model] = item.value;
																					}
																				});
																			});
																			break;
																		//text
																		default:
																			data.full_form_answers[this.post_var] = model;
																	}

																	// New questions
																	field.question_watchers.forEach(function(field) {
																		checkShow(step_index, field);
																	});

																	// New options
																	field.input?.options?.forEach(function (option) {
																		if(option.watchers?.length > 0)
																			checkShowOptions(step_index, option);
																	})

																	// New steps
																	field.step_watchers.forEach(function(step) {
																		checkShow(step_index, step);

																		// questions within new steps
																		if(step.rows) {
																			for (var j = 0; j < step.rows.length; j++) {
																				for (var k = 0; k < step.rows[j].fields.length; k++) {
																					var newFieldWithinStep = step.rows[j].fields[k];
																					checkShow(step.model, newFieldWithinStep)
																				}
																			}
																		}
																	})

																	// agreedToBill64
																	if ($scope.data.enableBill64 && data.full_form_answers.AHBill64AgreementConsentValue) {
																		$scope.data.agreedToBill64 = true;
																	} else {
																		$scope.data.agreedToBill64 = false;
																	}
																},
																onSuccess: (directive === 'upload') ? function(res) {
																	data.full_form_answers[this.post_var] = res.data.file_name;
																	this.model = res.data.file_name;
																} : undefined
															}
														}
													})

													if (field.question_watchers && field.question_watchers.length > 0) {
														// $scope.modules.risk_profile.card.header.icon.name = 'looks_3';
														(function() {
															$timeout(function() {
																field.question_watchers.forEach(function(field) {
																	checkShow(step_index, field);
																})
															}, 0);
														})(field);
													}

													field.input?.options?.forEach(function (option) {
														if (option.watchers?.length > 0) {
															(function() {
																$timeout(function() {
																	checkShowOptions(step_index, option);
																}, 0);
															})();
														}
													})

													if (field.step_watchers && field.step_watchers.length > 0) {
														// $scope.modules.risk_profile.card.header.icon.name = 'looks_3';
														(function() {
															$timeout(function() {
																field.step_watchers.forEach(function(step) {
																	checkShow(step_index, step);
																	// questions within new steps
																	if(step.rows) {
																		for (var j = 0; j < step.rows.length; j++) {
																			for (var k = 0; k < step.rows[j].fields.length; k++) {
																				var newFieldWithinStep = step.rows[j].fields[k];
																				checkShow(step.model, newFieldWithinStep)
																			}
																		}
																	}
																})
															}, 0);
														})(field);
													}

													// Make sure to check ng-if of current field as well since there might be no watchers
													// connected to it.
													(function() {
														$timeout(function() {
															checkShow(step_index, field);
														}, 0);
													})();
												});
												return columns;
											})(i)
										}]
									})
								}
							}
						})

					})(ResolveFormQuestions.form_instance),
				},
				manage_risk_profile_editor: {
					resource_id: ResolveLatestRiskProfile.kyc_instance._id,
					current_risk_profile: ResolveLatestRiskProfile,
					new_risk_profile: ResolveLatestRiskProfile,
					previewResource: data.can_preview ? ApiUserService.updateRiskProfile : undefined,
					previewResourceMap: data.can_preview ? function(res) { return res.data; } : undefined
				},
				access_code: {
					card: {
						header: new NwuiCardHeaderV2Factory({
							name_i18n: "accounts.new.access_code.header.name",
							level: 2,
							icon: {
								name: 'vpn_key'
							}
						})
					}
				},
				submit: {
					id: 'accounts-new-form-submit-button',
					text_i18n: 'nwui.common.form.button.submit',
					color: 'warning',
					icon: {
						name: 'check'
					},
					onClick: function() {

						NwButtonService.buttons[$scope.modules.submit.id].processing = true;
						var valid = true;
						if ($scope.data.has_form_questions) {
							for (var i = 0; i < $scope.modules.account.sections.length; i++) {
								if ($scope.modules.account.sections[i].show)
									valid = NwuiFormV3Service.validate($scope.modules.account.sections[i].form.full.name);

								if (valid !== true) {
									NwButtonService.buttons[$scope.modules.submit.id].processing = false;
									return;
								}
							}
						}

						var form_data = {};
						for (var i = 0; i < $scope.modules.account.sections.length; i++) {
							if ($scope.modules.account.sections[i].show)
								form_data = Object.assign(form_data, NwuiFormV3Service.getFormData($scope.modules.account.sections[i].form.full.name))
						}

						console.log('use_rpq_aao', organization_data.use_rpq_aao)
						if(!organization_data.use_rpq_aao) {
							var risk_profile_data = $scope.modules.manage_risk_profile_editor.getFormData();
							valid = $scope.modules.manage_risk_profile_editor.validateForm() && !isKYCBlocking(risk_profile_data);
						}

						if(!valid) {
							NwuiToastManagerService.register(
								new NwuiToastFactory({
									text_i18n: 'accounts.new.submit.error.contact_pm',
									type: 'error',
									icon: {
										name: 'close'
									}
								}
							));

							return NwButtonService.buttons[$scope.modules.submit.id].processing = false;
						}

						ApiUserService.submitEsignEvent(data.active_esign_event, {
							ownershipType: $stateParams.ownership_type,
							accounts: $stateParams.account_types.split(','),
							type: 'AccountOpening',
							kyc_answers: risk_profile_data ? risk_profile_data: {},
							tombstone: ($scope.data.has_form_questions) ? form_data : {},
							accessCodeId: ($scope.data.access_code && $scope.data.joint_code_required) ? $scope.data.access_code.id : undefined
						}).then(function(obj) {

							ApiUserService.getEsignEvents();

							if(organization_data.use_rpq_aao) {
								return go_to_rpq_aao($stateParams.account_types)
							} else {
								getEsignEventSign(obj.data._id);
							}

						}).catch(function(err) {

							handleErrorToast(err);
							//check model on all steps
							var step_match = 0;
							for (var i = 0; i < $scope.modules.account.sections.length; i++) {
								console.log('err.data.model', err)
								if (NwuiInputService.inputs['accounts-new-account-full-form-input-' + i + '-' + err.data.model[0]]) {
									step_match = i;
									break;
								}
							}

							if (NwuiInputService.inputs['accounts-new-account-full-form-input-' + step_match + '-' + err.data.model[0]]) {
								NwuiInputService.inputs['accounts-new-account-full-form-input-' + step_match + '-' + err.data.model[0]].error = true;
								NwuiInputService.inputs['accounts-new-account-full-form-input-' + step_match + '-' + err.data.model[0]].notification.show = true;
								NwuiInputService.inputs['accounts-new-account-full-form-input-' + step_match + '-' + err.data.model[0]].notification.error = true;
								NwuiInputService.inputs['accounts-new-account-full-form-input-' + step_match + '-' + err.data.model[0]].notification.text = locale.getString('nwui.common.server_errors.' + (err.data.modelErrorMessage || err.data.error_slug), err.data.i18n_data);
								NwuiInputService.inputs['accounts-new-account-full-form-input-' + step_match + '-' + err.data.model[0]].notification.text_i18n = undefined;
								NwuiInputService.inputs['accounts-new-account-full-form-input-' + step_match + '-' + err.data.model[0]].focusCb();
							}
						})
					}
				}
			}

			ApiUserService.getEsignEvents(function(obj) {
				if (obj.data && obj.data.data.length > 0) {
					data.active_esign_event = obj.data.data[0]._id
				}
			});

			// Error handling
			var handleErrorToast = function(err) {
				NwButtonService.buttons[$scope.modules.submit.id].processing = false;

				if (err.data && err.data.nw_error_code && (!err.data.model || err.data.model.length === 0)) {

					switch (err.data.nw_error_code) {
						case 4000:
							NwuiToastManagerService.register(
								new NwuiToastFactory({
									text_i18n: 'accounts.new.submit.error.in_progress',
									type: 'error',
									icon: {
										name: 'close'
									}
								}
								));
							break;
						default:
							NwuiToastManagerService.register(
								new NwuiToastFactory({
									text_i18n: 'nwui.common.error.unknown',
									type: 'error',
									icon: {
										name: 'close'
									}
								}
								));
					}

					return;

				}
			};

		}
	]);
