angular.module('portfolio_web.manage.security.controllers', [])

.controller('ManageSecurityController', [
	'$q',
	'$scope',
	'$timeout',
	'ApiClientsService',
	function(
		$q,
		$scope,
		$timeout,
		ApiClientsService
	) {
	
		////console.log('ManageSecurityController', 'init');
		
		$scope.data = {
			resources: undefined
		}

		$scope.blocks = {
			breadcrumbs: {
				root: {
					icon: {
						name: 'home'
					},
					state: 'app.summary'
				}
			}
		}

		var loadResources = function() {
			var deferred = $q.defer()
		
			ApiClientsService.getResources().then(function(obj) {
				deferred.resolve(obj.data);
			});
	
			return deferred.promise;
		}

		$timeout(function() {
			loadResources().then(function(obj) {
				$scope.data.resources = obj.resource.manage.tertiaryNav.security;
			})
		});
		
	}
]);