angular.module('portfolio_web.summary.savings.directives', [])

.directive('summarySavings', [
	'$rootScope',
	'NwuiLegacyModalService', 
	'ApiClientsService',
	'NwuiSession',
	'NwuiModalV2Factory',
	'NWUI_AUTH_EVENTS',
	function(
		$rootScope,
		NwuiLegacyModalService,
		ApiClientsService,
		NwuiSession,
		NwuiModalV2Factory,
		NWUI_AUTH_EVENTS
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: 'portfolio_webserver/summary/savings/template.html',
			scope: {
				show_advisor: "=?showAdvisor"
			},
			link: function(scope, element, attrs) {
				
				scope.data = {
					user: NwuiSession,
					advisor: NwuiSession.user.Advisor,
					savings: undefined
				}

				scope.shortName = window.organization_data.name_short;

				scope.modals = {
					savings_today: new NwuiModalV2Factory({
						open: false,
						locked: false,
						wide: true,
						header: {
							name_i18n: 'summary.savings.modal.savings_today.title',
							level: 2,
							icon: {
								name: 'attach_money'
							}
						}
					}),
					savings_all: new NwuiModalV2Factory({
						open: false,
						locked: false,
						wide: true,
						header: {
							name_i18n: 'summary.savings.modal.total_savings.title',
							level: 2,
							icon: {
								name: 'attach_money'
							}
						}
					})
				}

				scope.$watch('data.user', function(new_val, old_val) {

					if(new_val === undefined) return;

					ApiClientsService.getSavings(NwuiSession.user.client_id).then(function(obj) {
						scope.data.savings = obj.data.savings;
						scope.loaded = true;
					});

				});

				scope.active_tab = 1;
				
				scope.changeTab = function(tab_num) {
					scope.active_tab = tab_num;
				}
				
				scope.showDisclaimer = function(key) {
					scope.modals[key].open = true;
				}
				
			}
		}
	}
]);