angular.module('portfolio_web.manage.general.controllers', [])

.controller('ManageGeneralController', [
	'$q',
	'$sce',
	'$scope', 
	'$timeout',
	
	//'SELECT_OPTIONS',
	
	'NwuiCardHeaderFactory',
	'NwuiFormFactory',
	'NwuiToastFactory',
	'NwuiModalFactory',
	//'NwuiMapFactory',
	
	'NwuiSession',
	'UserService',
	'UserProfileService',
	'NwuiInputService',
	'NwuiToastManagerService',
	'NwuiValidationErrorService',
	function(
		$q,
		$sce,
		$scope, 
		$timeout,
		
		//SELECT_OPTIONS,
		
		NwuiCardHeaderFactory,
		NwuiFormFactory,
		NwuiToastFactory,
		NwuiModalFactory,
		//NwuiMapFactory,
		
		NwuiSession,
		UserService,
		UserProfileService,
		NwuiInputService,
		NwuiToastManagerService,
		NwuiValidationErrorService
	) {
	
		////console.log('ManageGeneralController Loaded');
		
		$scope.data = {
			user_profile: undefined
		}
		
		$scope.cards = {
			profile: {
				header: new NwuiCardHeaderFactory({
					name: 'Basic Profile',
			        icon: {
				        name: 'person'
			        },
			        button: {
				        id: 'open-password-button',
				        stretch: true,
				        text: 'Update Password',
				        icon: 'lock',
				        onClick: function() {
					        $scope.modals.password.open = true;
						}
			        }
				}),
				form: new NwuiFormFactory({
			    	name: 'manage-general-profile-form',
			    	onSubmit: function() {
				    	var user_profile = {
					    	first_name: NwuiInputService.inputs['manage-general-profile-form-first-name'].model,
					    	last_name: NwuiInputService.inputs['manage-general-profile-form-last-name'].model,
					    	primary_phone: NwuiInputService.inputs['manage-general-profile-form-phone'].model
				    	}
				
				    	return UserProfileService.update({ client_id: NwuiSession.user.client_id }, user_profile).$promise;
			    	},
			    	onComplete: function(obj) {
				    	NwuiToastManagerService.register(
							new NwuiToastFactory({
								text: 'Profile updated successfully',
								icon: {
									name: 'check'
								}
							})
						)
			    	},
			    	columns: [{
				        width: 25,
				        directive: 'input',
				        data: {
					        directive: 'text',
					        data: {
						        id: 'manage-general-profile-form-first-name',
						        label: 'First name',
						        model: '',
						        placeholder: 'John',
						        required: true
						    }
					    }
			        },{
				        width: 30,
				        directive: 'input',
				        data: {
					        directive: 'text',
					        data: {
						        id: 'manage-general-profile-form-last-name',
						        label: 'Last name',
						        model: '',
						        placeholder: 'Smith',
						        required: true
						    }
					    }
			        },{
				        width: 25,
				        directive: 'input',
				        data: {
					        directive: 'text',
					        data: {
						        id: 'manage-general-profile-form-phone',
						        type: 'tel',
						        label: 'Phone number',
						        model: '',
						        mask: 'phone',
						        validators: ['phone'],
						        placeholder: '(647) 725-2559',
						        required: true
						    }
					    }
			        },{
				        width: 20,
				        directive: 'button',
				        data: {
					        id: 'manage-general-profile-form-submit',
					        stretch: true,
					        text: 'Update',
					        onClick: function() { return; }
				        }
			        }]
			    })
			},
			address: {
				header: new NwuiCardHeaderFactory({
					name: 'Mailing Address',
			        icon: {
				        name: 'mailbox'
			        }
				})
			}
		}
		
		$scope.modals = {
			password: new NwuiModalFactory({
				open: false,
			    wide: false,
		        directive: 'form',
		        data: {
			    	header: new NwuiCardHeaderFactory({
						name: 'Update Password',
				        icon: {
					        name: 'lock'
				        }
					}),
					name: 'manage-general-password-form',
			        onSubmit: function() {
				        
				        if(NwuiInputService.inputs['form-input-manage-password-1'].model != NwuiInputService.inputs['form-input-manage-password-2'].model) {
					        
					        var deferred = $q.defer();
					        
					        NwuiInputService.inputs['form-input-manage-password-2'].error = true;
						    NwuiInputService.inputs['form-input-manage-password-2'].notification.error = true;
						    NwuiInputService.inputs['form-input-manage-password-2'].notification.text = $sce.trustAsHtml(NwuiValidationErrorService['password_match']());
							NwuiInputService.inputs['form-input-manage-password-2'].notification.show = true;
							
							$timeout(function() {
								deferred.resolve(false);
							}, 250);
							
					        return deferred.promise;
					        
				        }
				        
				        var data = {
							password: NwuiInputService.inputs['form-input-manage-password-2'].model
						}
						
						return UserService.updatePassword(data);
				    },
			        onComplete: function(obj) {
				        if(!obj) {
					        return;
				        }
				        
				        NwuiInputService.inputs['form-input-manage-password-1'].model = '';
						NwuiInputService.inputs['form-input-manage-password-2'].model = '';
				        
				        NwuiToastManagerService.register(
							new NwuiToastFactory({
								text: 'Password Updated Successfully',
								icon: {
									name: 'check'
								}
							})
						)
						
						$scope.modals.password.open = false;
				    },
				    columns: [{
				        width: 100,
				        directive: 'input',
				        data: {
					        directive: 'text',
					        data: {
						        id: 'form-input-manage-password-1',
						        label: 'New password',
						        model: '',
						        placeholder: '********',
						        validators: ['password'],
						        type: 'password',
						        required: true
						    }
					    }
			        },{
				        width: 100,
				        directive: 'input',
				        data: {
					        directive: 'text',
					        data: {
						        id: 'form-input-manage-password-2',
						        label: 'Confirm new password',
						        model: '',
						        placeholder: '********',
						        validators: ['password'],
						        type: 'password',
						        required: true
						    }
					    }
			        },{
				        width: 100,
				        directive: 'button',
				        data: {
					        id: 'form-button-manage-password-submit',
					        stretch: true,
					        text: 'Update',
					        onClick: function() { return; }
				        }
			        }]
		        }		     
			})
		}
		
		$scope.$watch('data.user_profile', function(new_val) {
			
			if(new_val !== undefined) {
				NwuiInputService.inputs['manage-general-profile-form-first-name'].model = new_val.first_name;
				NwuiInputService.inputs['manage-general-profile-form-last-name'].model = new_val.last_name;
				NwuiInputService.inputs['manage-general-profile-form-phone'].model = new_val.primary_phone;
			} else {
				UserProfileService.get({ client_id: NwuiSession.user.client_id }).$promise.then(function(user_profile) {
					$scope.data.user_profile = user_profile;
				});
			}
			
		});
		
	}
]);