angular.module('portfolio_web.manage.controllers', [])

.controller('ManageController', [
	'$scope',
	'$timeout',
	'$q',
	'NwuiCompoundMenuTertiaryFactory',
	'ApiClientsService',
	'ResolveResources',
	function(
		$scope,
		$timeout,
		$q,
		NwuiCompoundMenuTertiaryFactory,
		ApiClientsService,
		ResolveResources
	) {

		////console.log('ManageController Loaded');
		console.log('managecontroller scope', $scope);
		console.log('managecontroller resolve resources', ResolveResources)
		$scope.data.resources = ResolveResources.resource.manage;
		// $scope.data.esignevents = $scope.$parent.$parent.$parent.data.esignevents;

		$scope.compounds = {
			menu_tertiary: {
				// section: 'Manage',
				section_i18n: 'nav.manage',
				items: [],
				open: false
			}
		}

			var menu_items = [];

			for(var key in $scope.data.resources.tertiaryNav) {

				switch(key) {
					case 'security':
						menu_items.push({
							state: 'app.manage.password',
							// text: 'Security',
							text_i18n: 'nav.security',
							icon: {
								name: 'lock'
							}
						});
						break;
					case 'plan_preferences':
						menu_items.push({
							state: 'app.manage.plan_preferences',
							// text: 'Security',
							text_i18n: 'nav.plan_preferences',
							icon: {
								name: 'tune'
							}
						});
						break;
					case 'documents':
						menu_items.push({
							state: 'app.manage.documents',
							// text: 'My Documents',
							text_i18n: 'nav.documents',
							icon: {
								name: 'description'
							}
						});
						break;
					case 'risk_profiles':
						menu_items.push({
							state: 'app.manage.risk_profiles.list',
							active_state: 'app.manage.risk_profiles',
							// text: 'Risk Profiles',
							text_i18n: 'nav.risk_profiles',
							icon: {
								name: 'donut_large'
							}
						});
						break;
					case 'account_statements':
						menu_items.push({
							state: 'app.manage.account_statements',
							// text: 'Account Statements',
							text_i18n: 'nav.account_statements',
							icon: {
								name: 'library_books'
							}
						});
						break;
					case 'transfer_sources':
						if (!organization_data.port_data.hide_transfers) {
							menu_items.push({
								state: 'app.manage.transfer_sources.list',
								// text: 'Transfer Sources',
								text_i18n: 'nav.transfer_sources',
								icon: {
									name: 'payment'
								}
							});
						}
						break;
					case 'investment_loans':
						if (organization_data.port_data.accounts.borrow_to_invest &&
							organization_data.port_data.accounts.borrow_to_invest.enabled) {
							menu_items.push({
								state: 'app.manage.investment_loans',
								text_i18n: 'nav.investment_loans',
								icon: {
									name: 'keyboard_return'
								}
							});
						}
						break;
					// temp hidden from UI
					case 'withdrawals':
						menu_items.push({
							state: 'app.manage.withdrawals.list',
							// text: 'Withdrawal',
							text_i18n: 'nav.withdrawals',
							icon: {
								name: 'keyboard_return'
							}
						});
						break;
					case 'referrals':
						menu_items.push({
							state: 'app.manage.referrals',
							// text: 'Referrals',
							text_i18n: 'nav.referrals',
							icon: {
								name: 'people'
							}
						});
						break;
				}

			}

			$scope.compounds.menu_tertiary.items = menu_items;

	}
]);