angular.module('portfolio_web.api.idVerification.services', [])
.service('IdVerificationService', ['$http', '$q', function($http, $q) {
	this.checkIfVerified = function(userId) {
		return $http.get('/api/users/' + userId + '/id-verified');
	}

	this.getVerificationDocuments = function(id) {
		return $http.get('/api/id-verifications/' + id + '/documents');
	}

	this.requestVerificationApproval = function(id, postData) {
		return $http.patch('/api/id-verifications/' + id + '/request-approval', postData);
	}

	this.getDocumentTypes = function() {
		return $http.get('/api/id-verification-documents/types');
	}
}]);