angular.module('portfolio_web.transfers.new.type.destination.config', [
  'ui.router'
])

.config([
  '$stateProvider', 
  '$locationProvider', 
  '$urlRouterProvider', 

  'NWUI_AUTH_ROLES',
  function(
    $stateProvider, 
    $locationProvider, 
    $urlRouterProvider, 

    NWUI_AUTH_ROLES
  ) {

    var route = {
      controller : 'TransfersNewTypeDestinationController',
      url : '/:account_id',
      params: {
        account_id: null
      },
      templateUrl: 'portfolio_webserver/transfers/new/type/destination/template.html',
      data: {
        page_title_i18n: 'transfers.new.nav.destination',
        icon_name: 'compare_arrows',
        breadcrumb_label: false,
        auth_roles: [NWUI_AUTH_ROLES.user]
      },
      onEnter: [
        '$timeout',
        function(
          $timeout
        ) {
          // $timeout(function() {
          //   $('html, body').stop(false, false).animate({
          //     scrollTop: $("#app-transfers-new-type-destination").offset().top - 140
          //   }, 500);
          // }, 100)
        }
      ],
      resolve: {
        ResolveRiskProfile: [
          '$q',
          '$stateParams',
          'ApiGoalsService',
          'RiskProfileService',
          'ApiAccountsService',
          function(
            $q,
            $stateParams,
            ApiGoalsService,
            RiskProfileService,
            ApiAccountsService
          ) {

            var deferred = $q.defer();
            
            // ApiGoalsService.getGoals().then(function(obj) {

            //  RiskProfileService.getRiskProfile(obj.data[0].id).then(function(obj) {
            //    deferred.resolve(obj.risk_profiles.current);
            //  });

            // });

            ApiAccountsService.getRiskProfiles($stateParams.account_id).then(function(obj) {
              deferred.resolve(obj.data[0].current);
            }, function() {
              deferred.resolve([]);
            })
            
            return deferred.promise;
            
          }
        ]
      }
    }

    $stateProvider.state('app.transfers.new.type.destination', route);

  }
]);