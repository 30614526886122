angular.module('portfolio_web.transfers.new.type.config', [
  'ui.router'
])

.config([
  '$stateProvider', 
  '$locationProvider', 
  '$urlRouterProvider', 

  'NWUI_AUTH_ROLES',
  function(
    $stateProvider, 
    $locationProvider, 
    $urlRouterProvider, 

    NWUI_AUTH_ROLES
  ) {

    var route = {
      controller : 'TransfersNewTypeController',
      url : '/:transfer_type',
      params: {
        transfer_type: null
      },
      templateUrl: 'portfolio_webserver/transfers/new/type/template.html',
      data: {
        page_title_i18n: 'transfers.new.nav.type',
        icon_name: 'compare_arrows',
        breadcrumb_label: false,
        // breadcrumb_proxy: 'app.transfers.new',
        auth_roles: [NWUI_AUTH_ROLES.user]
      },
      onEnter: [
        '$timeout',
        function(
          $timeout
        ) {
          // $timeout(function() {
          //   $('html, body').stop(false, false).animate({
          //     scrollTop: $("#app-transfers-new-type").offset().top - 140
          //   }, 500);
          // }, 100)
        }
      ],
      resolve: {
        ResolveDestinationAccounts: [
          '$q',
          '$stateParams',
          'ApiAccountsService', 
          function(
            $q,
            $stateParams, 
            ApiAccountsService
          ) {

            var deferred = $q.defer();

            switch($stateParams.transfer_type) {
              case 'investment':
                ApiAccountsService.getBrokerageAccountDestinations().then(function(obj) {
                  deferred.resolve(obj.data);
                });
                break;
              case 'chequing-savings':
                ApiAccountsService.getAccountDestinations().then(function(obj) {
                  deferred.resolve(obj.data);
                });
                break;
              default:
                deferred.resolve([]);
            }

            return deferred.promise;
            
          }
        ]
      }
    }

    $stateProvider.state('app.transfers.new.type', route);

  }
]);