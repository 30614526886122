angular.module('portfolio_web.api.accounts.services', [])

.service('ApiAccountsService', [
	'$rootScope',
	'$http',
	'$q',
	'$timeout',
	'$stateParams',
	'NwuiSession',
	'PORTFOLIO_WEBSERVER',
	'CacheService',
	function(
		$rootScope,
		$http,
		$q,
		$timeout,
		$stateParams,
		NwuiSession,
		PORTFOLIO_WEBSERVER,
		CacheService
	) {

		this.approveRiskProfile = function(account_id, risk_profile_id) {
			return $http.patch(PORTFOLIO_WEBSERVER.app + '/api/accounts/' + account_id + '/risk-profiles/' + risk_profile_id, {
				is_client_approved: true
			})
		}

		this.getAssetMix = function(account_id, risk_profile_id) {
			if(account_id) {
				return $http.get(PORTFOLIO_WEBSERVER.app + '/api/accounts/' + account_id + '/risk-profiles/' + risk_profile_id + '/asset-mix');
			} else {
				return $http.get(PORTFOLIO_WEBSERVER.app + '/api/risk-profiles/' + risk_profile_id + '/asset-mix');
			}
		}

		var self = this;

		this.getAccounts = function(force) {

			var include_aggregate = include_aggregate || false;

			var deferred = $q.defer()

			if(cache.get('getAccounts') && force !== true)
				deferred.resolve(cache.get('getAccounts'));
			else {
				$http.get(PORTFOLIO_WEBSERVER.app + '/api/accounts/1/?aggregate=false').then(function(obj){
					cache.set('getAccounts', obj);
					deferred.resolve(obj)
				}, function(err){
					////console.log(err)
					deferred.reject(err)
				})
			}

			return deferred.promise

		}

		this.getAccountsWithAggregate = function(force) {

			var deferred = $q.defer()

			if(cache.get('getAccountsWithAggregate') && force !== true)
				deferred.resolve(cache.get('getAccountsWithAggregate'));
			else {
				$http.get(PORTFOLIO_WEBSERVER.app + '/api/accounts/1/?aggregate=false').then(function(obj){
					cache.set('getAccountsWithAggregate', obj);
					deferred.resolve(obj)
				}, function(err){
					////console.log(err)
					deferred.reject(err)
				})
			}

			return deferred.promise

		}

		this.getBrokerageAccountDestinations = function(force) {

			var deferred = $q.defer()

			if(cache.get('getBrokerageAccountDestinations') && force !== true)
				deferred.resolve(cache.get('getBrokerageAccountDestinations'));
			else {
				$http.get(PORTFOLIO_WEBSERVER.app + '/api/accounts/brokerage-transfer-destinations').then(function(obj){
					cache.set('getBrokerageAccountDestinations', obj);
					deferred.resolve(obj)
				}, function(err){
					////console.log(err)
					deferred.reject(err)
				})
			}

			return deferred.promise

		}

		this.getAccountDestinations = function(force) {

			var deferred = $q.defer()

			if(cache.get('getAccountDestinations') && force !== true)
				deferred.resolve(cache.get('getAccountDestinations'));
			else {
				$http.get(PORTFOLIO_WEBSERVER.app + '/api/accounts/bank-transfer-destinations').then(function(obj){
					cache.set('getAccountDestinations', obj);
					deferred.resolve(obj)
				}, function(err){
					////console.log(err)
					deferred.reject(err)
				})
			}

			return deferred.promise

		}

		this.getEftRequests = function(account) {

			var deferred = $q.defer()

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/accounts/' + account.id + '/eft-requests').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise

		}

		this.getAllocation = function(account) {

			var deferred = $q.defer()

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/accounts/' + account.id + '/allocation').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise

		}

		this.getHoldings = function(account) {

			var deferred = $q.defer()

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/accounts/' + account.id + '/holdings').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise

		}

		/*
		this.getBalance = function(account) {

			var deferred = $q.defer()

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/accounts/1/' + account.id + '/balance').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise

		}
		*/

		this.getBalanceSeries = function(account) {

			var deferred = $q.defer()

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/accounts/1/' + account.id + '/balance-series').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise

		}

		this.getPerformance = function(account) {

			var deferred = $q.defer()

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/accounts/1/' + account.id + '/performance').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise

		}

		this.getPerformanceSeries = function(account) {

			var deferred = $q.defer()

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/accounts/1/' + account.id + '/performance-series').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise

		}

		this.getRiskProfiles = function(account_id) {

			var deferred = $q.defer()

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/accounts/' + account_id + '/risk-profiles').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise

		}

		var cache = CacheService.register(this);
		this.cache = cache;

	}
]);
