angular.module('portfolio_web.api.legacy.services', [])

.service('ModalService', [function() {

	var self = this;
	this.modals = {};

	this.register = function(modal_name, cb) {
		self.modals[modal_name] = cb;
	};

}])

.service('UserService', [
	'$http',
	'$q',
	'NwuiSession',
	'PORTFOLIO_WEBSERVER',
	function(
		$http,
		$q,
		NwuiSession,
		PORTFOLIO_WEBSERVER
	) {

		var self = this;

		//update password for user
		this.updatePassword = function(data) {

			var deferred = $q.defer();

			$http.post(PORTFOLIO_WEBSERVER.app + '/api/user/' + NwuiSession.user.uuid + '/update_password', data).then(function(res, status) {
				deferred.resolve(res.data);
			}, function(data, status) {
	            deferred.reject(data);
	        });

		    return deferred.promise;

		}

	}
])

.service('UserProfileService', [
	'$resource',
	'PORTFOLIO_WEBSERVER',
	function(
		$resource,
		PORTFOLIO_WEBSERVER
	) {

		return $resource(PORTFOLIO_WEBSERVER.app + '/api/client/:client_id/user', null, {
			'update': { method: 'PUT' }
		});

	}
])

.service('ClientsService', [
  '$http',
  'PORTFOLIO_WEBSERVER',
  function(
    $http,
    PORTFOLIO_WEBSERVER
  ) {
    this.getMyClients = function() {
      return $http
        .get(PORTFOLIO_WEBSERVER.app + '/api/clients/all')
        .then(function(res) {
          return res.data
        });
    }
  }
])

.service('GoalService', [
	'$rootScope',
	'$http',
	'$q',
	'$timeout',
	'NwuiSession',
	'NWUI_AUTH_EVENTS',
	'PORTFOLIO_WEBSERVER',
	function(
		$rootScope,
		$http,
		$q,
		$timeout,
		NwuiSession,
		NWUI_AUTH_EVENTS,
		PORTFOLIO_WEBSERVER
	) {

		var self = this;
		var reset = function() {
			return {
				getGoalsSimple: null,
				getGoals: null,
				getGoalDetailById: []
			}
		};

		var cache = reset();

		//TODO: FIX ON API
		var bandiadPeriods = function(periods) {

			////console.log('PERF', periods);

			var new_periods = [{
				name: "This Week",
				change: {
					percent: 0,
					dollar: 0
				}
			}, {
				name: "This Month",
				change: {
					percent: 0,
					dollar: 0
				}
			}, {
				name: "This Year",
				change: {
					percent: 0,
					dollar: 0
				}
			}, {
				name: "All Time",
				change: {
					percent: 0,
					dollar: 0
				}
			}]

			periods.forEach(function(o,i) {
				////console.log(o);
			});

			return new_periods;

		}

		//clear the goal data cache
		this.clearCache = function() {
			cache = reset();
		}

		//get all goals for user - includes only goal id, name
		this.getGoalsSimple = function() {

			var deferred = $q.defer();

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/client/' + NwuiSession.user.client_id + '/goals-simple/').then(function(res, status) {
		        deferred.resolve(res.data);
		    }, function(data, status) {
			    deferred.reject(data);
			});

			return deferred.promise;

		}

		//get all goals for user - excludes performance/balance series data
		this.getGoals = function() {

			var deferred = $q.defer();

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/client/' + NwuiSession.user.client_id + '/goals/').then(function(res, status) {

				//bandaid perf/balance periods
				res.data.goals.forEach(function(goal,i) {
					if(parseInt(goal.holdings, 10) == 0) {
		                goal.performance.periods = bandiadPeriods(goal.performance.periods);
		            }
		        })

		        deferred.resolve(res.data);
		    }, function(data, status) {
			    deferred.reject(data);
			});

			return deferred.promise;

		}

		//get goal detial by id - includes performance/balance series data [DEPRICATED]
		this.getGoalDetailById = function(goal_id) {

			var deferred = $q.defer();

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/client/' + NwuiSession.user.client_id + '/goals/' + goal_id + '/detail' ).then(function(res, status) {

				//bandaid perf/balance periods
				if(parseInt(res.data.goal.holdings, 10) == 0) {
	                res.data.goal.performance.periods = bandiadPeriods(res.data.goal.performance.periods);
	                res.data.goal.balance.periods = bandiadPeriods(res.data.goal.balance.periods);
	            }

		        deferred.resolve(res.data);
		    }, function(data, status) {
			    deferred.reject(data);
			});

			return deferred.promise;

		}

		$rootScope.$on(NWUI_AUTH_EVENTS.loginSuccess, function() {
			cache = reset();
		});

	}
])

.service('EftService', [
	'$rootScope',
	'$http',
	'$q',
	'$timeout',
	'NwuiSession',
	'NWUI_AUTH_EVENTS',
	'PORTFOLIO_WEBSERVER',
	function(
		$rootScope,
		$http,
		$q,
		$timeout,
		NwuiSession,
		NWUI_AUTH_EVENTS,
		PORTFOLIO_WEBSERVER
	) {

		var self = this;
		var reset = function reset() {
			return {
				getEfts: []
			}
		}
		var cache = reset();

		this.getEfts = function getEfts(client_id) {

			client_id = client_id || NwuiSession.user.client_id

			var deferred = $q.defer();

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/client/' + client_id + '/efts').then(function(obj) {
				deferred.resolve(obj);
			}, function(err) {
				console.error(err);
				deferred.reject(err);
			});

			return deferred.promise;
		};

		this.getEftsAccounts = function getEftsAccounts() {

			var deferred = $q.defer();

			var userUuid = NwuiSession.user.uuid;

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/users/' + userUuid + '/efts/accounts').then(function(obj) {
				deferred.resolve(obj);
			}, function(err) {
				console.error(err);
				deferred.reject(err);
			});
			return deferred.promise;
		};

		this.getPendingEftSources = function getPendingEftSources(client_id) {
			client_id = client_id || NwuiSession.user.client_id

			var deferred = $q.defer();

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/client/' + client_id + '/efts/requested-sources').then(function(obj) {
				deferred.resolve(obj);
			}, function(err) {
				console.error(err);
				deferred.reject(err);
			});

			return deferred.promise;
		};

		this.createEftWithAccounts = function(data, source_type) {

			var deferred = $q.defer();

			if (!source_type) source_type = 'manual';

      var url = PORTFOLIO_WEBSERVER.app +
        '/api/client/' + NwuiSession.user.client_id +
        '/efts/accounts?source_type=' + source_type;

			$http.post(url, data).then(function(obj) {
				deferred.resolve(obj);
			}, function(err) {
				console.error(err);
				deferred.reject(err);
			});

			return deferred.promise;
		};

		this.createEft = function(data, source_type) {

			var deferred = $q.defer();

			if (!source_type) source_type = 'manual';

      var url = PORTFOLIO_WEBSERVER.app +
        '/api/client/' + NwuiSession.user.client_id +
        '/efts?source_type=' + source_type;

			$http.post(url, data).then(function(obj) {
				deferred.resolve(obj);
			}, function(err) {
				console.error(err);
				deferred.reject(err);
			});

			return deferred.promise;
		};

		this.submitEftRequest = function submitEftRequest(data) {
			//eft_id, amount, frequency, start_date

			var deferred = $q.defer();

			$http.post(PORTFOLIO_WEBSERVER.app + '/api/client/' + NwuiSession.user.client_id + '/efts/' + data.from_account + '/requests', {
				request_type: data.request_type,
				full_withdrawal: data.full_withdrawal,
				reason_for_withdrawal: data.reason,
				amount: data.amount,
				frequency: data.frequency,
				start_date: data.start_date,
				confirmed_contribution_room: data.confirmed_contribution_room,
				confirmed_not_leveraged_position: data.confirmed_not_leveraged_position,
				borrowed_amount: data.borrowed_amount,
				borrowed_currency: data.borrowed_currency,
				borrowed_to_invest: data.borrowed_to_invest,
			}).then(function(obj) {
				deferred.resolve(obj);
			}, function(err) {
				console.error(err);
				deferred.reject(err);
			});

			return deferred.promise;
		};

		this.removeEftSource = function removeEftSource(id) {

			var deferred = $q.defer();

			$http.delete(PORTFOLIO_WEBSERVER.app + '/api/client/' + NwuiSession.user.client_id + '/efts/' + id)
				.then(function(obj) {
					deferred.resolve(obj);
				}, function(err) {
					console.error(err);
					deferred.reject(err);
				});

			return deferred.promise;
		};

		$rootScope.$on(NWUI_AUTH_EVENTS.loginSuccess, function() {
			cache = reset();
		});

	}
])

.service('SavingsService', [
	'$rootScope',
	'$http',
	'$q',
	'NwuiSession',
	'NWUI_AUTH_EVENTS',
	'PORTFOLIO_WEBSERVER',
	function(
		$rootScope,
		$http,
		$q,
		NwuiSession,
		NWUI_AUTH_EVENTS,
		PORTFOLIO_WEBSERVER
	) {

		var self = this;
		var reset = function() {
			return {
				getSavings: null
			}
		}
		var cache = reset();

		//get savings for the user
		this.getSavings = function() {

			var deferred = $q.defer();

			////console.log('GET SAVINGS', cache.getSavings)

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/client/' + NwuiSession.user.client_id + '/savings/').then(function(res, status) {
		        deferred.resolve(res.data);
		    }, function(data, status) {
			    deferred.reject(data);
			});

			return deferred.promise;

		}

		$rootScope.$on(NWUI_AUTH_EVENTS.loginSuccess, function() {
			cache = reset();
		});

	}
])

.service('SummaryService', [
	'$rootScope',
	'$http',
	'$q',
	'NwuiSession',
	'NWUI_AUTH_EVENTS',
	'PORTFOLIO_WEBSERVER',
	function(
		$rootScope,
		$http,
		$q,
		NwuiSession,
		NWUI_AUTH_EVENTS,
		PORTFOLIO_WEBSERVER
	) {

		var self = this;
		var reset = function() {
			return {
				getHoldings: null
			}
		}
		var cache = reset();

		//get aggregate holdings for the user
		this.getHoldings = function() {

			var deferred = $q.defer();

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/client/' + NwuiSession.user.client_id + '/holdings').then(function(res, status) {
		        deferred.resolve(res.data);
		    }, function(data, status) {
			    deferred.reject(data);
			});

			return deferred.promise;

		}

		$rootScope.$on(NWUI_AUTH_EVENTS.loginSuccess, function() {
			cache = reset();
		});

	}
])

.service('ActivityService', [
	'$rootScope',
	'$http',
	'$q',
	'NwuiSession',
	'NWUI_AUTH_EVENTS',
	'PORTFOLIO_WEBSERVER',
	function(
		$rootScope,
		$http,
		$q,
		NwuiSession,
		NWUI_AUTH_EVENTS,
		PORTFOLIO_WEBSERVER
	) {

		var self = this;
		var reset = function() {
			return {
				getActivity: null
			}
		}
		var cache = reset();

		//get account activity
		this.getActivity = function() {
			return $http.get(PORTFOLIO_WEBSERVER.app + '/api/users/' + NwuiSession.user.uuid + '/activity')
				.then(function(res) {
					return res.data;
				});
		}

		this.getActivityNew = function() {
			return $http.get(PORTFOLIO_WEBSERVER.app + '/api/users/' + NwuiSession.user.uuid + '/activity')
				.then(function(res, status) {
					return res;
				});
		}

		$rootScope.$on(NWUI_AUTH_EVENTS.loginSuccess, function() {
			cache = reset();
		});

	}
])

.service('RiskProfileService', [
	'$rootScope',
	'$http',
	'$q',
	'$timeout',
	'GoalService',
	'NwuiSession',
	'NWUI_AUTH_EVENTS',
	'PORTFOLIO_WEBSERVER',
	function(
		$rootScope,
		$http,
		$q,
		$timeout,
		GoalService,
		NwuiSession,
		NWUI_AUTH_EVENTS,
		PORTFOLIO_WEBSERVER
	) {

		var self = this;
		var reset = function() {
			return {
				getRiskProfile: []
			}
		}

		var cache = reset();

		//TODO: FIX ON API
		var bandaidRiskProfile = function(risk_profile) {

		if(!risk_profile) return;

		//reverse and map for directive
		if(risk_profile.pie_data.data && risk_profile.pie_data.data.length > 0) {
			var pieData = [], rangeFlag = false, total = 0, index = 0, parentindex;
			risk_profile.pie_data.data[0].dimensions.forEach((d) => {
				
				if(!d.y_to) {
					pieData.push({
						name: index,
						percent: d.y,
						type: d.type,
						description: d.key
					})
					total += d.y;
					index ++;
				} else {
					if(!rangeFlag){
						rangeFlag = true;
						parentindex = index;
						index ++;
					}
					pieData.push({
						name: parentindex,
						percent: 0,
						type: 'primary',
						description: d.key,
						assetRanges: d.y + '-' + d.y_to + '%'
					})
				}
			});
			if(rangeFlag) {
				pieData.push({
					name: parentindex,
					percent: 100 - total,
					type: 'primary',
					description: risk_profile.pie_data.name.slice(-6),
					hide: true
				})
			}
			risk_profile.pie_data.data = pieData;
			risk_profile.pie_data.data.reverse();
		}
		risk_profile.kyc_instance.steps.splice(risk_profile.kyc_instance.steps.length-1,1);
		}

		//get risk profile
		this.getRiskProfile = function(goal_id, force) {

			var deferred = $q.defer();

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/client/' + NwuiSession.user.client_id + '/goals/' + goal_id + '/risk-profile').then(function(res, status) {

				bandaidRiskProfile(res.data.risk_profiles['current']);

			    //if a new risk profile exists
			    if(res.data.risk_profiles['new'])
				    bandaidRiskProfile(res.data.risk_profiles['new']);

		        deferred.resolve(res.data);
		    }, function(data, status) {
			    deferred.reject(data);
			});

			return deferred.promise;

		}

		//preview risk profile
		this.previewRiskProfile = function(goal_id, kyc_answers) {

			var deferred = $q.defer();
			var obj = {
				answers: kyc_answers
			}

			$http.post(PORTFOLIO_WEBSERVER.app + '/api/client/' + NwuiSession.user.client_id + '/goals/' + goal_id + '/risk-profile/preview', obj).then(function(res, status) {

				bandaidRiskProfile(res.data.risk_profiles['new']);

		        deferred.resolve(res.data);
		    }, function(data, status) {
			    deferred.reject(data);
			});

			return deferred.promise;

		}

		//submit risk profile for approval
		this.submitRiskProfile = function(goal_id) {

			var deferred = $q.defer();

			$http.post(PORTFOLIO_WEBSERVER.app + '/api/client/' + NwuiSession.user.client_id + '/goals/' + goal_id + '/risk-profile/submit', {}).then(function(res, status) {
		        deferred.resolve(res.data);
		        GoalService.clearCache();
		    }, function(data, status) {
			    deferred.reject(data);
			});

			return deferred.promise;

		}

		$rootScope.$on(NWUI_AUTH_EVENTS.loginSuccess, function() {
			cache = reset();
		});

	}
])

.service('ReportService', [
	'$rootScope',
	'$http',
	'$q',
	'NwuiSession',
	'NWUI_AUTH_EVENTS',
	'PORTFOLIO_WEBSERVER',
	function(
		$rootScope,
		$http,
		$q,
		NwuiSession,
		NWUI_AUTH_EVENTS,
		PORTFOLIO_WEBSERVER
	) {

		var self = this;
		var reset = function() {
			return {
				getReports: null
			}
		}
		var cache = reset();

		//get account reports
		this.getReports = function() {

			var deferred = $q.defer();

			if(cache.getReports)
				deferred.resolve(cache.getReports);

			else {

				$http.get(PORTFOLIO_WEBSERVER.app + '/api/client/' + NwuiSession.user.client_id + '/reports').then(function(res, status) {
					cache.getActivity = res;
			        deferred.resolve(res.data);
			    }, function(data, status) {
				    deferred.reject(data);
				});

			}

			return deferred.promise;

		}

    this.getClientReports = function(clientId) {
      return function(query, page, quantity) {
        if (!page) page = 1;
        if (!quantity) quantity = 5;
        return $http
          .get(
            PORTFOLIO_WEBSERVER.app + '/api/client/' + clientId + '/clientReports?query='
            + query+'&page_number=' + page + '&page_size=' + quantity
          );
      }
    }

		this.download = function(clientId, report_id) {

			var deferred = $q.defer();

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/client/' + clientId + '/reports/download/' + report_id).then(function(res, status) {

				////console.log('report_id', report_id, res);

				if(res.data.url)
					window.open(res.data.url);

				deferred.resolve(res.data);

		    }, function(data, status) {
			    deferred.reject(data);
			});

			return deferred.promise;

		}

		$rootScope.$on(NWUI_AUTH_EVENTS.loginSuccess, function() {
			cache = reset();
		});



	}
]);
