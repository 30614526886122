angular.module('portfolio_web.manage.personal_information.services', [])

.service('PersonalInformationService', [
	'$rootScope', 
	'$http', 
	'$q', 
	'$timeout',
	'$stateParams',
	'NwuiSession', 
	'PORTFOLIO_WEBSERVER',
	function(
		$rootScope, 
		$http, 
		$q, 
		$timeout, 
		$stateParams,
		NwuiSession,
		PORTFOLIO_WEBSERVER
	) {

		this.getQuestions = function() {
	
			return $http.get(PORTFOLIO_WEBSERVER.app + '/api/form_questions/user-edit');
			
		}

		this.updateQuestions = function(tombstone) {

			return $http.patch(PORTFOLIO_WEBSERVER.app + '/api/user/tombstone', {
				tombstone: tombstone
			});

		}

	}
]);