angular.module('portfolio_web.summary.performance.directives', [])

.directive('summaryPerformance', [
	'$q',
	'$timeout',
	'NwuiSession',
	'ApiUserService',
	'NwuiCardHeaderV2Factory',
	'NwuiPeriodFactory',
	function(
		$q,
		$timeout,
		NwuiSession,
		ApiUserService,
		NwuiCardHeaderV2Factory,
		NwuiPeriodFactory
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: 'portfolio_webserver/summary/performance/template.html',
			scope: {},
			link: function(scope, element, attrs) {

				scope.card = {
					header: new NwuiCardHeaderV2Factory({
						name_i18n: 'summary.performance.title',
						icon: {
							name: 'timeline'
						}
					}),
					periods: {
						this_month: new NwuiPeriodFactory({
							name_i18n: 'summary.performance.period_0',
							percent: 0
						}),
						all_time: new NwuiPeriodFactory({
							name_i18n: 'summary.performance.period_1',
							percent: 0
						})
					}
				}

				var fetchData = function() {
					return ApiUserService.getAggregatePerformance(NwuiSession.user.uuid)
						.then(function(obj) {
							return obj.data;
						});
				}

				fetchData()
					.then(function(data) {
							scope.card.periods.this_month.dollar = data.periods.this_month.change.dollar;
							scope.card.periods.this_month.percent = data.periods.this_month.change.percent;
							scope.card.periods.all_time.dollar = data.periods.all_time.change.dollar;
							scope.card.periods.all_time.percent = data.periods.all_time.change.percent;
					});
			}
		}
	}
]);
