angular.module('portfolio_web.activity.controllers', [
	'nwui-toolkit',
	'portfolio_web.api'
])

.controller('ActivityController', [
	'$rootScope',
	'$scope',
	'$http',
	'$filter',
	'$timeout',
	'$q',
	'$stateParams',
	'$state',
	'locale',
	'GoalService',
	'ActivityService',

	'NwuiModalV2Factory',
	'NwuiCardHeaderV2Factory',
	'NwuiFormV3Factory',
	'NwuiButtonV3Factory',
	'NwuiDrawerV1Factory',
	'NwuiRecommendationFactory',

	'ResolveDataAsOf',
	'ResolveAccounts',
	'NWUI_AUTH_EVENTS',
	'NWUI_SELECT_OPTIONS',
	'DATE_FORMAT',
	'NwuiSession',
	'NwuiOverlayService',
	'NwButtonService',
	'NwuiInputService',
	'NwuiLocalizeService',
	function(
		$rootScope,
		$scope,
		$http,
		$filter,
		$timeout,
		$q,
		$stateParams,
		$state,
		locale,
		GoalService,
		ActivityService,

		NwuiModalV2Factory,
		NwuiCardHeaderV2Factory,
		NwuiFormV3Factory,
		NwuiButtonV3Factory,
		NwuiDrawerV1Factory,
		NwuiRecommendationFactory,

		ResolveDataAsOf,
		ResolveAccounts,
		NWUI_AUTH_EVENTS,
		NWUI_SELECT_OPTIONS,
		DATE_FORMAT,
		NwuiSession,
		NwuiOverlayService,
		NwButtonService,
		NwuiInputService,
		NwuiLocalizeService
	) {

		// Any ResolveCA account that was created before this date should temporarily have certain UI elements hidden
		// until we can ingest their historical data from FCC. See PMO-381.
		var RESOLVECA_PRE_EXISTING_ACCOUNTS_CUTOFF_DATE = moment('20191201');


		var date = new Date(moment(ResolveDataAsOf.date).utc());

		var closeAllTransactions = function() {

			$scope.accounts.forEach(function(account) {
				account.transactions.forEach(function(transaction) {
					delete transaction.expanded;
					delete transaction.style;
				});
			});

		}


		$scope.permissions = {
			showTransactionDetails: window.organization_data.port_data
															&& window.organization_data.port_data.activities
															&& window.organization_data.port_data.activities.showTransactionDetails
		}

		$scope.data = {
			accounts: ResolveAccounts,
			is_translated: (organization_data.port_data.activities && organization_data.port_data.activities.translated),
			show_transaction_details: window.organization_data.port_data && window.organization_data.port_data.activities && window.organization_data.port_data.activities.showTransactionDetails
		}

		if (NwuiSession.user.org.slug === 'resolveca') {
			$scope.someAccountsAwaitingResolveCaHistoricalPerformanceIngestion = false;
			for(var i = 0; i < $scope.data.accounts.length; i++) {
				var accountOpenDate;
				if($scope.data.accounts[i].open_date && moment($scope.data.accounts[i].open_date).toString() !== 'Invalid date') {
					accountOpenDate = moment($scope.data.accounts[i].open_date);
				} else {
					accountOpenDate = null;
				}
	
				if(accountOpenDate && accountOpenDate < RESOLVECA_PRE_EXISTING_ACCOUNTS_CUTOFF_DATE) {
					$scope.someAccountsAwaitingResolveCaHistoricalPerformanceIngestion = true;
	
					$scope.historicalPerformanceRecommendation = new NwuiRecommendationFactory({
						type: 'info',
						icon: {
							name: 'info'
						},
						text_i18n: 'nwui.common.line_graph.awaiting_historical_data_message'
					});
	
					break;
				}
			}
		}

		$scope.is_empty = false;
		$scope.loaded = false;
		$scope.activity_loaded = false;
		$scope.activity;
		$scope.accounts;
		$scope.show_account_filter = false;
		$scope.show_mobile_filters = false;
		$scope.account_select_toggle = false;
		$scope.date_select_toggle = false;
		$scope.transactions_shown = [true];

		//for child scopes
		$scope.model = {
			active_date: null,
			active_account: null
		}

		$scope.dates = [{
			name: "This Month",
			date_st: new Date(date.getFullYear(), date.getMonth(), 0),
			date_en: new Date(date.getFullYear(), date.getMonth()+1, 1)
		},{
			name: "Last Month",
			date_st: new Date(date.getFullYear(), date.getMonth()-1, 0),
			date_en: new Date(date.getFullYear(), date.getMonth(), 0)
		},{
			name: "Last 6 Months",
			date_st: new Date(date.getFullYear(), date.getMonth()-5, 0),
			date_en: new Date(date.getFullYear(), date.getMonth()+1, 1)
		},{
			name: "This Year",
			date_st: new Date(date.getFullYear(), 0, 0),
			date_en: new Date(date.getFullYear()+1, 0, 1)
		},/*{
			name: "Last year",
			date_st: new Date(date.getFullYear()-1, 0, 1),
			date_en: new Date(date.getFullYear(), 0, 0)
		},/**/{
			name: "All time",
			date_st: new Date(date.getFullYear()-10, 0, 0),
			date_en: new Date(date.getFullYear()+1, 0, 1)
		}];

		$scope.model.active_date = $scope.dates[2];

		var getActivities = function() {

			ActivityService.getActivity().then(function(res) {
				res.accounts.forEach(function(account){
					var account_type;
					if (account.slug) {
						var slug = 'nwui.account_types.' + account.slug + '.name';
						account_type = locale.isTranslated(slug)
							? locale.getString(slug)
							: account.account_type;
					} else {
						account_type = locale.getString('nwui.account_types.' + account.account_type + '.name') || account.account_type;
					}

					account.description = account_type + " (" + account.custodian_id + ")";
					account.description_i18n = 'nwui.common.input.destination_account.option';
					account.account_type = account_type;
					account.description_i18n_data = {
						accountType: account_type,
						accountId: account.custodian_id
					}
				});

				res.transactions.forEach(function(transaction) {
					//////console.log('ActivityController', 'transaction', transaction);
					//transaction.date = moment(transaction.date).zone(-4).format('MMM d, YYYY');

					if(transaction.id === $stateParams.transaction_id) {
						////console.log('ActivityController', 'transaction match', transaction);
						$scope.state_transaction = transaction;
						$scope.modals.transaction_details.open = true;
					}
				});

				$scope.activity = res;

				$scope.accounts = [{
					// description: 'All Accounts',
					description_i18n: 'nwui.common.input.destination_account.option_aggregate',
					transactions: res.transactions
				}]

				$scope.accounts = $scope.accounts.concat($scope.activity.accounts)

				$scope.model.active_account = $scope.accounts[0]
				$scope.activity_loaded = true;

				if(NwuiInputService.inputs['activity-filter-account-form-account']) {
					NwuiInputService.inputs['activity-drawer-filter-form-account'].options = NwuiInputService.inputs['activity-filter-account-form-account'].options = $scope.accounts.map(function(account,i) {

						var acct_slug = undefined;
						$scope.data.accounts.forEach(function(acct, j) {
							if(acct.id === account.account_id) {
								acct_slug = acct.slug;
							}
						})

						var account_type = undefined;

						if(acct_slug) {
							var slug = 'nwui.account_types.' + acct_slug + '.name';
							account_type = locale.isTranslated(slug)
								? locale.getString(slug)
								: account.account_type;
						} else {
							account_type = account.account_type;
						}

						return {
							id: i,
							// text: account.account_type + custodian_id,
							text_i18n: account.description_i18n,
							text_i18n_data: {
								accountType: account_type,
								accountId: account.custodian_id
							},
							value: (i === 0) ? 'all' : i
						}
					})

					$timeout(function() {
						NwuiInputService.inputs['activity-drawer-filter-form-account'].model = 'all';
						NwuiInputService.inputs['activity-filter-account-form-account'].model = 'all';
					}, 0);

				}

			});

		};

		if(NwuiSession.user.id) {
			getActivities();
		} else {
			var deregister = $rootScope.$on(NWUI_AUTH_EVENTS.authDone, getActivities);
			$scope.$on('$destroy', deregister);
		}

		$scope.applied_filters = {};

		$scope.toggleFilter = function(item) {

			closeAllTransactions();

			if(!$scope.applied_filters[item])
				$scope.applied_filters[item] = true;
			else
				delete $scope.applied_filters[item];

			////console.log('ActivityController', 'applied_filters', $scope.applied_filters, NwuiInputService.inputs['activity-drawer-filter-form-transaction-type'].model);

			NwuiInputService.inputs['activity-drawer-filter-form-transaction-type'].options.forEach(function(option) {
				if($scope.applied_filters[option.model] === true)
					option.value = true;
				else
					option.value = false;
			});

			NwuiInputService.inputs['activity-drawer-filter-form-transaction-type'].model.forEach(function(option) {
				if($scope.applied_filters[option.model] === true)
					option.value = true;
				else
					option.value = false;
			});

			$scope.cards.activities.table.reload();

		}

		$scope.isFiltered = function() {
			return (Object.keys($scope.applied_filters).length > 0) ? true : false;
		};

		// $scope.toggleAccountFilter = function() {
		// 	$scope.show_account_filter = ($scope.show_account_filter) ? false : true;
		// }

		$scope.filterDate = function(item) {

			if(
				moment(item.date).utc().unix() >= moment($scope.model.active_date.date_st).utc().unix() &&
				moment(item.date).utc().unix() <= moment($scope.model.active_date.date_en).utc().unix()
			)
				return true;

			return false;

		}

		$scope.toggleExpanded = function(e, item) {

			if(!item.expanded) {
				item.expanded = true;
				item.style = {
					height: ($(e.target).closest('.nw-row').height() + $(e.target).closest('.outer').children('.sub').height()) + 'px'
				}
			} else {
				delete item.expanded;
				delete item.style;
			}

		}

		$scope.isEmpty = function() {

			////console.log(
			// 	'ActivityController',
			// 	'isEmpty',
			// 	// Boolean($scope.model.active_account),
			// 	// $scope.model.active_account.transactions.length,
			// 	$scope.transactions_shown
			// );

			if(!$scope.model.active_account || $scope.model.active_account.transactions.length == 0)
				return true;

			if($scope.transactions_shown.indexOf(true) != -1)
				return false;

			return true;

		}

		// $scope.toggleMobileFilters = function(bool) {

		// 	closeAllTransactions();
		// 	$scope.show_mobile_filters = bool;
		// 	$scope.drawerToggle(bool);

		// }

		// $scope.toggleSelect = function(select) {

		// 	switch(select) {
		// 		case 'account':
		// 			$scope.account_select_toggle = ($scope.account_select_toggle) ? false : true;
		// 			$scope.date_select_toggle = false;
		// 			break;
		// 		case 'date':
		// 			$scope.date_select_toggle = ($scope.date_select_toggle) ? false : true;
		// 			$scope.account_select_toggle = false;
		// 			break;
		// 	}

		// }

		$scope.$watchGroup(['model.active_account', 'model.active_date'], function(new_val, old_val) {
			if($scope.accounts)
				closeAllTransactions();
		});

		$scope.blocks = {
			left: {
				filter_account: {
					form: new NwuiFormV3Factory({
						name: 'activity-filter-account-form',
						submit_button: false,
						onSubmit: function(post_data) {
						},
						onComplete: function(obj) {
						},
						steps: [{
							columns: [{
								directive: 'input',
								width: 100,
								data: {
									directive: 'select',
									data: {
										id: 'activity-filter-account-form-account',
										// label: 'Filter by account',
										label_i18n: 'activity.input.account.label',
										model: -1,
										options: [],
										// placeholder: 'Select account...',
										placeholder_i18n: 'activity.input.account.placeholder',
										stretch: true,
										size: 'small',
										color: '',
										required: false,
										onChange: function(model) {

											if(
												$scope.accounts === undefined ||
												$scope.accounts.length === 0
											) return;

											var formatted_model = model === 'all' ? 0 : model;

											$scope.transactions_shown = [true];
											$scope.model.active_account = $scope.accounts[formatted_model];
											NwuiInputService.inputs['activity-drawer-filter-form-account'].model = model;

										}
									}
								}
							}]
						}]
					})
				},
				filter_date: {
					form: new NwuiFormV3Factory({
						name: 'activity-filter-date-form',
						submit_button: false,
						onSubmit: function(post_data) {
						},
						onComplete: function(obj) {
						},
						steps: [{
							columns: [{
								directive: 'input',
								width: 100,
								data: {
									directive: 'select',
									data: {
										id: 'activity-filter-date-form-range',
										// label: 'Filter range',
										label_i18n: 'activity.input.range.label',
										model: 2,
										options: NWUI_SELECT_OPTIONS.TIME_RANGES,
										// placeholder: 'Select range...',
										// placeholder_i18n: 'activity.input.range.placeholder',
										stretch: true,
										size: 'small',
										color: '',
										required: false,
										onChange: function(model) {

											$scope.model.active_date = $scope.dates[model];
											$scope.cards.activities.table.reload();
											if(NwuiInputService.inputs['activity-drawer-filter-form-range'])
												NwuiInputService.inputs['activity-drawer-filter-form-range'].model = model;

										}
									}
								}
							}]
						}]
					})
				},
				filter_expand: {
					button: new NwuiButtonV3Factory({
						id: 'activityfilter-expand-button',
						// text: 'Filter Activities',
						text_i18n: 'activity.nav.filter_button',
						size: 'small',
						color: (
							organization_data.theme &&
							organization_data.theme.form &&
							organization_data.theme.form.submit &&
							typeof organization_data.theme.form.submit.filter_color !== 'undefined'
						)
							? organization_data.theme.form.submit.filter_color || 'primary' //force primary if blank (falsey)
							: 'secondary',
						icon_only_mobile: true,
						icon: {
							name: 'filter_list'
						},
						stretch: true,
						onClick: function() {

							$scope.modules.filter_drawer.drawer.open = true;

						}
					})
				}
			},
			right: {
				account_statements: {
					button: new NwuiButtonV3Factory({
						id: 'account-statements-button',
						// text: 'Account Statements',
						text_i18n: 'activity.nav.account_statements_button',
						size: 'small',
						icon: {
							name: 'library_books'
						},
						color: 'text',
						stretch: true,
						onClick: function() {
							NwButtonService.buttons['account-statements-button'].processing = true;
							$state.go('app.manage.account_statements');
						}
					})
				}
			}
		}

		$scope.modules = {
			filter_drawer: {
				drawer: new NwuiDrawerV1Factory({
					id: 'activity-filters-drawer',
					// name: 'Activity Filters',
					name_i18n: 'activity.drawer.title',
					open: false,
					is_empty: false,
					empty_text: "",
					empty_icon: {
						name: 'notifications_active'
					},
					orientation: 'left',
					icon: {
						name: 'filter_list'
					},
					z_index: 102
				}),
				form: new NwuiFormV3Factory({
					name: 'activity-drawer-filter-form',
					submit_button: false,
					onSubmit: function(post_data) {
					},
					onComplete: function(obj) {
					},
					steps: [{
						columns: [{
							directive: 'input',
							width: 100,
							data: {
								directive: 'select',
								data: {
									id: 'activity-drawer-filter-form-account',
									// label: 'By account',
									label_i18n: 'activity.input.account.label_drawer',
									model: -1,
									options: ResolveAccounts.map(function(account,i) {

										var custodian_id = account.custodian_id
											? ' (' + account.custodian_id + ')'
											: ' (Aggregate)';

										return {
											id: i,
											text: account.account_type + custodian_id,
											value: account.id
										}

									}),
									// placeholder: 'Select account...',
									placeholder_i18n: 'activity.input.account.placeholder',
									stretch: true,
									size: 'small',
									color: '',
									required: false,
									onChange: function(model) {

										if(
											$scope.accounts === undefined ||
											$scope.accounts.length === 0
										) return;

										var formatted_model = model === 'all' ? 0 : model;

										$scope.model.active_account = $scope.accounts[formatted_model];
										$scope.cards.activities.table.reload();
										if(NwuiInputService.inputs['activity-filter-account-form-account'])
											NwuiInputService.inputs['activity-filter-account-form-account'].model = model;

									}
								}
							}
						}, {
							directive: 'input',
							width: 100,
							data: {
								directive: 'select',
								data: {
									id: 'activity-drawer-filter-form-range',
									// label: 'By Range',
									label_i18n: 'activity.input.range.label_drawer',
									model: 2,
									options: NWUI_SELECT_OPTIONS.TIME_RANGES,
									// placeholder: 'Select range...',
									stretch: true,
									size: 'small',
									color: '',
									required: false,
									onChange: function(model) {

										$scope.model.active_date = $scope.dates[model];
										$scope.cards.activities.table.reload();
										if(NwuiInputService.inputs['activity-filter-date-form-range'])
											NwuiInputService.inputs['activity-filter-date-form-range'].model = model;

									}
								}
							}
						}, {
							directive: 'input',
							width: 100,
							data: {
								directive: 'checkbox',
								data: {
									// label: 'By transaction type',
									label_i18n: 'activity.input.type.label_drawer',
									id: 'activity-drawer-filter-form-transaction-type',
									stretch: true,
									required: true,
									options: [],
									onChange: function(model) {

										////console.log('ActivityController', 'checkbox onChnage', model);

										model.forEach(function(item) {
											if(!$scope.applied_filters[item.model] && item.value === true)
												$scope.toggleFilter(item.model)
											else if($scope.applied_filters[item.model] === true && item.value === false)
												$scope.toggleFilter(item.model)
										});

									}
								}
							}
						}]
					}]
				})
			}
		}

		$scope.cards = {
      empty: {
        button: new NwuiButtonV3Factory({
          // text: 'Back to Summary',
          text_i18n: 'activity.empty.button',
          icon: {
            name: 'arrow_back'
          },
          onClick: function() {
            $state.go('app.summary');
          }
        })
      },
			activities: {
				header: {
					name_i18n: 'nav.activity',
					level: 2,
					icon: {
						name: 'history'
					}
				},
				table: {
					id: 'transfer-history-table',
					resource: function() {

						var deferred = $q.defer();

						ActivityService.getActivityNew().then(function(res) {

							console.log($scope.model.active_account, $scope.applied_filters);

							var transactions = ($scope.model.active_account)
								? $scope.model.active_account.transactions
								: res.data.transactions

							var obj = {
								data: {
									data: transactions
										.filter(function(activity) {
											return $scope.filterDate(activity);
										})
										// .filter(function(activity) {
										// 	//all accounts
										// 	if(!$scope.model.active_account || !$scope.model.active_account.account_id)
										// 		return true;
										// 	return ($scope.model.active_account.account_id === activity.account_id)
										// })
										.filter(function(activity) {
											//check for filters
											var has_filters = false;
											for(var key in $scope.applied_filters) {
												if($scope.applied_filters.hasOwnProperty(key)) {
													has_filters = true;
													break;
												}
											}
											if(!has_filters)
												return true;
											if($scope.applied_filters[activity.transaction_type])
												return true;
										})
										.sort(function(a, b) {
											if(a.date > b.date)
												return -1
											else if(a.date < b.date)
												return 1

											return 0;
										})
								}
							}

							deferred.resolve(obj);

						})

						return deferred.promise;
					},
					resourceCb: function(res) {
          	//res.data.data;
					},
					rowMap: function(activity,i) {

						var sub_rows = [];
						var lang = NwuiLocalizeService.getLang();

						if(activity.details) {
							sub_rows = activity.details.map(function(detail) {
								return {
									columns: [{
										text: ''
									}, {
										text: ''
									}, {
										text: detail.transaction_type
									}, {
										text: detail.description + ((detail.fill_price !== null && detail.fill_price !== 0 && detail.transaction_type !== 'CM') ? ' @$' + detail.fill_price : '')
									}, {
										text: detail.quantity ? $filter('shareCount')(detail.quantity) : ''
									}]
								}
							})
						}

						if($scope.data.show_transaction_details) {
							sub_rows.push({
								columns: [{
									override_name_i18n: '',
									text: ''
								}, {
									override_name_i18n: '',
									text: ''
								}, {
									override_name_i18n: 'activity.table.details.repName',
									text: (activity.representative) ? activity.representative.name : ''
								}, {
									override_name_i18n: '',
									text: ''
								}, {
									override_name_i18n: 'activity.table.details.settlementDate',
									text: moment(activity.settle_date).utc().format(DATE_FORMAT.short)
								}, {
									override_name_i18n: 'activity.table.details.unitPrice',
									number: activity.unit_price
								}]
							});
						}

						var getTranslationDescription = function(activity) {
							if ($scope.data.is_translated) {
								return locale.getString('activity.table.' + activity.transaction_type) || activity.description;
							} else {
								// only National has fr-CA transaction descriptions on the Transactions data model
								// other tenants fallback to en-CA regardless of language preference
								if (lang ===  'en-CA') {
									return activity.description;
								} else {
									return activity.description_fr || activity.description;
								}
							}
						}

						return {
							columns: [{
								text: moment(activity.date).utc().format(DATE_FORMAT.short)
							},{
								text: moment(activity.process_date).utc().format(DATE_FORMAT.short) !== 'Invalid date' ? moment(activity.process_date).utc().format(DATE_FORMAT.short) : moment(activity.createdAt).utc().format(DATE_FORMAT.short)
							}, {
								text: activity.transaction_type
							}, {
								text: getTranslationDescription(activity)
							}, {
								text: (activity.quantity !== null && activity.quantity != 0) ? $filter('shareCount')(activity.quantity) : ''
							}, {
								text: activity.symbol ? activity.symbol : '--'
							}, {
								number: activity.change
							}],
							sub_rows: sub_rows
						}
					},
					empty_text_i18n: 'cards.activities.table.empty_text_i18n',
					columns: [{
						name_i18n: 'activity.table.col_0',
						directive: 'text',
						sortable: false,
						width: 150
					}, {
						name_i18n: 'activity.table.process_date',
						directive: 'text',
						sortable: false,
						width: 150
					},{
						name_i18n: 'activity.table.col_1',
						directive: 'text',
						sortable: false,
						width: 120
					}, {
						name_i18n: 'activity.table.col_2',
						directive: 'text',
						sortable: false
					}, {
						name_i18n: 'activity.table.num_of_parts',
						directive: 'text',
						sortable: false,
						width: 125
					}, {
						name_i18n: 'activity.table.symbol',
						directive: 'text',
						sortable: false,
						width: 100
					}, {
						name_i18n: 'activity.table.col_3',
						directive: 'currency-delta',
						sortable: false,
						width: 100
					}],
					sub_columns: [{
						directive: 'text'
					}, {
						directive: 'text'
					}, {
						directive: 'text'
					}, {
						directive: 'text'
					}, {
						directive: 'text'
					}, {
						directive: 'currency-delta'
					}],
					rows: undefined,
					footer: false,
				}
			}
		}

		$scope.modals = {
			transaction_details: new NwuiModalV2Factory({
				wide: false,
				open: false,
				header: new NwuiCardHeaderV2Factory({
					name: 'Transaction Details',
					icon: {
						name: 'info'
					}
				})
			})
		}

		$scope.$watch('activity', function(new_val) {

			if(new_val === undefined) return;

			NwuiInputService.inputs['activity-drawer-filter-form-transaction-type'].options = new_val.filters.map(function(filter,i) {
				////console.log('ActivityController', 'filter', filter, i)
				return {
					label: filter,
					model: filter,
					value: false,
					required: false
				}
			});

		})

	}
]);
