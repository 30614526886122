angular.module('portfolio_web.service', [
	'ngCookies'
])
.service('AppService', [
	'$rootScope', 
	'$http', 
	'$q', 
	'PORTFOLIO_WEBSERVER',
	function(
		$rootScope, 
		$http, 
		$q, 
		PORTFOLIO_WEBSERVER
	) {
        this.data = {
			tombstone: null
        };
		this.getUserTombstone = () => {
			return $http.get(PORTFOLIO_WEBSERVER.app + '/api/user/tombstone').then(ts => this.setStoredTombstone(ts));
		}
		this.updateTombstone = (tombstone) => {
			return $http.patch(PORTFOLIO_WEBSERVER.app + '/api/user/tombstone', {tombstone}).then(ts => this.setStoredTombstone(ts));
		}
		this.setStoredTombstone = (ts) => {
			this.data.tombstone = ts;
			return ts;
		}
	}
]);
