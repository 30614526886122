angular.module('portfolio_web.manage.withdrawals.controllers', [])

.controller('ManageWithdrawalsController', [
	'$scope', 
	function(
		$scope
	) { 

		

	}
]);