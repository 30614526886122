angular.module('portfolio_web.accounts.directives', [])

.directive('nwAccountsContent', [
	function() {
		return {
			restrict: 'E',
	        replace: true,
	        templateUrl: 'portfolio_webserver/content/accounts.html',
	        scope: false,
	        link: function(scope, element, attrs) {}
	    }
	}
]);