angular.module('portfolio_web.manage.personal_information.config', [
	'ui.router'
])

.config([
	'$stateProvider',
	'NWUI_AUTH_ROLES',
	function(
		$stateProvider,
		NWUI_AUTH_ROLES
	) {

		var personal_information = {
			url : '/personal-information?redirect&id&ownership&accounts',
			templateUrl: 'portfolio_webserver/manage/personal_information/template.html',
			controller : 'ManagePersonalInformationController',
			params: {
				redirect: null,
				ownership: null,
				accounts: null,
				id: null
			},
			data: {
				page_title_i18n: 'nav.personal_information',
				icon_name: 'person',
				breadcrumb_label_i18n: 'nav.personal_information',
				auth_roles: [NWUI_AUTH_ROLES.user]
			},
			resolve: {
				ResolveFormAnswers: [
					'$q',
					'$stateParams',
					'ApiUserService',
					function(
						$q,
						$stateParams,
						ApiUserService
					) {

						var deferred = $q.defer();

						ApiUserService.getFormAnswers().then(function(obj) {
							deferred.resolve(obj.data);
						}, function() {
							deferred.resolve({});
						})
						
						return deferred.promise;

					}
				],
				ResolvePersonalInformationQuestions: [
					'$q',
					'$stateParams',
					'PersonalInformationService', 
					function(
						$q,
						$stateParams, 
						PersonalInformationService
					) {
						var deferred = $q.defer();
							
						PersonalInformationService.getQuestions().then(function(obj) {
							deferred.resolve(obj.data);
						}, function() {
							deferred.resolve([]);
						});
						
						return deferred.promise;
					}
				]
			}
		}

		$stateProvider.state('app.manage.personal_information', personal_information);

	}
]);