angular.module('portfolio_web.manage.plan_preferences.modules.beneficiary_info.directives', [])

.directive('managePlanPreferencesModuleBeneficiaryInfo', [
	'NwuiCardHeaderV2Factory',
	'NwuiModalV2Factory',
	'NwuiButtonV3Factory',
	'NwuiToastFactory',
	'NwuiInputService',
	'NwuiToastManagerService',
	'NwButtonService',
	'ApiClientsService',
	'NwuiSession',
	'NwuiCardTableFactory',
	function(
		NwuiCardHeaderV2Factory,
		NwuiModalV2Factory,
		NwuiButtonV3Factory,
		NwuiToastFactory,
		NwuiInputService,
		NwuiToastManagerService,
		NwButtonService,
		ApiClientsService,
		NwuiSession,
		NwuiCardTableFactory
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: 'portfolio_webserver/manage/plan_preferences/modules/beneficiary_info/template.html',
			scope: {},
			link: function(scope, element, attrs) {

				scope.card = {
					info: {
						header: {
							name_i18n: 'manage.plan_preferences.beneficiary_info.card.info.title',
							icon: {
								name: 'info'
							}
						},
						button: {
							id: 'manage-plan-preferences-module-beneficiary-info-download-button',
							text_i18n: 'manage.plan_preferences.beneficiary_info.card.info.button',
							stretch: false,
							icon: {
								name: 'file_download'
							},
							onClick: function() {
								window.open('https://s3.amazonaws.com/nw-public-images/forms/Nest+At+Work+-+Change+of+Beneficiary+Form.pdf');
							}
						}
					},
					list: {
						header: {
							name_i18n: 'manage.plan_preferences.beneficiary_info.card.list.title',
							icon: {
								name: 'info'
							}
						},
						table: new NwuiCardTableFactory({
							name: 'referrals-table',
							// empty_text: 'No referrals found',
							empty_text_i18n: 'anage.plan_preferences.beneficiary_info.card.list.empty',
							columns: [{
								// name: 'Referral',
								name_i18n: 'manage.plan_preferences.beneficiary_info.card.list.col_0',
								directive: 'text',
								sortable: false
							},{
								// name: 'Date',
								name_i18n: 'manage.plan_preferences.beneficiary_info.card.list.col_1',
								directive: 'text',
								width: 125,
								sortable: false
							}],
							rows: [{
								columns: [{
									text_i18n: 'manage.plan_preferences.beneficiary_info.card.list.p1'
								}, {
									text: '100%'
								}]
							}],
							loaded: false
						})
					}
				}

			}
		}
	}
]);