angular.module('portfolio_web.legacy.directives', [])

.directive('nwtkDeltaClass', ['$filter', function($filter) {
	return {
		restrict: 'E',
        replace: true,
        templateUrl: 'portfolio_webserver/partials/toolkit/delta-class.html',
        scope: {
	        data: '=',
	        filter: '='
        },
        link: function(scope, element, attrs) {
	        
	        scope.data_filtered = $filter(scope.filter)(scope.data);
	        
	        scope.getDeltaClass = function(input) {
		        
		        if(input < 0) return 'negative';
		        if(input > 0) return 'positive';
		        return 'neutral';
		        
	        }
	        
	    }
	}
}])