angular.module('portfolio_web.household.directives', [
	'nwui-toolkit',
	'nwui-legacy-toolkit',
	'portfolio_web.api'
])

.directive('nwHouseholdContent', [
	function() {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: 'portfolio_webserver/content/household.html',
			scope: false,
			link: function(scope, element, attrs) {}
		}
	}
]);
