angular.module('portfolio_web.constants', [])

.constant('_', window._)

.constant('INTERCOM_APPID', intercom_id)

.constant("PORTFOLIO_WEBSERVER", {
	app: ""
})

.constant("PowerFormBaseUrl", 
	"https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=a65c1aed-4ef2-438b-a393-146c87068746&env=na3&acct"
);