angular.module('portfolio_web.transfers.modules.table_transactions.directives', [])

.directive('transfersModuleTableTransactions', [
	'$filter',
	'$timeout',
	'NwuiCardTableV3Factory',
	'NwuiModalV2Factory',
	'NwuiButtonV3Factory',
	'NwuiTransferFrequencyFilter',
	'ApiClientsService',
	'NwuiSigningService',
	'NwuiToastManagerService',
	'NwuiRegisterService',
	'NwButtonService',
	'TransfersEftRequestService',
	'NwuiPageScrollService',
	'NwuiToastFactory',
	'DATE_FORMAT',
	'locale',
	function(
		$filter,
		$timeout,
		NwuiCardTableV3Factory,
		NwuiModalV2Factory,
		NwuiButtonV3Factory,
		NwuiTransferFrequencyFilter,
		ApiClientsService,
		NwuiSigningService,
		NwuiToastManagerService,
		NwuiRegisterService,
		NwButtonService,
		TransfersEftRequestService,
		NwuiPageScrollService,
		NwuiToastFactory,
		DATE_FORMAT,
		locale
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: 'portfolio_webserver/transfers/modules/table_transactions/template.html',
			scope: {
				title_i18n: '=titleI18n',
				type: "=",
				show_frequency: '=showFrequency',
				resource: '=',
				reload: '='
			},
			link: function(scope, element, attrs) {

			  var showBtApprovalStatus = (
			    organization_data.port_data &&
          organization_data.port_data.transfers &&
          organization_data.port_data.transfers.bt_requests &&
          organization_data.port_data.transfers.bt_requests.show_bt_approval_status
				);

				var showRequestId = (
						scope.type === 'eft'
						&& window.organization_data.port_data
						&& window.organization_data.port_data.transfers
						&& window.organization_data.port_data.transfers.efts
						&& window.organization_data.port_data.transfers.efts.has_request_id);

				var allowCancel = (
					(scope.type === 'eft'
					&& !scope.show_frequency
					&& window.organization_data.port_data
					&& window.organization_data.port_data.transfers
					&& window.organization_data.port_data.transfers.efts
					&& window.organization_data.port_data.transfers.efts.one_time
					&& window.organization_data.port_data.transfers.efts.one_time.allow_cancel)
					||
					(scope.type === 'eft'
					&& scope.show_frequency
					&& window.organization_data.port_data
					&& window.organization_data.port_data.transfers
					&& window.organization_data.port_data.transfers.efts
					&& window.organization_data.port_data.transfers.efts.recurring
					&& window.organization_data.port_data.transfers.efts.recurring.allow_cancel)
					||
					(scope.type === 'bt'
					&& window.organization_data.port_data
					&& window.organization_data.port_data.transfers
					&& window.organization_data.port_data.transfers.brokerage_transfers
					&& window.organization_data.port_data.transfers.brokerage_transfers.allow_cancel)
				)

				var uuid = NwuiRegisterService.register();

				scope.data = {
					has_transfer_sources_card: lodashGet(organization_data, 'port_data.transfers.ui.sources', false),
				}
				var current_date = moment(new Date()).format('YYYYMMDD');

				var calculate_next_date = function(start_date, frequency) {

					if(!start_date) return '--';

					var next_start_date = moment(start_date);

					while(next_start_date.format('YYYYMMDD') < current_date) {

						switch(frequency) {
							case 'weekly':
								next_start_date = next_start_date.add(1, 'week');
								break;
							case 'bi-weekly':
							case 'bi_weekly':
								next_start_date = next_start_date.add(2, 'week');
								break;
							case 'semi_monthly':
								next_start_date = next_start_date.add(0.5, 'month');
								break;
							case 'monthly':
								next_start_date = next_start_date.add(1, 'month');
								break;
							case 'bi_monthly':
								next_start_date = next_start_date.add(2, 'month');
								break;
							case 'quarterly':
								next_start_date = next_start_date.add(3, 'month');
								break;
							case 'every-four-months':
							case 'every_four_months':
								next_start_date = next_start_date.add(4, 'month');
								break;
							case 'semi-annual':
							case 'semi_annual':
								next_start_date = next_start_date.add(6, 'month');
								break;
							case 'annually':
							case 'yearly':
								next_start_date = next_start_date.add(1, 'year');
								break;
							default:
								return '--';
						}

					}

					return next_start_date.format(DATE_FORMAT.short);

				}

				var header_columns = [{
					name: '',
					directive: 'icon',
					sortable: false,
				}, {
					name_i18n: 'transfers.requests.col_0',
					directive: 'text',
					sortable: false,
					width: 100
				},{
					name_i18n: 'transfers.requests.col_1',
					directive: 'text',
					sortable: false,
					width: 175
				},{
					name_i18n: 'transfers.requests.col_5',
					directive: 'text',
					sortable: false,
					width: 110
				}]

				if(scope.type === 'eft') {
					header_columns.push({
						name_i18n: 'transfers.requests.col_2',
						directive: 'text',
						sortable: false
					})
				} else {
					header_columns.push({
						name_i18n: 'transfers.requests.col_7',
						directive: 'text',
						sortable: false
					})
				}

				if(scope.show_frequency) {
					header_columns.push({
						name_i18n: 'transfers.requests.col_3',
						directive: 'text',
						sortable: false
					});
					header_columns.push({
						name_i18n: 'transfers.requests.col_6',
						directive: 'text',
						sortable: false,
					});
				}

				if(showRequestId) {
					header_columns.push({
						name_i18n: 'transfers.requests.request_id',
						directive: 'text',
						sortable: false,
					});
				}

				header_columns.push({
					name_i18n: 'transfers.requests.col_4',
					directive: 'button',
					//width of status
					width: (scope.type === 'bt' && showBtApprovalStatus) ? (!allowCancel) ? 235 : 215 : (!allowCancel) ? 185 : 170,
					sortable: false
				});

				if(allowCancel) {
					header_columns.push({
						name: '',
						directive: 'button',
						width: '50',
						sortable: false
					})
				}

				scope.card = {
					header: {
						name_i18n: scope.title_i18n,
						level: 2,
						icon: {
							name: 'swap_horiz'
						}
					},
					table: new NwuiCardTableV3Factory({
						id: 'transfer-history-table-' + uuid,
						resource: scope.resource,
						rowMap: function(transfer,i) {

							var button = {
								id: 'eft-requests-button-' + uuid + '-' + i,
								stretch: true,
								size: 'short'
							}

							var icon = {}

							var status = '';
							if(scope.data.has_transfer_sources_card) {
								// cancelled
								if((transfer.envelope && transfer.is_cancelled === 1) || (transfer.cancelled === true || transfer.is_cancelled === true)) {
									button.color = 'status-cancel'
									button.text_i18n = 'transfers.requests.status_cancelled';
									button.onClick = function() {
										scope.cancelled_date = moment(transfer.cancelled_date).format(DATE_FORMAT.short);
										scope.modals.cancelled_transfers_info.open = true;
									}
									scope.modals.cancelled_transfers_info.body = 'transfers.modal.cancelled.p1'
									status = 'Cancelled';
									icon.name = 'close';
									icon.color = 'statuscancel'
								}
								//requested Cancellation
								else if(transfer.cancellation_request_status === 'requested') {
									button.color = 'status-cancel'
									button.text_i18n = 'transfers.requests.status_cancel_requested';								
									button.onClick = function() {
										scope.data.active_eft_request = transfer;
										scope.modals.cancelrequest_transfers_info.open = true;
									}
									scope.modals.cancelrequest_transfers_info.body = 'transfers.modal.cancel_requested.p1';
									icon.name = 'cached';
									icon.color = 'statuscancel'
								}

								//is complete
								else if(transfer.reconciled || transfer.is_reconciled) {
									button.color = 'status-completed'
							
									button.onClick = function() {
										if(scope.type == "bt") {
											scope.completed_date = moment(transfer.reconciled_at).format(DATE_FORMAT.short);
										} else {
											scope.completed_date = moment(transfer.reconciled_date).format(DATE_FORMAT.short);
										}
										scope.modals.completed_transfers_info.open = true;
									}	
									if(scope.type == "bt") {
										button.onClick = function() {
											scope.completed_date = moment(transfer.reconciled_at).format(DATE_FORMAT.short);
											scope.modals.completed_transfers_info.open = true;
										}
										button.text_i18n = 'transfers.requests.status_completed';
										scope.modals.completed_transfers_info.body = 'transfers.modal.completed.p1';
									} else if(scope.show_frequency) { //eft recurring
										button.onClick = function() {
											scope.completed_date =  moment(transfer.reconciled_date).format(DATE_FORMAT.short);
											scope.modals.active_transfers_info.open = true;
										}
										button.text_i18n = 'transfers.requests.status_active';
										scope.modals.active_transfers_info.body = 'transfers.modal.active.p1';
									} else { // eft one-time
										button.onClick = function() {
											scope.completed_date = moment(transfer.reconciled_date).format(DATE_FORMAT.short);
											scope.modals.completed_transfers_info.open = true;
										}
										button.text_i18n =  'transfers.requests.status_completed' ;
										scope.modals.completed_transfers_info.body = 'transfers.modal.completed.p1';
									}
									status = 'Complete';
									icon.name = 'check';
									icon.color = 'statuscompleted';
								}

							//is bt and not signed
								else if(transfer.envelope && transfer.envelope.signatures.length === 0) {
									button.text_i18n = 'transfers.requests.status_signature_req';
									button.color = 'status-action'
									button.onClick = function() {
										scope.data.active_brokerage_transfer = transfer;
										scope.modals.pending_brokerage_signature.open = true;
									}
									status = 'Pending';
									icon.color = 'error-red';
									icon.name = 'error_outline';
								}

								//is in processing - Pending
								else {
									button.color = 'status-pending'
									button.onClick = function() {
										scope.data.active_eft_request = transfer;
										scope.modals.pending_transfers_info.open = true;
									}

									status = 'Processing';

									if(scope.type === 'bt') {
										if(transfer.is_submitted && transfer.is_approved) {
											button.text_i18n = 'transfers.requests.status_transfer_pending';
											scope.modals.pending_transfers_info.body = "transfers.modal.transfer_pending.p1";
										} else {
											button.text_i18n = 'transfers.requests.status_pending_approval';
											scope.modals.pending_transfers_info.body = "transfers.modal.pending_approval.p1";
										}
									} else {
										scope.modals.pending_transfers_info.body = "transfers.modal.pending.p1";
										button.text_i18n = 'transfers.requests.status_pending';
									}
									icon.name = 'cached';
									icon.color = 'statuspending';
								}

								if (showBtApprovalStatus && scope.type === 'bt') {
									if (transfer.is_rejected) {
									button.text_i18n = 'transfers.requests.status_rejected';
									} else if (transfer.is_cancelled) {
										button.text_i18n = 'transfers.requests.status_cancelled';
									} else if (transfer.is_reconciled) {
									button.text_i18n = 'transfers.requests.status_completed';
									} else if (transfer.envelope && transfer.envelope.signatures.length === 0) {
										button.text_i18n = 'transfers.requests.status_signature_req';
									} else if (transfer.is_approved) {
									button.text_i18n = 'transfers.requests.status_transfer_pending';
									} else {
									button.text_i18n = 'transfers.requests.status_pending_approval';
									}
								}
							} else {
									//is bt and cancelled
								if(transfer.envelope && transfer.is_cancelled === 1) {

									// button.icon.name = 'close';
									// button.icon.color = 'alternate';
									// button.color = 'error';
									button.hollow = true;
									// button.text = 'Cancelled';
									button.text_i18n = 'transfers.requests.status_cancelled';
									button.onClick = function() {}

									status = 'Cancelled';

									icon.name = 'close';

								}

								//is eft and cancelled
								else if(transfer.cancelled === true || transfer.is_cancelled === true) {

									// button.icon.name = 'close';
									// button.icon.color = 'alternate';
									button.hollow = true;
									// button.color = 'error';
									// button.text = 'Cancelled';
									button.text_i18n = 'transfers.requests.status_cancelled';
									button.onClick = function() {}

									status = 'Cancelled';

									icon.name = 'close';

								}

								else if(transfer.cancellation_request_status === 'requested') {

									button.text_i18n = 'transfers.requests.status_cancel_requested';
									button.hollow = true;
									button.onClick = function() {
									}

									icon.name = 'cached';
									icon.color = 'warning';

								}

								else if(transfer.reconciled || transfer.is_reconciled) {
									button.hollow = true;
									button.text_i18n = (scope.show_frequency) ? 'transfers.requests.status_active' : 'transfers.requests.status_done';
									button.onClick = function() {}
									status = 'Complete';
									icon.name = 'check';
									icon.color = 'success';

								}

								//is bt and not signed
								else if(transfer.envelope && transfer.envelope.signatures.length === 0) {
									button.text_i18n = 'transfers.requests.status_signature_req';
									button.onClick = function() {
										scope.data.active_brokerage_transfer = transfer;
										scope.modals.pending_brokerage_signature.open = true;
									}

									status = 'Pending';
									icon.name = 'cached';
									icon.color = 'warning';
								}

								//is in processing
								else {
									button.text_i18n = 'transfers.requests.status_pending';
									button.hollow = true;
									button.onClick = function() {
										scope.data.active_eft_request = transfer;
										scope.modals.pending_transfers_info.open = true;
									}
									status = 'Processing';
									if(scope.type === 'bt') {
										scope.modals.pending_transfers_info.body = "transfers.modal.processing.p1_bt";
									} else {
										scope.modals.pending_transfers_info.body = "transfers.modal.processing.p1";
									}
									if (scope.type === 'eft') {
										if(transfer.submitted) {
											button.text_i18n = 'transfers.requests.status_active';
										} else {
											button.text_i18n = 'transfers.requests.status_pending';
										}
									}
									icon.name = 'cached';
									icon.color = 'warning';
								}

								if (showBtApprovalStatus && scope.type === 'bt') {
									if (transfer.is_rejected) {
									button.text_i18n = 'transfers.requests.status_rejected';
									} else if (transfer.is_cancelled) {
													button.text_i18n = 'transfers.requests.status_cancelled';
									} else if (transfer.is_reconciled) {
									button.text_i18n = 'transfers.requests.status_done';
									} else if (transfer.envelope && transfer.envelope.signatures.length === 0) {
													button.text_i18n = 'transfers.requests.status_signature_req';
									} else if (transfer.is_approved) {
									button.text_i18n = 'transfers.requests.status_pending';
									} else {
									button.text_i18n = 'transfers.requests.status_pending_approval';
									}
								}
							}

							var account_type;

							if(transfer.EFT && transfer.EFT.Account) {

								if(transfer.EFT.Account.slug) {
									var slug = 'nwui.account_types.' + transfer.EFT.Account.slug + '.name';
									account_type = locale.isTranslated(slug)
										? locale.getString(slug)
										: transfer.EFT.Account.account_type;
								} else {
									account_type = transfer.EFT.Account.account_type;
								}

							} else if(transfer.Account) {

								if(transfer.Account.slug) {
									var slug = 'nwui.account_types.' + transfer.Account.slug + '.name';
									account_type = locale.isTranslated(slug)
										? locale.getString(slug)
										: transfer.Account.account_type;
								} else {
									account_type = transfer.Account.account_type;
								}

							} else {
								account_type = '';
							}

							var columns = [{
								icon: icon
							}, {
								text: moment(transfer.createdAt).format(DATE_FORMAT.short)
							}, {
								text: transfer.financial_institution || transfer.EFT.bank_info
							}, {
								text: account_type
							}, {
								text: (transfer.EFT) ? transfer.amount ? $filter('currency')(transfer.amount) : '--' : '',
								text_i18n: (transfer.EFT) ? undefined : (transfer.type) ? 'transfers.requests.type_' + transfer.type : 'transfers.requests.type_bt_amount'
							}]

							if(scope.show_frequency) {
								columns.push({
									text: transfer.frequency ? NwuiTransferFrequencyFilter(transfer.frequency) : '',
									text_i18n: transfer.frequency ? undefined : 'transfers.requests.frequency_ad_hoc'
								});
								columns.push({
									text: scope.show_frequency ? calculate_next_date(transfer.start_date, transfer.frequency) : ''
								});
							}

							if(showRequestId) {
								columns.push({
									text: (scope.type === 'eft' && showRequestId) ? transfer.Request && transfer.Request.id: ''
								});
							}

							columns.push({
								button: button
							});

							if(allowCancel) {
								columns.push({
									button: {
										id: 'eft-requests-cancel-button-' + uuid + '-' + i,
										stretch: true,
										size: 'short',
										color: 'error',
										icon_only: true,
										disabled: !((transfer.can_request_cancel && allowCancel) || transfer.can_cancel),
										icon: {
											name: 'close'
										},
										onClick: function() {

											scope.data.active_transfer = transfer;
											scope.modals.cancel_transfer.header.name_i18n = (!transfer.can_cancel && transfer.can_request_cancel)
												? 'transfers.modal.cancel.title_request' : 'transfers.modal.cancel.title';

											$timeout(function() {
												scope.modals.cancel_transfer.open = true;
											}, 0);

										}
									}
								});
							}

							return {
								columns: columns
							}

						},
						empty_text_i18n: 'cards.activities.table.empty_text_i18n',
						columns: header_columns,
						rows: undefined,
						footer: {
							page_size: 5
						}
					})
				}

				scope.modals = {
					cancel_transfer: new NwuiModalV2Factory({
						open: false,
						wide: false,
						header: {
							level: 2,
							name_i18n: 'transfers.modal.cancel.title',
							icon: {
								name: 'info'
							}
						},
						button: new NwuiButtonV3Factory({
							id: 'pending-transfer-cancel-button-' + uuid,
							// text: 'Cancel Transfer',
							text_i18n: 'transfers.modal.cancel.button',
							color: 'error',
							size: '',
							icon: {
								name: 'close'
							},
							stretch: true,
							onClick: function() {

								var self = this;
								self.processing = true;

								var toast_i18n = 'transfers.modal.cancel.success';

								//can cancel
								if(scope.data.active_transfer.can_cancel) {


									var service = (scope.type === 'bt')
										? ApiClientsService.cancelBt
										: ApiClientsService.cancelEft

								}

								//can request cancel
								else if(scope.data.active_transfer.can_request_cancel) {

									var toast_i18n = 'transfers.modal.cancel.request_success';
									var service = (scope.type === 'bt')
										? ApiClientsService.requestCancelBt
										: ApiClientsService.requestCancelEft

								}

								//not sure how you should be able to get here but to be safe...
								else {
									scope.modals.cancel_transfer.open = false;
									self.processing = false;
									return;
								}

								var eft = scope.data.active_transfer.EFT;
								var clientId = null;
								if (eft && eft.Account) {
									clientId = eft.Account.client_id;
								}

								service(scope.data.active_transfer.id, clientId).then(function(obj) {

									scope.modals.cancel_transfer.open = false;
									scope.card.table.reload();

									NwuiToastManagerService.register(
										new NwuiToastFactory({
											text_i18n: toast_i18n,
											icon: {
												name: 'check'
											}
										}
									));

									self.processing = false;

								}, function(err) {

									scope.modals.cancel_transfer.open = false;
									self.processing = false;

									switch(err.data.nw_error_code) {
										case 4314:
											NwuiToastManagerService.register(
												new NwuiToastFactory({
													text_i18n: 'transfers.modal.cancel.request_error_4314',
													type: 'error',
													icon: {
														name: 'close'
													}
												}
											));
											break;
										default:
											NwuiToastManagerService.register(
												new NwuiToastFactory({
													text_i18n: 'nwui.common.error.unknown',
													type: 'error',
													icon: {
														name: 'close'
													}
												}
											));
									}

								})

							}
						})
					}),
					pending_transfers_info: new NwuiModalV2Factory({
						open: false,
						wide: false,
						header: {
							level: 2,
							name_i18n: 'transfers.modal.processing.title',
							icon: {
								name: 'info'
							}
						}
					}),
					completed_transfers_info: new NwuiModalV2Factory({
						open: false,
						wide: false,
						header: {
							level: 2,
							name_i18n: 'transfers.modal.completed.title',
							icon: {
								name: 'info'
							}
						}
					}),
					cancelled_transfers_info: new NwuiModalV2Factory({
						open: false,
						wide: false,
						header: {
							level: 2,
							name_i18n: 'transfers.modal.cancelled.title',
							icon: {
								name: 'info'
							}
						}
					}),
					active_transfers_info: new NwuiModalV2Factory({
						open: false,
						wide: false,
						header: {
							level: 2,
							name_i18n: 'transfers.modal.active.title',
							icon: {
								name: 'info'
							}
						}
					}),
					cancelrequest_transfers_info: new NwuiModalV2Factory({
						open: false,
						wide: false,
						header: {
							level: 2,
							name_i18n: 'transfers.modal.cancel_requested.title',
							icon: {
								name: 'info'
							}
						}
					}),
					pending_brokerage_signature: new NwuiModalV2Factory({
						open: false,
						wide: false,
						header: {
							level: 2,
							name_i18n: 'transfers.modal.signing.title',
							icon: {
								name: 'info'
							}
						},
						button: new NwuiButtonV3Factory({
							id: 'brokerage-transfer-info-sign-button-' + uuid,
							// text: 'Sign',
							text_i18n: 'transfers.modal.signing.button_yes',
							color: '',
							size: '',
							icon: {
								name: 'gesture'
							},
							stretch: true,
							onClick: function() {

								scope.modals.pending_brokerage_signature.open = false;
								$timeout(function() {
									NwuiSigningService.setEnvelope('global-document-signing', scope.data.active_brokerage_transfer.envelope_id);
									NwuiSigningService.setSubmitCb('global-document-signing', function(obj) {

										////console.log('TransfersController', 'NwuiSigningService.setSubmitCb', obj);

										if(obj.is_signed)
											scope.card.table.reload();

									});

									NwuiPageScrollService.open('global-document-signing');
								}, 0);

							}
						})
					})
				}

				scope.$watch('card.table.reload', function(new_val) {

					if(new_val)
						scope.reload = scope.card.table.reload;

				})

			}
		}
	}
]);
