angular.module('portfolio_web.main.constants', [])

.constant('AUTH_EVENTS', {
    loginSuccess: 'auth-login-success',
    loginFailed: 'auth-login-failed',
    logoutSuccess:'auth-logout-success',
    sessionTimeout:'auth-session-timeout',
    authDone: 'auth-done',
    notAuthenticated: 'auth-not-authenticated',
    notAuthorized: 'auth-not-authorized',
    authInactivityWarning: 'auth-inactivity-warning',
    authInactivityTimeout: 'auth-inactivity-timeout',
    ssoDone: 'sso-done'
})

.constant('USER_ROLES', {
	all:'*',
    admin:'admin',
    guest:'guest',
    user:'user'
});