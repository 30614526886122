angular.module('portfolio_web.manage.withdrawals.config', [
	'ui.router'
])

.config([
	'$stateProvider',
	'NWUI_AUTH_ROLES',
	function(
		$stateProvider,
		NWUI_AUTH_ROLES
	) {

		var withdrawals = {
			url : '/withdrawals',
			templateUrl: 'portfolio_webserver/manage/withdrawals.html',
			controller : 'ManageWithdrawalsController',
			abstract: true,
			data: {
				// page_title: 'Manage - Withdrawals',
				page_title_i18n: 'nav.withdrawals',
				icon_name: 'payment',
				breadcrumb_label_i18n: 'nav.withdrawals',
				breadcrumb_proxy: 'app.manage.withdrawals.list',
				auth_roles: [NWUI_AUTH_ROLES.user]
			}
		}

		$stateProvider.state('app.manage.withdrawals', withdrawals);

	}
]);