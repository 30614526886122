angular.module('portfolio_web.manage.security.modules.manage_password.directives', [])

.directive('manageSecurityModuleManagePassword', [
	'NwuiCardHeaderV2Factory',
	'ApiUserService',
	function(
		NwuiCardHeaderV2Factory,
		ApiUserService
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: 'portfolio_webserver/manage/security/modules/manage_password/template.html',
			scope: {},
			link: function(scope, element, attrs) {

				////console.log('manageSecurityModuleManagePassword', 'init');

				scope.card = {
					header: {
						// name: 'Manage Password',
						name_i18n: 'manage.security.password.card.title',
						icon: {
							name: 'lock'
						},
						button: {
							id: 'manage-password-module-edit-button',
							// text: 'Change Password',
							text_i18n: 'manage.security.password.card.button',
							color: organization_data.theme.form.submit.change_password_color,
							icon: {
								name: 'create'
							},
							onClick: function() {
								scope.compounds.reset_password_modal.open = true;
							}
						}
					}
				}

				scope.compounds = {
					reset_password_modal: {
						open: false,
						formResource: ApiUserService.updatePassword,
						submitCb: function(obj) {

						}
					}
				}

			}
		}
	}
]);