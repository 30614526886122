angular.module('portfolio_web.manage.withdrawals.new.services', [])

.service('WithdrawalService', [
	'$rootScope', 
	'$http', 
	'$q', 
	'$timeout',
	'$stateParams',
	'NwuiSession', 
	'PORTFOLIO_WEBSERVER',
	'AppService',
	function(
		$rootScope, 
		$http, 
		$q, 
		$timeout, 
		$stateParams,
		NwuiSession,
		PORTFOLIO_WEBSERVER,
		AppService
	) {

		// TODO: create generic getUserTombstone service that can be re-used by other components
		this.getTombstone = function() {
			var deferred =  $q.defer();
			AppService.getUserTombstone().then(function(obj) {
				deferred.resolve(obj.data.AHHomeProvince);
			});
			return deferred.promise;
		}

		this.getPendingWithdrawalRequests = function() {
			var deferred = $q.defer();
			var userId = NwuiSession.user.uuid;
			var withdrawalsPending = [];
			var numberOfWithdrawalsInProgress = 0;
			var pageSize = 100;

			var isPending = function(withdrawal) {
				return !withdrawal.cancelled && !withdrawal.reconciled;
			}

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/users/' + userId + '/eft-requests?type=withdrawal&page_size='+pageSize).then(function(obj) {
				// take all withdrawals and group by account id, sum amounts
				if (obj.data && obj.data.data) {
					obj.data.data.reduce(function(acc, curr) {
						if (!isPending(curr)) return acc;
						if (!acc[curr.EFT.account_id]) {
							acc[curr.EFT.account_id] = { accountId: curr.EFT.account_id, amount: 0 };
							withdrawalsPending.push(acc[curr.EFT.account_id]);
						}
						numberOfWithdrawalsInProgress++;
						acc[curr.EFT.account_id].amount += curr.amount;
						return acc;
					}, {});
				}
				deferred.resolve({
					total: numberOfWithdrawalsInProgress,
					withdrawalsPending: withdrawalsPending
				});
			});
			return deferred.promise;
		}
	}
]);
