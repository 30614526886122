angular.module('portfolio_web.login.config', [])

.config([
	'$stateProvider', 
	'$locationProvider', 
	'$urlRouterProvider', 

	'NWUI_AUTH_ROLES',
	function(
		$stateProvider, 
		$locationProvider, 
		$urlRouterProvider, 

		NWUI_AUTH_ROLES
	) {
		
		var login = {
			url : '/login',
			controller : 'LoginController',
			templateUrl: 'portfolio_webserver/login/template.html',
			data: {
				// page_title: 'Login',
				page_title_i18n: 'nav.login',
				auth_roles: []
			}
		}

    $stateProvider.state('app.login', login);

	}
]);