angular.module('portfolio_web.controllers', [
	'ngCookies'
])

.controller('AppController', [
	'$rootScope',
	'$scope',
	'$timeout',
	'$state',
	'$cookies',
	'$q',
	'ApiAccountsService',
	'ApiBroadcastNotificationsService',
	'NwuiLocalizeService',
	'NwuiSession',
	'ApiClientsService',
	'ApiSigningService',
	'ApiUserService',
	'NwuiPageTitleService',
	'NwuiSigningService',
	'NwuiPageScrollService',
	'NWUI_AUTH_EVENTS',
	'NwuiDrawerV1Factory',
	'NwuiDrawerV1NotificationFactory',
	'NwuiSigningFactory',
	'UserDataService',
	'ApiEsignEventService',
	'NwuiModalV2Factory',
	function(
		$rootScope,
		$scope,
		$timeout,
		$state,
		$cookies,
		$q,
		ApiAccountsService,
		ApiBroadcastNotificationsService,
		NwuiLocalizeService,
		NwuiSession,
		ApiClientsService,
		ApiSigningService,
		ApiUserService,
		NwuiPageTitleService,
		NwuiSigningService,
		NwuiPageScrollService,
		NWUI_AUTH_EVENTS,
		NwuiDrawerV1Factory,
		NwuiDrawerV1NotificationFactory,
		NwuiSigningFactory,
		UserDataService,
		ApiEsignEventService,
		NwuiModalV2Factory,
	) {
		console.log('run');
		window.globalRootScope = $rootScope;

		if (window.Intercom) { 
			window.Intercom('shutdown')
		}
		
		$scope.showRiskProfileChange = false;
		$scope.showHouseholdAcceptance = false;
		$scope.householdPrimaryMember = undefined;
		$scope.householdCurrentMemberType = undefined;
		$scope.householdName = undefined;
		$scope.showAdditionalVerificationModal = false;
		$scope.riskProfileData = undefined;
		$scope.accountData = undefined;
		$scope.closeShowRiskProfileChangeModal = function() {
			$scope.showRiskProfileChange = false;
		}

		$scope.openShowRiskProfileChangeModal = function() {
			if (organization_data.port_data && organization_data.port_data.user_risk_profile_approval === true) {
				var riskProfileData = [];
				var accountData = [];
				ApiAccountsService.getAccounts()
					.then(function(accountsRes){
						accountsRes.data.forEach(function(account, index) {
							ApiAccountsService.getRiskProfiles(account.id).then(function(riskProfileRes) {
								if(riskProfileRes.data[0].current.kyc_instance.is_client_approved === false) {
									riskProfileData.push( mapRiskProfileResToModal(riskProfileRes));
									accountData.push(account);
								}
								if(index === accountsRes.data.length - 1 ) {
									$scope.riskProfileData =riskProfileData;
									$scope.accountData = accountData;

								}
							});
						});
					});

				$scope.showRiskProfileChange = true;
			}
		}
		$scope.openShowHouseholdAcceptanceModal = function() {
			ApiClientsService.getHouseholdDetails().then(function(household){
				$scope.householdName = household.data.name;
				household.data.MembershipDetail.map(function(member,index){
					if(member.is_head){
						$scope.householdPrimaryMember = member;
					}
					if(member.WebsiteUser.uuid === NwuiSession.user.uuid){
						$scope.householdCurrentMemberType = member.is_head;
					}
					$scope.showHouseholdAcceptance = true;
					$cookies.remove('display_household');
				})
			});
		}

		function mapRiskProfileResToModal(riskProfileRes) {
			return {
				old: riskProfileRes.data[0].previous,
				new: riskProfileRes.data[0].current
			}
		}

		$scope.checkAAndOpenAdditionalVerificationModal = function() {
			if (organization_data.port_data && organization_data.port_data.aml_verification && organization_data.port_data.aml_verification.enable  === true) {
				$scope.showAdditionalVerificationModal = true;
			}
		}

		$scope.closeAdditionalVerificationModal = function() {
			$scope.showAdditionalVerificationModal = false;
		}

		$scope.customHeader = !!(organization_data.port_data && organization_data.port_data.custom_header);

		var notifications_loaded = false;

		var notifications_enabled = organization_data.port_data.notification_drawer
      ? organization_data.port_data.notification_drawer.show
      : true;

		var loadResources = function() {
			var deferred = $q.defer()

			ApiClientsService.getResources().then(function(obj) {
				deferred.resolve(obj.data);
			});

			return deferred.promise;
		}

		var loadBroadcastNotifications = function() {
			return ApiBroadcastNotificationsService
				.getBroadcastNotifications(NwuiSession.user.uuid)
				.then(function (response) {
					return response.data;
				});
		};

		NwuiPageTitleService.register(function() {
			$scope.data.page_title = NwuiPageTitleService.data;
		});

		$scope.p = false;
		$scope.authed = false;
		$scope.intercom_overlay = false;
		$scope.drawer_open = false;
		$scope.hide_scroll = false;
		$scope.wrap_style = {};
		$scope.user;
		$scope.auth_modal = {
			open: false,
			locked: true
		}

		$scope.parent_data = {
			esignevents: []
		}

		$scope.data = {
			page_title: undefined,
			resources: undefined,
			esignevents: [],
			hasAvailableAccountsToOpen: undefined,
			pendingBT: undefined,
			jointesignevents: undefined,
			intercom_open: false,
			notifications: [],
			notifications_loaded: false,
			joint_code_required: (((organization_data || {}).port_data || {}).accounts || {}).joint_code_required,
			has_account_create: (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.accounts &&
				organization_data.port_data.accounts.create
			),
			has_joint_signing: (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.accounts &&
				organization_data.port_data.accounts.joint_signing
			),
			single_account_select: (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.accounts &&
				organization_data.port_data.accounts.create_single
			),
			rough_market_active: (
				org_slug === 'nestwealth'
				// org_slug === 'nw'
			)
		}

		$scope.accountCreateModalOpenFunc = function() {
			$scope.modals.create_account_type_select.open = true;
		}

		$scope.accountSignModalOpenFunc = function() {
			$scope.modals.create_account_sign_review.open = true;
		}

		$scope.modules = {
			document_signing: new NwuiSigningFactory({
				id: 'global-document-signing',
				envelope_id: undefined,
				signingUrlResource: ApiSigningService.getUrl,
				signingPollResource: ApiSigningService.poll,
				allowCancel: true,
				styleForNav: true
			}),
			notifications_drawer: new NwuiDrawerV1Factory({
				id: 'global-notifications-drawer',
				name_i18n: 'header.a.notifications',
				open: false,
				is_empty: true,
				empty_text_i18n: "header.a.notifications_empty_text",
				empty_icon: {
					name: 'notifications_active'
				},
				orientation: 'right',
				icon: {
					name: 'notifications'
				},
				z_index: 2001,
        enabled: notifications_enabled
			})
		}

		$scope.modals = {
			create_account_type_select: {
				open: false,
				submit_cb: function(data, is_offline) {

					var query_string = '';
					if($scope.data.single_account_select) {
						query_string = data.accountTypes[0];
					} else {
						for(var i = 0; i < data.accountTypes.length; i++) {
							if(data.accountTypes[i].value !== true) continue;
							if(query_string.length > 0) query_string += ",";
							query_string += data.accountTypes[i].model;
						}
					}

					if(is_offline) {
						// Calendly removed
					} else {
						$scope.modals.create_account_type_select.open = false;
						$state.go('app.accounts.new', { ownership_type: data.ownershipType, account_types: query_string })
					}

				},
				account_types_resource: undefined
			},
			create_account_sign_review: {
				open: false,
				active_esign_event: undefined,
				submit_cb: function(res) {
					if(res === true)
						ApiUserService.getEsignEvents();
				}
			},
			confirm_joint_account_sign: {
				open: false,
        title_i18n: 'accounts.new.info_joint_account_sign.title',
        description_i18n: 'accounts.new.info_joint_account_sign.description',
        primary_button: {
          id: 'modal-confirm-joint-account-sign-yes-button',
          text_i18n: 'accounts.new.info_joint_account_sign.button',
          color: '',
          icon: {
            name: 'gesture'
          },
          onClick: function() {

            var self = this;
            self.processing = true;

				$timeout(function() {
					NwuiSigningService.setEnvelope('global-document-signing', $scope.data.jointesignevents[0].envelopeId);
					NwuiSigningService.setSubmitCb('global-document-signing', function(obj) {
						self.processing = false;
						$scope.modals.confirm_joint_account_sign.open = false;
					});
					NwuiPageScrollService.open('global-document-signing');
				}, 0);

          }
        }
			},
			esign_code: {
				open: false,
				submit_cb: function(data) {
					console.log('esign_code cb', data.joint_code)

					ApiEsignEventService.validateEsignEvent($scope.data.jointesignevents[0]._id, data.joint_code).then(function(res) {
						console.log(res.data.envelopeId);

						if(res.data.envelopeId) {
							$timeout(function() {
								NwuiSigningService.setEnvelope('global-document-signing', res.data.envelopeId);
								NwuiSigningService.setSubmitCb('global-document-signing', function(obj) {
									$scope.modals.esign_code.open = false;
									window.location.reload();
								});
								NwuiPageScrollService.open('global-document-signing');
							}, 0);
						} else {
							$scope.modals.esign_code.open = false;
						}

					}, function(err) {
						console.log(err);
					})
				}
			},
			rough_market: ($scope.data.rough_market_active) ? new NwuiModalV2Factory({
				open: false,
				locked: false,
				wide: true
			}) : undefined,
			broadcast_notifications: new NwuiModalV2Factory({
				open: false,
				locked: true,
				wide: true,
				selected_index: 0,
				items: [],
				color: 'default',
				checkbox: {
					id: 'modals-broadcast-notifications-inputs-checkbox',
					label_i18n: 'modals.broadcast_notifications.hide.label',
					value: false,
					required: false
				},
				button: {
					id: 'modals-broadcast-notifications-button',
					text_i18n: 'modals.broadcast_notifications.buttons.ok',
					stretch: false,
					processing: false,
					onClick: onModalBroadcastNotificationSubmit
				}
			}),
			sign_transfer: {
				open: false,
        		title_i18n: 'nwui.modal.sign_transfer.title',
        		description_i18n: 'nwui.modal.sign_transfer.description',
       			primary_button: {
          		id: 'modal-sign-transfer-button',
          			text_i18n: 'nwui.modal.sign_transfer.button',
          			color: '',
          			icon: {
            			name: 'gesture'
          			},
					onClick: function() {

						var self = this;
						self.processing = true;

						$timeout(function() {
							NwuiSigningService.setEnvelope('global-document-signing', $scope.data.pendingBT);
							NwuiSigningService.setSubmitCb('global-document-signing', function(obj) {
								self.processing = false;
								$scope.modals.sign_transfer.open = false;
							});
							NwuiPageScrollService.open('global-document-signing');
						}, 0);

						}
        		}	
			}
		}

		function onModalBroadcastNotificationSubmit() {
			if ($scope.modals.broadcast_notifications.button.processing) {
				return;
			}

			var selected_index = $scope.modals.broadcast_notifications.selected_index;
			var items = $scope.modals.broadcast_notifications.items;
			var item = items[selected_index];
			if (!item) {
				return;
			}

			var broadcast_notification_id = item.broadcast_notification_id;
			var hide = $scope.modals.broadcast_notifications.checkbox.value;
			var user_uuid = NwuiSession.user.uuid;

			if (hide) {
				$scope.modals.broadcast_notifications.button.processing = true;
				ApiBroadcastNotificationsService.createBroadcastNotificationReceipt(
					broadcast_notification_id,
					user_uuid
				)
					.then(function () {
						$scope.modals.broadcast_notifications.button.processing = false;
						item.do_not_show_again_flag = true;
						selectBroadcastNotification(selected_index + 1);
						$scope.modals.broadcast_notifications.open = selected_index + 1 < items.length;
					})
					.catch(function () {
						$scope.modals.broadcast_notifications.button.processing = false;
					});
			} else {
				item.do_not_show_again_flag = true;
				selectBroadcastNotification(selected_index + 1);
				var haveMore = selected_index + 1 < items.length;
				$scope.modals.broadcast_notifications.open = haveMore;

				if (!haveMore) {
					$cookies.put('broadcast-notifications', 'done');
				}
			}
		}

		function selectBroadcastNotification(index) {
			var item = $scope.modals.broadcast_notifications.items[index];
			if (!item) {
				return;
			}

			$scope.modals.broadcast_notifications.selected_index = index;
			$scope.modals.broadcast_notifications.checkbox.value = item.do_not_show_again_flag;
		}

		$scope.drawerToggle = function(state) {
			$scope.drawer_open = state;
			$scope.wrap_style = {}
		}

		$scope.$watch('modules.notifications_drawer.open', function(new_val, old_val) {

			if(new_val === undefined) return;

			//$scope.hide_scroll = new_val;

			if(old_val === true && new_val === false) {
				$scope.data.notifications.forEach(function(notification,i) {
					notification.read = true;
				})
			}

		});

		if($cookies.get('brand')) {
			$scope.brand = $cookies.get('brand');
		} else {
			$scope.brand = '';
		}

		$rootScope.$on(NWUI_AUTH_EVENTS.loginSuccess, function() {
			if(!$scope.data.jointesignevents) {
				ApiUserService.getEsignEventsJointSignaturePending().then(function(obj) {
					$scope.data.jointesignevents = obj.data.data;

					if($scope.data.jointesignevents && $scope.data.jointesignevents.length > 0) {

						if($scope.data.joint_code_required) {
							$scope.modals.esign_code.open = true;
						} else {
							$scope.modals.confirm_joint_account_sign.open = true;
						}

					}

				})
			}
			
			if(!$scope.data.pendingBT) {
				ApiUserService.getPendingBrokerageTransfer().then(function(obj) {
					if(obj.data.data.length) {
						$scope.data.pendingBT = obj.data.data[0].envelopeId
						$scope.modals.sign_transfer.open = true;
					}
				})
			}

			ApiUserService.getEsignEvents(function(obj) {
				$scope.data.esignevents = obj.data.data;
				$scope.parent_data.esignevents = obj.data.data;

				$scope.modals.create_account_sign_review.active_esign_event = obj.data.data[0];
			});

			ApiUserService.getAccountTypes().then(function (obj){
				$scope.data.hasAvailableAccountsToOpen = obj.data
					.map(function(ownershipType) { return ownershipType.accounts.length })
					.reduce(function(a, b) { return a + b; }, 0) > 0;
			});

			if($cookies.get('display_household') === 'true'){
				$scope.openShowHouseholdAcceptanceModal();
			}
			$scope.openShowRiskProfileChangeModal();
			$scope.checkAAndOpenAdditionalVerificationModal();

		});

		$rootScope.$on(NWUI_AUTH_EVENTS.authDone, function() {

			$scope.user = {
				id: NwuiSession.user.id,
				first_name: NwuiSession.user.first_name,
				last_name: NwuiSession.user.last_name,
				email: NwuiSession.user.email,
				role_id: NwuiSession.user.role_id,
				client_id: NwuiSession.user.client_id,
				organization_id: NwuiSession.user.organization_id,
				sub_org_slug: (NwuiSession.user.org) ? NwuiSession.user.org.slug : undefined
			}

			$timeout(function () {
				$rootScope.$broadcast(NWUI_AUTH_EVENTS.ssoDone);
				$rootScope.$emit(NWUI_AUTH_EVENTS.ssoDone);
			}, 500)

			if($cookies.get('broadcast-notifications') !== 'done') {
				$timeout(function() {
					if (NwuiSession.user && NwuiSession.user.id) {
						loadBroadcastNotifications().then(function (rows) {
							var items = [];
							var lang = NwuiLocalizeService.getLang();
							for (var i = 0; i < rows.length; i++) {
								var title;
								var body;
								if (lang === 'fr-CA') {
									title = rows[i].title_fr;
									body = (rows[i].body_fr || '').replace(/\n/g, '<br>');
								} else {
									title = rows[i].title_en;
									body = (rows[i].body_en || '').replace(/\n/g, '<br>');
								}

								if (!title || !body) {
									continue;
								}

								items.push({
									broadcast_notification_id: rows[i].id,
									title: title,
									body: body,
									do_not_show_again_flag: false
								});
							}
							$scope.modals.broadcast_notifications.items = items;
							$scope.modals.broadcast_notifications.open = items.length > 0;
							if (items.length) {
								selectBroadcastNotification(0);
							}
						});
					}
				}, 1500);
			}

			if(
				$scope.data.rough_market_active &&
				NwuiSession.user &&
				NwuiSession.user.clients &&
				NwuiSession.user.clients.length > 0 &&
				NwuiSession.user.clients[0].custodian_slug === 'nbcn'
			) {

				if($cookies.get('nw-statements-ready') !== 'done') {
			    $timeout(function() {
				    $scope.modals.rough_market.open = true;
					}, 1500);
					var now = new Date();
			    $cookies.put('nw-statements-ready', 'done', { 'expires': new Date(now.getFullYear(), now.getMonth(), now.getDate()+10000) });
		    }

		  }

			if($scope.user.sub_org_slug && $scope.user.sub_org_slug !== org_slug)
				$scope.brand = 'brand-' + $scope.user.sub_org_slug;
			else
				$scope.brand = '';

			UserDataService.setUserData($scope.user);

			$scope.authed = true;

			if(!NwuiSession.user.client_id) return;

			$scope.modals.create_account_type_select.account_types_resource = ApiUserService.getAccountTypes;

			if(notifications_loaded === true) return;

			loadResources().then(function(obj) {
				////console.log(obj);
				$scope.data.resources = obj.resource;
			})

      if (notifications_enabled) {
        //notifications
        $q.all([
          ApiClientsService.getNotifications(NwuiSession.user.client_id)
        ]).then(function(results) {
          ////console.log('AppController', 'ApiClientsService.getNotifications', results[0].data);
          $scope.data.notifications_loaded = true;
          notifications_loaded = true;

          $scope.modules.notifications_drawer.is_empty = results[0].data.length == 0;

          $scope.data.notifications = results[0].data.map(function(notification,i) {
            return new NwuiDrawerV1NotificationFactory({
              type: notification.notification.display_template,
              important: notification.notification.important,
              date: notification.meta ? new Date(notification.meta.sorting_date.replace(/(\d{4})(\d{2})(\d{1})/, '$1-$2-$3')) : new Date(notification.createdAt),
              read: notification.is_read,
              data: {
                id: notification._id,
                meta: notification.meta,
                notification: notification.notification
              }
            })
          });
        });
      }
		});

		$rootScope.$on(NWUI_AUTH_EVENTS.authInactivityTimeout, function() {
			$scope.data.notifications_loaded == false;
			$scope.data.resources = undefined;
			notifications_loaded = false;
			$scope.modals.esign_code.open = false;
			if($scope.data.rough_market_active) {
				$scope.modals.rough_market.open = false;
			}
			$scope.modals.broadcast_notifications.open = false;
			$timeout(function() {
				$scope.user = undefined;
				$scope.brand = '';
			}, 250);
		});

		$rootScope.$on(NWUI_AUTH_EVENTS.logoutSuccess, function() {
			$scope.data.notifications_loaded == false;
			$scope.data.resources = undefined;
			notifications_loaded = false;
			$scope.modals.esign_code.open = false;
			if($scope.data.rough_market_active) {
				$scope.modals.rough_market.open = false;
			}
			$scope.modals.broadcast_notifications.open = false;
			$scope.modals.sign_transfer.open = false;
			$timeout(function() {
				$scope.user = undefined;
				$scope.brand = '';
			}, 250);
			$scope.closeShowRiskProfileChangeModal();
		});

	}
]);
