angular.module('portfolio_web.summary.config', [
	'ui.router'
])

.config([
	'$stateProvider', 
	'$locationProvider', 
	'$urlRouterProvider', 

	'NWUI_AUTH_ROLES',
	function(
		$stateProvider, 
		$locationProvider, 
		$urlRouterProvider, 

		NWUI_AUTH_ROLES
	) {
	
		var summary = {
			url : '/summary',
			controller : 'SummaryController',
			templateUrl: 'portfolio_webserver/summary.html',
			data: {
				// page_title: 'Summary',
				page_title_i18n: 'nav.summary',
				icon_name: 'dashboard',
				auth_roles: [NWUI_AUTH_ROLES.user]
			},
			resolve: {
				ResolveResources: [
					'$q',
					'$stateParams',
					'ApiClientsService', 
					function(
						$q,
						$stateParams, 
						ApiClientsService
					) {
						var deferred = $q.defer();
						
						ApiClientsService.getResources().then(function(obj) {
							deferred.resolve(obj.data);
						}, function(e) {
							deferred.reject(e);
						});
						
						return deferred.promise;
					}
				]
			}
		}

		$stateProvider.state('app.summary', summary);

	}
]);