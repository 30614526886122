angular.module('portfolio_web.transfers.new.type.controllers', [])

.controller('TransfersNewTypeController', [
	'DATE_FORMAT',
	'$q',
	'$scope', 
	'$state',
	'$timeout', 
	'$filter',
	'$rootScope',
	'ApiAccountsService',
	'FundFactsService',
	'NwuiInputService',
	'locale',

	'NwuiFormV3Factory',
	'NwuiCardHeaderV2Factory',
	'NwuiButtonV3Factory',

	'ResolveAccounts',
	'ResolveDestinationAccounts',
	function(
		DATE_FORMAT,
		$q,
		$scope, 
		$state,
		$timeout, 
		$filter,
		$rootScope,
		ApiAccountsService,
		FundFactsService,
		NwuiInputService,
		locale,

		NwuiFormV3Factory,
		NwuiCardHeaderV2Factory,
		NwuiButtonV3Factory,

		ResolveAccounts,
		ResolveDestinationAccounts
	) {

		$scope.data = {
			supports_eft_source_page: (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.transfers &&
				organization_data.port_data.transfers.eft_sources &&
				organization_data.port_data.transfers.eft_sources.create &&
				organization_data.port_data.transfers.eft_sources.create.version === 'page'
			),
			risk_profile_approval_required: (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.transfers &&
				organization_data.port_data.transfers.risk_profile &&
				organization_data.port_data.transfers.risk_profile.approval_required === true
			),
			filter_temporary_account_numbers: (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.transfers &&
				organization_data.port_data.transfers.brokerage_transfers &&
				organization_data.port_data.transfers.brokerage_transfers.filter_temporary_account_numbers === true
			),
			has_destination_accounts: (ResolveDestinationAccounts && ResolveDestinationAccounts.length > 0)
		}

		var default_account_model = (ResolveDestinationAccounts.length === 1) ? ResolveDestinationAccounts[0].id : '';

		var redirect = function(val) {

			if(!val || val === '') return;

			if($scope.data.risk_profile_approval_required)
				$state.go('app.transfers.new.type.destination', { account_id: val })
			else
				$state.go('app.transfers.new.type.destination.form', { account_id: val })
		}
		
		var getRecommendationText = function() {
			if ($scope.data.filter_temporary_account_numbers && ResolveAccounts.length === 1) {
				if (ResolveAccounts[0].open_date == null) {
					return locale.getString('transfers.new.chequing_savings.destination.temp_account_warning');
				}
			} else {
				return locale.getString('transfers.new.chequing_savings.destination.recommendation');
			}
		}


		$scope.card = {
			header: new NwuiCardHeaderV2Factory({
				// name: 'Select Destination Account',
				name_i18n: "transfers.new.investment.destination.header",
				name_i18n_data: {
					org_name_short: organization_data.name_short
				},
				level: 2,
				// name_i18n: 'transfers.info.title',
				icon: {
					name: 'looks_one'
				}
			}),
			form: new NwuiFormV3Factory({
				name: 'create-brokerage-transfer-form',
				onSubmit: function(post_data) {
				},
				onComplete: function(obj) {
				},
				submit_button: false,
				steps: [
					{
						columns: [{
							directive: 'input',
							width: 100,
							data: {
								directive: 'select',
								data: {
									model: $state.params.account_id || default_account_model,
									id: 'create-brokerage-transfer-form-input-account',
									stretch: true,
									required: true,
									options: ResolveDestinationAccounts.filter(function(account){
										return account.status !== "Closed"
									}).map(function(account, i) {

										var account_type;
										
										if (account.slug) {
											var slug = 'nwui.account_types.' + account.slug + '.name';
											account_type = locale.isTranslated(slug)
												? locale.getString(slug)
												: account.account_type;
										} else {
											account_type = locale.getString('nwui.account_types.' + account.account_type + '.name') || account.account_type;
										}
										
										return {
											id: 'create-brokerage-transfer-form-input-account-'+i,
											text_i18n: 'nwui.common.input.destination_account.option',
											text_i18n_data: {
												accountType: account_type,
												accountId: account.custodian_id
											},
											value: account.id
										}

									}),
									size: '',
									post_var: 'account_id',
									placeholder_i18n: 'transfers.input.destination_account.placeholder',
									onChange: redirect
								}
							}
						}]
					}
				]
			}),
			recommendation: {
				type: '',
				icon: {
					name: 'error_outline',
					color: 'warning'
				},
				text_i18n: getRecommendationText(),
				button: undefined
			},
			button: new NwuiButtonV3Factory({
				id: 'transfers-new-create-new-source',
				text_i18n: 'transfers.new.chequing_savings.destination.button',
				color: 'warning',
				size: '',
				stretch: true,
				icon: {
					name: 'create'
				},
				onClick: function() {
					if($scope.data.supports_eft_source_page) {
						$state.go('app.manage.transfer_sources.new');
					} else {
						$state.go('app.manage.transfer_sources.list', { modal: true });
					}
				}
			})
		}

		$timeout(function() {
			if(NwuiInputService.inputs['create-brokerage-transfer-form-input-account']) {
				redirect(NwuiInputService.inputs['create-brokerage-transfer-form-input-account'].model)
			}
		}, 150);

	}
])