angular.module('portfolio_web.summary.holdings.directives', [])

.directive('summaryHoldings', [
	'$q',
	'$timeout',
	'NwuiSession',
	'ApiUserService',
	'NwuiCardHeaderV2Factory',
	'NwuiCardBigNumberFactory',
	'$state',
	'NwButtonService',
	'NwuiButtonV3Factory',
	function(
		$q,
		$timeout,
		NwuiSession,
		ApiUserService,
		NwuiCardHeaderV2Factory,
		NwuiCardBigNumberFactory,
		$state,
		NwButtonService,
		NwuiButtonV3Factory
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: 'portfolio_webserver/summary/holdings/template.html',
			scope: {},
			link: function(scope, element, attrs) {
				scope.data = {
					has_funding_enhancements: lodashGet(organization_data, 'port_data.summary.ui.funding_styles', false)
				}
				////console.log('summaryHoldings', 'init', scope);

				var deposit_button = {
					processing: false,
					id: 'deposit-funds-now',
					text_i18n: 'summary.holdings.deposit_button',
					color: scope.data.has_funding_enhancements ? organization_data.theme.form.submit.primary : organization_data.theme.form.submit.error_color,
					icon: {
						name: 'compare_arrows'
					},
					size: 'small',
					stretch: true,
					onClick: function() {
						NwButtonService.buttons['deposit-funds-now'].processing = true;
						$state.go('app.transfers.overview');
					}
				};
				scope.card = {
					header: new NwuiCardHeaderV2Factory({
						name_i18n: 'summary.holdings.title',
						icon: {
							name: 'account_balance'
						}
					}),
					big_number: new NwuiCardBigNumberFactory({
						value: undefined,
						filter: 'currency'
					}),
					deposit: new NwuiButtonV3Factory(deposit_button)
				}

				var fetchData = function() {
					var deferred = $q.defer()

					ApiUserService.getHoldings(NwuiSession.user.uuid).then(function(obj) {
						deferred.resolve(obj.data);
					});

					return deferred.promise;
				}

				fetchData().then(function(data) {
					scope.card.big_number.value = data.holdings;
					if(scope.card.big_number.value > 0){
						scope.card.header.button = deposit_button;
					}
				});

			}
		}
	}
]);
