angular.module('portfolio_web.main.filters', [])

.filter('toInteger', [function() {
	return function(input) {
		
		return Math.round(input);
		
	};
}])

.filter('toFixed', [function() {
	return function(input, digits) {
		
		return parseFloat(input).toFixed(digits);
		
	};
}])

.filter('dashNone', [function() {
	return function(input) {
		return (input.toLowerCase() === 'cash' || input.toLowerCase() === 'none' || input === undefined || input === '')
			? '—'
			: input;	
	};
}])

.filter('numberOfAccounts', [function() {
	return function(input) {
		
		var postfix = (input.length != 1)
			? 'accounts'
			: 'account';
			
		return input + ' ' + postfix;
		
	};
}])


.filter('customCurrencyUnit', ['$filter', '$sce', function($filter, $sce) {
	return function(input) {
		
		if(input.currency == "U" && input.change !== null)
			return 'USD';
			
		return '';
		
	};
}])

.filter('shareCount', ['$filter', '$sce', 'locale', function($filter, $sce, locale) {
	return function(input) {
		
		var pre = (input < 0) ? '' : '+';
		var post = (input == 1) ? locale.getString('activity.pipes.share') : locale.getString('activity.pipes.shares');
		
		return pre + $filter("number")(input) + " " + post;
		
	};
}])

.filter('customDate', ['$locale', function($locale) {
	return function(input, format) {
		return moment(input).utc().format($locale.DATETIME_FORMATS[format]);
	};
}]);