angular.module('portfolio_web.manage.risk_profiles.list.controllers', [])

.controller('ManageRiskProfilesListController', [
	'$q',
	'$scope', 
	'$state',
	'$timeout',
	'$filter',
	'locale',
	'NwuiCardHeaderV2Factory',
	'NwuiAccordionFactory',
	'NwuiCardTableFactory',
	'NwuiAllocationFactory',
	
	'ApiGoalsService',
	'RiskProfileService',
	'NwButtonService',
	
	'DATE_FORMAT',
	'ApiUserService',
	function(
		$q,
		$scope, 
		$state,
		$timeout,
		$filter,
		locale,
		NwuiCardHeaderV2Factory,
		NwuiAccordionFactory,
		NwuiCardTableFactory,
		NwuiAllocationFactory,
		
		ApiGoalsService,
		RiskProfileService,
		NwButtonService,
		
		DATE_FORMAT,
		ApiUserService
	) {
	
		$scope.blocks = {
			breadcrumbs: {
				root: {
					icon: {
						name: 'home'
					},
					state: 'app.summary'
				}
			}
		}
		
		$scope.data = {
			goals: undefined
		}
		
		$scope.cards = {
			risk_profiles: {
				header: {
					// name: 'Risk Profiles',
					name_i18n: 'manage.risk_profiles.list.about.title',
				icon: {
						name: 'info_outline'
					}
				},
			goals: []
			},
			accountLevelQuestions: []
		}
		
		var loadGoal = (goal, i, accountLevelQuestions, callback) => {

			var promises = [];
			
			$scope.cards.risk_profiles.goals.push({
				sub_header: new NwuiCardHeaderV2Factory({
					name: goal.localize ? locale.getString(goal.description) : goal.description,
					icon: {
						name: $filter('NwuiIconAutoPicker')(goal.description, 'data_usage')
					},
					level: 2,
				}),
				accordion: new NwuiAccordionFactory({
					name: goal.localize ? locale.getString(goal.description) : goal.description,
					color: 'alternate',
					expanded: (i==0),
					button: {
						processing: false,
						id: 'manage-risk-profiles-view-button-' + i,
						text_i18n: 'manage.risk_profiles.list.allocation.button',
						onClick: function() {
							NwButtonService.buttons['manage-risk-profiles-view-button-' + i].processing = true;
							$state.go('app.manage.risk_profiles.view', { goal_id: $scope.data.goals[i].id });
						}
					}
				}),
				allocation: new NwuiAllocationFactory({
					series: [],
					decimal: 0
				})
			})

			let risk_profile;
			RiskProfileService.getRiskProfile(goal.id).then((obj) => {
				risk_profile = obj.risk_profiles.current;
				if(accountLevelQuestions && accountLevelQuestions.asset_mix){
					$scope.cards.risk_profiles.goals[i].allocation.series = accountLevelQuestions.asset_mix.map((account_asset_mix) => {
						return {
							account_value: accountLevelQuestions.account_value,
							account_key: accountLevelQuestions.account_key,
							name: account_asset_mix.name.includes('Cash')  ?  'cash' : account_asset_mix.name,
							percent: account_asset_mix.value,
							type: '',
							hide: false,
							percent_range: null
						}
					})
				} else {
					$scope.cards.risk_profiles.goals[i].allocation.series = risk_profile.pie_data.data.map(function(datum,i) {
						return {
							name: datum.description,
							percent: datum.percent,
							type: datum.type,
							percent_range: datum.assetRanges ? datum.assetRanges : null,
							hide: datum.hide ? datum.hide : false
						}
					});
				}
				$scope.cards.risk_profiles.goals[i].sub_header.button = {
					id: 'manage-risk-profiles-view-button-' + i,
					text_i18n: risk_profile.locked === false ? 'manage.risk_profiles.list.allocation.button_unlocked' : 'manage.risk_profiles.list.allocation.button',
						icon: {
							name: risk_profile.locked === false ? 'assignment_late' : 'tune'
						},
						color: risk_profile.locked === false ? 'warning' : '',
						onClick: function() {
							this.processing = true;
							$state.go('app.manage.risk_profiles.view', { goal_id: $scope.data.goals[i].id });
						}
				}
			});
			

			callback()
		}
			


		var fetchData = function() {
			var deferred = $q.defer()

			ApiGoalsService.getGoals().then(function(obj) {
				deferred.resolve(obj.data);
			});
	
			return deferred.promise;
		}

		var fetchEsignEvents = function() {
			var deferred = $q.defer()

			ApiUserService.getEsignEvents().then(function(obj) {
				deferred.resolve(obj.data);
			});
	
			return deferred.promise;
		}

		fetchData().then(async (res) => {

			$scope.data.goals = res;


			await fetchEsignEvents()
			.then(resObj => {
				if(resObj.data[0] && resObj.data[0].signingData && resObj.data[0].signingData.accountLevelRpqKyc){
					$scope.cards.accountLevelQuestions = resObj.data[0].signingData.AccountLevelKycAnswers
				}
			})
			var promises = [];

			$scope.data.goals.forEach(function(goal,i) {
				if($scope.cards.accountLevelQuestions && $scope.cards.accountLevelQuestions.length){
					$scope.cards.accountLevelQuestions.forEach(accountQuestions => {

						let lifCheck = ApiUserService.getLifCheck(accountQuestions.account_key, goal.Accounts[0].account_type)
						let liraCheck = ApiUserService.getLiraCheck(accountQuestions.account_key, goal.Accounts[0].account_type)

						if((goal.Accounts[0].account_type == accountQuestions.account_key) || lifCheck || liraCheck){
							promises.push(function(callback) { loadGoal(goal,i,accountQuestions, callback) });
						}
					})
				} else {
					promises.push(function(callback) { loadGoal(goal,i,false, callback) });
				}
			});
			
			async.series(promises, function(err) {});
			
		});
	}
]);