angular.module('portfolio_web').run([
	'$rootScope',
	'$window',
	'$cookies',
	'$location',
	'$timeout',
	'tmhDynamicLocale',
	'locale',
	'localeConf',
	'NwuiLocalizeService',
	'DomainParserService',
	'NwuiAnalyticsService',
	'NWUI_AUTH_EVENTS',
	'NwuiSession',
	function(
		$rootScope,
		$window,
		$cookies,
		$location,
		$timeout,
		tmhDynamicLocale,
		locale,
		localeConf,
		NwuiLocalizeService,
		DomainParserService,
		NwuiAnalyticsService,
		NWUI_AUTH_EVENTS,
		NwuiSession
	) {

		var langParam = $cookies.get(localeConf.cookieName);
		var domainParam = DomainParserService.parse(window.location.href);

		//priorize url param over current cookie
		if($location.$$search && $location.$$search.lang) {
			langParam = $location.$$search.lang;
		}
		//check if lang can be grabbed from third party header
		else if(
			window.MDFM &&
			window.MDFM.SharedMenu &&
			window.MDFM.SharedMenu.currentLanguage
		) {
			switch(MDFM.SharedMenu.currentLanguage) {
				case 'fr':
					langParam = 'fr-CA';
					break;
				default:
					langParam = 'en-CA';
			}
		} else {
			langParam = $cookies.get(localeConf.cookieName);

			if(!langParam) {

				if(
					organization_data &&
					organization_data.url_bases
				) {

					//iterate over envs
					for(var key in organization_data.url_bases) {

						var environment = organization_data.url_bases[key];

						//check for object
						if(environment !== null && typeof environment === 'object') {

							//iterate over env languages
							for(var prop in environment) {

								//check for lang/url match
								if(window.location.href.indexOf(environment[prop]) !== -1) {

									//override default lang
									langParam = prop;
									break;
								}

							}

						}
						
					}

				}

			}
		}

		locale.setLocale(langParam, domainParam);
		moment.locale(locale.getLocale().toLowerCase()); 
		tmhDynamicLocale.set(locale.getLocale());
		NwuiLocalizeService.setLang(locale.getLocale());

		$rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {

			$timeout(function() {

				if (window.Fides && window.Fides.consent.analytics === true) {
					NwuiAnalyticsService.trackPage($location.url());
				
					if(organization_data && organization_data.analytics) {
						organization_data.analytics.forEach(function(item, i) {
							if(item.platform === 'adobedtm' && typeof _satellite !== 'undefined') {
								_satellite.track($location.url());
							}
						})
					}
				}
				
			}, 0);

		})
		
		window.globalRootScope = $rootScope;
	}]);