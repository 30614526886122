angular.module('portfolio_web.accounts.config', [
	'ui.router'
])

.config([
	'$stateProvider', 
	'$locationProvider', 
	'$urlRouterProvider', 

	'NWUI_AUTH_ROLES', 
	function(
		$stateProvider, 
		$locationProvider, 
		$urlRouterProvider, 

		NWUI_AUTH_ROLES
	) {

		var accounts = {
			url : '/accounts',
			templateUrl: 'portfolio_webserver/accounts.html',
			controller : 'AccountsController',
			abstract: true,
			data: {
				page_title_i18n: 'nav.accounts',
				icon_name: 'account_balance',
				breadcrumb_proxy: 'app.accounts.view',
				auth_roles: [NWUI_AUTH_ROLES.user]
			},
			resolve: {
				ResolveAccounts: [
					'$q',
					'$stateParams',
					'ApiAccountsService', 
					function(
						$q,
						$stateParams, 
						ApiAccountsService
					) {
						var deferred = $q.defer();
						
						ApiAccountsService.getAccountsWithAggregate().then(function(obj) {
							deferred.resolve(obj.data);
						});
						
						return deferred.promise;
					}
				],
				ResolveDestinationAccounts: [
					'$q',
					'$stateParams',
					'ApiAccountsService', 
					function(
						$q,
						$stateParams, 
						ApiAccountsService
					) {
						var deferred = $q.defer();
						
						if(
							organization_data &&
							organization_data.port_data &&
							organization_data.port_data.transfers &&
							organization_data.port_data.transfers.create &&
							organization_data.port_data.transfers.create.version === 'page'
						) {
							deferred.resolve([]);
						} else {
							ApiAccountsService.getAccountDestinations().then(function(obj) {
								deferred.resolve(obj.data);
							});
						}
						
						return deferred.promise;
					}
				]
			}
		}

		$stateProvider.state('app.accounts', accounts);

	}
]);