angular.module('portfolio_web.login.controllers', [
	'nwui-toolkit'
])

.controller('LoginController', [
	'$rootScope', 
	'$scope', 
	'$stateParams', 
	'$timeout', 
	'$cookies', 
	'NWUI_AUTH_EVENTS', 
	'ApiGoalsService',
	'ApiAccountsService',
	'NwuiAuthService',
	'ApiClientsService',
	function(
		$rootScope, 
		$scope, 
		$stateParams, 
		$timeout,
		$cookies, 
		NWUI_AUTH_EVENTS,
		ApiGoalsService,
		ApiAccountsService,
		NwuiAuthService,
		ApiClientsService
	) {
    $scope.disabled = organization_data.sso_enabled;

    $scope.show_welcome_text = organization_data.join_data.show_welcome_text;
		$scope.show_welcome_image = organization_data.join_data.show_welcome_image;

		$scope.show_nbf_login_banner = organization_data && organization_data.slug === 'nbf'
			? true
			: false;

		////console.log('LoginController', 'Loaded');
		
		$scope.loaded = false;

		//ApiGeneralService.clearCache();
		ApiGoalsService.cache.clear();
		ApiAccountsService.cache.clear();
		ApiClientsService.cache.clear();
		NwuiAuthService.logout();

		//BANDAID
		$rootScope.$broadcast(NWUI_AUTH_EVENTS.authInactivityTimeout);
		
		$scope.auth_modal = {
			open: false,
			locked: true,
			show_signup: organization_data.port_data.auth.sign_up,
			show_referral_source: organization_data.port_data.auth.referral_source,
			show_company_signup: organization_data.port_data.auth.company
		}

		$timeout(function() {
			$scope.auth_modal.open = true;
		}, 250);
			
		$rootScope.$on(NWUI_AUTH_EVENTS.loginSuccess, function(e, opts) {
			$timeout(function() {
				$scope.auth_modal.open = false;
			}, 750);
		});

		$timeout(function() {
			$scope.loaded = true;
		}, 500);

	}
]);