angular.module('portfolio_web.summary.blocked.directives', [])

.directive('summaryBlocked', [
	'$rootScope',
	'NwuiLegacyModalService', 
	'ApiClientsService',
	'NwuiSession',
	'NwuiModalV2Factory',
	'NWUI_AUTH_EVENTS',
	function(
		$rootScope,
		NwuiLegacyModalService,
		ApiClientsService,
		NwuiSession,
		NwuiModalV2Factory,
		NWUI_AUTH_EVENTS
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: 'portfolio_webserver/summary/blocked/template.html',
			scope: {
				show_advisor: "=?showAdvisor"
			},
			link: function(scope, element, attrs) {
				
				scope.data = {
					user: NwuiSession,
					advisor: NwuiSession.user.Advisor,
					savings: undefined
				}
			}
		}
	}
]);