angular.module('portfolio_web.api.signing.services', [])

.service('ApiSigningService', [
	'$rootScope', 
	'$http', 
	'$q', 
	'$timeout', 
	'$stateParams',
	'NwuiSession', 
	'PORTFOLIO_WEBSERVER',
	function(
		$rootScope, 
		$http, 
		$q, 
		$timeout, 
		$stateParams,
		NwuiSession,
		PORTFOLIO_WEBSERVER
	) {

		this.getUrl = function(envelope_id) {

			var deferred = $q.defer()

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/user/signing-envelope/' + envelope_id + '/embedded_url').then(function(obj) {
				deferred.resolve(obj.data)
			}, function(err) {
				////console.log(err)
				deferred.reject(err)
			})
		
			return deferred.promise;

		}

		this.poll = function(envelope_id) {

			var deferred = $q.defer()

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/user/signing-envelope/' + envelope_id + '/poll').then(function(obj) {
				deferred.resolve(obj.data)
			}, function(err) {
				////console.log(err)
				deferred.reject(err)
			});
		
			return deferred.promise;

		}
		
	}
]);