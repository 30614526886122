angular.module('portfolio_web.manage.withdrawals.new.config', [
	'ui.router'
])

.config([
	'$stateProvider',
	'NWUI_AUTH_ROLES',
	function(
		$stateProvider,
		NWUI_AUTH_ROLES
	) {

		var withdrawals = {
			url : '/new',
			templateUrl: 'portfolio_webserver/manage/withdrawals/new/template.html',
			controller : 'WithdrawalsController',
			data: {
				page_title_i18n: 'nav.withdrawals',
				icon_name: 'keyboard_return',
				breadcrumb_label_i18n: 'nav.withdrawals_new',
				auth_roles: [NWUI_AUTH_ROLES.user]
      },
      resolve: {
        ResolveSourceAccounts: [
          '$q',
          '$stateParams',
          'ApiAccountsService', 
          function(
            $q,
            $stateParams, 
            ApiAccountsService
          ) {
            var deferred = $q.defer();
            ApiAccountsService.getAccountDestinations().then(function(obj) {
              deferred.resolve(obj.data);
            });
            return deferred.promise;
            
          }
        ],
        ResolveDestinationEfts: [
          '$q',
          '$state',
          '$stateParams',
          'EftService',
          'NwuiSession',
          function(
            $q,
            $state,
            $stateParams,
            EftService,
            NwuiSession
          ) {

            var deferred = $q.defer();

            if (
              organization_data &&
              organization_data.port_data &&
              organization_data.port_data.withdrawals &&
              organization_data.port_data.withdrawals.available_account_types
            )
            EftService.getEftsAccounts().then(function(obj) {
              var available_account_types = organization_data.port_data.withdrawals.available_account_types;
              var filteredAccounts = obj.data.filter(function(account) {
                return available_account_types.indexOf(account.group_type) > -1;
              });
              deferred.resolve(filteredAccounts);
            });
            return deferred.promise;
          }
        ],
        ResolveProvince: [
					'$q',
					'$stateParams',
					'WithdrawalService',
					function(
						$q,
						$stateParams, 
						WithdrawalService
					) {
						var deferred = $q.defer();

            if (
              organization_data &&
              organization_data.port_data &&
              organization_data.port_data.withdrawals
            )
              WithdrawalService.getTombstone().then(function(province) {
                deferred.resolve(province);
              });

						return deferred.promise;
					}
        ]
      }
		}

		$stateProvider.state('app.manage.withdrawals.new', withdrawals);

	}
]);