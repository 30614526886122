angular.module('portfolio_web.manage.risk_profiles.view.edit.controllers', [])

.controller('ManageRiskProfilesViewEditController', [
	'$scope', 
	'$http', 
	'$q', 
	'$stateParams', 
	'$state',
	'$timeout', 
	'RiskProfileService', 
	'ApiGoalsService', 
	'NwButtonService',
	'NwuiToastManagerService',
	'NwuiFormV3Service',
	'NwuiSession',
	'NwuiCardHeaderV2Factory',
	'NwuiAllocationFactory',
	'NwuiFormV3Factory',
	'NwuiModalV2Factory',
	'NwuiButtonV3Factory',
	'NwuiRecommendationFactory',
	'NwuiAccordionFactory',
	'NwuiToastFactory',
	'ApiAccountsService',

	'ResolveGoal',
	'ResolveFormQuestions',
	'ResolveFormAnswers',

	'locale',
	function(
		$scope, 
		$http, 
		$q, 
		$stateParams, 
		$state,
		$timeout, 
		RiskProfileService,
		ApiGoalsService,
		NwButtonService,
		NwuiToastManagerService,
		NwuiFormV3Service,
		NwuiSession,
		NwuiCardHeaderV2Factory,
		NwuiAllocationFactory,
		NwuiFormV3Factory,
		NwuiModalV2Factory,
		NwuiButtonV3Factory,
		NwuiRecommendationFactory,
		NwuiAccordionFactory,
		NwuiToastFactory,
		ApiAccountsService,

		ResolveGoal,
		ResolveFormQuestions,
		ResolveFormAnswers,

		locale
	) {

		function mapRiskProfileResponseToModal(riskProfile) {
			return {
				old: riskProfile.current || $scope.data.risk_profiles['current'],
				new: riskProfile.new
			}
		}

		$scope.blocks = {
			breadcrumbs: {
				root: {
					icon: {
						name: 'home'
					},
					state: 'app.summary'
				}
			}
		}

		$scope.data = {
			has_fields_for_review: false,
			reviewed_personal_info: (
				$stateParams.status === 'personal-info-success'
			),
			review_personal_info: (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.risk_profiles &&
				organization_data.port_data.risk_profiles.review_personal_info === true
			),
			risk_profiles: undefined,
			risk_profile: undefined,
			openChat: window.Intercom ? function(e) {

				e.stopPropagation();
				e.preventDefault();
				
				if(window.Intercom) {
					window.Intercom('show');
				} else {

				}

			} : false
		}

		$scope.modules = {
			title: {
				text_i18n: 'manage.risk_profiles.edit.title.text',
				icon: {
					name: 'donut_large'
				},
				recommendation: {
					type: '',
					// icon: {
					// 	name: 'info_outline',
					// 	color: 'warning'
					// },
					text_i18n: 'manage.risk_profiles.edit.title.body',
					text_i18n_data: {
						orgNameShort: (organization_data || {}).name_short
					},
					button: undefined
				}
			},
			recommendation: {
				type: 'success',
				icon: {
					name: 'check'
				},
				text_i18n: 'manage.risk_profiles.edit.review.success',
				button: undefined
			},
			header: {
				verify: new NwuiCardHeaderV2Factory({
					name_i18n: "accounts.new.verify.header.name",
					level: 2,
					icon: {
						name: 'person'
					}
				})
				// edit: new NwuiCardHeaderV2Factory({
				// 	name_i18n: "manage.risk_profiles.edit.header.edit.title",
				// 	level: 2,
				// 	icon: {
				// 		name: 'looks_two'
				// 	}
				// })
			},
			// allocation: new NwuiAllocationFactory({
			// 	series: [],
			// 	decimal: 0
			// }),
			form: {
				verify: new NwuiFormV3Factory({
					name: 'manage-risk-profile-verify-form',
					onSubmit: function(post_data) {
					},
					onComplete: function(obj) {
					},
					submit_button: false,
					steps: [{
						columns: (function() {
							var columns = [];

							ResolveFormQuestions.form_instance.steps.forEach(function(step) {
								step.rows.forEach(function(row) {
									row.fields.forEach(function(field) {

										var value = (function(form_answers) {

											switch(field.input.input_type) {
												case 'select':
												case 'radio':
													for(var i = 0; i < field.input.options.length; i++) {
														if(form_answers[field.input.model] === field.input.options[i].value)
															return locale.getString('nwui.forms.questions.' + field.input.i18n + '.options.' + field.input.options[i].value)
													}
												case 'check':
													return field.input.options.reduce(function(total, option) {
														if(total.length > 0)
															total += ', ';
														
														if(form_answers[option.model])
															return total + locale.getString('nwui.forms.questions.' + field.input.i18n + '.options.' + option.model);
														else
															return total;
													}, '');
												case 'input':
												default:
													return form_answers[field.input.model];
											}

										})(ResolveFormAnswers);

										if(field.is_review_required) {
											$scope.data.has_fields_for_review = true;
											columns.push({
												width: (field.width > 50) ? 50 : field.width,
												directive: 'input',
												data: {
													directive: 'text',
													data: {
														// label: field.label,
														label_i18n: 'nwui.forms.questions.' + field.input.i18n + '.input.label',
														model: value,
														id: 'manage-risk-profile-verify-form-input-' + field.input.model,
														type: 'stacked',
														// placeholder: 'Not set',
														options: field.input.options,
														stretch: true,
														required: true,
														size: 'small',
														post_var: field.input.model,
														default_value: value,
														disabled: true
													}
												}
											})
										}
									});
								})
							});
					
							return columns
						})()
					}]
				}),
				review: new NwuiFormV3Factory({
					name: 'manage-risk-profile-review-form',
					onSubmit: function(post_data) {
					},
					onComplete: function(obj) {
					},
					submit_button: false,
					steps: [{
						columns: [{
							directive: 'input',
							width: 100,
							data: {
								directive: 'radio',
								data: {
									type: 'stacked',
									model: 'no',
									id: 'manage-risk-profile-review-form-has-changes',
									stretch: true,
									required: true,
									options: [{
										label_i18n: "accounts.new.verify.form.option.no",
										value: 'no'
									}, {
										label_i18n: "accounts.new.verify.form.option.yes",
										value: 'yes'
									}],
									onChange: function(model) {
										
										if(model === 'yes') {
											$state.go('app.manage.personal_information', { redirect: 'risk_profile', id: ResolveGoal.id })
										}

									},
									post_var: 'hasChanges'
								}
							}
						}]
					}]
				})
				// edit: new NwuiFormV3Factory({
				// 	name: 'manage-risk-profile-edit-form',
				// 	onSubmit: function(post_data) {},
				// 	onComplete: function(obj) {},
				// 	submit_button: false,
				// 	steps: [{
				// 		columns: []
				// 	}]
				// })
			},
			button: {
				submit: {
					id: 'manage-risk-profile-edit-submit-button',
					text_i18n: 'manage.risk_profiles.edit.cta.submit',
					color: 'warning',
					icon: {
						name: 'check'
					},
					onClick: function() {

						NwButtonService.buttons[$scope.modules.button.submit.id].processing = true;

						var valid = $scope.modules.manage_risk_profile_editor.validateForm();

						if(valid !== true)
							return NwButtonService.buttons[$scope.modules.button.submit.id].processing = false;

						var form_data = $scope.modules.manage_risk_profile_editor.getFormData();

						RiskProfileService
							.previewRiskProfile(ResolveGoal.id, form_data)
							.then(function(obj) {

								NwButtonService.buttons[$scope.modules.button.submit.id].processing = false;
								loadRiskProfile();

								$scope.$parent.data.risk_profiles['new'] = $scope.data.risk_profiles['new'] = obj.risk_profiles['new'];
								$scope.$parent.riskProfileForConfirmModal = mapRiskProfileResponseToModal(obj.risk_profiles);
								$scope.$parent.accountData = [];
								$scope.$parent.confirmRiskProfileModalOpen = true;

							}, function(err) {
								console.log(err);
							});

						// var valid = true;

						// NwButtonService.buttons[$scope.modules.button.submit.id].processing = true;

						// valid = NwuiFormV3Service.validate($scope.modules.form.edit.name);
						// if(valid !== true) {
						// 	NwButtonService.buttons[$scope.modules.button.submit.id].processing = false;
						// 	return;
						// }

						// var post_data = NwuiFormV3Service.getFormData($scope.modules.form.edit.name);
						// post_data.age = $scope.data.risk_profiles['new'].kyc_answers.age || $scope.data.risk_profiles['current'].kyc_answers.age;
						// post_data._id = $scope.data.risk_profiles['new'].kyc_answers._id;

						// RiskProfileService
						// 	.previewRiskProfile(ResolveGoal.id, post_data)
						// 	.then(function(obj) {

						// 		NwButtonService.buttons[$scope.modules.button.submit.id].processing = false;
						// 		loadRiskProfile();

						// 		$scope.$parent.data.risk_profiles['new'] = $scope.data.risk_profiles['new'] = obj.risk_profiles['new'];
						// 		$scope.$parent.riskProfileForConfirmModal = mapRiskProfileResponseToModal(obj.risk_profiles);
						// 		$scope.$parent.accountData = [];
						// 		$scope.$parent.confirmRiskProfileModalOpen = true;

						// 	}, function(err) {
						// 		console.log(err);
						// 	});
						
					}
				}
			},
			manage_risk_profile_editor: {
				resource_id: ResolveGoal.id,
				current_risk_profile: undefined,
				new_risk_profile: undefined,
				questionnaire_toggle_default: 'no',
				previewResource: RiskProfileService.previewRiskProfile,
				previewResourceMap: function(res) {
					return res.risk_profiles['new'];
				}
			}
		}
		
		var loadRiskProfile = function() {

			RiskProfileService.getRiskProfile(ResolveGoal.id, true).then(function(res) {
				$scope.data.risk_profiles = res.risk_profiles;
				$scope.$parent.riskProfileForConfirmModal = mapRiskProfileResponseToModal(res.risk_profiles);

				// $scope.modules.allocation.series = res.risk_profiles.current.pie_data.data.map(function(datum,i) {
				// 	return {
				// 		name: datum.description,
				// 		percent: datum.percent,
				// 		type: datum.type
				// 	}
				// });

				// var columns = [];
				if(res.risk_profiles['current']) {

					$scope.modules.manage_risk_profile_editor.current_risk_profile = res.risk_profiles['current'];

				}

				if(res.risk_profiles['new']) {

					$scope.modules.manage_risk_profile_editor.new_risk_profile = res.risk_profiles['new'];

				// 	res.risk_profiles['new'].kyc_instance.steps.forEach(function(step,i) {
				// 		step.rows.forEach(function(row,j) {
				// 			row.fields.forEach(function(field,k) {
								
				// 				if(
				// 					field.input.model === 'first_name' ||
				// 					field.input.model === 'last_name' ||
				// 					field.input.model === 'age'
				// 				) return;
									
				// 				var value = res.risk_profiles['new'].kyc_answers[field.input.model];
				// 				var input_type = (field.input.input_type == 'input') 
				// 					? 'text'
				// 					: (field.input.input_type == 'select' || field.input.input_type == 'radio') 
				// 						? 'select'
				// 						: (field.input.input_type == 'check')
				// 							? 'checkbox'
				// 							: 'text';
								
				// 				if(field.input.input_type == 'check') {
				// 					value = field.input.options = field.input.options.map(function(option,i) {
										
				// 						var value = false;
										
				// 						if(res.risk_profiles['new'].kyc_answers[field.input.model]) {
										
				// 							var index = 0;
				// 							res.risk_profiles['new'].kyc_answers[field.input.model].forEach(function(answer_option,j) {
				// 								if(answer_option.model === option.model)
				// 									index = j;
				// 							});
											
				// 							value = res.risk_profiles['new'].kyc_answers[field.input.model][index].value;
										
				// 						}
										
				// 						return {
				// 							label_i18n: 'nwui.questionnaire.questions.' + field.input.model + '.options.' + option.model,
				// 							model: option.model,
				// 							value: value
				// 						}
				// 					});
				// 				} else if(input_type == 'select') {
				// 					field.input.options.forEach(function(option,k) {
				// 						option.text_i18n = 'nwui.questionnaire.questions.' + field.input.model + '.options.' + option.value;
				// 					});
				// 				}
								
				// 				columns.push({
				// 					width: 100,
				// 					directive: 'input',
				// 					data: {
				// 						directive: input_type,
				// 						data: {
				// 							label_i18n: 'nwui.questionnaire.questions.' + field.input.model + '.input.label',
				// 							model: value,
				// 							id: 'edit-goal-risk-profile-input-' + field.input.model,
				// 							type: (input_type === 'text') ? 'text' : 'stacked',
				// 							placeholder_i18n: 'nwui.questionnaire.questions.' + field.input.model + '.input.placeholder',
				// 							options: field.input.options,
				// 							stretch: true,
				// 							required: true,
				// 							size: 'small',
				// 							post_var: field.input.model,
				// 							default_value: value,
				// 							disabled: (field.input.model === 'country')
				// 						}
				// 					}
				// 				});
																
				// 			})
				// 		})
				// 	});

				}

				// $scope.modules.form.edit.steps[0].columns = columns;

			}, function() {});

		}

		loadRiskProfile();

	}
]);