angular.module('portfolio_web.api.clients.services', [])

.service('ApiClientsService', [
	'$rootScope',
	'$http',
	'$q',
	'$timeout',
	'$stateParams',
	'NwuiSession',
	'PORTFOLIO_WEBSERVER',
	'NwuiCacheService',
	'locale',
	function(
		$rootScope,
		$http,
		$q,
		$timeout,
		$stateParams,
		NwuiSession,
		PORTFOLIO_WEBSERVER,
		NwuiCacheService,
		locale
	) {

		this.getSavings = function(client_id) {

			var deferred = $q.defer()

			client_id = client_id || NwuiSession.user.client_id;

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/clients/' + client_id + '/savings').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise

		}

		this.getHoldings = function(client_id) {

			var deferred = $q.defer()

			client_id = client_id || NwuiSession.user.client_id;

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/clients/' + client_id + '/holdings').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise

		}

		this.getGoals = function(client_id) {

			var deferred = $q.defer()

			////console.log('NwuiSession 2', NwuiSession)
			client_id = client_id || NwuiSession.user.client_id;

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/clients/1/' + client_id + '/goals').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise

		}

		this.getAccounts = function(client_id) {

			var deferred = $q.defer()

			client_id = client_id || NwuiSession.user.client_id;

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/clients/' + client_id + '/accounts').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise

		}

		this.getEfts = function getEfts(client_id) {

			var deferred = $q.defer()

			client_id = client_id || NwuiSession.user.client_id;

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/client/' + client_id + '/notifications').then(function(obj) {
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise;

		}

		this.getEftRequests = function(client_id) {

			var deferred = $q.defer()

			client_id = client_id || NwuiSession.user.client_id;

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/clients/' + client_id + '/efts/requests').then(function(obj) {
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise;

		}

		this.getTransferRequests = function(query, page, quantity) {

			var deferred = $q.defer()

			var client_id = NwuiSession.user.client_id;

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/clients/' + client_id + '/transfers/requests?query='+query+'&page_number='+page+'&page_size='+quantity).then(function(obj) {
				if(obj.data.data) {
					obj.data.data.forEach(function(row, i) {
						var slug = undefined;
						if(row.Account && row.Account.slug) {
							var slug = 'nwui.account_types.' + row.Account.slug + '.name';
							row.Account.account_type = locale.isTranslated(slug)
								? locale.getString(slug)
								: row.Account.account_type;
						}
					})
				}

				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise;

		}

		this.getTransferRequestsEftsOneTime = function(query, page, quantity) {

			var deferred = $q.defer()

			var client_id = NwuiSession.user.client_id;

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/clients/' + client_id + '/eft-requests?type=deposit&frequency=one_time&query='+query+'&page_number='+page+'&page_size='+quantity).then(function(obj) {
				if(obj.data.data) {
					obj.data.data.forEach(function(row, i) {
						var slug = undefined;
						if(row.Account && row.Account.slug) {
							var slug = 'nwui.account_types.' + row.Account.slug + '.name';
							row.Account.account_type = locale.isTranslated(slug)
								? locale.getString(slug)
								: row.Account.account_type;
						}
					})
				}

				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise;

		}

		this.getTransferRequestsEftsRecurring = function(query, page, quantity) {

			var deferred = $q.defer()

			var client_id = NwuiSession.user.client_id;

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/clients/' + client_id + '/eft-requests?type=deposit&frequency=pac&query='+query+'&page_number='+page+'&page_size='+quantity).then(function(obj) {
				if(obj.data.data) {
					obj.data.data.forEach(function(row, i) {
						var slug = undefined;
						if(row.Account && row.Account.slug) {
							var slug = 'nwui.account_types.' + row.Account.slug + '.name';
							row.Account.account_type = locale.isTranslated(slug)
								? locale.getString(slug)
								: row.Account.account_type;
						}
					})
				}

				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise;

		}

		this.getTransferRequestsBts = function(query, page, quantity) {

			var deferred = $q.defer()

			var client_id = NwuiSession.user.client_id;

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/clients/' + client_id + '/bt-requests?query='+query+'&page_number='+page+'&page_size='+quantity).then(function(obj) {
				if(obj.data.data) {
					obj.data.data.forEach(function(row, i) {
						var slug = undefined;
						if(row.Account && row.Account.slug) {
							var slug = 'nwui.account_types.' + row.Account.slug + '.name';
							row.Account.account_type = locale.isTranslated(slug)
								? locale.getString(slug)
								: row.Account.account_type;
						}
					})
				}

				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise;

		}

		this.getNotifications = function(client_id) {

			var deferred = $q.defer()

			client_id = client_id || NwuiSession.user.client_id;

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/client/' + client_id + '/notifications').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise;

		}

		this.getNotificationsUnread = function(client_id) {

			var deferred = $q.defer()

			client_id = client_id || NwuiSession.user.client_id;

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/client/' + client_id + '/notifications/unread').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise;

		};

		this.markRead = function(client_id) {

			var deferred = $q.defer()

			client_id = client_id || NwuiSession.user.client_id;

			$http.post(PORTFOLIO_WEBSERVER.app + '/api/client/' + client_id + '/notifications/mark-read', {}).then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise

		}

		this.cancelBt = function(bt_id, clientId) {

			var deferred = $q.defer()

			var client_id = NwuiSession.user.client_id;

			$http.post(PORTFOLIO_WEBSERVER.app + '/api/client/' + ( clientId || client_id ) + '/bt-request/' + bt_id + '/cancel', {})
				.then(function(obj){
					deferred.resolve(obj)
				}, function(err){
					////console.log(err)
					deferred.reject(err)
				})

			return deferred.promise

		}

		this.cancelEft = function(eft_id, clientId) {

			var deferred = $q.defer()

			var client_id = NwuiSession.user.client_id;

			$http.put(PORTFOLIO_WEBSERVER.app + '/api/clients/' + ( clientId || client_id )  + '/eft-requests/' + eft_id + '/cancel', {})
				.then(function(obj){
					deferred.resolve(obj)
				}, function(err){
					////console.log(err)
					deferred.reject(err)
				})

			return deferred.promise

		}

		this.requestCancelBt = function(bt_id, clientId) {

			var deferred = $q.defer()

			var client_id = NwuiSession.user.client_id;

			$http.post(PORTFOLIO_WEBSERVER.app + '/api/client/' +  ( clientId || client_id ) + '/bt-request/' + bt_id + '/cancellation-request', {})
				.then(function(obj){
					deferred.resolve(obj)
				}, function(err){
					////console.log(err)
					deferred.reject(err)
				})

			return deferred.promise

		}

		this.requestCancelEft = function(eft_id, clientId) {

			var deferred = $q.defer()

			var client_id = NwuiSession.user.client_id;

			$http.post(PORTFOLIO_WEBSERVER.app + '/api/clients/' + ( clientId || client_id ) + '/eft-requests/' + eft_id + '/cancellation-request', {})
				.then(function(obj){
					deferred.resolve(obj)
				}, function(err){
					////console.log(err)
					deferred.reject(err)
				})

			return deferred.promise

		}

		this.getAggregatePerformance = function(client_id) {

			var client_id = client_id || NwuiSession.user.client_id;

			var deferred = $q.defer()

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/clients/' + client_id + '/aggregate/performance').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise

		}


		this.getResources = function() {

			var deferred = $q.defer()

			if(cache.get('getResources'))
				deferred.resolve(cache.get('getResources'));
			else {
				$http.get(PORTFOLIO_WEBSERVER.app + '/api/clients/resources').then(function(obj){
					cache.set('getResources', obj);
					deferred.resolve(obj)
				}, function(err){
					////console.log(err)
					deferred.reject(err)
				})
			}

			return deferred.promise

		}

		this.getHouseholdDetails = function() {
			var deferred = $q.defer();
			$http.get(PORTFOLIO_WEBSERVER.app + '/api/households').then(function(data){
				deferred.resolve(data);
			}, function(data){
				deferred.reject(data);
			});
			return deferred.promise;
		}

		this.setTfa = function(bool, client_id) {

			var deferred = $q.defer()

			var client_id = client_id || NwuiSession.user.client_id;

			var put_obj = {
				enable: bool
			}

			$http.put(PORTFOLIO_WEBSERVER.app + '/api/clients/' + client_id + '/two-factor-auth', put_obj).then(
				function(obj){
					cache.set('getResources', obj);
					deferred.resolve(obj)
				}, function(err){
					////console.log(err)
					deferred.reject(err)
				}
			);

			return deferred.promise

		}

		this.getClientUploadDocumentList = function() {
			var deferred = $q.defer();
			$http.get(PORTFOLIO_WEBSERVER.app + '/api/clients/upload-document-list').then(function (data) {
				// should sort the data list here
				deferred.resolve(data);
			}, function (data) {
				deferred.reject(data);
			});
			return deferred.promise;
		};

		var cache = NwuiCacheService.register(this);
		this.cache = cache;

	}

]);