angular.module('portfolio_web.manage.transfer_sources.config', [
	'ui.router'
])

.config([
	'$stateProvider',
	'NWUI_AUTH_ROLES',
	function(
		$stateProvider,
		NWUI_AUTH_ROLES
	) {

		var transfer_sources = {
			url : '/transfer-sources',
			templateUrl: 'portfolio_webserver/manage/transfer_sources.html',
			controller : 'ManageTransferSourcesController',
			abstract: true,
			data: {
				// page_title: 'Manage - Transfer Sources',
				page_title_i18n: 'nav.transfer_sources',
				icon_name: 'payment',
				breadcrumb_label_i18n: 'nav.transfer_sources',
				breadcrumb_proxy: 'app.manage.transfer_sources.list',
				auth_roles: [NWUI_AUTH_ROLES.user]
			}
		}

		$stateProvider.state('app.manage.transfer_sources', transfer_sources);

	}
]);