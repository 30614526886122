angular.module('portfolio_web.manage.security.modules.manage_tfa.directives', [])

.directive('manageSecurityModuleManageTfa', [
	'NwuiCardHeaderV2Factory',
	'NwuiModalV2Factory',
	'NwuiButtonV3Factory',
	'NwuiToastFactory',
	'NwuiInputService',
	'NwuiToastManagerService',
	'NwButtonService',
	'ApiClientsService',
	'NwuiSession',
	function(
		NwuiCardHeaderV2Factory,
		NwuiModalV2Factory,
		NwuiButtonV3Factory,
		NwuiToastFactory,
		NwuiInputService,
		NwuiToastManagerService,
		NwButtonService,
		ApiClientsService,
		NwuiSession
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: 'portfolio_webserver/manage/security/modules/manage_tfa/template.html',
			scope: {},
			link: function(scope, element, attrs) {

				////console.log('manageSecurityModuleManageTfa', 'init', NwuiSession.user);

				scope.card = {
					header: {
						// name: 'Two-Factor Authentication',
						name_i18n: 'manage.security.tfa.card.title',
						icon: {
							name: 'vpn_key'
						},
						input: {
							directive: 'toggle',
							data: {
								id: 'manage-tfa-module-enable-toggle',
								model: NwuiSession.user.tfa_enabled,
								toggle_only_mobile: true,
								// label_i18n: 'manage.security.tfa.card.toggle',
								onChange: function(model) {
									////console.log('manageSecurityModuleManageTfa', 'toggle', 'onChange', model);

									if(model === true) {
										scope.modal.open = true;
									} else if(model === false) {
										
										ApiClientsService.setTfa(model).then(function(obj) {

											NwuiToastManagerService.register(
												new NwuiToastFactory({
													// text: 'Two-factor authentication disabled successfully.',
													text_i18n: 'manage.security.tfa.status.disabled',
													color: '',
													icon: {
														name: 'check'
													}
												}
											));

										}, function() {

											NwuiInputService.inputs['manage-tfa-module-enable-toggle'].model = true;

											NwuiToastManagerService.register(
												new NwuiToastFactory({
													// text: 'Something went wrong.',
													text_i18n: 'nwui.common.error.unknown',
													color: '',
													icon: {
														name: 'error'
													}
												}
											));

										});

									}
								}
							}
						}
					}
				}

				scope.modal = new NwuiModalV2Factory({
					open: false,
					locked: true,
					header: {
						// name: 'Two-Factor Authentication',
						name_i18n: 'manage.security.tfa.modal.title',
						icon: {
							name: 'vpn_key'
						}
					},
					copy: {
						buttons: {
							yes: {
								id: 'enable-tfa-button-yes',
								icon: {
									name: 'check'
								},
								// text: 'Enable',
								text_i18n: 'manage.security.tfa.modal.button_yes',
								stretch: true,
								onClick: function() {
									
									NwButtonService.buttons['enable-tfa-button-yes'].processing = true;

									ApiClientsService.setTfa(NwuiInputService.inputs['manage-tfa-module-enable-toggle'].model).then(function(obj) {

										NwuiToastManagerService.register(
											new NwuiToastFactory({
												// text: 'Two-factor authentication enabled successfully.',
												text_i18n: 'manage.security.tfa.status.enabled',
												color: '',
												icon: {
													name: 'check'
												}
											}
										));

										NwButtonService.buttons['enable-tfa-button-yes'].processing = false;
										scope.modal.open = false;

									}, function(obj) {

										NwuiToastManagerService.register(
											new NwuiToastFactory({
												// text: 'Something went wrong.',
												text_i18n: 'nwui.common.error.unknown',
												color: '',
												icon: {
													name: 'error'
												}
											}
										));

										NwButtonService.buttons['enable-tfa-button-yes'].processing = false;
										NwuiInputService.inputs['manage-tfa-module-enable-toggle'].model = false;
										scope.modal.open = false;

									});

								}
							},
							no: {
								id: 'enable-tfa-button-no',
								icon: {
									name: 'close'
								},
								color: 'error',
								stretch: true,
								// text: 'Cancel',
								text_i18n: 'manage.security.tfa.modal.button_no',
								onClick: function() {
									NwuiInputService.inputs['manage-tfa-module-enable-toggle'].model = false;
									scope.modal.open = false;
								}
							}
						}
					}
				});

			}
		}
	}
]);