angular.module('portfolio_web.summary', [
	'portfolio_web.summary.holdings',
	'portfolio_web.summary.performance',
	'portfolio_web.summary.savings',
	'portfolio_web.summary.blocked',
	'portfolio_web.summary.holdings-earnings-split',
	'portfolio_web.summary.goals',

	'portfolio_web.summary.config',
	'portfolio_web.summary.controllers',
	'portfolio_web.summary.directives'
]);