angular.module('portfolio_web.manage.risk_profiles.config', [
	'ui.router'
])

.config([
	'$stateProvider',
	'NWUI_AUTH_ROLES',
	function(
		$stateProvider,
		NWUI_AUTH_ROLES
	) {

		var risk_profiles = {
			url: '/risk-profiles',
			templateUrl: 'portfolio_webserver/manage/risk_profiles.html',
			abstract: true,
			data: {
				breadcrumb_proxy: 'app.manage.risk_profiles.list',
				auth_roles: [NWUI_AUTH_ROLES.user]
			}
		}

		$stateProvider.state('app.manage.risk_profiles', risk_profiles);

	}
]);