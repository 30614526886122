angular.module('portfolio_web.manage.investment_loans.config', [
	'ui.router'
])

.config([
	'$stateProvider',
	'NWUI_AUTH_ROLES',
	function(
		$stateProvider,
		NWUI_AUTH_ROLES
	) {

		var investment_loans = {
			url : '/investment-loans',
			templateUrl: 'portfolio_webserver/manage/investment_loans.html',
			controller : 'ManageInvestmentLoansController',
			data: {
				// page_title: 'Manage - Investment Loans',
				page_title_i18n: 'nav.investment_loans',
				icon_name: 'description',
				breadcrumb_label_i18n: 'nav.investment_loans',
				auth_roles: [NWUI_AUTH_ROLES.user]
			}
		}

		$stateProvider.state('app.manage.investment_loans', investment_loans);

	}
]);