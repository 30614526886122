angular.module('portfolio_web.main.config', [
	'portfolio_web.constants',
	'ui.router',
	// 'ngIntercom'
])

.config([
	'$stateProvider', 
	'$locationProvider', 
	'$urlRouterProvider', 

	'NWUI_AUTH_ROLES',
	function(
		$stateProvider, 
		$locationProvider, 
		$urlRouterProvider, 

		NWUI_AUTH_ROLES
	) {
		
		var main = {
			url : '',
			controller : 'MainController',
			abstract: true,
			templateUrl: 'portfolio_webserver/main.html',
			data: {
				auth_roles: []
			},
			resolve: {
				ResolveLocale: [
					'$q',
					'locale',
					function(
						$q,
						locale
					) {
						var deferred = $q.defer();
						
						locale.ready().then(function() {
							deferred.resolve();
						}, function() {
							deferred.resolve();
						});
						
						return deferred.promise;
					}
				],
				ResolveDataAsOf: [
					'$q',
					'$stateParams',
					'ApiGeneralService', 
					function(
						$q,
						$stateParams, 
						ApiGeneralService
					) {
						var deferred = $q.defer();
						
						ApiGeneralService.getDataAsOf().then(function(obj) {
							deferred.resolve(obj.data);
						}, function(e) {
							deferred.resolve();
						});
						
						return deferred.promise;
					}
				]
			}
		}

		$stateProvider.state('app', main);

	}
])

// .config([
// 	// '$intercomProvider', 
// 	'INTERCOM_APPID', 
// 	function(
// 		// $intercomProvider, 
// 		INTERCOM_APPID
// 	) {
	
// 		// $intercomProvider.appID(INTERCOM_APPID);
// 		// $intercomProvider.asyncLoading(true);
		
// 	}
// ]);