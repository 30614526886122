angular.module('portfolio_web.transfers.new.type.destination.controllers', [])

.controller('TransfersNewTypeDestinationController', [
	'DATE_FORMAT',
	'$q',
	'$scope', 
	'$state',
	'$timeout', 
	'$filter',
	'$rootScope',
	'$stateParams',
	'FundFactsService',
	'locale',

	'NwuiFormV3Factory',
	'NwuiCardHeaderV2Factory',
	'NwuiAllocationFactory',
	'NwuiButtonV3Factory',

	'ResolveRiskProfile',
	'ResolveDestinationAccounts',
	function(
		DATE_FORMAT,
		$q,
		$scope, 
		$state,
		$timeout, 
		$filter,
		$rootScope,
		$stateParams,
		FundFactsService,
		locale,

		NwuiFormV3Factory,
		NwuiCardHeaderV2Factory,
		NwuiAllocationFactory,
		NwuiButtonV3Factory,

		ResolveRiskProfile,
		ResolveDestinationAccounts
	) {

		var account_name;
		ResolveDestinationAccounts.forEach(function(account, i) {

			if(account.id !== $stateParams.account_id) return;

			var account_type;
			
			if (account.slug) {
				var slug = 'nwui.account_types.' + account.slug + '.name';
				account_type = locale.isTranslated(slug)
					? locale.getString(slug)
					: account.account_type;
			} else {
				account_type = locale.getString('nwui.account_types.' + account.account_type + '.name') || account.account_type;
			}

			account_name = locale.getString('nwui.common.input.destination_account.option', {
				accountType: account_type,
				accountId: account.custodian_id
			});

		});

		$scope.data = {
			risk_profile_approval_required: (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.transfers &&
				organization_data.port_data.transfers.risk_profile &&
				organization_data.port_data.transfers.risk_profile.approval_required === true
			)
		}

		$scope.card = {
			header: new NwuiCardHeaderV2Factory({
				// name: 'Risk Profile',
				name_i18n: "transfers.new.investment.risk_profile.header.name",
				name_i18n_data: {
					account: account_name
				},
				level: 2,
				icon: {
					name: 'looks_two'
				}
			}),
			allocation: new NwuiAllocationFactory({
				series: ResolveRiskProfile.pie_data.data[0].dimensions.map(function(datum,i) {
					return {
						name: datum.key,
						percent: datum.y,
						type: datum.type
					}
				}),
				decimal: 0
			}),
			form: new NwuiFormV3Factory({
				name: 'create-brokerage-transfer-new-destination-form',
				onSubmit: function(post_data) {
				},
				onComplete: function(obj) {
				},
				submit_button: false,
				steps: [
					{
						description_i18n: 'nwui.modal.create_eft.fund_facts_desc',
						descriptionFunction: function() {
							FundFactsService.getFundFactArray($stateParams.account_id, ResolveRiskProfile.kyc_instance._id).then(function(fundFactArray) {
								fundFactArray.forEach(function(cusip) {
									window.open(locale.getString('nwui.common.asset.fund.' + cusip + '.facts_url'));
								});
							});
						},
						columns: [{
							directive: 'input',
							width: 100,
							data: {
								directive: 'checkbox',
								data: {
									id: 'create-brokerage-transfer-new-destination-form-input-approve-risk-profile',
									label: "",
									stretch: true,
									required: true,
									options: [{
										label_i18n: "transfers.new.investment.risk_profile.input.option_label",
										model: 'approve',
										value: $state.is('app.transfers.new.type.destination.form'),
										required: true
									}],
									size: '',
									post_var: 'risk_profile',
									onChange: function(val) {

										if(val == undefined || val == '') 
											return false;

										if(val[0].value === true)
											$state.go('app.transfers.new.type.destination.form')
										else
											$state.go('app.transfers.new.type.destination')

									}
								}
							}
						}]
					}
				]
			})
		}

	}
])