angular.module('portfolio_web.transfers.services', [])

.service('TransfersEftRequestService', [ 
	'$http', 
	'$q',
	'$stateParams',
	'NwuiSession',
	'PORTFOLIO_WEBSERVER',
	function(
		$http, 
		$q,
		$stateParams,
		NwuiSession,
		PORTFOLIO_WEBSERVER
	) {
		
		var self = this;

		this.get = function(eft_request_id) {

			eft_request_id = eft_request_id ? eft_request_id : $stateParams.eft_request_id;
			var client_id = NwuiSession.user.client_id ? NwuiSession.user.client_id : '0';

			var deferred = $q.defer()

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/clients/' + NwuiSession.user.client_id + '/efts/requests/' + eft_request_id).then(function(obj) {
				deferred.resolve(obj)
			}, function(err){
				deferred.reject(err)
			})

			return deferred.promise;
			
		}

		this.archive = function(eft_request_id) {

			eft_request_id = eft_request_id ? eft_request_id : $stateParams.eft_request_id;
			var client_id = NwuiSession.user.client_id ? NwuiSession.user.client_id : '0';

			var deferred = $q.defer()

			$http.put(PORTFOLIO_WEBSERVER.app + '/api/clients/' + NwuiSession.user.client_id + '/eft-requests/' + eft_request_id + '/archive').then(function(obj) {
				////console.log('TransfersEftRequestService', 'cancel', 'res', obj);
				deferred.resolve(obj)
			}, function(err){
				////console.log('TransfersEftRequestService', 'cancel', 'rej', err);
				deferred.reject(err)
			})

			return deferred.promise;	

		}

		this.cancel = function(eft_request_id) {

			eft_request_id = eft_request_id ? eft_request_id : $stateParams.eft_request_id;
			var client_id = NwuiSession.user.client_id ? NwuiSession.user.client_id : '0';

			var deferred = $q.defer()

			$http.put(PORTFOLIO_WEBSERVER.app + '/api/clients/' + NwuiSession.user.client_id + '/eft-requests/' + eft_request_id + '/cancel').then(function(obj) {
				////console.log('TransfersEftRequestService', 'cancel', 'res', obj);
				deferred.resolve(obj)
			}, function(err){
				////console.log('TransfersEftRequestService', 'cancel', 'rej', err);
				deferred.reject(err)
			})

			return deferred.promise;	
			
		}
	}
]);