angular.module('portfolio_web.accounts.new.config', [
	'ui.router'
])

.config([
	'$stateProvider', 
	'$locationProvider', 
	'$urlRouterProvider', 

	'NWUI_AUTH_ROLES', 
	function(
		$stateProvider, 
		$locationProvider, 
		$urlRouterProvider, 

		NWUI_AUTH_ROLES
	) {

		var config = {
			controller : 'AccountsNewController',
			url : '/new/:ownership_type/:account_types?status',
			templateUrl: 'portfolio_webserver/accounts/new/template.html',
			params: {
				ownership_type: null,
				account_types: null,
				status: null
			},
			data: {
				page_title_i18n: 'accounts.new.nav.title',
				breadcrumb_label_i18n: 'accounts.new.nav.base',
				icon_name: 'account_balance',
				auth_roles: [NWUI_AUTH_ROLES.user]
			},
			resolve: {
				ResolveFormQuestions: [
					'$q',
					'$stateParams',
					'ApiGeneralService',
					function(
						$q,
						$stateParams,
						ApiGeneralService
					) {

						var deferred = $q.defer();

						ApiGeneralService.getFormQuestions().then(function(obj) {
							deferred.resolve(obj.data);
						}, function() {
							deferred.resolve({
								form_instance: { steps: [] }
							});
						})
						
						return deferred.promise;

					}
				],
				ResolveFormAnswers: [
					'$q',
					'$stateParams',
					'ApiUserService',
					function(
						$q,
						$stateParams,
						ApiUserService
					) {

						var deferred = $q.defer();

						ApiUserService.getFormAnswers().then(function(obj) {
							deferred.resolve(obj.data);
						}, function() {
							deferred.resolve({});
						})
						
						return deferred.promise;

					}
				],
				ResolveEftSources: [
					'$q',
					'$stateParams',
					'ApiUserService',
					function(
						$q,
						$stateParams,
						ApiUserService
					) {
						var deferred = $q.defer();
						var ownershipType = $stateParams.ownership_type || '';
						ApiUserService.getFundingSources(ownershipType).then(function(obj) {
							const eftsources = obj.data.reduce(function(sources, source) {
								const foundSource = sources.find(function (s) { return s.bank_info === source.bank_info });
								if (!foundSource) {
									sources.push(source);
								}

								return sources;
							}, []);

							deferred.resolve(eftsources);
						}, function(err) {
							deferred.resolve({})
						});
				
						return deferred.promise;

					}
				],
				ResolveLatestRiskProfile: [
					'$q',
					'$stateParams',
					'ApiUserService',
					function(
						$q,
						$stateParams,
						ApiUserService
					) {

						var deferred = $q.defer();

						var endpoint = ((((organization_data || {}).port_data || {}).accounts || {}).show_risk_profile_preview) 
							? ApiUserService.createRiskProfile
							: ApiUserService.getLatestRiskProfile;

						endpoint().then(function(obj) {
							deferred.resolve(obj.data);
						}, function() {
							deferred.resolve({ 
								pie_data: { data: [{ dimensions: [] }] },
								kyc_instance: { steps: [] }
							});
						})
						
						return deferred.promise;
						
					}
				]
			}
		}

    $stateProvider.state('app.accounts.new', config);

	}
]);