angular.module('portfolio_web.manage.documents.config', [
	'ui.router'
])

.config([
	'$stateProvider',
	'NWUI_AUTH_ROLES',
	function(
		$stateProvider,
		NWUI_AUTH_ROLES
	) {

		var documents = {
			url : '/documents',
			templateUrl: 'portfolio_webserver/manage/documents.html',
			controller : 'ManageDocumentsController',
			data: {
				// page_title: 'Manage - Documents',
				page_title_i18n: 'nav.documents',
				icon_name: 'description',
				breadcrumb_label_i18n: 'nav.documents',
				auth_roles: [NWUI_AUTH_ROLES.user]
			}
		}

		$stateProvider.state('app.manage.documents', documents);

	}
]);