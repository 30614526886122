angular.module('portfolio_web.api.user.services', [])

.service('ApiUserService', [
	'$rootScope',
	'$http',
	'$q',
	'$timeout',
	'$cookies',
	'$stateParams',
	'NwuiSession',
	'PORTFOLIO_WEBSERVER',
	'locale',
	'NWUI_AUTH_EVENTS',
	function(
		$rootScope,
		$http,
		$q,
		$timeout,
		$cookies,
		$stateParams,
		NwuiSession,
		PORTFOLIO_WEBSERVER,
		locale,
		NWUI_AUTH_EVENTS
	) {

		var subscribers = {
			getEsignEvents: []
		}

		var locks = {
			getEsignEvents: false
		}

		this.getHoldings = function(user_uuid) {

			var deferred = $q.defer()

			user_uuid = user_uuid || NwuiSession.user.uuid;

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/users/' + user_uuid + '/holdings').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise

		}

		this.getFormAnswers = function() {

			var deferred = $q.defer()

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/user/tombstone').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				deferred.reject(err)
			})

			return deferred.promise

		}

		this.getLatestRiskProfile = function() {

			var deferred = $q.defer()

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/user/latest-risk-profile').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				deferred.reject(err)
			})

			return deferred.promise

		}

		this.getAccountTypes = function() {

			var deferred = $q.defer()

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/user/organization/ownership').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				deferred.reject(err)
			})

			return deferred.promise

		}

		this.getReferrals = function() {

			var deferred = $q.defer()

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/user/referrals').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				deferred.reject(err)
			})

			return deferred.promise

		}

		this.getReferralCode = function() {

			var deferred = $q.defer()

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/user/referral-code').then(function(obj) {
				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})

			return deferred.promise

		}

		this.refer = function(body) {

			var deferred = $q.defer()

			$http.post(PORTFOLIO_WEBSERVER.app + '/api/user/refer', body).then(function(obj) {
				deferred.resolve(obj)
			}, function(err){
				deferred.reject(err)
			})

			return deferred.promise

		}

		this.updatePassword = function(body) {

			var deferred = $q.defer()

			$http.post(PORTFOLIO_WEBSERVER.app + '/api/user/user/' + NwuiSession.user.uuid + '/set_password', body).then(function(obj) {
				deferred.resolve(obj.data)
			}, function(err){
				deferred.reject(err)
			})

			return deferred.promise;

		}

		this.updateUserLang = function(lang) {

			return $http.put(PORTFOLIO_WEBSERVER.app + '/api/user', JSON.stringify({
				language: lang
			}));

		}

		this.getWidgetDetails = function(provider) {
			var data = {
				language: locale.getLocale(),
				access_token: $cookies.get('openid_acccess_token')
			};
			console.log('getWidgetDetails', data);
			return $http.post(PORTFOLIO_WEBSERVER.app + '/api/user/bank-verification/' + provider + '/widget', data);
		}

		this.getApiBankAccounts = function(data, provider) {
			data.language = locale.getLocale();
			data.access_token = $cookies.get('openid_acccess_token');
			console.log('getApiBankAccounts', data);
			return $http.post(PORTFOLIO_WEBSERVER.app + '/api/user/bank-verification/' + provider + '/accounts', data);
		}

		this.openOfflineAccount = function(accounts) {
			return $http.post(PORTFOLIO_WEBSERVER.app + '/api/user/open-offline-account', {
				accounts: accounts
			});
		}

		this.getEsignEvents = function(subscription_cb) {

			//if new subscription
			if(subscription_cb) {
				subscribers.getEsignEvents.push(subscription_cb)

				//if already requested, wait for subscription cb
				if(locks.getEsignEvents) return;
			}

			locks.getEsignEvents = true;

			var deferred = $q.defer()

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/user/' + NwuiSession.user.uuid + '/esign-events?status=active').then(function(obj) {
				locks.getEsignEvents = false;

				//notify
				subscribers.getEsignEvents.forEach(function(subscriber) {
					subscriber(obj);
				})

				deferred.resolve(obj)
			}, function(err) {
				locks.getEsignEvents = false;

				deferred.reject(err)
			})

			return deferred.promise

		}

		this.getAggregatePerformance = function(user_uuid) {
			return $http.get(PORTFOLIO_WEBSERVER.app + '/api/users/' + (user_uuid || NwuiSession.user.uuid) + '/performance');
		}

		this.getEsignEventsJointSignaturePending = function(data) {
			return $http.get(PORTFOLIO_WEBSERVER.app + '/api/user/' + NwuiSession.user.uuid + '/esign-events?status=joint-signature-pending');
		}

		this.getPendingBrokerageTransfer = function(data) {
			return $http.get(PORTFOLIO_WEBSERVER.app + '/api/user/' + NwuiSession.user.uuid + '/esign-events?status=advisor-led-bt');
		}

		this.submitEsignEvent = function(esign_event_id, data) {
			if(esign_event_id) {
				return $http.put(PORTFOLIO_WEBSERVER.app + '/api/user/' + NwuiSession.user.uuid + '/esign-events/' + esign_event_id, { update: data });
			}
			return $http.post(PORTFOLIO_WEBSERVER.app + '/api/user/' + NwuiSession.user.uuid + '/esign-events', data);
		}

		this.getEsignEventSign = function(esign_event_id) {
			return $http.post(PORTFOLIO_WEBSERVER.app + '/api/user/' + NwuiSession.user.uuid + '/esign-events/' + esign_event_id + '/sign');
		}

		this.createRiskProfile = function() {
			return $http.post(PORTFOLIO_WEBSERVER.app + '/api/user/risk-profiles');
		}

		this.updateRiskProfile = function(risk_profile_id, data) {
			return $http.patch(PORTFOLIO_WEBSERVER.app + '/api/user/risk-profiles/' + risk_profile_id, { kyc_answers: data });
		}

		this.getRequestedBrokerageTransfers = function(query, page, quantity) {

			var deferred = $q.defer()
		
			var userId = NwuiSession.user.uuid;
	
			$http.get(PORTFOLIO_WEBSERVER.app + '/api/users/' + userId + '/bt-requests?query='+query+'&page_number='+page+'&page_size='+quantity).then(function(obj) {
				if(obj.data.data) {
					obj.data.data.forEach(function(row, i) {
						var slug = undefined;
						if(row.Account && row.Account.slug) {
							var slug = 'nwui.account_types.' + row.Account.slug + '.name';
							row.Account.account_type = locale.isTranslated(slug)
								? locale.getString(slug)
								: row.Account.account_type;
						}
					})
				}

				deferred.resolve(obj)
			}, function(err){
				////console.log(err)
				deferred.reject(err)
			})
	
			return deferred.promise;
		}

		this.getOutstandingBorrowedInvestmentSources = function(query, curPage, pageSize) {
            var deferred = $q.defer();

            var getUserBorrowedInvestmentSources = function() {
                var userId = NwuiSession.user.uuid;
                var httpConfig = {};

                $http.get(PORTFOLIO_WEBSERVER.app + '/api/users/' + userId + '/investment-loans?query='+query+'&page_number='+curPage+'&page_size='+pageSize, httpConfig).then(function(obj) {
                    deferred.resolve(obj);
                }, function(err) {
                    deferred.reject(err);
                });
            }

            if (NwuiSession.user.uuid) {
                getUserBorrowedInvestmentSources();
            } else {
                $rootScope.$on(NWUI_AUTH_EVENTS.authDone, function() {
                    getUserBorrowedInvestmentSources();
                });
            }
            return deferred.promise;
        }

		this.getFundingSources = function (filterByOwnershipType) {
			var deferred = $q.defer();

			var getUserFundingSources = function() {
				var userId = NwuiSession.user.uuid;
				var httpConfig = {};
				if (filterByOwnershipType) {
					httpConfig.params = {
						ownershipType: filterByOwnershipType
					};
				}
				$http.get(PORTFOLIO_WEBSERVER.app + '/api/users/' + userId + '/funding-sources', httpConfig).then(function(obj) {
					deferred.resolve(obj);
				}, function(err) {
					deferred.reject(err);
				});
			}

			if (NwuiSession.user.uuid) {
				getUserFundingSources();
			} else {
				$rootScope.$on(NWUI_AUTH_EVENTS.authDone, function() {
					getUserFundingSources();
				});
			}
			return deferred.promise;
		};

		this.getPendingFundingSources = function () {
			
			var userId = NwuiSession.user.uuid;
			
			var deferred = $q.defer();

			$http.get(PORTFOLIO_WEBSERVER.app + '/api/users/' + userId + '/pending-funding-sources').then(function(obj) {
				deferred.resolve(obj);
			}, function(err) {
				console.error(err);
				deferred.reject(err);
			});

			return deferred.promise;
		};

		this.getWithdrawalFundingRequests = function(status) {
			return function(query, page) {

				var deferred = $q.defer();
			
				var userId = NwuiSession.user.uuid;
		
				$http.get(PORTFOLIO_WEBSERVER.app + '/api/users/' + userId + '/eft-requests?type=withdrawal&query='+query+'&page_number='+page+'&page_size='+100).then(function(obj) {
					if(obj.data.data) {
						obj.data.data.forEach(function(row, i) {
							var slug = undefined;
							if(row.EFT.Account && row.EFT.Account.slug) {
								var slug = 'nwui.account_types.' + row.EFT.Account.slug + '.name';
								row.EFT.Account.account_type = locale.isTranslated(slug)
									? locale.getString(slug)
									: row.EFT.Account.account_type;
							}
						})
					}

					obj.data.data = obj.data.data.filter(function(row, i) {
						switch(status) {
							case 'pending':
								return !row.submitted && !row.cancelled && !row.reconciled;
							case 'inprocess':
								return row.submitted && !row.cancelled && !row.reconciled;
							case 'approved':
								return row.submitted && !row.cancelled && row.reconciled;
							case 'cancelled':
								return row.cancelled;
						}
					})
					obj.data.page_data.total_items = obj.data.data.length;
					obj.data.page_data.total_pages = 1 + (obj.data.page_data.length / obj.data.page_data.page_size);

					deferred.resolve(obj)
				}, function(err){
					deferred.reject(err)
				})
		
				return deferred.promise;
	
			}
		}
		


		this.getOneTimeFundingRequests = function(query, page, quantity) {

			var deferred = $q.defer()
		
			var userId = NwuiSession.user.uuid;
	
			$http.get(PORTFOLIO_WEBSERVER.app + '/api/users/' + userId + '/eft-requests?type=deposit&frequency=one_time&query='+query+'&page_number='+page+'&page_size='+quantity).then(function(obj) {
				if(obj.data.data) {
					obj.data.data.forEach(function(row, i) {
						var slug = undefined;
						if(row.Account && row.Account.slug) {
							var slug = 'nwui.account_types.' + row.Account.slug + '.name';
							row.Account.account_type = locale.isTranslated(slug)
								? locale.getString(slug)
								: row.Account.account_type;
						}
					})
				}

				deferred.resolve(obj)
			}, function(err){
				deferred.reject(err)
			})
	
			return deferred.promise;

		}

		this.getRecurringFundingRequests = function(query, page, quantity) {

			var deferred = $q.defer()
		
			var userId = NwuiSession.user.uuid;
	
			$http.get(PORTFOLIO_WEBSERVER.app + '/api/users/' + userId + '/eft-requests?type=deposit&frequency=pac&query='+query+'&page_number='+page+'&page_size='+quantity).then(function(obj) {
				if(obj.data.data) {
					obj.data.data.forEach(function(row, i) {
						var slug = undefined;
						if(row.Account && row.Account.slug) {
							var slug = 'nwui.account_types.' + row.Account.slug + '.name';
							row.Account.account_type = locale.isTranslated(slug)
								? locale.getString(slug)
								: row.Account.account_type;
						}
					})
				}

				deferred.resolve(obj)
			}, function(err){
				deferred.reject(err)
			})
	
			return deferred.promise;

		}

		let lifArray = ['Lif', 'Prif', 'Lrif', 'Rlif', 'LifNewON'];
		let liraArray = ['Lira', 'LiraCad', 'Rlsp', 'Lrsp']
		this.getLifCheck = (account_key, account_type ) => (account_key === 'Lif' &&  lifArray.includes(account_type)) 

		this.getLiraCheck = (account_key, account_type) => (account_key === 'Lira' &&  liraArray.includes(account_type))
	}
]);
