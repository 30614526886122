angular.module('portfolio_web.transfers.config', [
	'ui.router'
])

.config([
	'$stateProvider', 
	'$locationProvider', 
	'$urlRouterProvider', 

	'NWUI_AUTH_ROLES',
	function(
		$stateProvider, 
		$locationProvider, 
		$urlRouterProvider, 

		NWUI_AUTH_ROLES
	) {

		var transfers = {
			controller : 'TransfersController',
			url : '/transfers',
			abstract: true,
			templateUrl: 'portfolio_webserver/transfers/template.html',
			data: {
				// page_title: 'Transfers',
				page_title_i18n: 'nav.transfers',
				icon_name: 'compare_arrows',
				breadcrumb_proxy: 'app.transfers.overview',
				auth_roles: [NWUI_AUTH_ROLES.user]
			},
			resolve: {
				ResolveAccounts: [
					'$q',
        	'$stateParams',
        	'ApiAccountsService', 
        	function(
          	$q,
        		$stateParams, 
        		ApiAccountsService
        	) {
          	var deferred = $q.defer();

          	ApiAccountsService.getAccounts().then(function(obj) {
            	deferred.resolve(obj.data);
          	});
          	
          	return deferred.promise;
        	}
				],
				ResolveDestinationAccounts: [
					'$q',
					'$stateParams',
					'ApiAccountsService', 
					function(
						$q,
						$stateParams, 
						ApiAccountsService
					) {
						var deferred = $q.defer();
						
						if(
							organization_data &&
							organization_data.port_data &&
							organization_data.port_data.transfers &&
							organization_data.port_data.transfers.create &&
							organization_data.port_data.transfers.create.version === 'page'
						) {
							deferred.resolve([]);
						} else {
							ApiAccountsService.getAccountDestinations().then(function(obj) {
								deferred.resolve(obj.data);
							});
						}
						
						return deferred.promise;
					}
				]
			}
		}

		$stateProvider.state('app.transfers', transfers);

	}
]);