angular.module('portfolio_web.summary.directives', [
	'nwui-toolkit',
	'portfolio_web.api'
])

.directive('nwSummaryContent', [
	function() {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: 'portfolio_webserver/content/summary.html',
			scope: false,
			link: function(scope, element, attrs) {}
		}
	}
])

// .directive('nwSummaryModals', [
// 	function() {
// 		return {
// 			restrict: 'E',
// 			replace: true,
// 			templateUrl: 'portfolio_webserver/partials/summary/modals.html',
// 			scope: false,
// 			link: function(scope, element, attrs) {}
// 		}
// 	}
// ])

.directive('summaryGoal', ['$state', '$filter', function($state, $filter) {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'portfolio_webserver/partials/summary/goal.html',
		scope:{
			data: "=",
			index: "="
		},
		link: function(scope, element, attrs) {
			
			var changeSlide = function(slide_num) {
				
				scope.current_slide = slide_num;
				scope.goal_slider_style = {
					left: (-100 * slide_num) + '%'
				}
				  
			}
			
			if(!scope.data.tracking.not_set) {
				
				var text = ' - ' + $filter('currency')(scope.data.target_amount, '$', 0) + ' by ' + moment(scope.data.target_date, 'YYYYMMDD').format('YYYY/MM/DD')
				
				scope.target = {
					status: (scope.data.tracking.on_track) ? 'green' : 'off-track',
					text: (scope.data.tracking.on_track) ? 'On track'+text : 'Off track'+text
				}
			}
			
			scope.show_allocation_list = true;
			scope.current_slide = 0;
			scope.goal_slider_style = { left: 0 }
			
			scope.changeSlide = changeSlide;
			
			scope.nextSlide = function() {
				var slide = (scope.current_slide+1)%3;
				changeSlide(slide);
			}
			
			scope.prevSlide = function() {
				var slide = (scope.current_slide-1+3)%3;
				changeSlide(slide);
			}
			
			scope.toggleAllocationView = function() {
				scope.show_allocation_list = scope.show_allocation_list ? false : true;
			}
			
			scope.viewGoalDetail = function(goal) {
				$state.go('app.goals', { index: goal.goal_id })
			}
			
		}
	}
}])

// .directive('summaryGoalDonut', [
// 	'$window', 
// 	'$timeout', 
	
// 	'NwuiChartDonutV2Factory',
// 	function(
// 		$window, 
// 		$timeout,
		
// 		NwuiChartDonutV2Factory
// 	) {
// 		return {
// 			restrict: 'E',
// 			replace: true,
// 			templateUrl: 'portfolio_webserver/partials/summary/goal-donut.html',
// 			scope:{
// 				data: "="
// 			},
// 			link: function(scope, element, attrs) {
				
// 				scope.modules = {
// 					donut: undefined
// 				}
				
// 				scope.$watch('data', function(new_val) {
					
// 					if(new_val === undefined) return;
					
// 					////console.log('summary-goal-donut', 'watch data', new_val);
// 					var series = new_val.map(function(datum,i) {
// 						return {
// 							label: datum.name,
// 							type: datum.type,
// 							percent: datum.percent
// 						}
// 					});
					
// 					scope.modules.donut = new NwuiChartDonutV2Factory({
// 						active: series[0],
// 						series: series,
// 						decimals: 1
// 					});
					
// 				});
				
// 			}
// 		}
// 	}
// ])

// .directive('summaryAddGoal', ['', function() {
// 	return {
// 		restrict: 'E',
// 		replace: true,
// 		templateUrl: 'portfolio_webserver/partials/summary/add-goal.html',
// 		scope:{},
// 		link: function(scope, element, attrs) {
			
// 		}
// 	}
// }]);