angular.module('portfolio_web.transfers.new.controllers', [])

.controller('TransfersNewController', [
	'DATE_FORMAT',
	'$q',
	'$scope', 
	'$state',
	'$stateParams',
	'$timeout', 
	'$filter',
	'$rootScope',

	'NwuiFormV3Factory',
	'NwuiCardHeaderV2Factory',
	'NwuiRecommendationFactory',
	function(
		DATE_FORMAT,
		$q,
		$scope, 
		$state,
		$stateParams,
		$timeout, 
		$filter,
		$rootScope,

		NwuiFormV3Factory,
		NwuiCardHeaderV2Factory,
		NwuiRecommendationFactory
	) {

		$scope.data = {
			transfer_type: $state.params.transfer_type
		}

		$scope.blocks = {
			breadcrumbs: {
				root: {
					icon: {
						name: 'home'
					},
					state: 'app.summary'
				}
			}
		}

		$scope.card = {
			investment: {
				title: {
					text_i18n: 'transfers.new.investment.title.text',
					icon: {
						name: 'swap_horiz'
					},
					button: {
						id: 'transfers-new-switch-type',
						text_i18n: 'transfers.new.investment.title.button',
						// color: 'success-alternate',
						// size: 'small',
						// stretch: true,
						// icon: {
						// 	name: 'create'
						// },
						onClick: function() {
							$scope.data.transfer_type = 'chequing-savings';
							$state.go('app.transfers.new.type', { transfer_type: 'chequing-savings' })
						}
					},
					recommendation: {
						type: '',
						// icon: {
						// 	name: 'info_outline',
						// 	color: 'warning'
						// },
						text_i18n: 'transfers.new.investment.title.recommendation',
						button: undefined
					}
				}
			},
			chequing_savings: {
				title: {
					text_i18n: 'transfers.new.chequing_savings.title.text',
					icon: {
						name: 'swap_horiz'
					},
					button: {
						id: 'transfers-new-switch-type-2',
						text_i18n: 'transfers.new.chequing_savings.title.button',
						// color: 'success-alternate',
						// size: 'small',
						// stretch: true,
						// icon: {
						// 	name: 'create'
						// },
						onClick: function() {
							$scope.data.transfer_type = 'investment';
							$state.go('app.transfers.new.type', { transfer_type: 'investment' })
						}
					}
				}
			},
			// header: new NwuiCardHeaderV2Factory({
			// 	level: 2,
			// 	name_i18n: 'transfers.new.investment.header',
			// 	icon: {
			// 		name: 'account_balance'
			// 	}
			// }),
			// form: new NwuiFormV3Factory({
			// 	name: 'select-transfer-type-form',
			// 	onSubmit: function(post_data) {
			// 	},
			// 	onComplete: function(obj) {
			// 	},
			// 	submit_button: false,
			// 	steps: [
			// 		{
			// 			columns: [{
			// 				directive: 'input',
			// 				width: 100,
			// 				data: {
			// 					directive: 'radio',
			// 					data: {
			// 						model: $state.params.transfer_type || '',
			// 						id: 'select-transfer-type-form-transfer-type',
			// 						stretch: true,
			// 						required: true,
			// 						options: [{
			// 							label_i18n: "transfers.new.investment.input.investment",
			// 							value: 'investment'
			// 						}, {
			// 							label_i18n: "transfers.new.investment.input.chequing_savings",
			// 							value: 'chequing-savings'
			// 						}],
			// 						size: '',
			// 						post_var: 'transfer_type',
			// 						onChange: function(val) {

			// 							if(val == undefined || val == '') 
			// 								return false;

			// 							$scope.data.transfer_type = val;
			// 							$state.go('app.transfers.new.type', { transfer_type: val })

			// 						}
			// 					}
			// 				}
			// 			}]
			// 		}
			// 	]
			// }),
			// recommendations: {
			// 	bt: {
			// 		type: '',
			// 		icon: {
			// 			name: 'info_outline',
			// 			color: 'warning'
			// 		},
			// 		text_i18n: 'transfers.new.investment.info',
			// 		button: undefined
			// 	}
			// }
		}
		
	}
])