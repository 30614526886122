angular.module('portfolio_web.manage', [
	'ui.router',
	'portfolio_web.constants',

	'portfolio_web.manage.config',
	'portfolio_web.manage.controllers',
	'portfolio_web.manage.services',

	'portfolio_web.manage.general',
	'portfolio_web.manage.security',
	'portfolio_web.manage.documents',
	'portfolio_web.manage.risk_profiles',
	'portfolio_web.manage.account_statements',
	'portfolio_web.manage.transfer_sources',
	'portfolio_web.manage.investment_loans',

	// temp hidden from UI
	'portfolio_web.manage.withdrawals',
	'portfolio_web.manage.referrals',
	'portfolio_web.manage.plan_preferences',
	'portfolio_web.manage.personal_information'
]);