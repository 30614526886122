angular.module('portfolio_web.main.directives', [])

.directive('nwFooter', [
	'$filter',
	'$timeout',
	'$rootScope',
	'ApiGeneralService',
	'ApiUserService',
	'NwuiSession',
	'NwuiLocalizeService',
	'locale',
	'DATE_FORMAT',
	'NWUI_AUTH_EVENTS',
	'INTERCOM_APPID',
	'tmhDynamicLocale',
	'DomainParserService',
	function(
		$filter,
		$timeout,
		$rootScope,
		ApiGeneralService,
		ApiUserService,
		NwuiSession,
		NwuiLocalizeService, 
		locale,
		DATE_FORMAT,
		NWUI_AUTH_EVENTS,
		INTERCOM_APPID,
		tmhDynamicLocale,
		DomainParserService
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: 'portfolio_webserver/partials/footer.html',
			scope: {
				overlay: "="
			},
			link: function(scope, element, attrs) {
				scope.showLocalizationToggle = getShowLocalizationToggle(organization_data);
				
				scope.has_chat = (INTERCOM_APPID !== undefined && INTERCOM_APPID !== '') ? true : false;

				scope.has_zendesk = (zendesk !== undefined) ? true : false;

				if (window.Intercom) {
					window.Intercom('hide');
				}
				
				scope.data = {
					as_of: undefined,
					market_close: undefined,
					exchange_rate: undefined,
					org_slug: org_slug,
					org: organization_data
				}

				var onLangToggle = function(overrideLang) {
					var domain = DomainParserService.parse(window.location.href);
					var lang;
					if(overrideLang) {
						lang = overrideLang;
					}
					else if(NwuiLocalizeService.getLang() == "en-CA") {
						lang = "fr-CA";
					}
					else {
						lang = "en-CA";
					}

					if(scope.user) {
						locale.setLocaleCookie(lang, domain);

						ApiUserService.updateUserLang(lang).then(function(){
							window.location.href = window.location.href.split('?')[0];
						}).catch(function(){
							console.error("There was an error writing the language to the user object");
						})
					} else {
						locale.setLocaleCookie(lang, domain);
						
						ApiGeneralService.setLanguage(lang).then(function(res) {
							window.location.href = res.data[lang] + window.location.pathname + '?lang='+lang;
						}).catch(function(){
							console.error("There was an error switching the language");
						})
					}
				};

				if(window.NW_GLOBAL_HELPERS && typeof window.NW_GLOBAL_HELPERS.langReady === 'function') {
					window.NW_GLOBAL_HELPERS.langReady(onLangToggle);
				}

				scope.buttons = {
					locale_toggle: {
						id: 'footer-locale-toggle',
						text_i18n: 'footer.help.locale',
						size: 'short',
						color: 'text',
						onClick: onLangToggle
					}
				}

				scope.user = undefined;
				
				scope.openZendesk = function(e) {
					zEmbed.activate({ hideOnClose: true });
				}

				scope.openIntercom = function(e) {
					////console.log('nwFooter', 'openIntercom', e);
					e.stopPropagation();
					e.preventDefault();
					scope.overlay = true;
					if(window.Intercom) {
						window.Intercom('show');
					}
				}

				ApiGeneralService.getDataAsOf().then(function(obj) {

					setTimeout(function(){
						scope.data.as_of = obj.data;

						if(scope.data.as_of) {
							scope.data.market_close = moment(scope.data.as_of.date).utc().format(DATE_FORMAT['human']);
						}
					},0);
					
				});

				$rootScope.$on(NWUI_AUTH_EVENTS.authDone, function() {

					if(NwuiSession.user && NwuiSession.user.id) {
						scope.user = {
							id: NwuiSession.user.id
						}
					}
					
				});

				$rootScope.$on(NWUI_AUTH_EVENTS.authInactivityTimeout, function() {
					$timeout(function() {
						scope.user = undefined;
					}, 250);
				});
		
				$rootScope.$on(NWUI_AUTH_EVENTS.logoutSuccess, function() {
					$timeout(function() {
						scope.user = undefined;
					}, 250);
				});
				
			}
		}
	}
]);

/**
 * @returns boolean - to show language toggle or not
 */
function getShowLocalizationToggle(organizationData) {
	var hasMultipleLanguages = lodashGet(organizationData, 'join_data.localization.length', 0) > 1;
	var showLocalizationButton = lodashGet(organizationData, 'port_data.language.toggle.post_auth', true);

	if (!hasMultipleLanguages) {
		return false;
	}

	return showLocalizationButton;
}
