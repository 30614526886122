angular.module('portfolio_web.manage.personal_information.controllers', [])

.controller('ManagePersonalInformationController', [
	'$q',
	'$scope', 
	'$state',
	'$timeout',
	'$stateParams',
	'$sce',
	'NwuiMaskService',
	'NwuiCardHeaderV2Factory',
	'NwuiFormV3Factory',
	'NwuiOverlayFactory',
	'NwuiRecommendationFactory',
	'NwuiButtonV3Factory',
	'NwuiToastFactory',
	'NwuiCardTableFactory',

	'NwuiToastManagerService',
	'ApiUserService',
	'NwuiInputService',
	'PersonalInformationService',
	'NwButtonService',
	'NwuiFormV3Service',

	'ResolveFormAnswers',
	'ResolvePersonalInformationQuestions',

	'DATE_FORMAT',
	function(
		$q,
		$scope, 
		$state,
		$timeout,
		$stateParams,
		$sce,
		NwuiMaskService,
		NwuiCardHeaderV2Factory,
		NwuiFormV3Factory,
		NwuiOverlayFactory,
		NwuiRecommendationFactory,
		NwuiButtonV3Factory,
		NwuiToastFactory,
		NwuiCardTableFactory,

		NwuiToastManagerService,
		ApiUserService,
		NwuiInputService,
		PersonalInformationService,
		NwButtonService,
		NwuiFormV3Service,

		ResolveFormAnswers,
		ResolvePersonalInformationQuestions,

		DATE_FORMAT
	) {

		console.log(ResolvePersonalInformationQuestions, ResolveFormAnswers);

		var parseConditions = function(ng_if) {

			if (ng_if.or) {
				return ng_if.or.some(function (item) {
					return parseIndividualCondition(item);
				});
			} else if (ng_if.and) {
				return ng_if.and.every(function (item) {
					return parseIndividualCondition(item);
				});
			} else {
				return parseIndividualCondition(ng_if);
			}

		}

		var parseIndividualCondition = function(item) {

			if(item.ng_if_model) {

				var value = item.ng_if_value_model === undefined
					? item.ng_if_value
					: data.full_form_answers[item.ng_if_value_model];

				switch(item.ng_if_operator) {
					case 'gt':
						return data.full_form_answers[item.ng_if_model] > value;
						break;
					case 'gte':
						return data.full_form_answers[item.ng_if_model] >= value;
						break;
					case 'lt':
						return data.full_form_answers[item.ng_if_model] < value;
						break;
					case 'lte':
						return data.full_form_answers[item.ng_if_model] <= value;
						break;
					case 'ne':
						return data.full_form_answers[item.ng_if_model] != value;
						break;
					case 'regex':
						const regex = new RegExp(value, 'gi');
						return regex.test(data.full_form_answers[item.ng_if_model]);
						break;
					default:
						return data.full_form_answers[item.ng_if_model] == value;
				}
				
			} else if (item.or) {
				return parseConditions(item);
			} else if (item.and) {
				return parseConditions(item);
			} else {
				return data.full_form_answers[item.ng_if_model] == item.ng_if_value;
			}

		}

		var checkShow = function(field) {

			var show = true;

			if(field.ng_if) {
				show = parseConditions(field.ng_if);
			}

			NwuiInputService.inputs['personal-information-full-form-' + field.model].hide = !show;

		}

		var checkShowOptions = function (option) {
			// filter from total options and assign to 'options to be displayed'
			NwuiInputService.inputs['personal-information-full-form-' + option.watchers[0].model].options = NwuiInputService.inputs['personal-information-full-form-' + option.watchers[0].model].totalOptions.filter(op => {
				return !op.ng_if || (op.ng_if && parseConditions(op.ng_if))
			})
		}

		var applyWatchersLoop = function(ng_if, questions, question, map, isOptionWatcher = false) {

			if(ng_if.and) {

				return applyWatchersLoop(ng_if.and, questions, question, map, isOptionWatcher);

			} else if(ng_if.or) {

				return applyWatchersLoop(ng_if.or, questions, question, map, isOptionWatcher);

			} else if(ng_if.ng_if_model) {

				//apply watcher to the question
				if (!isOptionWatcher && questions[map[ng_if.ng_if_model]] && questions[map[ng_if.ng_if_model]].watchers.indexOf(question.model) === -1) {
					questions[map[ng_if.ng_if_model]].watchers.push(question);
				}

				//apply watcher to the options
				if (isOptionWatcher && questions[map[ng_if.ng_if_model]]) {
					const questionOptions = questions[map[ng_if.ng_if_model]].input.options
					// find the index of the option based on ng_if_value
					const index = questionOptions.map(el => el.value).indexOf(ng_if.ng_if_value)
					// if no wather on that option
					if (index > -1 && questionOptions[index].watchers.indexOf(question.model) === -1) {
						// set watcher for that option
						questionOptions[index].watchers.push(question);
					}
				}

			} else if(ng_if) {

				for(var i = 0; i < ng_if.length; i++) {
					applyWatchersLoop(ng_if[i], questions, question, map, isOptionWatcher);
				}
				
			}

		}

		var applyWatchers = function(questions) {

			//build map from array
			var map = {};
			for(var i = 0; i < questions.length; i++) {
				questions[i].watchers = [];
				map[questions[i].model] = i;
				if (questions[i].input?.options?.length > 0)
					for (let j = 0; j < questions[i].input.options.length; j++) {
						questions[i].input.options[j].watchers = [];
					}
			}

			questions.forEach(function(question, i) {

				// loop through ng_if conditions for question
				if(question.ng_if)
					applyWatchersLoop(question.ng_if, questions, question, map);
				
				// loop through ng_if conditions for options
				if (question.input?.options?.length > 0) {
					for (let i = 0; i < question.input.options.length; i++) {
						if (question.input.options[i].ng_if) {
							applyWatchersLoop(question.input.options[i].ng_if, questions, question, map, true)
						}
					}
				}

			})

		}

		var getFieldFromResults = function(results, fieldName) {
			if (Array.isArray(fieldName)) {
				var fields = [];
				for (var i in fieldName) {
					var field = getSingleFieldFromResults(results, fieldName[i]);
					if (field) {
						fields.push(field);
					}
				}
				return fields.join(', ');
			}
			return getSingleFieldFromResults(results, fieldName);
		};

		var getSingleFieldFromResults = function(results, fieldName) {
			var value = '';
			for(var i in results.retrieve) {
				var result = results.retrieve[i];
				if(
					result.FieldName === fieldName &&
					result.FieldGroup !== 'CommonAlternative' &&
					result.FieldGroup !== 'CountryAlternative'
				){
					value = result.FormattedValue;
					break;
				}
			}
			return value;
		}

		var updateModel = function(model, value) {
			if(NwuiInputService.inputs['personal-information-full-form-' + model]) {
				NwuiInputService.inputs['personal-information-full-form-' + model].model = value;
				// NwuiFormV3Service.answers[model] = value;
			}else if(NwuiInputService.inputs['personal-information-full-form-' + model]){
				NwuiInputService.inputs['personal-information-full-form-' + model].model = value;
				// NwuiFormV3Service.answers[model] = value;
			}
		};

		$scope.blocks = {
			breadcrumbs: {
				root: {
					// label: 'Home',
					icon: {
						name: 'home'
					},
					state: 'app.summary'
				}
			}
		}

		var data = {
			form_questions: ResolvePersonalInformationQuestions.slice(),
			full_form_answers: Object.assign({}, ResolveFormAnswers)
		}

		$scope.data = {
			openChat: window.Intercom ? function(e) {

				e.stopPropagation();
				e.preventDefault();
				
				if(window.Intercom) {
					window.Intercom('show');
				} else {

				}

			} : false
		}

		$scope.modules = {
			title: {
				text_i18n: 'manage.personal_information.title.text',
				icon: {
					name: 'person'
				}
			},
			form: new NwuiFormV3Factory({
				name: 'personal-information-full-form',
				onSubmit: function(post_data) {
				},
				onComplete: function(obj) {
				},
				submit_button: false,
				steps: [{
					columns: (function() {
						var columns = [];

						applyWatchers(data.form_questions);
						data.form_questions.forEach(function(field) {

							var directive = '';
							switch (field.input.input_type) {
								case "subheading":
									directive = 'subheading';
									break;
								case "select":
									directive = 'select'
									break;
								case "radio":
									directive = 'radio'
									break;
								case "input":
									directive = 'text'
									break;
								case "check":
									directive = 'checkbox';
									break;
								case "upload":
									directive = 'upload';
									break;
								case "address":
									directive = 'address';
									break;
								default:
									directive = 'text'
							}

							if(ResolveFormAnswers[field.model] == null) {
								ResolveFormAnswers[field.model] = '';
							}

							if (directive === 'checkbox') {
								for (var i = 0; i < field.input.options.length; i++) {
									field.input.options[i].label = field.input.options[i].text;
									field.input.options[i].label_i18n = 'nwui.forms.questions.' + field.i18n + '.options.' + field.options[i].model;
									field.input.options[i].value = ResolveFormAnswers[field.input.options[i].model] || false;
									field.input.options[i].ng_if = field.input.options[i].ng_if;
									field.input.options[i].watchers = field.input.options[i].watchers
								}
							}

							if (directive === 'radio') {
								field.input.options = field.input.options.map(function (option, i) {
									return {
										id: i,
										label: option.text,
										label_i18n: 'nwui.forms.questions.' + field.i18n + '.options.' + option.value,
										value: option.value,
										ng_if: option.ng_if,
										watchers: option.watchers
									}
								})
							}

							if (directive === 'select') {
								field.input.options = field.input.options.map(function (option, i) {
									return {
										id: i,
										text: option.text,
										text_i18n: 'nwui.forms.questions.' + field.i18n + '.options.' + option.value,
										value: option.value,
										ng_if: option.ng_if,
										watchers: option.watchers
									}
								})
							}

							if(field.input.mask) {
								ResolveFormAnswers[field.model] = NwuiMaskService.unmask(field.input.mask, ResolveFormAnswers[field.model]);
							}

							columns.push({
								width: field.width,
								directive: 'input',
								data: {
									directive: directive,
									data: {
										label: field.label,
										label_i18n: (directive === 'subheading') ? field.model : 'nwui.forms.questions.' + field.i18n + '.input.label',
										type: 'stacked',
										// model: (directive==='checkbox') ? undefined : '',
										model: (directive === 'checkbox') ? field.input.options : ResolveFormAnswers[field.model],
										id: 'personal-information-full-form-' + field.model,
										placeholder_i18n: 'nwui.forms.questions.' + field.i18n + '.input.placeholder',
										size: 'short',
										maxlength: field.input.maxlength,
										required: (field.input.optional !== true),
										totalOptions: field.input.options, // keeps the total / original options intact
										options: field.input.options.filter(option => !option.ng_if || parseConditions(option.ng_if)),
										post_var: field.model,
										validators: field.input.validate ? [field.input.validate] : [],
										mask: field.input.mask,
										onChange: function(model) {

											switch (directive) {
												case 'select':
												case 'radio':
													var post_var = this.post_var;
													this.options.forEach(function (option) {
														if(option.value === model) {
															data.full_form_answers[post_var] = option.value;
														}
													});
													break;
												case 'checkbox':
													this.options.forEach(function(option) {
														model.forEach(function (item) {
															if (option.model === item.model && data.full_form_answers[option.model] !== item.value) {
																data.full_form_answers[option.model] = item.value;
															}
														});
													});
													break;
													//text
												default:
													data.full_form_answers[this.post_var] = model;
											}

											field.watchers.forEach(function(field) {
												checkShow(field);
											})
											field.input?.options?.forEach(function (option) {
												if(option.watchers?.length > 0)
													checkShowOptions(option);
											})

										},
										onSuccess: (directive === 'upload') ? function(data) {

											data.full_form_answers[this.post_var] = this.model;

										} : undefined,
										address_selected_cb: function(results) {
											if (field.address_autocomplete) {
												var mappings = field.address_autocomplete.mapping;
												mappings.forEach(function(mapping) {
													// if(mapping.model === field.model) return;
													var value = getFieldFromResults(results, mapping.field);
													updateModel(mapping.model, value);
												});
											}
										}
									}
								}
							})

							if(field.watchers && field.watchers.length > 0) {
								(function() {
									$timeout(function() {
										field.watchers.forEach(function(field) {
											checkShow(field);
										})
									}, 0);
								})(field);
							}

							field.input?.options?.forEach(function (option) {
								if (option.watchers?.length > 0) {
									(function() {
										$timeout(function() {
											checkShowOptions(option);
										}, 0);
									})();
								}
							})
						});

						return columns;
					})()
				}]
			}),
			submit: {
				id: 'personal-information-update-submit-button',
				text_i18n: 'nwui.common.form.button.submit',
				color: 'warning',
				icon: {
					name: 'check'
				},
				onClick: function() {

					var valid = true;

					NwButtonService.buttons[$scope.modules.submit.id].processing = true;

					valid = NwuiFormV3Service.validate($scope.modules.form.name);
					if(valid !== true) {
						NwButtonService.buttons[$scope.modules.submit.id].processing = false;
						return;
					}

					PersonalInformationService
						.updateQuestions(NwuiFormV3Service.getFormData($scope.modules.form.name))
						.then(function(obj) {

							NwButtonService.buttons[$scope.modules.submit.id].processing = false;

							NwuiToastManagerService.register(
								new NwuiToastFactory({
									text_i18n: 'manage.personal_information.submit.success',
									icon: {
										name: 'check'
									}
								}
							));

							switch($stateParams.redirect) {
								case 'risk_profile':
									$state.go('app.manage.risk_profiles.view.edit', { goal_id: $stateParams.id, status: 'personal-info-success' })
									break;
								case 'account_opening':
									$state.go('app.accounts.new', { ownership_type: $stateParams.ownership, account_types: $stateParams.accounts, status: 'personal-info-success' })
									break;
								default:
									$state.go('app.summary')
							}

						}, function(err) {
							console.log(err);
						});

					// var valid = true;
					// if($scope.data.has_form_questions) {
					// 	for(var i = 0; i < $scope.modules.account.sections.length; i++) {
					// 		valid = NwuiFormV3Service.validate($scope.modules.account.sections[i].form.full.name);
					// 		if(valid !== true) {
					// 			NwButtonService.buttons[$scope.modules.submit.id].processing = false;
					// 			return;
					// 		}
					// 	}
					// }

				}
			}
		}
		
	}
]);