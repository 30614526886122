angular.module('portfolio_web.manage.withdrawals.list.config', [
	'ui.router'
])

.config([
	'$stateProvider',
	'NWUI_AUTH_ROLES',
	function(
		$stateProvider,
		NWUI_AUTH_ROLES
	) {

		var list = {
			url : '',
			templateUrl: 'portfolio_webserver/manage/withdrawals/list/template.html',
			controller : 'ManageWithdrawalsListController',
			params: {
				modal: null	
			},
			data: {
				page_title_i18n: 'nav.withdrawals',
				icon_name: 'payment',
				breadcrumb_label_i18n: 'nav.withdrawals',
				breadcrumb_proxy: undefined,
				auth_roles: [NWUI_AUTH_ROLES.user]
			},
			resolve: {
				ResolveResources: [
					'$q',
					'$stateParams',
					'ApiClientsService',
					function(
						$q,
						$stateParams,
						ApiClientsService
					) {
						return ApiClientsService.getResources().then(function(obj) {
							return obj.data;
						});
					}
				]
			}
		}

		$stateProvider.state('app.manage.withdrawals.list', list);

	}
]);