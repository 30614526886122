angular.module('portfolio_web.accounts.view.config', [])

.config([
	'$stateProvider', 
	'$locationProvider', 
	'$urlRouterProvider', 

	'NWUI_AUTH_ROLES', 
	function(
		$stateProvider, 
		$locationProvider, 
		$urlRouterProvider, 

		NWUI_AUTH_ROLES
	) {

		var view = {
			controller : 'AccountsViewController',
			url : '/:account_id',
			templateUrl: 'portfolio_webserver/accounts/view.html',
			params: {
				account_id: null,
				reload: null
			},
			data: {
				page_title_i18n: 'nav.accounts',
				icon_name: 'account_balance',
				breadcrumb_label_i18n: 'nav.accounts',
				breadcrumb_proxy: undefined,
				auth_roles: [NWUI_AUTH_ROLES.user]
			}
		}

		$stateProvider.state('app.accounts.view', view);

	}
]);