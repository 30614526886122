angular.module('portfolio_web.manage.investment_loans.controllers', [])

.controller('ManageInvestmentLoansController', [
	'$q',
	'$scope', 
	'$state',
	'$timeout',
	'$stateParams',
	
	'NwuiCardHeaderV2Factory',
	'NwuiButtonV3Factory',
	'NwuiModalV2Factory',
	'NwuiFormV3Factory',
	'NwuiToastFactory',
	'NwuiCardTableV3Factory',

	'NwuiInputService',
	'NwButtonService',
	'NwuiToastManagerService',
	'NwuiSession',
	'ApiUserDocumentsService',
	'ApiUserService',
	'NwuiButtonV2Service',

	'DATE_FORMAT',
	'locale',
	'$filter',
	function(
		$q,
		$scope, 
		$state,
		$timeout,
		$stateParams,
		
		NwuiCardHeaderV2Factory,
		NwuiButtonV3Factory,
		NwuiModalV2Factory,
		NwuiFormV3Factory,
		NwuiToastFactory,
		NwuiCardTableV3Factory,
		
		NwuiInputService,
		NwButtonService,
		NwuiToastManagerService,
		NwuiSession,
		ApiUserDocumentsService,
		ApiUserService,
		NwuiButtonV2Service,

		DATE_FORMAT,
		locale,
		$filter
	) {
		
		$scope.data = {
			user: NwuiSession.user,
			org_slug: org_slug,
			org: organization_data
		}
		
		$scope.blocks = {
			breadcrumbs: {
				root: {
					icon: {
						name: 'home'
					},
					state: 'app.summary'
				}
			}
		}
		
		$scope.cards = {
			info: {
				header: {
					// name: 'My Documents',
					name_i18n: 'manage.investment_loans.about.title',
					icon: {
						name: 'info_outline'
					}
				}
			},
			investment_loans: {
				header: {
					// name: 'Documents',
					name_i18n: 'manage.investment_loans.table.title',
					icon: {
						name: 'description'
					}
				},
				table: new NwuiCardTableV3Factory({
					id: 'investment-loans-list-table',
					resource: ApiUserService.getOutstandingBorrowedInvestmentSources,
					rowMap: function(investment,i) {
						const slug = 'nwui.account_types.' + investment.account_type + '.name';
						const account_name = locale.isTranslated(slug) ?
							locale.getString(slug) : investment.account_type;
						const amount = investment.borrowed_balance ?
							$filter('currency')(investment.borrowed_balance) : '';
						return {
							columns: [{
								text: account_name
							}, {
								text: investment.custodian_id
							}, {
								text: amount
							}]
						}
					},
					// empty_text: 'no documents found',
					empty_text_i18n: 'manage.investment_loans.table.empty',
					columns: [{
						// name: 'Account ',
						name_i18n: 'manage.investment_loans.table.col_0',
						directive: 'text',
						sortable: false
					},{
						// name: 'Account number',
						name_i18n: 'manage.investment_loans.table.col_1',
						directive: 'text',
						sortable: false
					},{
						// name: 'Borrowed amount',
						name_i18n: 'manage.investment_loans.table.col_2',
						directive: 'text',
						sortable: false
					}],
					footer: {
						page_size: 10
					}
				})
			}
		}

	}
]);