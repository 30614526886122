angular.module('portfolio_web.manage.transfer_sources.controllers', [])

.controller('ManageTransferSourcesController', [
	'$scope', 
	function(
		$scope
	) { 

		

	}
]);