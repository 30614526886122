angular.module('portfolio_web.manage.transfer_sources.list.controllers', [])

.controller('ManageTransferSourcesListController', [
	'$q',
	'$scope', 
	'$state',
	'$timeout',
	'$stateParams',
	
	'NwuiCardHeaderV2Factory',
	'NwuiAccordionFactory',
	'NwuiCardTableFactory',
	'NwuiButtonV3Factory',
	'NwuiModalV2Factory',
	'NwuiFormV2Factory',
	'NwuiToastFactory',
	
	'EftService',
	'ApiUserService',
	'NwuiInputService',
	'NwButtonService',
	'NwuiToastManagerService',
	'NwuiSession',

	'locale',

	'ResolveResources',
	'NwuiSigningService',
	'NwuiPageScrollService',
	function(
		$q,
		$scope, 
		$state,
		$timeout,
		$stateParams,
		
		NwuiCardHeaderV2Factory,
		NwuiAccordionFactory,
		NwuiCardTableFactory,
		NwuiButtonV3Factory,
		NwuiModalV2Factory,
		NwuiFormV2Factory,
		NwuiToastFactory,
		
		EftService,
		ApiUserService,
		NwuiInputService,
		NwButtonService,
		NwuiToastManagerService,
		NwuiSession,

		locale,

		ResolveResources,
		NwuiSigningService,
		NwuiPageScrollService
	) {

		
		var loadPendingEftSources = function() {

			var deferred = $q.defer();
			
			ApiUserService.getPendingFundingSources().then(function(obj) {
				$scope.data.pending_eft_sources = obj.data;
				deferred.resolve();
			});
			
			return deferred.promise;
			
		}

		function loadEftSources() {
			var deferred = $q.defer();

			ApiUserService.getFundingSources().then(function(obj) {
				$scope.data.eft_sources = obj.data;
				deferred.resolve();
			});

			return deferred.promise;
		}
		
		$scope.data = {
			supports_eft_source_page: (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.transfers &&
				organization_data.port_data.transfers.eft_sources &&
				organization_data.port_data.transfers.eft_sources.create &&
				organization_data.port_data.transfers.eft_sources.create.version === 'page'
			),
			eft_sources: undefined,
			pending_eft_sources: undefined
		}
		
		$scope.blocks = {
			breadcrumbs: {
				root: {
					// label: 'Home',
					icon: {
						name: 'home'
					},
					state: 'app.summary'
				}
			}
		}

		$scope.compounds = {
			add_eft_source: {
				open: $stateParams.modal || false,
				formResource: EftService.createEft,
				submitCb: function(obj) {

					loadPendingEftSources();
				}
			},
			remove_eft_source: {
				modal: {
					open: false
				},
				header: {
					name_i18n: 'manage.transfer_sources.modal.remove_eft_source.header'
				},
				body: {
					name_i18n: 'manage.transfer_sources.modal.remove_eft_source.body',
					name_i18n_data: {
						source: ''
					}
				},
				confirm: {
					text_i18n: 'manage.transfer_sources.modal.remove_eft_source.confirm',
					icon: {
						name: 'check'
					},
					stretch: true
				},
				cancel: {
					text_i18n: 'manage.transfer_sources.modal.remove_eft_source.cancel',
					icon: {
						name: 'close'
					},
					stretch: true,
					color: 'error',
					onClick: function() {
						$scope.compounds.remove_eft_source.modal.open = false;
					}
				},
				data: {}
			},
      bank_verifications: {
				open: false,
				provider: null,
				autoApprove: false,
				needsSigning: true,
				singleAccountSelector: false,
        manualClickedCb: function() {
			    $scope.compounds.bank_verifications.open = false;
			    setTimeout(function() {
			      goToManualForm();
          }, 50);
				},
				getAccountsCb: function(data) {
					var requestBody = {
						login_id: data.data.loginId
					};
					return ApiUserService.getApiBankAccounts(requestBody, data.provider)
				},
				getWidgetCb: function(provider) {
					return ApiUserService.getWidgetDetails(provider)
				},
        submitCb: function(data) {
					var provider = $scope.compounds.bank_verifications.provider;
					var autoApprove = $scope.compounds.bank_verifications.autoApprove;
					console.log('createEftSource', data, provider, autoApprove);

					return createEftSource(data, provider, autoApprove);
        },
        signCb: function(eft_source) {
			    $scope.compounds.bank_verifications.open = false;
			    checkEnvelopeIdAndSign(eft_source);
        }
      }
		};

		setTimeout(function() {
			if ($stateParams.redirectAddNew) {
				if (organization_data.bankVerification) {
					goToBankVerificationForm();
				} else {
					goToManualForm();
				}
			}
		}, 50);

		function checkEnvelopeIdAndSign(eft_source) {
      if (eft_source.envelope_id) {
        $timeout(function() {
          NwuiSigningService.setEnvelope('global-document-signing', eft_source.envelope_id);
          NwuiSigningService.setSubmitCb('global-document-signing', function(obj) {
            loadPendingEftSources();
          });
          NwuiPageScrollService.open('global-document-signing');
        }, 0);
      }
    }

		function createEftSource(data, provider, autoApprove) {
			if(autoApprove) {
				return EftService.createEftWithAccounts(data, 'integration')
					.then(function(res) {
						loadPendingEftSources();
						return res.data;
					});
			} else {
				return EftService.createEft(data[0], 'integration')
					.then(function(res) {
						loadPendingEftSources();
						return res.data;
					});
			}
    }

		function getLocalizedSourceName(eft_source) {
			return locale.getString(
				eft_source.nickname ? 'manage.transfer_sources.active.title' : 'manage.transfer_sources.active.title_default',
				{
					accountNickname: eft_source.nickname,
					bankName: eft_source.bank_name,
					accountType: eft_source.Account && eft_source.Account.account_type ? eft_source.Account.account_type : '',
					accountNumber: eft_source.Account && eft_source.Account.custodian_id	? eft_source.Account.custodian_id : '',
					bankNameAndAccountType: eft_source.bank_name ?
						eft_source.Account && eft_source.Account.account_type ?
							eft_source.bank_name + ' - ' + eft_source.Account.account_type
							: eft_source.bank_name
						: eft_source.Account && eft_source.Account.account_type ?
							eft_source.Account.account_type
							: ''
				}
			);
		}

		function showRemoveModal(eft_source, i) {
			$scope.compounds.remove_eft_source.body.name_i18n_data.source = getLocalizedSourceName(eft_source);
			$scope.compounds.remove_eft_source.modal.open = true;
			$scope.compounds.remove_eft_source.data.eft_source = eft_source;
			$scope.compounds.remove_eft_source.data.index = i;
			$scope.compounds.remove_eft_source.confirm.onClick = removeEftSource(eft_source, i);
		}

		function removeEftSource(eft_source, i) {
			return function() {
				NwButtonService.buttons['eft-source-remove-button-'+i].processing = true;
				$scope.compounds.remove_eft_source.modal.open = false;
				EftService.removeEftSource(eft_source.id)
					.then(function() {
						loadEftSources()
						NwuiToastManagerService.register(
							new NwuiToastFactory({
									text_i18n: 'manage.transfer_sources.modal.remove_eft_source.success',
									icon: {
										name: 'check'
									}
								}
							)
						);
					}).catch(function(err) {
						if (
							err.status === 400 &&
							err.data &&
							(err.data.code === 4443 || err.data.code === 4444)
						) {
							NwuiToastManagerService.register(
								new NwuiToastFactory({
										text_i18n: 'manage.transfer_sources.error.' + err.data.code,
										icon: {
											name: 'close'
										}
									}
								)
							);
						} else {
							NwuiToastManagerService.register(
								new NwuiToastFactory({
										text_i18n: 'nwui.common.error.unknown',
										icon: {
											name: 'close'
										}
									}
								)
							);
						}
						NwButtonService.buttons['eft-source-remove-button-'+i].processing = false;
					})
			}
		}

		if($scope.data.supports_eft_source_page) {
			$timeout(function() {
				$scope.compounds.add_eft_source.open = $stateParams.modal || false;
			}, 150);
		}
		
		$scope.cards = {
			info: {
				header: {
					// name: 'Transfer Sources',
					name_i18n: 'manage.transfer_sources.about.title',
					icon: {
						name: 'info_outline'
					},
					button: {
						id: 'new-eft-button-sm',
						text: '',
						color: '',
						icon_only: true,
						icon: {
							name: 'create'
						},
						onClick: function() {
							
							if(organization_data.bankVerification
									&& organization_data.bankVerification.provider) {
								goToBankVerificationForm();
							} else {
								goToManualForm();
							}
						}
					}
				}
			},
			eft_sources: {
				accordions: []
			},
			pending_eft_sources: {
				header: {
					// name: 'Pending Transfer Sources',
					name_i18n: 'manage.transfer_sources.pending.title',
					icon: {
						name: 'access_time'
					},
					button: {
						id: 'whats-this-transfers-button',
						// text: "What's this?",
						text_i18n: "manage.transfer_sources.pending.button",
						icon_only_mobile: true,
						color: '',
						icon: {
							name: 'help'
						},
						onClick: function() {
							
							$scope.modals.pending_sources_info.open = true;
					
						}
					}
				},
				accordions: []
			}
		}

		function goToBankVerificationForm() {
			$scope.compounds.bank_verifications.provider = organization_data.bankVerification.provider;
			$scope.compounds.bank_verifications.needsSigning = organization_data.bankVerification.providerSettings.needsSigning;
			$scope.compounds.bank_verifications.autoApprove = organization_data.bankVerification.providerSettings.autoApprove;
			$scope.compounds.bank_verifications.singleAccountSelector = organization_data.bankVerification.providerSettings.singleAccountSelector;
			$scope.compounds.bank_verifications.open = true;
		}

		function goToManualForm() {
      if($scope.data.supports_eft_source_page) {
        $state.go('app.manage.transfer_sources.new')
      } else {
        $scope.compounds.add_eft_source.open = true;
      }
    }
		
		$scope.cutouts = {
			new_eft: {
				button: new NwuiButtonV3Factory({
					id: 'new-eft-button',
					// text: 'Add New Transfer&nbsp;Source',
					text_i18n: 'manage.transfer_sources.cutout.button',
					icon: {
						name: 'create'
					},
					color: 'secondary',
					onClick: function() {
						
						//$scope.modals.add_eft_source.open = true;
						if(organization_data.bankVerification
								&& organization_data.bankVerification.provider) {
							goToBankVerificationForm();
            } else {
              goToManualForm();
            }
				
					}
				})
			}
		}
		
		$scope.modals = {
			pending_sources_info: new NwuiModalV2Factory({
				open: false,
				wide: false,
				header: {
					// name: 'About Transfer Sources',
					name_i18n: 'manage.transfer_sources.modal.about_transfer_sources.title',
					icon: {
						name: 'access_time'
					}
				},
				button: new NwuiButtonV3Factory({
					id: 'pending-sources-info-button',
					text_i18n: 'manage.transfer_sources.modal.about_transfer_sources.button',
					color: '',
					icon: {
						name: 'check'
					},
					//color: 'secondary',
					stretch: true,
					onClick: function() {
						
						$scope.modals.pending_sources_info.open = false;
				
					}
				})
			})
		}

		loadEftSources();
		loadPendingEftSources();

		var canRemove = (
			ResolveResources.resource &&
			ResolveResources.resource.manage &&
			ResolveResources.resource.manage.tertiaryNav &&
			ResolveResources.resource.manage.tertiaryNav.transfer_sources &&
			ResolveResources.resource.manage.tertiaryNav.transfer_sources.permissions &&
			ResolveResources.resource.manage.tertiaryNav.transfer_sources.permissions.remove_sources
		);

		$scope.$watch('data.eft_sources', function(new_val) {
			
			if(new_val === undefined) return;

			$scope.cards.eft_sources.accordions = new_val.map(function(eft_source,i) {

				var removeButton = canRemove ? {
					text_i18n: 'nwui.common.form.button.remove',
					id: 'eft-source-remove-button-'+i,
					onClick: function() {
						showRemoveModal(eft_source, i)
					}
				} : null;

				return new NwuiAccordionFactory({
					// name: eft_source.nickname || "My Bank Account",
					name_i18n: eft_source.nickname ? 'manage.transfer_sources.active.title' : 'manage.transfer_sources.active.title_default',
					name_i18n_data: {
						accountNickname: eft_source.nickname,
						bankName: eft_source.bank_name,
						accountType: eft_source.Account && eft_source.Account.account_type ? eft_source.Account.account_type : '',
						accountNumber: eft_source.Account && eft_source.Account.custodian_id	? eft_source.Account.custodian_id : '',
						bankNameAndAccountType: eft_source.bank_name ?
							eft_source.Account && eft_source.Account.account_type ?
								eft_source.bank_name + ' - ' + eft_source.Account.account_type
								: eft_source.bank_name
							: eft_source.Account && eft_source.Account.account_type ?
								eft_source.Account.account_type
								: ''
					},
					expanded: (i===0),
					form: new NwuiFormV2Factory({
						name: 'eft-source-form-'+i,
						onSubmit: function(post_data) {},
						onComplete: function(obj) {},
						columns: [{
							directive: 'input',
							width: 90,
							data: {
								directive: 'text',
								data: {
									// label: 'Inst. #',
									label_i18n: 'nwui.common.input.institution_number.label',
									model: eft_source.bank_institution_str,
									id: 'eft-source-form-input-institution-'+i,
									stretch: true,
									required: true,
									disabled: true,
									size: 'short',
									mask: 'bank_institution',
									validators: ['bank_institution'],
									// placeholder: "001",
									placeholder_i18n: 'nwui.common.input.institution_number.placeholder',
								}
							}
						}, {
							directive: 'input',
							width: 115,
							data: {
								directive: 'text',
								data: {
									// label: 'Transit #',
									label_i18n: 'nwui.common.input.transit_number.label',
									model: eft_source.bank_branch_str,
									id: 'eft-source-form-input-transit-'+i,
									stretch: true,
									required: true,
									disabled: true,
									size: 'short',
									mask: 'bank_transit',
									validators: ['bank_transit'],
									// placeholder: "01234",
									placeholder_i18n: 'nwui.common.input.transit_number.placeholder'
								}
							}
						}, {
							directive: 'input',
							data: {
								directive: 'text',
								data: {
									// label: 'Account #',
									label_i18n: 'nwui.common.input.account_number.label',
									model: eft_source.bank_account,
									id: 'eft-source-form-input-account-'+i,
									stretch: true,
									required: true,
									disabled: true,
									size: 'short',
									mask: 'bank_account',
									// placeholder: "01234ABCDE",
									placeholder_i18n: 'nwui.common.input.account_number.placeholder'
								}
							}
						}]
					}),
					removeButton: removeButton
				})
			})
			
		});
		
		$scope.$watch('data.pending_eft_sources', function(new_val) {
			
			if(new_val === undefined) return;
			
			$scope.cards.pending_eft_sources.accordions = [];
			
			$timeout(function() {
				$scope.cards.pending_eft_sources.accordions = new_val.map(function(eft_source,i) {
					return new NwuiAccordionFactory({
						// name: eft_source.nickname || "My New Account",
						name_i18n: eft_source.nickname ? 'manage.transfer_sources.pending.nickname' : 'manage.transfer_sources.pending.nickname_default',
						name_i18n_data: {
							accountNickname: eft_source.nickname,
							bankName: eft_source.bank_name,
						},
						button: (eft_source.envelope_id && !eft_source.is_digitally_signed) ? {
							text_i18n: 'manage.transfer_sources.pending.signButton',
							id: 'eft-source-sign-button-' + i,
							size: 'short',
							onClick: function() {
								checkEnvelopeIdAndSign(eft_source);
							}
						} : null,
						expanded: false,
						/*button: {
							text: 'Pending approval',
							hollow: true,
							color: 'warning',
							icon: {
								name: 'access_time'
							},
							onClick: function() {
								return;
							}
						},*/
						form: new NwuiFormV2Factory({
							name: 'pending-eft-source-form-'+i,
							onSubmit: function(post_data) {},
							onComplete: function(obj) {},
							columns: [{
								directive: 'input',
								width: 90,
								data: {
									directive: 'text',
									data: {
										// label: 'Inst. #',
										label_i18n: 'nwui.common.input.institution_number.label',
										model: eft_source.bank_institution_str,
										id: 'pending-eft-source-form-input-institution-'+i,
										stretch: true,
										required: true,
										disabled: true,
										size: 'short',
										mask: 'bank_institution',
										validators: ['bank_institution'],
										// placeholder: "001",
										placeholder_i18n: 'nwui.common.input.institution_number.placeholder',
									}
								}
							}, {
								directive: 'input',
								width: 115,
								data: {
									directive: 'text',
									data: {
										// label: 'Transit #',
										label_i18n: 'nwui.common.input.transit_number.label',
										model: eft_source.bank_branch_str,
										id: 'pending-eft-source-form-input-transit-'+i,
										stretch: true,
										required: true,
										disabled: true,
										size: 'short',
										mask: 'bank_transit',
										validators: ['bank_transit'],
										// placeholder: "01234",
										placeholder_i18n: 'nwui.common.input.transit_number.placeholder'
									}
								}
							}, {
								directive: 'input',
								data: {
									directive: 'text',
									data: {
										// label: 'Account #',
										label_i18n: 'nwui.common.input.account_number.label',
										model: eft_source.bank_account,
										id: 'pending-eft-source-form-input-account-'+i,
										stretch: true,
										required: true,
										disabled: true,
										size: 'short',
										mask: 'bank_account',
										// placeholder: "01234ABCDE",
										placeholder_i18n: 'nwui.common.input.account_number.placeholder'
									}
								}
							}]
						})
					})
				})
			}, 0);
			
		});
		
	}
]);