angular.module('portfolio_web.manage.account_statements.config', [
	'ui.router'
])

.config([
	'$stateProvider',
	'NWUI_AUTH_ROLES',
	function(
		$stateProvider,
		NWUI_AUTH_ROLES
	) {

		var account_statements = {
			url : '/account-statements',
			templateUrl: 'portfolio_webserver/manage/account_statements.html',
			controller : 'ManageAccountStatementsController',
			data: {
				// page_title: 'Manage - Account Statements',
				page_title_i18n: 'nav.account_statements',
				icon_name: 'library_books',
				breadcrumb_label_i18n: 'nav.account_statements',
				auth_roles: [NWUI_AUTH_ROLES.user]
			}
		}

		$stateProvider.state('app.manage.account_statements', account_statements);

	}
]);