angular.module('portfolio_web.manage.withdrawals.new.controllers', [])

.controller('WithdrawalsController', [
	'$q',
	'$scope',
	'$state',
	'$timeout',
	'$stateParams',
	'$filter',
	'ApiAccountsService',
	'EftService',
	'NwuiSession',
	'NwuiInputService',
	'WithdrawalService',
	'NwuiToastManagerService',
	'NwuiToastFactory',
	'NwuiCardHeaderV2Factory',
	'NwuiInputSelectV2Factory',
	'NwuiFormV3Factory',
	'NwuiModalV2Factory',
	'NwuiRecommendationFactory',
	'NwuiCardBigNumberFactory',

	'ResolveProvince',
	'ResolveDestinationEfts',
	'ResolveSourceAccounts',
	'locale',
	function(
		$q,
		$scope,
		$state,
		$timeout,
		$stateParams,
		$filter,
		ApiAccountsService,
		EftService,
		NwuiSession,
		NwuiInputService,
		WithdrawalService,
		NwuiToastManagerService,
		NwuiToastFactory,
		NwuiCardHeaderV2Factory,
		NwuiInputSelectV2Factory,
		NwuiFormV3Factory,
		NwuiModalV2Factory,
		NwuiRecommendationFactory,
		NwuiCardBigNumberFactory,

		ResolveProvince,
		ResolveDestinationEfts,
		ResolveSourceAccounts,
		locale
	) {

		console.log('WithDrawalsController', 'init');

		$scope.gross_amount_selected = false;
		$scope.account_selected = false;
		$scope.full_withdrawal = false;

		$scope.data = {
			user: NwuiSession.user,
			accounts: ResolveSourceAccounts,
			accountsEfts: ResolveDestinationEfts,
			province: ResolveProvince,
			hasPendingWithdrawals: WithdrawalService.getPendingWithdrawalRequests(),
			org_slug: org_slug,
			org: organization_data,
			use_withdrawals_page: (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.withdrawals,
				organization_data.port_data.withdrawals.enabled === true
			),
			enable_mandatory_confirmation: (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.withdrawals,
				organization_data.port_data.withdrawals.enable_mandatory_confirmation === true
			),
			hide_fees_if_zero: (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.withdrawals,
				organization_data.port_data.withdrawals.hide_fees_if_zero === true
			),
			has_source_accounts: (ResolveSourceAccounts && ResolveSourceAccounts.length > 0)
		}

		// defaults
		var resetWithdrawalCalculator = function() {
			$scope.gross_amount = 0.00;
			$scope.net_amount = 0.00;
			$scope.withholding_tax = 0.00;
			$scope.fees = 0.00;
		}


		var enableRemainingFormInputs = function() {
			NwuiInputService.inputs['withdrawals-form-input-amount'].disabled = false;
			NwuiInputService.inputs['withdrawals-form-destination-bank-account'].disabled = false;
			NwuiInputService.inputs['withdrawals-form-input-reason'].disabled = false;
			NwuiInputService.inputs['withdrawals-form-input-full-withdrawal'].disabled = false;
		}

		$scope.select_account = {
			message: 'manage.withdrawals.about.select_account'
		};
		$scope.i18n_data = {
			organization_name_short: $scope.data.org.name_short
		};

		$scope.data.hasPendingWithdrawals.then(function(obj) {
			$scope.numberOfPendingWithdrawals = obj.total;
			$scope.dollarAmountPendingPerAccount = obj.withdrawalsPending;

			$scope.pending_withdrawals_recommendation = new NwuiRecommendationFactory({
				type: 'warning',
				icon: {
					name: 'access_time'
				},
				text_i18n: 'manage.withdrawals.pending_withdrawls_text.message',
				text_i18n_data: {
					numberOfPendingWithdrawals: $scope.numberOfPendingWithdrawals
				},
				button: undefined
			});
		});

		$scope.pending_withdrawals = {
			button: {
				id: 'view-pending-withdrawals',
				text_i18n: 'manage.withdrawals.view_pending_withdrawals',
				size: 'short',
				color: 'warning',
				onClick: function() {
					$state.go('app.manage.withdrawals.list');
				}
			}
		}

		$scope.blocks = {
			breadcrumbs: {
				root: {
					icon: {
						name: 'home'
					},
					state: 'app.summary'
				}
			}
		};
		$scope.modal = {
			withdrawal_confirmation: new NwuiModalV2Factory({
				open: false,
				wide: false,
				header: {
					name_i18n: 'manage.withdrawals.modal.header'
				},
				button: {
					id: 'withdrawal-modal-submit-button',
					text_i18n: 'manage.withdrawals.modal.confirmation_button',
					color: '',
					onClick: function() {
						return EftService.submitEftRequest($scope.modal.withdrawal_confirmation.submit_data)
							.then(function(res) {
								$scope.modal.withdrawal_confirmation.open = false;
								$scope.cards.withdrawal_form.form.submit_button.processing = false;

								var isWithdrawalCancelled = res.data && res.data.cancelled && res.data.cancelled_date;

								if (res.status === 200 && !isWithdrawalCancelled) {
									// show toast
									NwuiToastManagerService.register(
										new NwuiToastFactory({
											text_i18n: 'manage.withdrawals.message.success',
											icon: {
												name: 'check'
											}
										}
									));
								} else {
									NwuiToastManagerService.register(
										new NwuiToastFactory({
											text_i18n: 'nwui.modal.create_eft.error',
											type: 'error',
											icon: {
												name: 'close'
											}
										}
									));
								}
								$state.go('app.manage.withdrawals.list');
							}, function(error) {
								$scope.cards.withdrawal_form.form.submit_button.processing = false;

								if (typeof error.data === 'undefined') return;

								if (error && error.data) {
									NwuiToastManagerService.register(
										new NwuiToastFactory({
											text_i18n: 'nwui.modal.create_eft.error',
											type: 'error',
											icon: {
												name: 'close'
											}
										}
									));
								}
							});
					}
				}
			})
		};

		const withdrawalFormStepColumns = [{
			directive: 'input',
			width: 100,
			data: {
				directive: 'select',
				data: {
					label_i18n: 'nwui.common.input.withdrawal_source_account.label',
					model: '',
					id: 'withdrawal-form-input-account',
					stretch: true,
					required: true,
					disabled: $scope.data.accountsEfts.length === 0 ? true : false,
					placeholder_i18n: 'accounts.view.switcher.placeholder',
					options: ($scope.data.accountsEfts || []).map(function(account, i) {

						var account_type;

						if (account.slug) {
							var slug = 'nwui.account_types.' + account.slug + '.name';
							account_type = locale.isTranslated(slug)
								? locale.getString(slug)
								: account.account_type;
						} else {
							account_type = locale.getString('nwui.account_types.' + account.account_type + '.name') || account.account_type;
						}

						return {
							id: 'withdrawal-form-input-account-'+i,
							text_i18n: 'nwui.common.input.source_account.option_include_market_val',
							text_i18n_data: {
								accountType: account_type,
								accountId: account.custodian_id,
								marketValue: $filter("currency")(account.market_value)
							},
							value: account
						}
					}),
					size: '',
					post_var: 'account',
					onChange: function (selectedAccount) {
						$scope.account_selected = true;
						$scope.total_withdrawal_amount_pending = undefined;
						$scope.full_withdrawal = false;

						$scope.dollarAmountPendingPerAccount.find(function(account) {
							if (account.accountId === selectedAccount.id) {
								$scope.total_withdrawal_amount_pending = account.amount;
							}
						});

						$scope.available_funds = (selectedAccount.market_value - ($scope.total_withdrawal_amount_pending || 0)).toFixed(2);

						$scope.account_messaging_text = 'manage.withdrawals.about.messaging.' + selectedAccount.group_type;

						// reset previously selected choices if a different account type is selected
						NwuiInputService.inputs['withdrawals-form-input-reason'].options = [];
						NwuiInputService.inputs['withdrawals-form-input-reason'].model = '';

						NwuiInputService.inputs['withdrawals-form-destination-bank-account'].options = [];
						NwuiInputService.inputs['withdrawals-form-destination-bank-account'].model = '';

						NwuiInputService.inputs['withdrawals-form-input-amount'].model = '';
						NwuiInputService.inputs['withdrawals-form-input-full-withdrawal'].options[0].value = false;

						enableRemainingFormInputs();
						resetWithdrawalCalculator();

						if(!NwuiInputService.inputs['withdrawals-form-destination-bank-account']) return;
						$scope.data.accountsEfts.forEach(function(account) {
							if (account.id === selectedAccount.id) {
								$scope.fees = organization_data.port_data.withdrawals.custodian_processing_fee[account.custodian_slug];
								
								NwuiInputService.inputs['withdrawals-form-destination-bank-account'].options = account.EFTs.map(function(eft, i) {
									return {
										id: 'withdrawals-form-destination-bank-account-' + i,
										text_i18n: 'nwui.common.input.source_account.option_integrated',
										text_i18n_data: {
											accountWebData: eft.web_data,
											accountBalance: eft.bank_balance ? ': ' + $filter("currency")(eft.bank_balance) : ''
										},
										value: eft
									};
								});
							}
						});
						// add reasons step here
						if (selectedAccount.group_type === 'rsp') {
							organization_data.port_data.withdrawals.rrsp_accounts.reasons.forEach(function(reason, i) {
								NwuiInputService.inputs['withdrawals-form-input-reason'].options.push({
									id: 'withdrawals-form-input-reason-'+i,
									value: reason.slug,
									text_i18n: 'nwui.common.form.select.option.rrsp_reason.' + reason.slug,
									text_i18n_data: {
										withdrawalReason: reason.slug
									}
								});
							});
							$scope.has_withholding_tax_details = true;
						} else {
							// all other account types
							organization_data.port_data.withdrawals.default_accounts.reasons.forEach(function(reason, i) {
								NwuiInputService.inputs['withdrawals-form-input-reason'].options.push({
									id: 'withdrawals-form-input-reason-'+i,
									value: reason.slug,
									text_i18n: 'nwui.common.form.select.option.default_reason.' + reason.slug,
									text_i18n_data: {
										withdrawalReason: reason.slug
									}
								});
							});
							// no tax applied to non-rsp accounts
							$scope.has_withholding_tax_details = false;
						}
					}
				}
			}
		}, {
			directive: 'input',
			width: 100,
			data: {
				directive: 'select',
				data: {
					label_i18n: 'nwui.common.input.withdrawal_destination_account.label',
					model: '',
					id: 'withdrawals-form-destination-bank-account',
					stretch: true,
					required: true,
					disabled: true,
					placeholder_i18n: 'nwui.common.input.withdrawal_destination_account.placeholder',
					mask: 'bank_account',
					validators: ['bank_account_number'],
					// maxlength: 12,
					post_var: 'from_account',
					options: []
				}
			}
		}, {
			directive: 'input',
			width: 100,
			data: {
				directive: 'select',
				data: {
					label_i18n: 'nwui.common.input.reason.label',
					model: '',
					id: 'withdrawals-form-input-reason',
					stretch: true,
					required: true,
					disabled: true,
					placeholder_i18n: 'nwui.common.input.reason.placeholder',
					post_var: 'reason',
					options: []
				}
			}
		}, {
			directive: 'input',
			width: 50,
			data: {
				directive: 'text',
				data: {
					label_i18n: 'nwui.common.input.amount.label',
					model: '',
					id: 'withdrawals-form-input-amount',
					stretch: true,
					disabled: true,
					mask: 'currency_decimal',
					validators: ['withdrawal_min_max_amount'],
					min_val: organization_data.port_data.withdrawals.range.min_dollar_amount || 0,
					required: true,
					post_var: '',
					placeholder_i18n: 'nwui.common.input.amount.placeholder',
					onChange: function(amount) {
						var parsedAmount = amount.replace(/,/g, '.'); // handle comma-separated decimalized currency
						$scope.gross_amount_selected = true;
						$scope.gross_amount = parsedAmount;

						// apply tax deductions to RRSPs
						if ($scope.has_withholding_tax_details) {
							var tax_percentage;
							if (parsedAmount >= 0 && parsedAmount <= 5000) {
								tax_percentage = $scope.data.province === ('Quebec' || 'QC') ? 0.05 : 0.1;
								$scope.withholding_tax = (parsedAmount * tax_percentage).toFixed(2);
							} else if (parsedAmount >= 5000 && parsedAmount <= 15000) {
								tax_percentage = $scope.data.province === ('Quebec' || 'QC') ? 0.1 : 0.2;
								$scope.withholding_tax = (parsedAmount * tax_percentage).toFixed(2);
							} else {
								tax_percentage = $scope.data.province === ('Quebec' || 'QC') ? 0.15 : 0.30;
								$scope.withholding_tax = (parsedAmount * tax_percentage).toFixed(2);
							}
						}

						$scope.net_amount = parsedAmount - $scope.withholding_tax - $scope.fees;
					}
				}
			}
		}, {
			directive: 'input',
			width: 50,
			data: {
				directive: 'checkbox',
				data: {
					id: 'withdrawals-form-input-full-withdrawal',
					label: "",
					disabled: true,
					stretch: true,
					required: false,
					options: [{
						label_i18n: "nwui.common.input.full_withdrawal.checkbox",
						model: 'approve',
						value: false,
						required: false
					}],
					size: '',
					post_var: '',
					onChange: function(val) {
						if(val[0].value === true) {
							NwuiInputService.inputs['withdrawals-form-input-amount'].model = $filter("currency")($scope.available_funds);
							NwuiInputService.inputs['withdrawals-form-input-amount'].disabled = true;
							$scope.full_withdrawal = true;
						} else {
							NwuiInputService.inputs['withdrawals-form-input-amount'].disabled = false;
							$scope.full_withdrawal = false;
						}
					}
				}
			}
		}];

		if ($scope.data.enable_mandatory_confirmation) {
			withdrawalFormStepColumns.push({
				width: 100,
				directive: 'input',
				data: {
					directive: 'subheading',
					data: {
						id: 'withdrawals-form-mandatory-confirm-text',
						label: '',
						label_i18n: 'withdrawal_mandatory_confirm'
					}
				}
			});
			withdrawalFormStepColumns.push({
				directive: 'input',
				width: 100,
				data: {
					directive: 'checkbox',
					data: {
						id: 'withdrawals-form-mandatory-confirm-form-input-check',
						label: "",
						stretch: true,
						required: true,
						options: [{
							label_i18n: "withdrawals.new.mandatory_confirm.checkbox",
							model: 'approve',
							value: false,
							required: true
						}],
						size: '',
						post_var: '',
						onChange: function(val) {
						}
					}
				}
			});
		}

		$scope.cards = {
			withdrawals: {
				header: {
					name_i18n: 'manage.withdrawals.about.title'
				}
			},
			withdrawal_form: {
				header: new NwuiCardHeaderV2Factory({
					name_i18n: 'nwui.withdrawal_form.title',
					level: 2
				}),
				form: new NwuiFormV3Factory({
					name: 'withdrawal-request-form',
					button: {
						processing: false,
						id: 'withdrawal-request-form-button',
						text_i18n: 'tools.efts.modals.confirm_approve.button'
					},
					onSubmit: function(post_data) {
						// prevents confirm button from continuously spinning once modal activated
						$scope.disableAutomaticButtonProcessing = true;

						if (post_data.account.market_value < $scope.gross_amount) {
							NwuiToastManagerService.register(
								new NwuiToastFactory({
										text_i18n: 'manage.withdrawals.message.insufficient_funds',
										icon: {
											name: 'close'
										}
									}
								)
							);
							$scope.modal.withdrawal_confirmation.open = false;
							return $q.resolve();
						}

						// handle case where pending withdrawal funds affect account market val
						if (post_data.account.market_value - $scope.gross_amount - $scope.total_withdrawal_amount_pending < 0) {
							NwuiToastManagerService.register(
								new NwuiToastFactory({
										text_i18n: 'manage.withdrawals.message.pending_insufficient_funds',
										icon: {
											name: 'close'
										}
									}
								)
							);
							$scope.modal.withdrawal_confirmation.open = false;
							return $q.resolve();
						}

						$scope.modal.withdrawal_confirmation.submit_data = {
							from_account: post_data.from_account.id,
							bank_account: post_data.from_account.bank_account,
							reason: post_data.reason,
							amount: parseFloat($scope.gross_amount),
							full_withdrawal: $scope.full_withdrawal,
							request_type: 'withdrawal',
							frequency: 'one time',
							confirmed_contribution_room: true
						}

						$scope.modal.withdrawal_confirmation.message = 'manage.withdrawals.modal.confirmation.p1',
						$scope.i18n_data = {
							amount: $filter('currency')($scope.modal.withdrawal_confirmation.submit_data.amount),
							bank_account: $scope.modal.withdrawal_confirmation.submit_data.bank_account,
						}
						$scope.modal.withdrawal_confirmation.open = true;

						return $q.resolve();

					},
					onComplete: function(obj) {
					},
					onError: function(obj) {
					},
					steps: [{
						columns: withdrawalFormStepColumns
					}]
				})
			}
		}

	}
]);
