"use strict";
var localeSupported = ['en-CA'];
var localeFallbacks = {
  en: 'en-CA'
};

if (organization_data &&
	organization_data.join_data &&
	organization_data.join_data.localization) {
  if (organization_data.join_data.localization.length !== 0) {
    localeSupported = [];
    organization_data.join_data.localization.forEach(function(localize) {
      localeSupported.push(localize.language);
    })
  }
}

angular.module('portfolio_web', [
	//app templates
	'portfolio_webserver-templates',

	//toolkit
	'nwui-toolkit',

	//main app
	'portfolio_web.config',
	'portfolio_web.constants',
	'portfolio_web.controllers',
	'portfolio_web.directives',
	'portfolio_web.service',
	
	//sub apps
	'portfolio_web.main',
	
	//includes
  'ngResource',
  'ngAnimate',
  'ngStorage',
  'ngLocalize',
	'ngLocalize.Config',
	'ngLocalize.InstalledLanguages',
	'tmh.dynamicLocale',
	'ngSegment'
])

.value('localeSupported', localeSupported)

.value('localeFallbacks', localeFallbacks)

.value('localeConf', {
	basePath: 'api/translations/lang',
	defaultLocale: 'en-CA',
	sharedDictionary: 'common',
	fileExtension: '?bundles=' + window.translationBundles.join(','),
	persistSelection: true,
	cookieName: 'nw_i18n_lang',
	observableAttrs: new RegExp('^data-(?!ng-|i18n)'),
	delimiter: '::',
	validTokens: new RegExp('^[\\w\\.-]+\\.[\\w\\s\\.-]+\\w(:.*)?$'),
	allowNestedJson: true
})

.config(function(tmhDynamicLocaleProvider, segmentProvider) {
    tmhDynamicLocaleProvider.localeLocationPattern('/static/lang/locale-config/{{locale}}.js');
		if (window.segmentData) {
			segmentProvider.setConfig({
				debug: window.segmentData.debug,
				apiKey: window.segmentData.id
			});
		}
  });