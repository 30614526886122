angular.module('portfolio_web.manage.transfer_sources.list.config', [
	'ui.router'
])

.config([
	'$stateProvider',
	'NWUI_AUTH_ROLES',
	function(
		$stateProvider,
		NWUI_AUTH_ROLES
	) {

		var list = {
			url : '',
			templateUrl: 'portfolio_webserver/manage/transfer_sources/list/template.html',
			controller : 'ManageTransferSourcesListController',
			params: {
				modal: null,
				redirectAddNew: false,
			},
			data: {
				page_title_i18n: 'nav.transfer_sources',
				icon_name: 'payment',
				breadcrumb_label_i18n: 'nav.transfer_sources',
				breadcrumb_proxy: undefined,
				auth_roles: [NWUI_AUTH_ROLES.user]
			}
		}

		$stateProvider.state('app.manage.transfer_sources.list', list);

	}
]);