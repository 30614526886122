angular.module('portfolio_web.main.run', [
	// 'ngIntercom',
	'ngCookies'
])

.run([
	'$rootScope', 
	'$state', 
	'$location', 
	// '$intercom', 
	'$cookies', 
	'$timeout',
	'$http',

	'intercomUser', 
	'NwuiAuthService', 
	'NwuiSession', 
	'NwuiPageTitleService',
	'NwuiOverlayService',
	'NWUI_AUTH_EVENTS', 
	'PORTFOLIO_WEBSERVER',
	'$localStorage',
	function(
		$rootScope, 
		$state, 
		$location, 
		// $intercom, 
		$cookies, 
		$timeout,
		$http,

		intercomUser, 
		NwuiAuthService, 
		NwuiSession,
		NwuiPageTitleService,
		NwuiOverlayService,
		NWUI_AUTH_EVENTS,
		PORTFOLIO_WEBSERVER,
		$localStorage
	) {

		$rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {

			if(
				toState.name !== 'login' &&
				toState.name !== 'app.login' &&
				toState.name !== 'auth.login'
			) {
				$rootScope.returnToUrl = window.location.href;
				$rootScope.returnToState = toState;
				$rootScope.returnToParams = toParams;
			}

			if(toState.data && toState.data.page_title_i18n) {
				NwuiPageTitleService.set(toState.data.page_title_i18n);
			}
			
			//check if auth required
			if(toState.data.auth_roles.length > 0) {
				
				NwuiOverlayService.stateEnter(toState);

				$timeout(function() {
					//check auth
					NwuiAuthService.isAuthenticated().then(function(res) {
						
						// $timeout(function() {
							//check role access
							if(!NwuiAuthService.isAuthorized(toState.data.auth_roles)) {
								$rootScope.$emit(NWUI_AUTH_EVENTS.notAuthorized);
							} else {
								$rootScope.$broadcast(NWUI_AUTH_EVENTS.authDone);
								$rootScope.$emit(NWUI_AUTH_EVENTS.authDone);
							}
						// }, 0)
						
					}, function() {
						
						NwuiSession.destroy();
							
						$rootScope.$emit(NWUI_AUTH_EVENTS.notAuthenticated);
						
					});
				});
				
			} else {
				
				NwuiSession.destroy();
					
				$rootScope.$emit(NWUI_AUTH_EVENTS.authDone);

			}
			
		});
		
		$rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {

			////console.log('$stateChangeSuccess');

			$timeout(function() {
							
				dataLayer.push({
					'event': 'portfolioVirtualPageView',
					'virtualUrl': $location.url()
				});

				if (window.Fides && window.Fides.consent.analytics === true) {
					if(organization_data && organization_data.analytics) {
						organization_data.analytics.forEach(function(item, i) {
							if(item.platform === 'adobedtm' && typeof _satellite !== "undefined") {
								_satellite.track($location.url());
							} 
						})
					}
				}
				
			}, 0);
			
		});
		
		var protocol = window.location.protocol;
		var host = window.location.host;
		var redirectUrl = protocol + '//' + host;

		$rootScope.$on(NWUI_AUTH_EVENTS.authInactivityTimeout, function() {
			if (organization_data.sso_enabled) {
				window.location.href = redirectUrl;
			} else {
				$state.go('app.login');
			}
		});
		
		$rootScope.$on(NWUI_AUTH_EVENTS.notAuthenticated, function() {
			if (organization_data.sso_enabled) {
				window.location.href = redirectUrl;
			} else {
				$state.go('app.login');
			}
		});
		
		$rootScope.$on(NWUI_AUTH_EVENTS.notAuthorized, function() {
			if (organization_data.sso_enabled) {
				window.location.href = redirectUrl;
			} else {
				$state.go('app.login');
			}
		});

		setInterval(function() {
		  if (NwuiSession.user.uuid) {
		    $http.get('/check-session').then(function(res) {

        }, function(err) {
					if (err.data.message === 'redirect required' && err.data.redirect) {
						window.location.href = err.data.redirect;
					}
        });
      }
    }, 30 * 1000);
	}
]);