angular.module('portfolio_web.manage.risk_profiles.view.config', [
	'ui.router'
])

.config([
	'$stateProvider',
	'NWUI_AUTH_ROLES',
	function(
		$stateProvider,
		NWUI_AUTH_ROLES
	) {

		var view = {
			url: '/:goal_id',
			templateUrl: 'portfolio_webserver/manage/risk_profiles/view/template.html',
			controller: 'ManageRiskProfilesViewController',
			data: {
				// page_title: 'Manage - Risk Profiles',
				page_title_i18n: 'nav.risk_profiles',
				icon_name: 'donut_large',
				breadcrumb_label_i18n: undefined,
				breadcrumb_label: '{{ResolveGoal.localize ? (ResolveGoal.description | i18n) : ResolveGoal.description}}',
				auth_roles: [NWUI_AUTH_ROLES.user]
			},
			params: {
				goal_id: null,
				review: null
			},
			resolve: {
				ResolveGoal: [
					'$q',
					'$stateParams',
					'ApiGoalsService', 
					function(
						$q,
						$stateParams, 
						ApiGoalsService
					) {
						var deferred = $q.defer();
							
						ApiGoalsService.getGoal({ id: $stateParams.goal_id }).then(function(obj) {
							deferred.resolve(obj.data);
						});
						
						return deferred.promise;
					}
				],
				// BROKEN BECAUSE NO CLIENT ID AT THIS POINT
				// ResolveRiskProfile: [
				// 	'$q',
				// 	'$stateParams',
				// 	'RiskProfileService', 
				// 	function(
				// 		$q,
				// 		$stateParams, 
				// 		RiskProfileService
				// 	) {
				// 		var deferred = $q.defer();
							
				// 		RiskProfileService.getRiskProfile($stateParams.goal_id).then(function(obj) {
				// 			deferred.resolve(obj.data);
				// 		});
						
				// 		return deferred.promise;
				// 	}
				// ]
			}
		}

		$stateProvider.state('app.manage.risk_profiles.view', view);

	}
]);