angular.module('portfolio_web.activity.config', [
	'ui.router'
])

.config([
	'$stateProvider', 
	'$locationProvider', 
	'$urlRouterProvider',
	'NWUI_AUTH_ROLES',
	function(
		$stateProvider, 
		$locationProvider, 
		$urlRouterProvider,
		NWUI_AUTH_ROLES
	) {

		var activity = {
			controller : 'ActivityController',
			url : '/activity',
			templateUrl: 'portfolio_webserver/activity.html',
			params: {
				transaction_id: null
			},
			data: {
				// page_title: 'Activity',
				page_title_i18n: 'nav.activity',
				icon_name: 'event_note',
				auth_roles: [NWUI_AUTH_ROLES.user]
			},
			resolve: {
				ResolveAccounts: [
					'$q',
					'$stateParams',
					'ApiAccountsService', 
					function(
						$q,
						$stateParams, 
						ApiAccountsService
					) {

						////console.log('ResolveAccounts', 'init');
						var deferred = $q.defer();
						
						ApiAccountsService.getAccountsWithAggregate().then(function(obj) {
							deferred.resolve(obj.data);
						});
						
						return deferred.promise;
						
					}
				],
				ResolveDataAsOf: [
					'$q',
					'$stateParams',
					'ApiGeneralService', 
					function(
						$q,
						$stateParams, 
						ApiGeneralService
					) {
						var deferred = $q.defer();
						
						ApiGeneralService.getDataAsOf().then(function(obj) {
							deferred.resolve(obj.data);
						});
						
						return deferred.promise;
					}
				]
			}
		}

		$stateProvider.state('app.activity', activity);

	}
]);