angular.module('portfolio_web.manage.referrals.controllers', [])

.controller('ManageReferralsController', [
	'$q',
	'$scope', 
	'$state',
	'$timeout',
	'$stateParams',
	'$sce',
	'NwuiCardHeaderV2Factory',
	'NwuiFormV3Factory',
	'NwuiOverlayFactory',
	'NwuiRecommendationFactory',
	'NwuiButtonV3Factory',
	'NwuiToastFactory',
	'NwuiCardTableFactory',

	'NwuiToastManagerService',
	'ApiUserService',
	'NwuiInputService',

	'ResolveReferrals',
	'ResolveReferralCode',

	'DATE_FORMAT',
	function(
		$q,
		$scope, 
		$state,
		$timeout,
		$stateParams,
		$sce,
		NwuiCardHeaderV2Factory,
		NwuiFormV3Factory,
		NwuiOverlayFactory,
		NwuiRecommendationFactory,
		NwuiButtonV3Factory,
		NwuiToastFactory,
		NwuiCardTableFactory,

		NwuiToastManagerService,
		ApiUserService,
		NwuiInputService,
		
		ResolveReferrals,
		ResolveReferralCode,

		DATE_FORMAT
	) {
	
		////console.log('ManageReferralsController Loaded', $state);

		var loadReferrals = function() {

			return $scope.data.referrals.map(function(referral,i) {

				// ////console.log('ManageReferralsController', 'sign up date', moment(referral.created_at).unix(), moment(referral.website_user_created_at).unix())
				var signed_up_at = moment(referral.created_at).unix() > moment(referral.website_user_created_at).unix() ? referral.created_at : referral.website_user_created_at

				return {
					columns: [{
						text: (referral.last_name) ? referral.last_name + ', ' + referral.first_name : referral.recipient_email
					}, {
						// text: (referral.is_redeemed) ? 'Redeemed on ' + moment(referral.redeemed_at).format(DATE_FORMAT.human) : (referral.is_user_created) ? 'Signed up on ' + moment(signed_up_at).format(DATE_FORMAT.human) : 'Invited on ' + moment(referral.created_at).format(DATE_FORMAT.human)
						text_i18n: (referral.is_redeemed) 
							? 'manage.referrals.table.referral_done' 
							: (referral.is_user_created) 
								? 'manage.referrals.table.referral_signed'
								: 'manage.referrals.table.referral_pending',
						text_i18n_data: {
							date: (referral.is_redeemed) 
								? moment(referral.redeemed_at).format(DATE_FORMAT.human)
								: (referral.is_user_created) 
									? moment(signed_up_at).format(DATE_FORMAT.human)
									: moment(referral.created_at).format(DATE_FORMAT.human)
						}
					}, {
						button: {
							id: 'referral-status-' + i,
							color: (referral.is_redeemed) ? 'done' : 'waiting',
							// text: (referral.is_redeemed) ? 'Redeemed' : 'Pending',
							text_i18n: (referral.is_redeemed) ? 'manage.referrals.table.button_done' : 'manage.referrals.table.button_pending',
							hollow: true,
							onClick: function() {}
						}
					}]
				}
			})

		}

		var copyReferralLink = function() {
			var range = document.createRange();
			range.selectNode(document.getElementById('referral-code-form-url'));
			var selection = window.getSelection();
			selection.removeAllRanges();
			selection.addRange(range);
	
			var error = false;

			try {
				var copied = document.execCommand("copy");
				
				if(copied) {
					NwuiToastManagerService.register(
						new NwuiToastFactory({
							// text: 'Code copied successfully!',
							text_i18n: 'manage.referrals.cutout.copy_success',
							icon: {
								name: 'assignment_turned_in'
							}
						})
					)
				} else {
					error = true;
				}

			} catch(err) {
				////console.log(err);
				error = true;
			}

			if(error) {
				NwuiToastManagerService.register(
					new NwuiToastFactory({
						// text: 'Unable to copy to clipboard.',
						text_i18n: 'manage.referrals.cutout.copy_failed',
						icon: {
							name: 'close'
						}
					})
				)
			}

		}

		$scope.blocks = {
			breadcrumbs: {
				root: {
					icon: {
						name: 'home'
					},
					state: 'app.summary'
				}
			}
		}

		$scope.data = {
			referrals: ResolveReferrals,
			referral_code: ResolveReferralCode,
			referral_url: join_web[0] + '/' + ResolveReferralCode.code,
			referral_terms_url: com_web + '/referral-terms-and-conditions',
			org_slug: org_slug,
			org: organization_data,
			show_terms_link: organization_data.port_data.referrals.show_terms_link,
			show_fiscal_impacts_info_card: organization_data.port_data.transfers.brokerage_transfers.show_fiscal_impacts_info_card
		}

		$scope.cards = {
			info: {
				header: new NwuiCardHeaderV2Factory({
					// name: 'Referrals',
					name_i18n: 'manage.referrals.about.title',
					icon: {
						name: 'info_outline'
					}
				}),
				copy: [],
				form: new NwuiFormV3Factory({
					name: 'referral-code-form',
					submit_button: {
						// text: 'Invite',
						text_i18n: 'manage.referrals.form.button',
						size: 'small',
						disabled: false,
						icon: {
							name: 'email'
						},
						color: (
							organization_data.theme &&
							organization_data.theme.form &&
							organization_data.theme.form.submit &&
							typeof organization_data.theme.form.submit.referral_color !== 'undefined'
						)
							? organization_data.theme.form.submit.referral_color || 'primary' //force primary ('' is falsey)
							: 'warning'
					},
					steps: [{
						columns: [{
							width: 100,
							directive: 'input',
							data: {
								directive: 'text',
								data: {
									id: 'referral-code-form-url',
									// label: 'Referral Link',
									label_i18n: 'manage.referrals.input.link.label',
									model: $scope.data.referral_url,
									// placeholder: '',
									placeholder_i18n: 'manage.referrals.input.link.placeholder',
									disabled: true,
									post_var: 'referral_code',
									size: 'small'
								}
							}
						},{
							width: 100,
							directive: 'input',
							data: {
								directive: 'text',
								data: {
									id: 'referral-code-form-email',
									// label: 'Email Referral',
									label_i18n: 'manage.referrals.input.email.label',
									color: 'alternate',
									model: '',
									// placeholder: 'john.smith@site.com',
									placeholder_i18n: 'nwui.common.input.email.placeholder',
									required: true,
									post_var: 'email',
									size: 'small'
								}
							}
						}]
					}],
					onSubmit: function(data) {

						////console.log(data);
						return ApiUserService.refer(data);

					},
					onComplete: function(obj) {

						NwuiToastManagerService.register(
							new NwuiToastFactory({
								// text: 'Invite sent successfully!',
								text_i18n: 'manage.referrals.form.success',
								icon: {
									name: 'check'
								}
							})
						)

						NwuiInputService.inputs['referral-code-form-email'].model = '';

						ApiUserService.getReferrals().then(function(obj) {
							$scope.data.referrals = obj.data;
							$scope.cards.referrals.table.rows = loadReferrals();
						});

					},
					onError: function(obj) {

						NwuiToastManagerService.register(
							new NwuiToastFactory({
								text: obj.data.nw_error_message,
								type: 'error',
								icon: {
									name: 'close'
								}
							})
						)

						NwuiInputService.inputs['referral-code-form-email'].model = '';

					}
				}),
				button: new NwuiButtonV3Factory({
					id: 'referral-copy-button',
					// text: 'Terms &amp; Conditions',
					text_i18n: 'manage.referrals.about.terms_label',
					color: '',
					//icon_only: true,
					//stretch: true,
					size: 'short',
					icon: {
						name: 'assignment'
					},
					onClick: function() {
						window.open($scope.data.referral_terms_url);
					}
				})
			},
			empty: {
				button: new NwuiButtonV3Factory({
					id: 'referral-empty-referrals-copy-button',
					// text: 'Copy to Clipboard',
					text_i18n: 'manage.referrals.cutout.button',
					color: (
						organization_data.theme &&
						organization_data.theme.form &&
						organization_data.theme.form.submit &&
						typeof organization_data.theme.form.submit.referral_color !== 'undefined'
					)
						? organization_data.theme.form.submit.referral_color || 'primary' //force primary
						: 'warning',
					icon: {
						name: 'assignment'
					},
					onClick: copyReferralLink
				}),
				copy: []
			},
			referrals: {
				header: new NwuiCardHeaderV2Factory({
					// name: 'Your Referrals',
					name_i18n: 'manage.referrals.table.title',
					icon: {
						name: 'people'
					}
				}),
				table: new NwuiCardTableFactory({
					name: 'referrals-table',
					// empty_text: 'No referrals found',
					empty_text_i18n: 'manage.referrals.table.empty',
					columns: [{
						// name: 'Referral',
						name_i18n: 'manage.referrals.table.col_0',
						directive: 'text',
						sortable: false
					},{
						// name: 'Date',
						name_i18n: 'manage.referrals.table.col_1',
						directive: 'text',
						sortable: false
					},{
						name: '',
						width: 140,
						directive: 'button',
						sortable: false
					}],
					rows: loadReferrals(),
					loaded: false
				})
			}
		}
		
	}
]);