angular.module('portfolio_web.manage.transfer_sources.new.controllers', [])

.controller('ManageTransferSourcesNewController', [
	'$scope',
	'$state',
	'$timeout',
	'$filter',

	'NwuiModalV2Factory',
	'NwuiToastFactory',
	'NwuiCardHeaderV2Factory',
	'NwuiFormV3Factory',
	'NwuiSigningService',
	'NwuiPageScrollService',

	'NwuiToastManagerService',
	'NwuiInputService',
	'NwuiFormV3Service',
	'NwButtonService',
	'EftService',
	'locale',
	function(
		$scope,
		$state,
		$timeout,
		$filter,

		NwuiModalV2Factory,
		NwuiToastFactory,
		NwuiCardHeaderV2Factory,
		NwuiFormV3Factory,
		NwuiSigningService,
		NwuiPageScrollService,

		NwuiToastManagerService,
		NwuiInputService,
		NwuiFormV3Service,
		NwButtonService,
		EftService,
		locale
	) {

		var uploaded_file = undefined;

		$scope.blocks = {
			breadcrumbs: {
				root: {
					icon: {
						name: 'home'
					},
					state: 'app.summary'
				}
			}
		}

    $scope.data = {
      isHideHyperlink: (
        organization_data &&
        organization_data.port_data &&
        organization_data.port_data.hide_email_hyperlink),
			void_cheque_institution_whitelist: (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.transfers &&
				organization_data.port_data.transfers.eft_sources &&
				organization_data.port_data.transfers.eft_sources.create &&
				organization_data.port_data.transfers.eft_sources.create.void_cheque_institution_whitelist
			) ? organization_data.port_data.transfers.eft_sources.create.void_cheque_institution_whitelist : [],
			openChat: function(e) {

				e.stopPropagation();
				e.preventDefault();
				
				if(window.Intercom) {
					window.Intercom('show');
				} else {
					var contactArr = organization_data.footer.contact;
					var urlInfo = contactArr.filter(contactInfo => contactInfo.type === "email");
					window.location.href = `mailto:${urlInfo[0].data.id}`;
				}

			}
		}

		$scope.modules = {
			title: {
				text_i18n: 'manage.transfer_sources.new.form.header',
				icon: {
					name: 'swap_horiz'
				},
				recommendation: {
					type: '',
					// icon: {
					// 	name: 'info_outline',
					// 	color: 'warning'
					// },
					text_i18n: 'manage.transfer_sources.about.p1',
					button: undefined
				}
			},
			card: {
				header: new NwuiCardHeaderV2Factory({
					name_i18n: 'nwui.modal.add_eft_source.title',
					level: 2,
					icon: {
						name: 'create'
					}
				}),
				form: new NwuiFormV3Factory({
					name: 'create-eft-source-form',
					active_step_index: 0,
					submit_button: false,
					onSubmit: function(post_data) {

						if(uploaded_file !== undefined)
							post_data.upload = uploaded_file;

						return EftService.createEft(post_data);

					},
					onComplete: function(obj) {

						// submitCb(obj.data);
						// scope.open = false;

						//show toast
						NwuiToastManagerService.register(
							new NwuiToastFactory({
								// text: 'Transfer source submitted successfully!',
								text_i18n: 'nwui.modal.add_eft_source.success',
								icon: {
									name: 'check'
								}
							}
						));

						if (obj.data.envelope_id) {
							//console.log('ManageTransferSourcesController', 'has envelope_id', obj.data.envelope_id);
							$timeout(function() {
								NwuiSigningService.setEnvelope('global-document-signing', obj.data.envelope_id);
								NwuiSigningService.setSubmitCb('global-document-signing', function(obj) {
									$state.go('app.manage.transfer_sources.list')
								});

								NwuiPageScrollService.open('global-document-signing');
							}, 0);
						} else {
							$state.go('app.manage.transfer_sources.list')
						}

					},
					onError: function(error) {

            if(error.data.code === 4813) {
              NwuiToastManagerService.register(
                new NwuiToastFactory({
                    text_i18n: 'transfers.new.chequing_savings.error.4813',
                    type: 'error',
                    icon: {
                      name: 'close'
                    }
                  }
                ));
            }

						NwButtonService.buttons[$scope.modules.buttons.submit.id].processing = false;

					},
					steps: [{
						columns: [{
							directive: 'input',
							width: 100,
							data: {
								directive: 'text',
								data: {
									label_i18n: 'nwui.common.input.eft_nickname.label',
									model: '',
									id: 'create-eft-source-form-input-nickname',
									stretch: true,
									required: false,
									placeholder_i18n: 'nwui.common.input.eft_nickname.placeholder',
									post_var: 'nickname'
								}
							}
						}, {
							directive: 'input',
							width: 100,
							data: {
								directive: 'text',
								data: {
									label_i18n: 'nwui.common.input.institution_name.label',
									model: '',
									id: 'create-eft-source-form-input-name',
									stretch: true,
									required: true,
									post_var: 'bank_name',
									placeholder_i18n: 'nwui.common.input.institution_name.placeholder',
								}
							}
						}, {
							directive: 'input',
							width: 50,
							data: {
								directive: 'text',
								data: {
									// label: 'Inst. Number',
									label_i18n: 'nwui.common.input.institution_number.label',
									model: '',
									id: 'create-eft-source-form-input-institution',
									stretch: true,
									required: true,
									mask: 'bank_institution',
									validators: ['bank_institution'],
									// placeholder: "001",
									placeholder_i18n: 'nwui.common.input.institution_number.placeholder',
									post_var: 'bank_institution',
									onChange: function(model) {

										if($scope.data.void_cheque_institution_whitelist.indexOf(model) === -1) {
											NwuiInputService.inputs['manage-documents-upload-file'].hide = false;
										} else {
											NwuiInputService.inputs['manage-documents-upload-file'].hide = true;
										}

									}
								}
							}
						}, {
							directive: 'input',
							width: 50,
							data: {
								directive: 'text',
								data: {
									// label: 'Transit Number',
									label_i18n: 'nwui.common.input.transit_number.label',
									model: '',
									id: 'create-eft-source-form-input-transit',
									stretch: true,
									required: true,
									mask: 'bank_transit',
									validators: ['bank_transit'],
									// placeholder: "01234",
									placeholder_i18n: 'nwui.common.input.transit_number.placeholder',
									post_var: 'bank_branch'
								}
							}
						}, {
							directive: 'input',
							width: 100,
							data: {
								directive: 'text',
								data: {
									// label: 'Account Number',
									label_i18n: 'nwui.common.input.account_number.label',
									model: '',
									id: 'create-eft-source-form-input-account',
									stretch: true,
									required: true,
									mask: 'bank_account',
									validators: ['bank_account_number'],
									maxlength: 12,
									// placeholder: "01234ABCDE",
									placeholder_i18n: 'nwui.common.input.account_number.placeholder',
									post_var: 'bank_account'
								}
							}
						}, {
							directive: 'input',
							width: 100,
							data: {
								directive: 'upload',
								data: {
									id: 'manage-documents-upload-file',
									// label: 'Void Cheque&nbsp;Upload',
									label_i18n: 'nwui.common.input.upload_void_cheque.label',
									sub_label_i18n: 'nwui.modal.add_eft_source.void_cheque_info',
									model: '',
									url: '/api/documents',
									required: true,
									// placeholder: 'Choose a file...',
									placeholder_i18n: 'nwui.common.input.file_upload.placeholder',
									onUpload : ()=>{
										NwButtonService.buttons[$scope.modules.buttons.submit.id].disabled = true;
									},
									onSuccess: function(res) {

										uploaded_file = res.data.file_name;
										NwButtonService.buttons[$scope.modules.buttons.submit.id].disabled = false;

										NwuiToastManagerService.register(
											new NwuiToastFactory({
												// text: 'Document upload successful',
												text_i18n: "nwui.common.toast.document_upload",
												icon: {
													name: 'check'
												}
											}
										));

									}
								}
							}
						}]
					}]
				})
			},
			buttons: {
				submit: {
					id: 'transfers-source-new-form-submit-button',
					text_i18n: 'nwui.common.form.button.submit',
					processing: false,
					color: 'warning',
					icon: {
						name: 'check'
					},
					onClick: function() {

						if(NwButtonService.buttons[$scope.modules.buttons.submit.id].processing == true) {
							return false;
						}

						NwButtonService.buttons[$scope.modules.buttons.submit.id].processing = true;

						var valid = NwuiFormV3Service.validate($scope.modules.card.form.name);
						if(valid !== true) {
							NwButtonService.buttons[$scope.modules.buttons.submit.id].processing = false;							
							return;
						}

						NwuiFormV3Service.submit($scope.modules.card.form.name);
					}
				}
			}
		}
		
	}
]);
