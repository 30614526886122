angular.module('portfolio_web.summary.holdings-earnings-split.directives', [])

.directive('summaryHoldingsEarningsSplit', [
	'ApiClientsService',

	'NwuiSession',
	function(
		ApiClientsService,

		NwuiSession
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: 'portfolio_webserver/summary/holdings-earnings-split/template.html',
			scope: {},
			link: function(scope, element, attrs) {
				scope.data = {
					user: NwuiSession
				}
				scope.port_data = {
					show_summary_performance: lodashGet(organization_data, 'port_data.summary.show_performance', true)
				}
				scope.$watch('data.user', function(new_val, old_val) {

					if(new_val === undefined) return;

					ApiClientsService.getHoldings(NwuiSession.user.client_id).then(function(obj) {
						scope.data = obj.data;
						scope.data.change.dollar = parseFloat(obj.data.change.dollar);
						scope.loaded = true;
					});

				});

			}
		}
	}
]);