angular.module('portfolio_web.config', [
  'ui.router'
])

.config([
  '$locationProvider', 
  '$urlRouterProvider', 
  function(
    $locationProvider, 
    $urlRouterProvider
  ) {

      $locationProvider
        .html5Mode(true);
        
      $urlRouterProvider
        .otherwise('/login');

  }
])

.config([
  '$provide',
  function(
    $provide
  ) {
    $provide.decorator('$uiViewScroll', ["$delegate", function($delegate) {
      return function(uiViewElement) {
        // var top = uiViewElement.getBoundingClientRect().top;
        window.scrollTo(0, 0);
      }; 
    }]);
  }	
])

.config([
  '$animateProvider',
  function(
    $animateProvider
  ) {
    $animateProvider.classNameFilter(/^(?:(?!ng-animate-disabled).)*$/);
  }
])

.config([
  "$httpProvider",
  function(
    $httpProvider
  ){


    function traverseObjectAndRetrieveStrings(obj, strings) {
      if (!strings) {
        strings = [];
      }
      for (var key in obj) {
        var entry = obj[key];
        if (typeof entry === 'string') {
          strings.push(entry);
        } else if (Array.isArray(entry)) {
          entry.forEach(function(e) {
            traverseObjectAndRetrieveStrings(e, strings);
          });
        } else if (typeof entry === 'object') {
          traverseObjectAndRetrieveStrings(entry, strings);
        }
      }
      return strings;
    }

    $httpProvider.xsrfWhitelistedOrigins = traverseObjectAndRetrieveStrings(organization_data.url_bases);
  }
]);
