angular.module('portfolio_web.accounts.view.directives', [])

.directive('nwAccountsViewContent', [
	function() {
		return {
			restrict: 'E',
					replace: true,
					templateUrl: 'portfolio_webserver/content/accounts/view.html',
					scope: false,
					link: function(scope, element, attrs) {}
			}
	}
])

.directive('nwAccountsViewModals', [
	function() {
		return {
			restrict: 'E',
					replace: true,
					templateUrl: 'portfolio_webserver/partials/accounts/modals.html',
					scope: false,
					link: function(scope, element, attrs) {}
			}
	}
])