angular.module('portfolio_web.manage.risk_profiles.list.config', [
	'ui.router'
])

.config([
	'$stateProvider',
	'NWUI_AUTH_ROLES',
	function(
		$stateProvider,
		NWUI_AUTH_ROLES
	) {

		var list = {
			url : '/',
			templateUrl: 'portfolio_webserver/manage/risk_profiles/index.html',
			controller : 'ManageRiskProfilesListController',
			data: {
				// page_title: 'Manage - Risk Profiles',
				page_title_i18n: 'nav.risk_profiles',
				icon_name: 'donut_large',
				breadcrumb_label_i18n: 'nav.risk_profiles',
				breadcrumb_proxy: undefined,
				auth_roles: [NWUI_AUTH_ROLES.user]
			}
		}

		$stateProvider.state('app.manage.risk_profiles.list', list);

	}
]);