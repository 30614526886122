angular.module('portfolio_web.manage.documents.controllers', [])

.controller('ManageDocumentsController', [
	'$q',
	'$scope', 
	'$state',
	'$timeout',
	'$stateParams',
	
	'NwuiCardHeaderV2Factory',
	'NwuiButtonV3Factory',
	'NwuiModalV2Factory',
	'NwuiFormV3Factory',
	'NwuiToastFactory',
	'NwuiCardTableV3Factory',

	'NwuiInputService',
	'NwButtonService',
	'NwuiToastManagerService',
	'NwuiSession',
	'ApiUserDocumentsService',
	'ApiClientsService',
	'NwuiButtonV2Service',

	'DATE_FORMAT',
	function(
		$q,
		$scope, 
		$state,
		$timeout,
		$stateParams,
		
		NwuiCardHeaderV2Factory,
		NwuiButtonV3Factory,
		NwuiModalV2Factory,
		NwuiFormV3Factory,
		NwuiToastFactory,
		NwuiCardTableV3Factory,
		
		NwuiInputService,
		NwButtonService,
		NwuiToastManagerService,
		NwuiSession,
		ApiUserDocumentsService,
		ApiClientsService,
		NwuiButtonV2Service,

		DATE_FORMAT
	) {
		
		$scope.data = {
			user: NwuiSession.user,
			org_slug: org_slug,
			org: organization_data,
			documentUploadOptions: []
		}
		
		$scope.blocks = {
			breadcrumbs: {
				root: {
					icon: {
						name: 'home'
					},
					state: 'app.summary'
				}
			}
		}
		
		$scope.cards = {
			info: {
				header: {
					// name: 'My Documents',
					name_i18n: 'manage.documents.about.title',
					icon: {
						name: 'info_outline'
					}
				}
			},
			documents: {
				header: {
					// name: 'Documents',
					name_i18n: 'manage.documents.table.title',
					icon: {
						name: 'description'
					},
					button: {
						id: 'manage-documents-upload-file',
						icon: { name:'file_upload' },
						text_i18n: 'nestkit.buttons.upload',
						onClick: ()=> {
							setTimeout(()=>{
								$scope.modals.upload_document.open = true
							}, 100)
						}
					}
				},
				table: new NwuiCardTableV3Factory({
					id: 'documents-list-table',
					resource: ApiUserDocumentsService.getDocuments,
					rowMap: function(doc,i) {

						var button = {
							id: 'documents-download-button-' + i,
							text_i18n: 'nestkit.buttons.download',
							onClick: function() {
								var self = this;
								self.processing = true;
								ApiUserDocumentsService.getSignedUrl(doc._id).then(function(res) {
									window.open(res.data.url)
									self.processing = false;
								})
							}
						}
						return {
							columns: [{
								text: moment(doc.createdAt).format(DATE_FORMAT.short)
							}, {
								text_i18n: "nwui.common.document."+doc.template.name.replace(/\(|\)|\#|\,/g, "").replace(/ |-/g, "_").replace("<", "lt").replace(">", "gt")
							}, {
								button: button
							}]
						}

					},
					// empty_text: 'no documents found',
					empty_text_i18n: 'manage.documents.table.empty',
					columns: [{
						// name: 'Date',
						name_i18n: 'manage.documents.table.col_0',
						directive: 'text',
						sortable: false,
						width: 100
					},{
						// name: 'name',
						name_i18n: 'manage.documents.table.col_1',
						directive: 'text',
						sortable: false
					},{
						name: '',
						directive: 'button',
						width: 110,
						sortable: false
					}],
					rows: undefined,
					footer: {
						page_size: 10
					}
				})
			}
		}

		// This is the columns array we start with based on if the tenant has upload doc types enabled
		const uploadDocumentTypeColumn = $scope.data.org.port_data.upload_document_types.enabled ? [{
			directive: 'input',
			width: 100,
			data: {
				directive: 'select',
				data: {
					label_i18n: 'nwui.modal.upload_doc.inputs.doc_type.label',
					model: '',
					id: 'document-upload-select-input',
					options: $scope.data.documentUploadOptions || [],
					stretch: true,
					required: true,
					placeholder_i18n: 'nwui.modal.upload_doc.inputs.doc_type.placeholder',
					onChange: function () {
						if (NwuiInputService.inputs['document-upload-file-input'] === undefined) return;
						NwuiInputService.inputs['document-upload-file-input'].hide = false;
						NwuiInputService.inputs['document-upload-file-input'].url = NwuiInputService.inputs['document-upload-select-input'].model;
					}
				}
			}
		}] : [];

		$scope.modals = {
			upload_document: new NwuiModalV2Factory({
				open: false,
				header: {
					name_i18n: 'nwui.modal.upload_doc.title',
					icon: {
						name: 'file_upload'
					}
				},
				form: new NwuiFormV3Factory({
					name: 'manage-documents-upload-form',
					submit_button: false,
					onSubmit: function(data) {},
					onComplete: function() {},
					steps: [{
						columns: [
							// Copy our upload document type column(makes it cleaner to enable/disable the doc type dropdown this way i think)
							...uploadDocumentTypeColumn, {
							directive: 'input',
							width: 100,
							data: {
								directive: 'upload',
								data: {
									hide: $scope.data.org.port_data.upload_document_types.enabled ? true : false,
									id: 'document-upload-file-input',
									label_i18n: 'nwui.modal.upload_doc.inputs.file_upload.label',
									model: '',
									url: '',
									placeholder_i18n: 'nwui.modal.upload_doc.inputs.file_upload.placeholder',
									onSuccess: function () {
										NwuiToastManagerService.register(
											new NwuiToastFactory({
												text_i18n: 'nwui.modal.upload_doc.toasts.success',
												icon: {
													name: 'check'
												}
											}
										));
										$scope.modals.upload_document.open = false;
										setTimeout(()=>{
											$scope.cards.documents.table.reload();
										}, 100)
									}
								}
							}
						}]
					}]
				})
			}),
		}

		// if we have any document uploads enabled
		if($scope.data.org.port_data.upload_document_types.enabled) {
			ApiClientsService.getClientUploadDocumentList().then(docsToUpload => {
				$scope.data.documentUploadOptions = docsToUpload.data.clientDocuments;
				if(NwuiInputService.inputs['document-upload-select-input']) {
					NwuiInputService.inputs['document-upload-select-input'].options = $scope.data.documentUploadOptions.map((documentType,i) => ({
						id: documentType._id,
						text: documentType.name,
						value: `/api/user/${NwuiSession.user.uuid}/document/${documentType._id}`
					}));
				}
			});
		}
	}
]);