angular
  .module('portfolio_web.api.broadcast_notifications.services', [])
  .service('ApiBroadcastNotificationsService', [
    '$http',
    'PORTFOLIO_WEBSERVER',
    function (
      $http,
      PORTFOLIO_WEBSERVER
    ) {
      this.getBroadcastNotifications = function(user_id) {
        return $http.get(
          PORTFOLIO_WEBSERVER.app
          + '/api/users/'
          + user_id
          + '/broadcast-notifications'
        );
      }

      this.createBroadcastNotificationReceipt = function(broadcast_notification_id, user_id) {
        return $http.post(
          PORTFOLIO_WEBSERVER.app
          + '/api/users/'
          + user_id
          + '/broadcast-notifications/'
          + broadcast_notification_id
          + '/receipts'
        );
      }
    }
  ]);
