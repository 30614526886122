angular.module('portfolio_web.manage.referrals.config', [
	'ui.router'
])

.config([
	'$stateProvider',
	'NWUI_AUTH_ROLES',
	function(
		$stateProvider,
		NWUI_AUTH_ROLES
	) {

		var referrals = {
			url : '/referrals',
			templateUrl: 'portfolio_webserver/manage/referrals.html',
			controller : 'ManageReferralsController',
			params: {
				modal: null	
			},
			data: {
        // page_title: 'Manage - Referrals',
        page_title_i18n: 'nav.referrals',
        icon_name: 'people',
				breadcrumb_label_i18n: 'nav.referrals',
				auth_roles: [NWUI_AUTH_ROLES.user]
			},
			resolve: {
				ResolveReferrals: [
          '$q',
        	'$stateParams',
        	'ApiUserService', 
        	function(
          	$q,
        		$stateParams, 
        		ApiUserService
        	) {
          	var deferred = $q.defer();
          	
          	ApiUserService.getReferrals().then(function(obj) {
            	deferred.resolve(obj.data);
          	});
          	
          	return deferred.promise;
        	}
        ],
        ResolveReferralCode: [
          '$q',
        	'$stateParams',
        	'ApiUserService', 
        	function(
          	$q,
        		$stateParams, 
        		ApiUserService
        	) {
          	var deferred = $q.defer();
            	
          	ApiUserService.getReferralCode().then(function(obj) {
            	deferred.resolve(obj.data);
          	});
          	
          	return deferred.promise;
        	}
        ]
			}
		}

		$stateProvider.state('app.manage.referrals', referrals);

	}
]);