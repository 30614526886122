angular.module('portfolio_web.main.controllers', [])

.controller('MainController', [
	'$rootScope',
	'$scope', 
	'$state',
	'$timeout',
	'$q',
	'$cookies',
	'$location',
	'ApiClientsService',
	'NwuiSession',
	'INTERCOM_APPID',
	'NWUI_AUTH_EVENTS',
	'NwuiAuthService',
	'NwuiLocalizeService',
	'localeEvents',
	'locale',
	function(
		$rootScope,
		$scope,
		$state,
		$timeout,
		$q,
		$cookies,
		$location,
		ApiClientsService,
		NwuiSession,
		INTERCOM_APPID,
		NWUI_AUTH_EVENTS,
		NwuiAuthService,
		NwuiLocalizeService,
		localeEvents,
		locale
	) {
		
		////console.log('MainController', 'Loaded');

		var loadResources = function() {
			var deferred = $q.defer()
		
			ApiClientsService.getResources().then(function(obj) {
				deferred.resolve(obj.data);
			});

			return deferred.promise;
		}

		$scope.data = {
			resources: undefined
		}

		$scope.auth_modal = {
			open: false,
			locked: true,
			show_signup: organization_data.port_data.auth.sign_up,
			show_referral_source: organization_data.port_data.auth.referral_source,
			show_company_signup: organization_data.port_data.auth.company
		}

		$scope.intercom_booted = false

		var bootIntercom = function (user) {
			if(window.Intercom) {
				if(NwuiSession && NwuiSession.user && NwuiSession.user.uuid) {
					if (!$scope.intercom_booted) {
						window.Intercom('shutdown')
						let urlParams = $location.search();
						setTimeout(() => {
							window.Intercom('boot', {
								app_id: INTERCOM_APPID,
								language_override: urlParams.lang ? urlParams.lang : 'en-CA',
								user_id: NwuiSession.user.uuid,
								user_hash: NwuiSession.user.intercom_hash || undefined,
								email: NwuiSession.user.email,
								name: NwuiSession.user.first_name + " " + NwuiSession.user.last_name
							});
						})
					}
					$scope.intercom_booted = true;
				} else {
					window.Intercom('shutdown');
				}

				window.Intercom('hide');

				window.Intercom('onShow', function() {
					////console.log('MainController', 'onShow');
					$scope.$parent.intercom_overlay = true;
					$scope.$parent.data.intercom_open = true;
					$scope.$apply();
				});

				window.Intercom('onHide', function() {
					////console.log('MainController', 'onHide');
					$scope.$parent.intercom_overlay = false;
					$scope.$parent.data.intercom_open = false;
					$scope.$apply();
				});
			}

		}

		$scope.closeIntercom = function() {
					// $intercom.hide();
					if(window.Intercom) {
						window.Intercom('hide');
					}
					$scope.intercom_overlay = false;
			}
			
			// $intercom.$on('hide', function() {
			//     $scope.intercom_overlay = false;
			//     $scope.data.intercom_open = false;
			// })

			// if(window.Intercom) {
			 //  window.Intercom('onHide', function() {
			 //  	$scope.intercom_overlay = false;
			//     $scope.data.intercom_open = false;
			//     $scope.$apply();
			 //  });
			 // }
		
		$rootScope.$on(NWUI_AUTH_EVENTS.ssoDone, function () {
			$scope.user = {
				id: NwuiSession.user.id,
				first_name: NwuiSession.user.first_name,
				last_name: NwuiSession.user.last_name,
				email: NwuiSession.user.email,
				role_id: NwuiSession.user.role_id,
				client_id: NwuiSession.user.client_id,
				organization_id: NwuiSession.user.organization_id
			}
			
			bootIntercom();
		})
				 
		$rootScope.$on(NWUI_AUTH_EVENTS.authDone, function() {
			
			$scope.user = {
				id: NwuiSession.user.id,
				first_name: NwuiSession.user.first_name,
				last_name: NwuiSession.user.last_name,
				email: NwuiSession.user.email,
				role_id: NwuiSession.user.role_id,
				client_id: NwuiSession.user.client_id,
				organization_id: NwuiSession.user.organization_id
			}
			
			bootIntercom();
				
			$scope.authed = true;

			if(!NwuiSession.user.client_id) return;

			loadResources().then(function(obj) {
				$scope.data.resources = obj.resource;
			})
			
		});

		$rootScope.$on(NWUI_AUTH_EVENTS.authInactivityTimeout, function() {
			NwuiAuthService.logout().then(function(res) {
				$state.go('app.login');
				$timeout(function() {
					$scope.auth_modal.open = true;
					bootIntercom();
					//$scope.user = undefined;
				}, 250);
			});

		});
		
		$rootScope.$on(NWUI_AUTH_EVENTS.logoutSuccess, function() {
			NwuiAuthService.logout().then(function(res) {
				$rootScope.returnToState = null;
				$rootScope.returnToParams = null;
				$timeout(function() {
					$scope.auth_modal.open = true;
					bootIntercom();
				}, 250);
			});
		});
			
		$rootScope.$on(NWUI_AUTH_EVENTS.loginSuccess, function(e, opts) {
			// we have to load our translations for both angular 1 and 2 methods
			// the proper solution is to combine these in some way but for now following suit
			// with the way its currently done on load (both items loading separatly)
			NwuiLocalizeService.getAllLangStrings().forEach(lang => locale.loadBundle(lang, true));
			NwuiLocalizeService.updateAllLangsFromAPI().take(1).subscribe(() => {
				$timeout(() => {
					$scope.auth_modal.open = false;
					var state = ($rootScope.returnToState)
						? $rootScope.returnToState.name
						: 'app.summary';
	
					if(!opts.query_params || opts.query_params.length === 0)
						$state.go(state, $rootScope.returnToParams);
					else {
						window.location.href = 'summary' + opts.query_params
					}
					bootIntercom();
				}, 750)
			});
		});

	}
]);