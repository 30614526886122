angular.module('portfolio_web.transfers.overview.directives', [
	'nwui-toolkit',
	'portfolio_web.api'
])

.directive('nwTransfersContent', [
	function() {
		return {
			restrict: 'E',
	        replace: true,
	        templateUrl: 'portfolio_webserver/content/transfers.html',
	        scope: false,
	        link: function(scope, element, attrs) {}
	    }
	}
])

.directive('nwTransfersModals', [
	function() {
		return {
			restrict: 'E',
	        replace: true,
	        templateUrl: 'portfolio_webserver/partials/transfers/modals.html',
	        scope: false,
	        link: function(scope, element, attrs) {}
	    }
	}
])