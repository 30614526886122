angular.module('portfolio_web.household.controllers', [
	'nwui-toolkit',
	'portfolio_web.api',
])

.controller('HouseholdController', [
	'$scope',
	'$state',
	'NwuiCardHeaderV2Factory',
	'NwuiButtonV3Factory',
	'NwuiCardBigNumberFactory',
	'$filter',

	'ResolveAccounts',
	'ResolveHouseholds',
	function(
		$scope,

		$state,
		NwuiCardHeaderV2Factory,
		NwuiButtonV3Factory,
		NwuiCardBigNumberFactory,
		$filter,

		ResolveAccounts,
		ResolveHouseholds
	) {

		/**
		 * General HH data
		 */
		$scope.household = ResolveHouseholds;
		$scope.hasHouseholds = !!(ResolveHouseholds);

		var householdHoldings = ResolveAccounts.reduce(function(prev, cur){
			return prev + cur.total_holdings;
		}, 0);

		/**
		 * Members & Accounts Table data and configs
		 */
		var tableColumns = [{
			name_i18n: 'household.members.accounts.col_0',
			directive: 'text',
			sortable: false,
			width: 200
		},{
			name_i18n: 'household.members.accounts.col_1',
			directive: 'text',
			sortable: false,
			width: 200
		},{
			name_i18n: 'household.members.accounts.col_2',
			directive: 'text',
			sortable: false,
			width: 200
		},{
			name_i18n: 'household.members.accounts.col_3',
			directive: 'text',
			sortable: false,
			width: 125
		}];

		var tableHeader = function(user) {
			return {
 				name: user.first_name + ' ' + user.last_name,
				level: 2,
				icon: {
					name: 'person'
				}
			}
		};
			
		var membersTables = ResolveAccounts.map(function(member, i) {
			var memberAccountTable = {
				header: tableHeader(member),
				table: {
					id: 'household-member-accounts-' + i,
					empty_text_i18n: 'cards.activities.table.empty_text_i18n',
					columns: tableColumns,
					rows: member.Accounts.map(function(account){
							return {
								columns: [{
									text: account.account_type
								},{
									text: account.custodian_id
								},{
									text: $filter('currency')(account.market_value)
								},{
									text: account.status,
								}]
							}
						})
				},
			}

			return memberAccountTable
		})

		/**
		 * Scope cards
		 */
		$scope.cards = {
			empty:{
				button: new NwuiButtonV3Factory({
					text_i18n: 'accounts.view.empty.button',
					icon: {
						name: 'arrow_back'
					},
					onClick: function() {
						$state.go('app.summary');
					}
				})
			},
			total_holdings: {
				header: new NwuiCardHeaderV2Factory({
					name_i18n: 'household.view.holdings.title',
					level: 2,
					icon: {
						name: 'account_balance'
					}
				}),
				big_number: new NwuiCardBigNumberFactory({
					filter: 'currency',
					value: householdHoldings
				})
			},
			members: membersTables
		};
	}
]);
