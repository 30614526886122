angular.module('portfolio_web.api.esignevents.services', [])

.service('ApiEsignEventService', [
	'$rootScope', 
	'$http', 
	'$q', 
	'$timeout', 
	'$stateParams',
	'NwuiSession', 
	'PORTFOLIO_WEBSERVER',
	function(
		$rootScope, 
		$http, 
		$q, 
		$timeout, 
		$stateParams,
		NwuiSession,
		PORTFOLIO_WEBSERVER
	) {

		this.validateEsignEvent = function(id, code) {
	
			return $http.post(PORTFOLIO_WEBSERVER.app + '/api/esign-events/' + id + '/validate', { accessCode: code });
			
		}

		this.archiveEsignEvent = function(id) {

			return $http.post(PORTFOLIO_WEBSERVER.app + '/api/esign-events/' + id + '/archive', { });

		}

	}
]);