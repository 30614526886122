angular.module('portfolio_web.manage.plan_preferences.config', [
	'ui.router'
])

.config([
	'$stateProvider',
	'NWUI_AUTH_ROLES',
	function(
		$stateProvider,
		NWUI_AUTH_ROLES
	) {

		var plan_preferences = {
			url : '/plan-preferences',
			templateUrl: 'portfolio_webserver/manage/plan_preferences/template.html',
			controller : 'ManagePlanPreferencesController',
			params: {},
			data: {
				page_title_i18n: 'nav.plan_preferences',
				icon_name: 'tune',
				breadcrumb_label_i18n: 'nav.plan_preferences',
				auth_roles: [NWUI_AUTH_ROLES.user]
			}
		}

		$stateProvider.state('app.manage.plan_preferences', plan_preferences);

	}
]);