angular.module('portfolio_web.transfers.new.type.destination.form.config', [
  'ui.router'
])

.config([
  '$stateProvider', 
  '$locationProvider', 
  '$urlRouterProvider', 

  'NWUI_AUTH_ROLES',
  function(
    $stateProvider, 
    $locationProvider, 
    $urlRouterProvider, 

    NWUI_AUTH_ROLES
  ) {

    var route = {
      controller : 'TransfersNewTypeDestinationFormController',
      url : '/form',
      params: {},
      templateUrl: 'portfolio_webserver/transfers/new/type/destination/form/template.html',
      data: {
        page_title_i18n: 'transfers.new.nav.form',
        icon_name: 'compare_arrows',
        breadcrumb_label: false,
        auth_roles: [NWUI_AUTH_ROLES.user]
      },
      onEnter: [
        '$timeout',
        function(
          $timeout
        ) {
          // $timeout(function() {
          //   $('html, body').stop(false, false).animate({
          //       scrollTop: $("#app-transfers-new-type-destination-form").offset().top - 140
          //   }, 500);
          // }, 500)
        }
      ],
      resolve: {
        ResolveEftAccountOptions: [
          '$q',
          '$stateParams',
          '$filter',
          'EftService',
          function(
            $q,
            $stateParams,
            $filter,
            EftService
          ) {
            var deferred = $q.defer();

            switch($stateParams.transfer_type) {
              case 'chequing-savings':
                var options = [];
                EftService.getEftsAccounts().then(function(obj) {

                  obj.data.forEach(function(account_eft, k) {
                    if($stateParams.account_id === account_eft.id) {
                      account_eft.EFTs.forEach(function(eft, l) {

                        options.push({
                          id: $stateParams.account_id + '-eft-' + k + '-' + l,
                          text_i18n: (eft.source === 'integration') ? 'nwui.common.input.source_account.option_integrated' : 'nwui.common.input.source_account.option_integrated',
                          text_i18n_data: {
                            accountNickname: eft.nickname,
                            accountWebData: eft.web_data,
                            accountNumber: eft.bank_info,
                            accountBalance: eft.bank_balance ? ': ' + $filter("currency")(eft.bank_balance) : ''
                          },
                          value: eft.id
                        });

                      })
                    }
                  });

                  options.push({
                    id: 'eft-add-new',
                    text_i18n: 'nwui.common.input.source_account.new_account',
                    value: 'add-new'
                  })

                  deferred.resolve(options);

                });
                break;
              default:
                deferred.resolve([]);
            }

            return deferred.promise;

          }
        ]
      }
    }

    $stateProvider.state('app.transfers.new.type.destination.form', route);

  }
]);