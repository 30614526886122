angular.module('portfolio_web.main', [
	'portfolio_web.main.config',
	'portfolio_web.main.constants',
	'portfolio_web.main.controllers',
	'portfolio_web.main.directives',
	'portfolio_web.main.run',
	'portfolio_web.main.values',
	'portfolio_web.main.filters',

	'portfolio_web.login',
	'portfolio_web.summary',
	'portfolio_web.accounts',
	'portfolio_web.transfers',
	'portfolio_web.activity',
	'portfolio_web.household',
	'portfolio_web.manage',
	'portfolio_web.api',

	'portfolio_web.legacy'
]);