angular.module('portfolio_web.household.config', [
	'ui.router'
])

.config([
	'$stateProvider', 
	'$locationProvider', 
	'$urlRouterProvider',
	'NWUI_AUTH_ROLES',
	function(
		$stateProvider, 
		$locationProvider, 
		$urlRouterProvider,
		NWUI_AUTH_ROLES
	) {

		var household = {
			controller : 'HouseholdController',
			url : '/household',
			templateUrl: 'portfolio_webserver/household.html',
			params: {
				transaction_id: null
			},
			data: {
				page_title: 'household',
				page_title_i18n: 'nav.household',
				icon_name: 'location_city',
				auth_roles: [NWUI_AUTH_ROLES.user]
			},
			resolve: {
				ResolveHouseholds: [
					'$q',
					'$stateParams',
					'ApiHouseholdService', 
					function(
						$q,
						$stateParams, 
						ApiHouseholdService
					) {
						var deferred = $q.defer();
						
						ApiHouseholdService.getHouseholdDetails().then(function(household) {
							deferred.resolve(household.data);
						});
						
						return deferred.promise;
					}
				],
				ResolveAccounts: [
					'$q',
					'$stateParams',
					'ApiHouseholdService', 
					function(
						$q,
						$stateParams, 
						ApiHouseholdService
					) {
						var deferred = $q.defer();
						
						ApiHouseholdService.getHouseholdAccounts().then(function(household) {
							deferred.resolve(household.data);
						});
						
						return deferred.promise;
					}
				]
			}
		}

		$stateProvider.state('app.household', household);

	}
]);
