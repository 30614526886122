angular.module('portfolio_web.summary.controllers', [
	'nwui-toolkit',
	'portfolio_web.api',
	'portfolio_web.main.constants',
	'ngCookies'
])

.controller('SummaryController', [
	'$scope',
	'$q',
	'$state',
	'$timeout',
	'$cookies',
	'ResolveResources',
	'NwuiSession',
	'NwuiButtonV3Factory',
	'NwuiModalV2Factory',
	'AppService',
	function(
		$scope,
		$q,
		$state,
		$timeout,
		$cookies,
		ResolveResources,
		NwuiSession,
		NwuiButtonV3Factory,
		NwuiModalV2Factory,
		AppService
	) {
		var alwaysShowFundingButton = lodashGet(organization_data, 'port_data.summary.ui.always_show_funding_button', true)
		var showFundingInfoButton = NwuiSession.user.clients
			? NwuiSession.user.clients.some(client => client.is_open_at_custodian)
			: NwuiSession.user.is_open_at_custodian;
		$scope.data = {
			user: NwuiSession.user,
			has_funding_enhancements: lodashGet(organization_data, 'port_data.summary.ui.funding_styles', false),
			show_funding_card: lodashGet(organization_data, 'port_data.summary.ui.show_funding_card', false),
			showFundingInfoButton: alwaysShowFundingButton || showFundingInfoButton,
			info: {
				button: new NwuiButtonV3Factory({
					id: 'funding-info-button',
					text_i18n: 'summary.funding.info.button.funding',
					size: 'large',
					onClick: function() {
						$state.go('app.transfers.overview');
					}
				})
			},
			popupNotifications: [],
			popupNotifsVisitedThisVisit: []
		}
		
		if( !NwuiSession.user.isImpersonating ) {
			// We wanna make sure users that are logged in but re-visit the summary page get the popup notifs
			AppService.getUserTombstone().then(ts => {
				organization_data.popup_notifications.filter(popupNotif =>
					// its not expired
					(
						!popupNotif.expiry_date || 
						!moment().isSameOrAfter(moment(popupNotif.expiry_date, 'DD MM YYYY'), 'date')
					) 
					&&
					// and its not been visited
					(
						!ts.data.visitedPopupNotifications || 
						ts.data.visitedPopupNotifications.indexOf(popupNotif.name) === -1
					)
				).forEach((popupNotif, ind) => {
					$scope.data.popupNotifications.push({
						"index"	: ind,
						"name"	: popupNotif.name,
						"body"	: `modals.popup_notifications.${popupNotif.name}.body`,
						"footer": `modals.popup_notifications.${popupNotif.name}.footer`,
						"modal"	: new NwuiModalV2Factory({
							open: ind === 0? true: false, // only open the first popup by default - closing popups will open others in sequence
							locked: false,
							wide: true,
							header: {
								name_i18n: `modals.popup_notifications.${popupNotif.name}.header`,
								icon: {
									name: 'info'
								}
							}
						})
					})
				});
			});
		}
		
		$scope.hasResource = function(slug) {
			if(data.resources === undefined) return false;
			return data.resources.indexOf(slug) !== -1
		}

		$scope.isEnabled = function() {
			if(
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.ui &&
				organization_data.port_data.ui.enable === false
			) {
				return false
			}
			return true;
		}

		$scope.popupNotifModalOpenChange = (notif) => {
			//we only care if the popup was closed
			if(notif.modal.open === true) {
				closeAndFlagSeenPopupNotification(notif);
				const nextPopupNotifInd = notif.index+1;
				const nextPopupNotif = $scope.data.popupNotifications[nextPopupNotifInd];
				if(nextPopupNotif) { // if we have a next popup to view - view it
					openPopupNotif(nextPopupNotif);
				} else { // we have no more popups to show
					// we can update our seen popups in tombstone
					updateVisitedPopupNotifs();
				}
			}
		}
		const openPopupNotif = (notif) => {
			$timeout(function() {
				notif.modal.open = true;
			}, 250);
		}
		const closeAndFlagSeenPopupNotification = (notif) => {
			notif.modal.open = false; // close current modal
			$scope.data.popupNotifsVisitedThisVisit.push(notif.name);
		}

		// We're batch updating our visited popups after all have been seen
		// perhaps we should do them individually?
		const updateVisitedPopupNotifs = () => {
			const alreadyVisitedPopups = AppService.data.tombstone && AppService.data.tombstone.data.visitedPopupNotifications? AppService.data.tombstone.data.visitedPopupNotifications : [];
			AppService.updateTombstone({
				visitedPopupNotifications: [...alreadyVisitedPopups, ...$scope.data.popupNotifsVisitedThisVisit]
			});
		}
		var data = {
			resources: ResolveResources.resource.summary
		}
	}
]);
